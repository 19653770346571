import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import StatusHandlerContainer from "./StatusHandlerContainer";
import Skeleton from "@mui/material/Skeleton";
import { LogoContainer, LogoImg } from "components/MessageCard/MessageCard.components";
import outputLogoStripes from "components/DrawerMenu/outputLogoStripes.svg";

function LoadingFallback(props: { animation?: false | "pulse"; message?: string }) {
  const { animation = false, message } = props;
  const { t } = useTranslation();

  return (
    <StatusHandlerContainer>
      <Box display="flex" justifyContent="center" alignItems="center" position="relative">
        <Skeleton
          animation={animation}
          variant="circular"
          width="32px"
          height="32px"
          sx={(theme) => ({
            position: "absolute",
            background: theme.palette.primary.main,
          })}
        />
        <LogoContainer
          sx={{
            padding: 0,
            zIndex: 1,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <LogoImg height={24} width={24} src={outputLogoStripes} alt="Output Sports Stripes Logo" />
        </LogoContainer>
      </Box>

      <Typography>{message || t("DashboardsHome.loadingData")}</Typography>
    </StatusHandlerContainer>
  );
}

export default LoadingFallback;
