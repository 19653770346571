import { alpha, styled } from "@mui/material";
import Paper from "components/mui5/Paper";
import Skeleton from "@mui/material/Skeleton";

export const CardPaper = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(0, 0),
  padding: theme.spacing(2.5, 3),
  display: "flex",
  justifyContent: "stretch",
  alignItems: "center",
  height: "56px",
  borderBottom: `1px solid ${alpha(theme.palette.grey[400], 0.3)}`,
  "&:last-of-type": {
    borderBottom: "none",
  },
}));

export const EmptySkeleton = styled(Skeleton)(({ theme }) => ({
  background: theme.palette.grey[100],
}));
