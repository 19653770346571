import DashboardsHome from "./DashboardsHome/DashboardsHome.translations-en.json";
import DashboardFilters from "features/Dashboards/DashboardsHome/DashboardFilters/DashboardFilters.translations-en.json";
import DashboardSelectorsPermissionsToggle from "features/Dashboards/DashboardsHome/DashboardSelectors/DashboardSelectorsPermissionsToggle/DashboardSelectorsPermissionsToggle.translations-en.json";
import DashboardSelectorsMoreMenu from "features/Dashboards/DashboardsHome/DashboardSelectors/DashboardSelectorsMoreMenu/DashboardSelectorsMoreMenu.translations-en.json";
import DashboardConfirmDeleteDialog from "features/Dashboards/DashboardsHome/DashboardSelectors/DashboardSelectorsMoreMenu/DashboardConfirmDeleteDialog/DashboardConfirmDeleteDialog.translations-en.json";
import DashboardNoDataWrapper from "./providers/DashboardProvider/DashboardNoDataWrapper/DashboardNoDataWrapper.translations-en.json";
import DashboardEmptyWidget from "./DashboardsHome/DashboardWidgetFactory/DashboardEmptyWidget/DashboardEmptyWidget.translations-en.json";
import AddWidgetDialog from "./DashboardsHome/DashboardWidgetFactory/DashboardEmptyWidget/AddWidgetDialog/AddWidgetDialog.translations-en.json";
import AthleteProgressChartWidget from "./DashboardsHome/DashboardWidgetFactory/Widgets/AthleteWidgets/AthleteProgressChartWidget/AthleteProgressChartWidget.translations-en.json";
import Widgets from "./DashboardsHome/DashboardWidgetFactory/Widgets/Widgets.translations-en.json";
import AddWidgetSelectWidget from "./DashboardsHome/DashboardWidgetFactory/DashboardEmptyWidget/AddWidgetDialog/AddWidgetSelectWidget/AddWidgetSelectWidget.translations-en.json";
import DashboardDuplicateToEditBar from "./DashboardsHome/DashboardDuplicateToEditBar/DashboardDuplicateToEditBar.translations-en.json";
import TooltipProgressTitle from "./DashboardsHome/DashboardWidgetFactory/components/Tooltips/TooltipProgressTitle.translations-en.json";
import LegendPersonalBest from "./DashboardsHome/DashboardWidgetFactory/Widgets/AthleteWidgets/AthleteProgressChartWidget/components/LegendPersonalBest/LegendPersonalBest.translations-en.json";
import AthleteGroupHorizontalBarChart from "./DashboardsHome/DashboardWidgetFactory/Widgets/AthleteWidgets/AthleteGroupHorizontalBarChart/AthleteGroupHorizontalBarChart.translations-en.json";
import LegendTarget from "./DashboardsHome/DashboardWidgetFactory/Widgets/AthleteWidgets/AthleteProgressChartWidget/components/LegendTarget/LegendTarget.translations-en.json";
import AthleteGroupNumerical from "./DashboardsHome/DashboardWidgetFactory/Widgets/AthleteWidgets/AthleteGroupNumerical/AthleteGroupNumerical.translations-en.json";
import DashboardWelcomePopup from "./DashboardsHome/DashboardWelcomeMenuDialog/DashboardWelcomePopup.translations-en.json";
import GaugeChart from "./DashboardsHome/DashboardWidgetFactory/Widgets/AthleteWidgets/AthleteTargetGaugeWidget/components/GaugeChart.translations-en.json";
import CreateDashboardDialog from "./providers/CreateDashboardProvider/CreateDashboardDialog/CreateDashboardDialog.translations-en.json";
import WidgetEditMenu from "./DashboardsHome/DashboardWidgetFactory/components/WidgetEditMenu/WidgetEditMenu.translations-en.json";
import GroupKpiAbsolute from "./DashboardsHome/DashboardWidgetFactory/Widgets/GroupWidgets/GroupKpiAbsolute/GroupKpiAbsolute.translations-en.json";
import GroupKpiRelative from "./DashboardsHome/DashboardWidgetFactory/Widgets/GroupWidgets/GroupKpiRelative/GroupKpiRelative.translations-en.json";
import GroupKpiAbsoluteTooltip from "./DashboardsHome/DashboardWidgetFactory/Widgets/GroupWidgets/GroupKpiAbsolute/components/Tooltip/GroupKpiAbsoluteTooltip.translations-en.json";
import WidgetMoreActions from "./DashboardsHome/DashboardWidgetFactory/components/WidgetMoreActions/WidgetMoreActions.translations-en.json";
import GroupKpiRelativeTooltip from "./DashboardsHome/DashboardWidgetFactory/Widgets/GroupWidgets/GroupKpiRelative/components/Tooltip/GroupKpiRelativeTooltip.translations-en.json";
import GroupDashboardOnBoardingTooltip from "./DashboardsHome/DashboardWidgetFactory/Widgets/GroupWidgets/GroupDashboardOnBoardingTooltip/GroupDashboardOnBoardingTooltip.translations-en.json";
import GroupKpiAbsoluteTarget from "./DashboardsHome/DashboardWidgetFactory/Widgets/GroupWidgets/GroupKpiAbsoluteTarget/GroupKpiAbsoluteTarget.translations-en.json";

const DashboardsTranslationsEn = Object.assign(
  {
    Dashboards: {
      loadingMessage: "Loading Dashboards...",
    },
  },
  AddWidgetDialog,
  AddWidgetSelectWidget,
  AthleteGroupHorizontalBarChart,
  AthleteGroupNumerical,
  AthleteProgressChartWidget,
  CreateDashboardDialog,
  DashboardConfirmDeleteDialog,
  DashboardDuplicateToEditBar,
  DashboardEmptyWidget,
  DashboardFilters,
  DashboardNoDataWrapper,
  DashboardSelectorsMoreMenu,
  DashboardSelectorsPermissionsToggle,
  DashboardsHome,
  DashboardWelcomePopup,
  GaugeChart,
  GroupDashboardOnBoardingTooltip,
  GroupKpiAbsolute,
  GroupKpiAbsoluteTarget,
  GroupKpiRelative,
  GroupKpiAbsoluteTooltip,
  GroupKpiRelativeTooltip,
  LegendPersonalBest,
  LegendPersonalBest,
  LegendTarget,
  TooltipProgressTitle,
  WidgetEditMenu,
  WidgetMoreActions,
  Widgets
);

export default DashboardsTranslationsEn;
