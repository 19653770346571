import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import VerticalPageLayout from "components/VerticalPageLayout";
import PageContentTitle from "components/PageContent/PageContentTitle";
import LeaderboardTables from "./LeaderboardTables";
import LeaderboardsProvider from "./providers/LeaderboardsProvider";
import ShowLeaderboardCreateButton from "./ShowLeaderboardCreateButton";
import LeaderboardControlsProvider from "./providers/LeaderboardControlsProvider";
import * as Components from "./Leaderboards.components";
import { useCallback, useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Modal from "components/Modal";
import LeaderboardsInfoCard from "./LeaderboardsInfoCard";
import { useTranslation } from "react-i18next";
import logEvent from "utils/logEvent";

interface LeaderboardsProps {}

function Leaderboards(props: LeaderboardsProps) {
  const { t } = useTranslation();
  const [showInfoCard, setShowInfoCard] = useState(false);
  const toggleInfoCard = useCallback(() => {
    setShowInfoCard((prev) => {
      const updatedValue = !prev;

      if (updatedValue) logEvent("INFO_CARD_VIEW", { page: "leaderboards" });

      return updatedValue;
    });
  }, []);

  useEffect(() => {
    logEvent("LEADERBOARDS_VIEW_ALL");
  }, []);

  return (
    <>
      <LeaderboardsProvider>
        <LeaderboardControlsProvider>
          <VerticalPageLayout
            top={
              <Box p={2} pr={0}>
                <Grid container spacing={0} alignItems="center" justifyContent="flex-start">
                  <Grid item pr={0}>
                    <PageContentTitle title={t("Leaderboard.pageTitle")} />
                  </Grid>
                  <Grid item m={0} p={0} textAlign="left">
                    <IconButton size="small" color="primary" onClick={toggleInfoCard}>
                      <InfoOutlined fontSize="small" />
                    </IconButton>
                  </Grid>
                  <Grid item xs />
                  <Components.CreateButtonGrid item>
                    <ShowLeaderboardCreateButton />
                  </Components.CreateButtonGrid>
                </Grid>
              </Box>
            }
            main={<LeaderboardTables />}
          />
        </LeaderboardControlsProvider>
      </LeaderboardsProvider>
      <Modal open={showInfoCard} toggleOpen={toggleInfoCard}>
        <LeaderboardsInfoCard onClose={toggleInfoCard} />
      </Modal>
    </>
  );
}

export default Leaderboards;
