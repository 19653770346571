import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import SmsCodeInput from "./components/SmsCodeInput/SmsCodeInput";
import { Collapse } from "@mui/material";
import LoginForm from "./components/LoginForm/LoginForm";
import Div from "components/primitives/Div";
import Typography from "components/Typography";
import ThemeProvider2024 from "components/MuiThemeProvider/ThemeProvider2024";
import { FirebaseError } from "firebase/app";
import MicrosoftLogin from "./components/MicrosoftLogin/MicrosoftLogin";
import { useCallback, useMemo, useState } from "react";
import { MultiFactorError } from "firebase/auth";
import UserService from "services/UserService";
import MfaService from "services/MfaService";
import OutputError from "config/OuputError";
import { useTranslation } from "react-i18next";
import useRemoteConfig from "providers/RemoteConfigProvider/useRemoteConfig";

function Login(props: {
  setError(error: null | OutputError | MultiFactorError): void;
  error: null | OutputError | MultiFactorError;
}) {
  const { t } = useTranslation();
  const { setError, error } = props;
  const [showMfa, setShowMfa] = useState(false);

  const mfaService = useMemo(() => new MfaService("recaptcha-mfa-signin-container"), []);

  const clearError = useCallback(() => setError(null), [setError]);

  const catchSignInError = useCallback(
    async (error: MultiFactorError) => {
      if (UserService.currentUser) {
        await UserService.signOut();
      }

      setError(error);
    },
    [setError]
  );

  const onMfaFlowRequest = useCallback(
    async (error: MultiFactorError) => {
      if (error.code !== "auth/multi-factor-auth-required") throw error;

      await mfaService.init(error);

      setShowMfa(true);
    },
    [mfaService]
  );

  const onVerifyMfaCode = useCallback(
    async (smsCode: string) => {
      await mfaService.verifyCode(smsCode).catch(catchSignInError);
    },
    [catchSignInError, mfaService]
  );

  const onSignIn = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      clearError();

      await UserService.signIn(email, password) //
        .catch(onMfaFlowRequest)
        .catch(catchSignInError);
    },
    [clearError, catchSignInError, onMfaFlowRequest]
  );

  const onSignInWithMicrosoft = useCallback(async () => {
    clearError();

    await UserService.signInWithMicrosoft() //
      .catch(onMfaFlowRequest);
  }, [clearError, onMfaFlowRequest]);

  const onMfaCancel = useCallback(() => {
    setShowMfa(false);
  }, []);

  const HUB_ENABLE_MICROSOFT_SSO = useRemoteConfig("HUB_ENABLE_MICROSOFT_SSO");

  return (
    <Box bgcolor="primary.main">
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Stack>
          <Collapse in={!showMfa} unmountOnExit={false}>
            <LoginForm
              onSignIn={onSignIn}
              clearErrors={clearError}
              errorMessage={error?.message}
              errorCode={(error as FirebaseError)?.code || undefined}
            />
          </Collapse>
          <Collapse in={showMfa} unmountOnExit>
            <SmsCodeInput
              verifyCode={onVerifyMfaCode}
              resendCode={mfaService.requestVerificationCode}
              onCancel={onMfaCancel}
              hint={mfaService.hint}
              errorMessage={error?.message}
              errorCode={(error as FirebaseError)?.code || undefined}
            />
          </Collapse>
          {HUB_ENABLE_MICROSOFT_SSO && (
            <>
              <ThemeProvider2024>
                <Typography variant="TextM" sx={{ color: "common.white", p: 1 }} textAlign="center">
                  {t("orLabel")}
                </Typography>
              </ThemeProvider2024>
              <MicrosoftLogin onSignInWithMicrosoft={onSignInWithMicrosoft} setError={props.setError} />
            </>
          )}
          <Div id="recaptcha-mfa-signin-container" style={{ display: "none" }} />
        </Stack>
      </Box>
    </Box>
  );
}
export default Login;
