import useMuiField from "../../../components/Form/hooks/useMuiField";
import PropTypes from "prop-types";
import Checkbox from "../../../components/Inputs/MuiCheckbox";

function FormCheckbox(props) {
  const [fieldProps, , helpers] = useMuiField(props.name);

  return (
    <Checkbox
      label={props.label}
      {...fieldProps}
      color={props.color}
      disabled={props.disabled}
      onChange={() => helpers.setValue(!fieldProps.value)}
    />
  );
}

FormCheckbox.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormCheckbox;
