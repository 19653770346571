import { useMemo } from "react";
import ChartDataContext from "components/charting/contexts/ChartDataContext";
import useChartSelections from "components/charting/hooks/useChartSelections";
import ChartColorRegistryProvider from "features/Charting/providers/ChartColorRegistryProvider";
import { MetadataType, Wellness, WellnessMetadata } from "types/index";
import { LineData } from "components/charting/types/Chart.types";
import useChartLineAggregator from "features/Charting/hooks/useChartLineAggregator";
import useChartLineVariantSeparator from "features/Charting/hooks/useChartLineVariantSeparator";
import useChartDataApi from "features/Charting/hooks/useChartDataApi";
import useValidChartMeasures from "features/Charting/hooks/useValidChartMeasures";
import useChartSourceIds from "./hooks/useChartSourceIds";
import useChartEntityIds from "./hooks/useChartEntityIds";
import useChartLineCommonPropertiesCalculator from "features/Charting/hooks/useChartLineCommonPropertiesCalculator";
import ChartDataActionsContext from "components/charting/contexts/ChartDataActionsContext";
import ChartPersonalBestsContext from "components/charting/contexts/ChartPersonalBestsContext";
import useChartLinePersonalBest from "features/Charting/hooks/useChartLinePersonalBest";

interface LineChartDataProviderProps {
  children?: React.ReactNode;
}

function LineChartDataProvider(props: LineChartDataProviderProps) {
  const selections = useChartSelections();

  const sourceIds = useChartSourceIds();
  const validMeasures = useValidChartMeasures();
  const entityIds = useChartEntityIds();
  const [measureData, measureDataActions] = useChartDataApi({
    sourceIds,
    measures: validMeasures,
    startDate: selections.startDate,
    endDate: selections.endDate,
    period: selections.period,
  });

  const chartData = useMemo(() => {
    const lines = sourceIds.reduce<LineData[]>(
      (acc, sourceId) => [
        ...acc,
        ...selections.measures
          .filter((measure) =>
            measureData.find((dataset) => dataset.metadataId === measure.id && dataset.sourceId === sourceId)
          )
          .map((measure) => {
            const dataset = measureData.find(
              (dataset) => dataset.metadataId === measure.id && dataset.sourceId === sourceId
            );

            if (typeof dataset === "undefined") {
              throw new Error("Dataset cannot be undefined as empty datasets have been pre-filtered out of the array");
            }

            const lineProperties: Omit<LineData, "line" | "metadataType" | "sortOrder"> = {
              entityId:
                sourceIds.length > 1
                  ? dataset.sourceId
                  : [dataset.metadataId, measure.metric?.field].filter(Boolean).join("-"),
              source: { id: dataset.sourceId },
              measure: { id: dataset.metadataId, metricField: measure.metric?.field || null },
            };

            if (dataset.metadataType === MetadataType.WELLNESS) {
              return {
                ...lineProperties,
                metadataType: MetadataType.WELLNESS as const,
                sortOrder: "desc" as const,
                line: dataset.measurements
                  .map((measurement) => {
                    return {
                      ...lineProperties,
                      metadataType: MetadataType.WELLNESS as const,

                      x: new Date(measurement.measurementDate),
                      y: measurement[(measure.metadata as WellnessMetadata)?.field as keyof Wellness] as number,
                      measurement,
                    };
                  })
                  .filter((point) => point.y), // filter out empty / null document values from chart
              };
            }

            if (dataset.metadataType === MetadataType.ANTHROPOMETRIC) {
              return {
                ...lineProperties,
                metadataType: MetadataType.ANTHROPOMETRIC as const,
                sortOrder: "desc" as const,
                line: dataset.measurements.map((measurement) => {
                  return {
                    ...lineProperties,
                    metadataType: MetadataType.ANTHROPOMETRIC as const,
                    x: new Date(measurement.measurementDate),
                    y: measurement.value,
                    measurement,
                  };
                }),
              };
            }

            const exerciseMetric = measure.metric;
            const exerciseMetricField = measure.metric?.field;
            const sortOrder: "asc" | "desc" = exerciseMetric?.sortType === 1 ? "desc" : "asc";

            return {
              ...lineProperties,
              metadataType: MetadataType.EXERCISE as const,
              sortOrder,
              line: dataset.measurements
                .filter((measurement) => {
                  const metricValue = measurement.metrics.find((m) => m.field === exerciseMetricField)?.value;
                  return typeof metricValue !== "undefined" && metricValue !== null;
                })
                .map((measurement) => {
                  return {
                    ...lineProperties,
                    metadataType: MetadataType.EXERCISE as const,
                    variant: measurement.variant || undefined,
                    x: new Date(measurement.completedDate),
                    y: measurement.metrics.find((m) => m.field === exerciseMetricField)?.value!,
                    measurement,
                  };
                }),
            };
          }),
      ],
      []
    );

    return lines;
  }, [measureData, selections.measures, sourceIds]);

  const separatedVariantChartLines = useChartLineVariantSeparator(chartData, entityIds);
  const withCommonProperties = useChartLineCommonPropertiesCalculator(separatedVariantChartLines);
  const aggregatedChartLines = useChartLineAggregator(withCommonProperties);
  const { data, personalBests } = useChartLinePersonalBest(aggregatedChartLines);

  return (
    <ChartDataActionsContext.Provider value={measureDataActions}>
      <ChartDataContext.Provider value={data}>
        <ChartPersonalBestsContext.Provider value={personalBests}>
          <ChartColorRegistryProvider entityIds={entityIds}>{props.children}</ChartColorRegistryProvider>
        </ChartPersonalBestsContext.Provider>
      </ChartDataContext.Provider>
    </ChartDataActionsContext.Provider>
  );
}

export default LineChartDataProvider;
