import { useCallback, useRef } from "react";
import * as Components from "./ProfilePhotoInput.components";
import useLoading from "hooks/useLoading";
import ButtonBase from "@mui/material/ButtonBase";
import Loader from "components/Loader/Loader";
import ErrorBoundary from "components/ErrorBoundary";

export interface ProfilePhotoInputProps {
  /**
   * Reference: {@link|https://stackoverflow.com/a/65877297}
   */
  customRef?: React.RefObject<HTMLInputElement>;
  size?: "medium" | "large";
  imageUrl?: string | null;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  onDelete: (event: React.MouseEvent<HTMLDivElement>) => Promise<void>;
}

function ProfilePhotoInput(props: ProfilePhotoInputProps) {
  const { imageUrl, onChange, onDelete, size = "medium", customRef } = props;
  const defaultInputRef = useRef<HTMLInputElement>(null);
  const [loading] = useLoading();

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      await onChange(event);
    },
    [onChange]
  );

  const handleDelete = useCallback(
    async (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      if (defaultInputRef.current) {
        defaultInputRef.current.value = "";
      }

      await onDelete(event);
    },
    [onDelete]
  );

  const openFileInput = useCallback(() => {
    customRef?.current?.click();
    defaultInputRef?.current?.click();
  }, [customRef]);

  return (
    <ErrorBoundary>
      <Components.HiddenFileInput
        ref={customRef || defaultInputRef}
        data-testid="photo-file-input"
        type="file"
        accept="image/*"
        onChange={handleChange}
      />
      <Components.ProfilePhotoButtonBox
        component={ButtonBase}
        onClick={openFileInput}
        display="inline-block"
        position="relative"
      >
        {loading && <Loader isSmall />}
        <Components.ProfilePhoto size={size} aria-label="profile-image" photoUrl={imageUrl} />
        <Components.CameraIconButton component="div" size="small">
          <Components.CameraIcon />
        </Components.CameraIconButton>
        {Boolean(imageUrl) && (
          <Components.DeleteIconButton
            aria-label="delete-profile-image"
            component="div"
            size="small"
            onClick={handleDelete}
          >
            <Components.DeleteIcon />
          </Components.DeleteIconButton>
        )}
      </Components.ProfilePhotoButtonBox>
    </ErrorBoundary>
  );
}

export default ProfilePhotoInput;
