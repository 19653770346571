import { useMemo } from "react";
import type { Status } from "types/Status";

interface UseQueryStatusBuilderProps {
  isLoading: boolean;
  isError: boolean;
}

/**
 * @description
 * This hook is used to determine the status of a query. It should be used in conjunction with the useQuery hook.
 */
function useQueryStatusBuilder(props: UseQueryStatusBuilderProps): Status {
  const status = useMemo(() => {
    if (props.isLoading) {
      return "fetching";
    } else if (props.isError) {
      return "error";
    }
    return "success";
  }, [props.isLoading, props.isError]);

  return status;
}

export default useQueryStatusBuilder;
