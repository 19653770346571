import NotFound from "features/NotFound";
import { useCallback, useMemo, useState } from "react";

const errorPages = {
  "404": NotFound,
};

type UseErrorPagesProps = [undefined | ((props: any) => JSX.Element), (error: { response: { status: 404 } }) => void];

function useErrorPages(): UseErrorPagesProps {
  const [httpStatus, setHttpStatus] = useState<404 | undefined>(undefined);
  const ErrorPage = httpStatus && errorPages[httpStatus];

  const getStatusFromError = useCallback(
    (error: { response: { status: 404 } }) => setHttpStatus(error?.response?.status),
    []
  );

  return useMemo((): UseErrorPagesProps => [ErrorPage, getStatusFromError], [ErrorPage, getStatusFromError]);
}
export default useErrorPages;
