import { useMemo } from "react";
import type { Athlete } from "types/athlete";
import AthleteHelpers from "helpers/AthleteHelpers";
import useMakeProfileImageURL from "hooks/useMakeProfileImageURL";

interface UseAthleteProfilePhotoURL {
  src: string | undefined;
  alt: string | undefined;
  initials: string | undefined;
}

function useAthleteProfilePhotoURL(args: Athlete | undefined): UseAthleteProfilePhotoURL {
  const profilePath = useMemo(() => {
    if (typeof args === "undefined") return null;
    return AthleteHelpers.getProfileImagePath(args);
  }, [args]);

  const path = useMakeProfileImageURL(profilePath) ?? undefined;

  const alt = useMemo(() => {
    if (typeof args === "undefined") return undefined;
    return AthleteHelpers.getFullName(args);
  }, [args]);

  const initials = useMemo(() => {
    if (typeof args === "undefined") return undefined;
    return AthleteHelpers.getInitials(args);
  }, [args]);

  return useMemo(() => ({ src: path, alt, initials }), [path, alt, initials]);
}

export default useAthleteProfilePhotoURL;
