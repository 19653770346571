import { useCallback, useMemo, useState } from "react";
import Constants from "config/Constants";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import MuiLink from "components/Link";
import ColorableChip from "components/ColorableChip";
import * as Components from "./AthleteProfileGroups.components";
import useCanDo from "hooks/useCanDo";
import Mui5 from "components/mui5";
import { useTranslation } from "react-i18next";

interface AthleteProfileGroupsProps {
  max?: number;
  groups: {
    id: string;
    name: string;
    colour: string;
  }[];
}

function AthleteProfileGroups(props: AthleteProfileGroupsProps) {
  const { t } = useTranslation();
  const { max = 3, groups } = props;
  const canDo = useCanDo();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const renderGroup = useCallback(
    (group: { id: string; name: string; colour: string }) => {
      const chip = <ColorableChip label={group.name} customColor={group.colour} />;

      return (
        <Grid key={group.id} item>
          {canDo("group.any") ? (
            <MuiLink inheritColor to={`${Constants.routes.groups.route}/${group.id}`}>
              {chip}
            </MuiLink>
          ) : (
            chip
          )}
        </Grid>
      );
    },
    [canDo]
  );

  const open = Boolean(anchorEl);
  const id = open ? "more-athlete-groups-popover" : undefined;

  const visibleGroups = useMemo(() => groups.slice(0, max), [groups, max]);
  const otherGroups = useMemo(() => groups.slice(max), [groups, max]);

  return (
    <Box pt={2}>
      <Grid container spacing="8px" alignItems="center">
        {visibleGroups.map(renderGroup)}
        {Boolean(otherGroups.length) && (
          <Grid item>
            <Mui5.Button aria-describedby={id} onClick={handleClick} size="medium" variant="text">
              <Box component="span" color="grey.500" fontWeight="fontWeightRegular">
                {t("AthleteProfileGroups.showMoreButton", { count: otherGroups.length })}
              </Box>
            </Mui5.Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Components.PopoverBox p={2}>
                <Grid container spacing="8px" alignContent="center">
                  {otherGroups.map(renderGroup)}
                </Grid>
              </Components.PopoverBox>
            </Popover>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default AthleteProfileGroups;
