import Grid from "@mui/material/Grid";
import InfoCard from "components/InfoCard";
import InfoCardControlDescriptor from "components/InfoCard/InfoCardControlDescriptor";
import Tables from "components/Tables";
import GroupIcon from "components/Icons/GroupsIcon";
import Mui5 from "components/mui5";
import { useTranslation } from "react-i18next";
import NotePadIcon from "components/IconsStandardized/NotePadIcon/NotePadIcon";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

export interface GroupsInfoCardProps {
  onClose(): void;
}

function GroupsInfoCard(props: GroupsInfoCardProps) {
  const { t } = useTranslation();

  return (
    <InfoCard title={t("Group.All.infoCardHeaderText")} icon={<GroupIcon />} onClose={props.onClose}>
      <Grid container>
        <Grid item xs={12} pb={0}>
          <Typography gutterBottom variant="h4">
            {t("Group.All.infoCardDescription1")} {t("Group.All.infoCardDescription2")}
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="center" p={2}>
          <Mui5.Button
            fullWidth={false}
            sx={{ width: 320 }}
            size="medium"
            color="primary"
            LinkComponent="a"
            // @ts-ignore
            rel="noopener noreferrer"
            // @ts-ignore
            target="_blank"
            href="https://25970650.hs-sites-eu1.com/knowledge/what-are-groups"
          >
            {t("moreInfoButtonText")}
          </Mui5.Button>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 1 }} />
        </Grid>
        <Grid item xs={12}>
          <InfoCardControlDescriptor
            title={t("Group.All.infoCardTitle1")}
            control={
              <>
                <Mui5.Button size="small" sx={{ pointerEvents: "none" }}>
                  {t("Group.All.infoCardAddButtonText")}
                </Mui5.Button>
              </>
            }
            label={t("Group.All.infoCardLabel1")}
          />
        </Grid>
        <Grid item xs={12} pb={0.5}>
          <InfoCardControlDescriptor
            title={t("Group.All.infoCardTitle2")}
            control={<NotePadIcon fontSize="small" />}
            label={t("Group.All.infoCardLabel2")}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoCardControlDescriptor
            title={t("Group.All.infoCardTitle3")}
            control={<Tables.Icons.Delete />}
            label={t("Group.All.infoCardLabel3")}
          />
        </Grid>
      </Grid>
    </InfoCard>
  );
}

export default GroupsInfoCard;
