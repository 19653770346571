import { useCallback, useMemo } from "react";
import Tables from "../../../../../components/Tables";
import useGroups from "providers/GroupsProvider/useGroups";
import useMultiSelectIds from "providers/MultiSelectProvider/useMultiSelectIds";
import useRequest from "hooks/useRequest";
import difference from "lodash/difference";
import uniq from "lodash/uniq";
import { Group } from "types/groups";
import useWorkoutsActions from "features/Workouts/providers/WorkoutsProvider/useWorkoutsActions";
import useIntersectingGroupIds from "hooks/useIntersectingGroupIds";
import useEntityFilterById from "hooks/useEntityFilterById";
import IconPopperMenu from "components/IconPopperMenu";
import TableGroupAssignPopperContent from "components/Tables/TableGroupAssignPopperContent";
import WorkoutHelpers from "helpers/WorkoutHelpers";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import { AutocompleteChangeReason } from "@mui/material/Autocomplete";
import useFilteredWorkouts from "features/Workouts/providers/FilteredWorkoutsProvider/useFilteredWorkouts";

interface GroupAssignmentControlProps {
  tooltipTitle: string;
  accessRemovedWarning: string;
}

function AllWorkoutsBulkGroupAssignmentControl(props: GroupAssignmentControlProps) {
  const selectedWorkoutIds = useMultiSelectIds();
  const filteredWorkouts = useFilteredWorkouts();
  const workoutActions = useWorkoutsActions();
  const groups = useGroups();
  const bulkUpdateGroupId = useRequest(workoutActions.bulkUpdateGroupId);
  const removeWorkoutFromLocalStore = workoutActions.removeFromLocalStore;

  const visibleSelectedWorkouts = useEntityFilterById(selectedWorkoutIds, filteredWorkouts);
  const visibleSelectedWorkoutIds = useMemo(() => visibleSelectedWorkouts.map((a) => a.id), [visibleSelectedWorkouts]);
  const intersectingGroupIds = useIntersectingGroupIds(visibleSelectedWorkouts);
  const intersectingGroups = useEntityFilterById(intersectingGroupIds, groups);

  const handleGroupAssignment = useCallback(
    async (value: Group[], reason: AutocompleteChangeReason) => {
      let diffGroups: Group[];
      const newGroupIds: string[] = value.map((group) => group.id);

      if (reason === "selectOption") {
        diffGroups = difference(value, intersectingGroups);
        await bulkUpdateGroupId(visibleSelectedWorkoutIds, diffGroups[0].id, true);
      } else if (reason === "removeOption") {
        diffGroups = difference(intersectingGroups, value);
        await bulkUpdateGroupId(visibleSelectedWorkoutIds, diffGroups[0].id, false);
      }

      visibleSelectedWorkouts.forEach((workout) => {
        const updatedGroupIds = uniq([
          ...workout.groupIds.filter((id) => !intersectingGroupIds.includes(id)),
          ...newGroupIds,
        ]);

        if (WorkoutHelpers.willAccessBeRemoved(workout) && updatedGroupIds.length === 0) {
          removeWorkoutFromLocalStore(workout.id);
        }
      });
    },
    [
      visibleSelectedWorkouts,
      bulkUpdateGroupId,
      intersectingGroupIds,
      intersectingGroups,
      removeWorkoutFromLocalStore,
      visibleSelectedWorkoutIds,
    ]
  );

  const willAccessBeRemovedForAnyWorkouts = useMemo(
    () => visibleSelectedWorkouts.filter(WorkoutHelpers.willAccessBeRemoved).length === 1,
    [visibleSelectedWorkouts]
  );

  const accessRemovedAlert = useMemo(
    () =>
      willAccessBeRemovedForAnyWorkouts ? (
        <Alert severity="warning" variant="filled" icon={false} sx={{ m: 1, py: 0, px: 1 }}>
          <AlertTitle>Warning</AlertTitle>
          <Box maxWidth={288}>{props.accessRemovedWarning}</Box>
        </Alert>
      ) : undefined,
    [props.accessRemovedWarning, willAccessBeRemovedForAnyWorkouts]
  );

  return (
    <IconPopperMenu
      tooltipTitle={props.tooltipTitle}
      icon={<Tables.Icons.AssignGroups />}
      disabled={!Boolean(visibleSelectedWorkoutIds.length)}
      alert={accessRemovedAlert}
      popperContent={
        <TableGroupAssignPopperContent commonGroups={intersectingGroups} onChange={handleGroupAssignment} />
      }
    />
  );
}

export default AllWorkoutsBulkGroupAssignmentControl;
