import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { metadataAutocompleteFilterOptions } from "config/autocomplete";
import { memo, useCallback, useMemo, useState } from "react";
import { IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TrashIcon2 from "components/Icons/TrashIcon2";
import useColorRegistry from "features/Charting/hooks/useColorRegistry";
import { SelectedChartMeasure, SelectedChartMeasureMetric } from "components/charting/types";
import { Metadata, MetadataType } from "types/metadata";
import { ExerciseMetric } from "types";
import Mui5 from "components/mui5";
import * as Components from "./MeasureSelector.components";
import { useTranslation } from "react-i18next";

interface MeasureSelectorProps {
  initialOpen?: boolean;
  measure: SelectedChartMeasure;
  options: Metadata[];
  setMeasure(measure: Metadata | null): void;
  setMeasureMetric(measureMetric: SelectedChartMeasureMetric): void;
  removeMeasure?: false | (() => void);
}

function MeasureSelector(props: MeasureSelectorProps) {
  const { initialOpen = false, measure, options, removeMeasure, setMeasure, setMeasureMetric } = props;
  const getEntityColor = useColorRegistry();
  const [open, setOpen] = useState(!measure.metadata || initialOpen);
  const { t } = useTranslation();

  const { name, category } = useMemo(() => {
    let category = "";
    let name = "";

    if (measure.type === MetadataType.ANTHROPOMETRIC) {
      category = MetadataType.ANTHROPOMETRIC;
      name = measure.metadata.label;
    } else if (measure.type === MetadataType.WELLNESS) {
      category = MetadataType.WELLNESS;
      name = measure.metadata.name;
    } else if (measure.type === MetadataType.EXERCISE) {
      category = measure.metadata.category;
      name = measure.metadata.name;
    }

    return { category, name };
  }, [measure.metadata, measure.type]);

  const showMetricSelector = measure.type === MetadataType.EXERCISE || measure.type === null;

  const metadataValue = useMemo(
    () => options.find((option) => option.metadata === measure.metadata) || null,
    [measure.metadata, options]
  );

  const handleMetricValueChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: ExerciseMetric | null) => {
      setMeasureMetric(value);
    },
    [setMeasureMetric]
  );

  const handleGetOptionLabel = useCallback((option: Metadata) => {
    return option.type === MetadataType.ANTHROPOMETRIC ? option.metadata?.label : option.metadata?.name;
  }, []);

  const handleGroupBy = useCallback((option: Metadata) => {
    return option.type === MetadataType.EXERCISE ? option.metadata?.category : option.type;
  }, []);

  const handleGetOptionKey = useCallback((option: Metadata) => {
    return option.id;
  }, []);

  return (
    <Components.RootDiv>
      <Components.ColourStripDiv
        borderColour={
          measure.metadata ? getEntityColor([measure.id, measure.metric?.field].filter(Boolean).join("-")) : undefined
        }
      />
      <Components.CardDiv>
        <Grid container spacing="0px" p={0}>
          <Grid container width="100%" p={0}>
            <Grid item xs>
              <Box title={category} component="span" color="grey.800" fontWeight="fontWeightNormal" fontSize={12}>
                {category || <>&nbsp;</>}
              </Box>
              <Typography variant="h4" noWrap title={name}>
                {name || <>&nbsp;</>}
              </Typography>
            </Grid>
            <Grid display="flex" alignItems="center">
              {removeMeasure && (
                <IconButton size="small" onClick={removeMeasure} color="primary">
                  <TrashIcon2 />
                </IconButton>
              )}
              <Components.MeasureSelectorOpenButton
                size="small"
                color="primary"
                onClick={() => setOpen((prev) => !prev)}
                open={open}
              >
                <ExpandMoreIcon />
              </Components.MeasureSelectorOpenButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={open} unmountOnExit>
              <Box pt={1}>
                <Grid container spacing="0px" p={0}>
                  <Grid item xs={12}>
                    <Mui5.Autocomplete
                      getOptionKey={handleGetOptionKey}
                      options={options}
                      disableClearable={!!metadataValue?.metadata}
                      value={metadataValue}
                      getOptionLabel={handleGetOptionLabel}
                      groupBy={handleGroupBy}
                      filterOptions={metadataAutocompleteFilterOptions}
                      onChange={(_, value) => {
                        setMeasure(value);
                      }}
                      renderInput={(params) => (
                        <Mui5.TextField
                          {...params}
                          label={t("Charting.measurementSelectorLabel")}
                          variant="outlined"
                          margin="dense"
                          size="small"
                          color="primary"
                        />
                      )}
                    />
                  </Grid>
                  {showMetricSelector && (
                    <Grid item xs={12}>
                      <Mui5.Autocomplete
                        disableClearable={!!measure.metric}
                        options={measure.type === MetadataType.EXERCISE ? measure.metadata.metrics : []}
                        value={measure.metric}
                        getOptionLabel={(option: ExerciseMetric) => option.name}
                        onChange={handleMetricValueChange}
                        renderInput={(params) => (
                          <Mui5.TextField
                            {...params}
                            label={t("Charting.metricSelectorLabel")}
                            variant="outlined"
                            margin="dense"
                            size="small"
                            color="primary"
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Collapse>
          </Grid>
        </Grid>
      </Components.CardDiv>
    </Components.RootDiv>
  );
}

export default memo(MeasureSelector);
