import { Form, Formik } from "formik";
import { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Mui5 from "components/mui5";
import FormText from "components/Form/FormText";
import FormProfilePhoto from "../../Form/ProfilePhoto";
import { Group } from "contexts/GroupsContext/GroupsContext";
import useGroupsContext from "hooks/useGroupsContext";
import { CoachFormProps } from "./CoachForm.types";
import { useCoachFormValues, useHandleCoachFormSubmit } from "./CoachForm.hooks";
import { coachFormSchema } from "./CoachForm.schema";
import { useCallback } from "react";
import FormAutocomplete from "components/Form/FormAutocomplete";
import AutocompleteOptionColourable from "components/AutocompleteOptionColourable";

function CoachForm(props: CoachFormProps) {
  const [groups] = useGroupsContext();
  const formValues = useCoachFormValues(props.initialValues, { groups });
  const handleFormSubmit = useHandleCoachFormSubmit(props.onSubmit);

  const renderGroupTags = useCallback(
    (tagValue: Group[], getTagProps: AutocompleteRenderGetTagProps) =>
      tagValue.map(({ id, name, colour }, index) => {
        const { key, ...tagProps } = getTagProps({ index });
        return <Mui5.Chip key={id} label={name} customColor={colour} {...tagProps} />;
      }),
    []
  );

  return (
    <>
      <Formik
        initialValues={formValues}
        onSubmit={handleFormSubmit}
        validationSchema={coachFormSchema}
        {...props.FormikProps}
      >
        <Form>
          <Grid container spacing="24px">
            <Grid item xs={12} textAlign="center">
              <FormProfilePhoto name="profilePhotoName" buttonText={props.photoChangeButtonText} />
            </Grid>
            <Grid item xs={12}>
              <FormText fullWidth variant="standard" color="secondary" label="Name" name="name" />
            </Grid>
            <Grid item xs={12}>
              <FormText fullWidth variant="standard" color="secondary" label="Email Address" name="email" />
            </Grid>
            <Grid item xs={12}>
              <FormAutocomplete
                multiple
                name="groups"
                options={groups}
                getOptionLabel={(option) => option.name}
                disableCloseOnSelect
                filterSelectedOptions
                renderTags={renderGroupTags}
                renderOption={(optionProps, option) => (
                  <AutocompleteOptionColourable {...optionProps} key={option.id} colour={option.colour}>
                    {option.name}
                  </AutocompleteOptionColourable>
                )}
                renderInput={(params) => (
                  <Mui5.TextField
                    {...params}
                    placeholder={"Search Groups..."}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    color="secondary"
                    label="Groups"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs>
              <Mui5.Button type="submit" disabled={props.submitButtonDisabled}>
                {props.submitButtonText}
              </Mui5.Button>
            </Grid>
            {props.secondaryAction && (
              <Grid item xs>
                {props.secondaryAction}
              </Grid>
            )}
          </Grid>
        </Form>
      </Formik>
    </>
  );
}

export default CoachForm;
