import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { Leaderboard, LeaderboardBase } from "types";
import LeaderboardEditableData from "./LeaderboardEditableData";
import LeaderboardEditableLabel from "./LeaderboardEditableLabel";
import LeaderboardFormikProvider from "./LeaderboardFormikProvider";
import * as Components from "./LeaderboardEditable.components";
import { memo, useCallback, useMemo, useState } from "react";
import Portal from "components/Portal";
import LeaderboardEditableFullscreen from "./LeaderboardEditableFullscreen";
import LeaderboardEditableConfigPanel from "./LeaderboardEditableConfigPanel";
import TickerProvider from "components/Ticker/TickerProvider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import muiTheme from "components/MuiThemeProvider/muiTheme";
import { fullscreenLeaderboardTheme } from "./LeaderboardEditableFullscreen/LeaderboardEditableFullscreen.theme";

interface LeaderboardEditableProps extends Partial<Leaderboard> {
  onSubmit(leaderboard: Omit<Leaderboard, "id">): void;
  onDelete(): void;
  configPanelOpen?: boolean;
  leaderboard?: LeaderboardBase;
}

const LeaderboardEditable = memo(function LeaderboardEditable(props: LeaderboardEditableProps) {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { onSubmit, onDelete, leaderboard, configPanelOpen } = props;

  const toggleFullscreen = useCallback(() => setIsFullscreen((open) => !open), []);

  const LeaderboardWrapperComponent = useCallback(
    ({ children }: { children?: React.ReactNode }) => {
      if (isFullscreen) {
        return (
          <Portal>
            <LeaderboardEditableFullscreen>{children}</LeaderboardEditableFullscreen>
          </Portal>
        );
      }

      return <>{children}</>;
    },
    [isFullscreen]
  );

  const outputTheme = useMemo(() => createTheme(muiTheme), []);

  return (
    <LeaderboardFormikProvider leaderboard={leaderboard} onSubmit={onSubmit}>
      <TickerProvider>
        <LeaderboardWrapperComponent>
          <Box position="relative" display="flex" flexDirection="column" height="100%">
            <Components.CardPaper>
              <ThemeProvider theme={isFullscreen ? fullscreenLeaderboardTheme : outputTheme}>
                <LeaderboardEditableLabel
                  onDelete={onDelete}
                  toggleFullscreen={toggleFullscreen}
                  isFullscreen={isFullscreen}
                />
                <Divider />
                <LeaderboardEditableData />
                <Divider />
              </ThemeProvider>
              <LeaderboardEditableConfigPanel
                isFullscreen={isFullscreen}
                onDelete={props.onDelete}
                configPanelOpen={configPanelOpen}
              />
            </Components.CardPaper>
          </Box>
        </LeaderboardWrapperComponent>
      </TickerProvider>
    </LeaderboardFormikProvider>
  );
});

export default LeaderboardEditable;
