import { grey } from "@mui/material/colors";
import SortIcon from "components/Icons/SortIcon";
import { useCallback } from "react";
import useSetTableSort from "providers/TableSortProvider/useSetTableSort";
import useTableSort from "providers/TableSortProvider/useTableSort";
import * as Components from "./TableHeaderSortButton.components";

export interface TableHeaderSortButtonProps<T> {
  propKey: keyof T;
  label: string;
}

function TableHeaderSortButton<T extends {}>(props: TableHeaderSortButtonProps<T>) {
  const { label, propKey } = props;
  const sortBy = useTableSort();
  const setSortBy = useSetTableSort();
  const currySort = useCallback(
    (key: keyof T) => () => {
      setSortBy((prev) => ({
        ...prev,
        key,
        direction: prev.key === key ? (prev.direction === "asc" ? "desc" : "asc") : prev.direction,
      }));
    },
    [setSortBy]
  );

  return (
    <Components.SortButton
      variant="text"
      size="small"
      color="inherit"
      onClick={currySort(propKey)}
      fullWidth={false}
      showSort={sortBy.key === propKey}
      endIcon={
        <SortIcon htmlColor={sortBy.key === propKey ? "currentColor" : grey[500]} direction={sortBy.direction} />
      }
    >
      {label}
    </Components.SortButton>
  );
}

export default TableHeaderSortButton;
