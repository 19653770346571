import { useMemo, useReducer, useCallback, useEffect } from "react";
import WorkoutsStoreContext from "./WorkoutsContext";
import WorkoutsActionsContext from "./WorkoutsActionsContext";
import * as WorkoutsReducer from "./reducer";
import WorkoutService from "features/Workouts/services/WorkoutService";
import { Workout, WorkoutBase } from "features/Workouts/Workouts.types";
import { useRequest } from "hooks";

interface WorkoutsProviderProps {
  children?: React.ReactNode;
}

function WorkoutsProvider(props: WorkoutsProviderProps) {
  const [workouts, dispatchWorkouts] = useReducer(WorkoutsReducer.reducer, []);

  const getAll = useCallback(
    () =>
      WorkoutService.getAll().then((payload) => {
        dispatchWorkouts({ type: WorkoutsReducer.Actions.ADD_ALL, payload });
        return payload;
      }),
    []
  );

  const getById = useCallback(
    (id: string) =>
      WorkoutService.getById(id).then((payload) => {
        dispatchWorkouts({ type: WorkoutsReducer.Actions.ADD_ONE, payload });
        return payload;
      }),
    []
  );

  const create = useCallback(
    async (workout: WorkoutBase) => {
      const result = await WorkoutService.create(workout);

      return getById(result.id);
    },
    [getById]
  );

  const update = useCallback(
    ({ id, ...workout }: Workout) =>
      WorkoutService.update(id, workout).then((payload) => {
        dispatchWorkouts({ type: WorkoutsReducer.Actions.UPDATE_ONE, payload });
        return payload;
      }),
    []
  );

  const updateAthleteIds = useCallback(
    async (id: string, athleteIds: string[]) => {
      const originalWorkout = workouts.find((workout) => workout.id === id)!;

      try {
        dispatchWorkouts({ type: WorkoutsReducer.Actions.UPDATE_ONE, payload: { id, athleteIds } });
        await WorkoutService.updateAthleteIds(id, athleteIds);
      } catch (error) {
        dispatchWorkouts({ type: WorkoutsReducer.Actions.UPDATE_ONE, payload: originalWorkout });
        throw error;
      }
    },
    [workouts]
  );

  const bulkUpdateAthleteId = useCallback(async (ids: string[], athleteId: string, isAssignment: boolean) => {
    try {
      dispatchWorkouts({
        type: WorkoutsReducer.Actions.UPDATE_MANY_ATHLETES,
        payload: { workoutIds: ids, athleteId, isAssignment },
      });
      await WorkoutService.bulkUpdateAthleteId(ids, athleteId, isAssignment);
    } catch (error) {
      dispatchWorkouts({
        type: WorkoutsReducer.Actions.UPDATE_MANY_ATHLETES,
        payload: { workoutIds: ids, athleteId, isAssignment: !isAssignment },
      });
      throw error;
    }
  }, []);

  const updateGroupIds = useCallback(
    async (id: string, groupIds: string[]) => {
      const originalWorkout = workouts.find((workout) => workout.id === id)!;

      try {
        dispatchWorkouts({ type: WorkoutsReducer.Actions.UPDATE_ONE, payload: { id, groupIds } });
        await WorkoutService.updateGroupIds(id, groupIds);
      } catch (error) {
        dispatchWorkouts({ type: WorkoutsReducer.Actions.UPDATE_ONE, payload: originalWorkout });
        throw error;
      }
    },
    [workouts]
  );

  const bulkUpdateGroupId = useCallback(async (ids: string[], groupId: string, isAssignment: boolean) => {
    try {
      dispatchWorkouts({
        type: WorkoutsReducer.Actions.UPDATE_MANY_GROUPS,
        payload: { workoutIds: ids, groupId, isAssignment },
      });
      await WorkoutService.bulkUpdateGroupId(ids, groupId, isAssignment);
    } catch (error) {
      dispatchWorkouts({
        type: WorkoutsReducer.Actions.UPDATE_MANY_GROUPS,
        payload: { workoutIds: ids, groupId, isAssignment: !isAssignment },
      });
      throw error;
    }
  }, []);

  const bulkUpdateLabel = useCallback(async (ids: string[], label: string, isAssignment: boolean) => {
    try {
      dispatchWorkouts({
        type: WorkoutsReducer.Actions.UPDATE_MANY_LABELS,
        payload: { workoutIds: ids, label, isAssignment },
      });
      await WorkoutService.bulkUpdateLabel(ids, label, isAssignment);
    } catch (error) {
      dispatchWorkouts({
        type: WorkoutsReducer.Actions.UPDATE_MANY_LABELS,
        payload: { workoutIds: ids, label, isAssignment: !isAssignment },
      });
      throw error;
    }
  }, []);

  const deleteWorkout = useCallback(
    (id: string) =>
      WorkoutService.delete(id).then(() => {
        dispatchWorkouts({ type: WorkoutsReducer.Actions.REMOVE_ONE, payload: { id } });
      }),
    []
  );

  const removeFromLocalStore = useCallback(
    (id: string) => dispatchWorkouts({ type: WorkoutsReducer.Actions.REMOVE_ONE, payload: { id } }),
    []
  );

  const togglePublishById = useCallback(
    (id: string, isPublished: boolean) =>
      WorkoutService.publishById(id, isPublished).then(() => {
        dispatchWorkouts({ type: WorkoutsReducer.Actions.UPDATE_ONE, payload: { id, isPublished } });
      }),
    []
  );

  const togglePublishByIds = useCallback(
    (ids: string[], isPublished: boolean) =>
      WorkoutService.publishByIds(ids, isPublished).then(() => {
        ids.forEach((id) =>
          dispatchWorkouts({ type: WorkoutsReducer.Actions.UPDATE_ONE, payload: { id, isPublished } })
        );
      }),
    []
  );

  const actionsContextValue = useMemo(
    () => ({
      create,
      bulkUpdateGroupId,
      updateGroupIds,
      bulkUpdateAthleteId,
      updateAthleteIds,
      bulkUpdateLabel,
      getAll,
      getById,
      update,
      delete: deleteWorkout,
      togglePublishById,
      togglePublishByIds,
      removeFromLocalStore,
    }),
    [
      create,
      bulkUpdateGroupId,
      updateGroupIds,
      bulkUpdateAthleteId,
      bulkUpdateLabel,
      updateAthleteIds,
      getAll,
      getById,
      update,
      deleteWorkout,
      togglePublishById,
      togglePublishByIds,
      removeFromLocalStore,
    ]
  );

  const getAllWorkouts = useRequest(getAll);

  useEffect(() => {
    getAllWorkouts();
  }, [getAllWorkouts]);

  return (
    <WorkoutsActionsContext.Provider value={actionsContextValue}>
      <WorkoutsStoreContext.Provider value={workouts}>{props.children}</WorkoutsStoreContext.Provider>
    </WorkoutsActionsContext.Provider>
  );
}

export default WorkoutsProvider;
