import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Athlete } from "types/athlete";
import AthleteBiometrics from "./AthleteBiometrics";
import NotePadIcon from "components/IconsStandardized/NotePadIcon/NotePadIcon";
import Constants from "config/Constants";
import ClickableRAGStatusWrapper from "./ClickableRAGStatusWrapper";
import RagStatusIndicator from "components/RagStatusIndicator";
import useGroupsContext from "hooks/useGroupsContext";
import { useNavigate } from "react-router-dom";
import AthleteProfileGroups from "../AthleteProfileGroups";
import { useCallback, useMemo, useState } from "react";
import { useAthleteRagStatuses, useLoadingContext } from "hooks";
import PopoverWrapper from "components/PopoverWrapper";
import RagStatusSummaryCard from "components/RagStatusSummaryCard";
import ButtonBase from "@mui/material/ButtonBase";
import RagMonitoringTemplateSelectorMenu from "components/RagMonitoringTemplateSelectorMenu";
import CircularProgress from "@mui/material/CircularProgress";
import MeasureHelpers from "features/Charting/helpers/MeasureHelpers";
import * as Components from "./AthleteProfileDetails.components";
import AthleteProfile1RMEdit from "./AthleteProfile1RMEdit/AthleteProfile1RMEdit";
import Tables from "components/Tables";
import Modal from "components/Modal";
import OneRepMaxProvider from "providers/OneRepMaxProvider";
import Tooltip from "components/Tooltip";
import CanDoAction from "components/CanDoAction";
import logEvent from "utils/logEvent";
import { useTranslation } from "react-i18next";
import useAthleteProfilePhotoURL from "hooks/useAthleteProfilePhotoURL";
import AthleteHelpers from "helpers/AthleteHelpers";

type AthleteProfileDetailsProps = Athlete;

function AthleteProfileDetails(props: AthleteProfileDetailsProps) {
  const { t } = useTranslation();
  const [groups] = useGroupsContext();
  const navigate = useNavigate();
  const [loading] = useLoadingContext();
  const [open1RMModal, setOpen1RMModal] = useState(false);
  const toggleOpen1RMModal = useCallback(
    () =>
      setOpen1RMModal((prev) => {
        const update = !prev;
        if (update) logEvent("ATHLETE_PROFILE_VIEW_1RM_MODAL");
        return update;
      }),
    []
  );

  const athleteIds = useMemo(() => [props.id], [props.id]);
  const getAthleteRagStatuses = useAthleteRagStatuses({ athleteIds });

  function editAthlete() {
    navigate([Constants.routes.athletes.route, props.id, "edit"].join("/"));
  }

  const avatar = useAthleteProfilePhotoURL(props);
  const fullName = useMemo(() => AthleteHelpers.getFullName(props), [props]);

  return (
    <Grid container spacing={0} columns={12}>
      <Grid item sm md={6}>
        <Box>
          <Grid container spacing={2}>
            <Grid item>
              <Components.ProfilePhotoDiv backgroundImage={avatar.src || null} />
            </Grid>
            <Grid item xs>
              <Typography variant="h2" gutterBottom sx={{ display: "flex", alignItems: "center" }}>
                <Box component="span" fontWeight="fontWeightMedium">
                  {fullName}
                </Box>
                <Box component="span" pl={2}>
                  <Tooltip title={t("AthleteProfileDetails.editIconTooltipText")} arrow placement="bottom">
                    <IconButton onClick={editAthlete} aria-label="notepad-icon" color="primary">
                      <NotePadIcon />
                    </IconButton>
                  </Tooltip>
                  <>
                    <Tooltip title={t("AthleteProfileDetails.set1rmIconTooltipText")} arrow placement="bottom">
                      <IconButton color="primary" aria-label="1rm-icon" onClick={toggleOpen1RMModal}>
                        <Tables.Icons.OneRepMax />
                      </IconButton>
                    </Tooltip>
                    <Modal open={open1RMModal} toggleOpen={toggleOpen1RMModal}>
                      <OneRepMaxProvider>
                        <AthleteProfile1RMEdit onClose={toggleOpen1RMModal} athleteId={props.id} />
                      </OneRepMaxProvider>
                    </Modal>
                  </>
                </Box>
              </Typography>
              <AthleteBiometrics dateOfBirth={props.dateOfBirth} height={props.height} weight={props.weight} />
              <CanDoAction action="group.view">
                <AthleteProfileGroups groups={groups.filter(({ id }) => props.groupIds?.includes(id))} />
              </CanDoAction>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item sm md={6}>
        <div className="oh-athlete-stats-icons">
          <Box pb={1.5}>
            <RagMonitoringTemplateSelectorMenu id="athlete-profile-rag-template-selector">
              {({ open, anchorProps, currentTemplate }) => (
                <Typography
                  variant="h3"
                  gutterBottom
                  component={ButtonBase}
                  {...anchorProps}
                  style={{ borderRadius: "4px", padding: "0 4px" }}
                >
                  <Box component="span" fontWeight="fontWeightMedium">
                    {currentTemplate?.name || t("AthleteProfileDetails.noRagTemplateSelected")}
                  </Box>
                  <Components.EndIconSpan>
                    <Components.AthleteProfileExpandMoreIcon open={open} />
                  </Components.EndIconSpan>
                </Typography>
              )}
            </RagMonitoringTemplateSelectorMenu>
          </Box>
          <Grid container justifyContent="center" alignItems="flex-end" spacing={2}>
            {getAthleteRagStatuses(props.id)?.map((summary, index) => {
              const hasValues = Boolean(summary?.values.find((value) => value.progressArrow !== -2));

              return (
                <Grid item xs={4} md={2} key={index}>
                  {!summary && loading && <CircularProgress size={20} color="secondary" />}
                  {summary && (
                    <PopoverWrapper id={`rag-popover-${props.id}-${summary.metadataId}`}>
                      {({ anchorProps, Popover }) => (
                        <>
                          <ClickableRAGStatusWrapper
                            to={MeasureHelpers.getAthleteProfileChartUrl(props.id, {
                              metadataId: summary.metadataId,
                            })}
                            {...anchorProps}
                          >
                            <Box pb={1.5} fontSize={12}>
                              <Typography component="div" variant="caption" align="center">
                                <Box component="span" fontWeight="fontWeightLight" fontSize={12}>
                                  {summary.metadataName}
                                </Box>
                              </Typography>
                            </Box>
                            <Box pb={1.5}>
                              <RagStatusIndicator values={summary.values} />
                            </Box>
                          </ClickableRAGStatusWrapper>
                          {hasValues && (
                            <Popover>
                              <RagStatusSummaryCard {...summary} />
                            </Popover>
                          )}
                        </>
                      )}
                    </PopoverWrapper>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

export default AthleteProfileDetails;
