import Tables from "components/Tables";
import NoDataWrapper from "components/NoDataWrapper";
import useGetCompletedWorkoutsByWorkoutId from "features/Workouts/hooks/useGetCompletedWorkoutsByWorkoutId";
import CompletedWorkoutsTableRow from "./CompletedWorkoutsTableRow";
import CompletedWorkoutsTableControls from "./CompletedWorkoutsTableControls";
import { useMemo, useRef } from "react";
import useSearchText from "providers/SearchTextProvider/useSearchText/useSearchText";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore/useAthleteStore";
import { useVirtualizer } from "@tanstack/react-virtual";
import Typography from "@mui/material/Typography";
import CompletedWorkoutsTableHeader from "./CompletedWorkoutsTableHeader";
import VirtualTable from "components/VirtualTable";
import Stack from "@mui/material/Stack";

interface CompletedWorkoutsTableProps {
  workoutId: string;
}

function CompletedWorkoutsTable(props: CompletedWorkoutsTableProps) {
  const completedWorkouts = useGetCompletedWorkoutsByWorkoutId(props.workoutId);
  const searchText = useSearchText();
  const completedWorkoutAthleteIds = useMemo(
    () => completedWorkouts.map((completedWorkout) => completedWorkout.athleteId),
    [completedWorkouts]
  );
  const athletes = useAthleteStore(completedWorkoutAthleteIds);

  const filteredCompletedWorkouts = useMemo(() => {
    if (!searchText) {
      return completedWorkouts;
    }

    const inputKeywords = searchText.toLowerCase().split(" ");

    const filteredAthletesIds = athletes
      .filter((athlete) => {
        const candidateKeywordString = [athlete.firstName, athlete.lastName].filter(Boolean).join(" ").toLowerCase();

        return inputKeywords.every((inputKeyword) => candidateKeywordString.indexOf(inputKeyword) > -1);
      })
      .map((athlete) => athlete.id);

    return completedWorkouts.filter(({ athleteId }) => filteredAthletesIds.includes(athleteId));
  }, [athletes, completedWorkouts, searchText]);

  // The scrollable element for your list
  const parentRef = useRef<HTMLDivElement>(null);

  // The virtualizer
  const rowVirtualizer = useVirtualizer({
    count: filteredCompletedWorkouts.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 56,
    getItemKey: (index) => filteredCompletedWorkouts[index].id,
  });

  return (
    <Stack minHeight={0} height="100%">
      <Typography variant="h3" gutterBottom fontWeight="fontWeightMedium">
        Workout History
      </Typography>
      <Stack flex={1} position="relative" minHeight={0}>
        <Tables.Components.PaperCard>
          <CompletedWorkoutsTableControls />
          <CompletedWorkoutsTableHeader />
          <Tables.Components.RowsBox>
            <NoDataWrapper
              hasData={Boolean(filteredCompletedWorkouts.length)}
              fallback={<Tables.Components.Skeleton text="No Workouts available" />}
            >
              <VirtualTable.Parent ref={parentRef}>
                <VirtualTable.Inner style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                  {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                    <VirtualTable.Row
                      key={virtualRow.index}
                      data-index={virtualRow.index}
                      ref={rowVirtualizer.measureElement}
                      style={{
                        transform: `translateY(${virtualRow.start}px)`,
                        height: `${virtualRow.size}px`,
                      }}
                    >
                      <CompletedWorkoutsTableRow {...filteredCompletedWorkouts[virtualRow.index]} />
                    </VirtualTable.Row>
                  ))}
                </VirtualTable.Inner>
              </VirtualTable.Parent>
            </NoDataWrapper>
          </Tables.Components.RowsBox>
        </Tables.Components.PaperCard>
      </Stack>
    </Stack>
  );
}

export default CompletedWorkoutsTable;
