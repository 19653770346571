import usePreferences from "hooks/usePreferences";
import { Exercise, MeasurementSet } from "types";
import { useCallback, useEffect, useState } from "react";
import SetComparisonChartService from "services/SetComparisonChartService";
import { firstBy } from "thenby";
import { SetComparisonFilter } from "../../../../../ModuleModal.types";
import FormAutoComplete from "components/Form/FormAutocomplete";
import Mui5 from "components/mui5";
import moment from "moment";
import UnitHelper from "helpers/UnitHelper";

interface AtheteSetComparisonChartModuleFormSetSelectorProps {
  name: string;
  athleteId: string;
  exercise: Exercise | null;
  sets: SetComparisonFilter[];
}

function AtheteSetComparisonChartModuleFormSetSelector(props: AtheteSetComparisonChartModuleFormSetSelectorProps) {
  const { athleteId, exercise, sets } = props;
  const [options, setOptions] = useState<SetComparisonFilter[]>([]);

  const [{ imperialSystemEnabled }] = usePreferences();

  const makeLabel = useCallback(
    (set: MeasurementSet) =>
      [
        set.weightValue &&
          UnitHelper.process(set.weightValue, {
            from: UnitHelper.units.Kilogram,
            to: imperialSystemEnabled && UnitHelper.units.Pound,
            decimals: 0,
          }),
        set.variant,
        `Set ${set.setNumber}`,
        `${set.repetitionCount} Reps`,
        moment(set.completedDate).format("DD MMM YYYY"),
      ]
        .filter(Boolean)
        .join(" / "),
    [imperialSystemEnabled]
  );

  useEffect(() => {
    let completePromise = true;

    (async () => {
      if (!exercise?.id) return;

      const sets = await SetComparisonChartService.getSets(athleteId, { exerciseId: exercise?.id });

      if (!completePromise) {
        return;
      }

      const labelledSets = sets.map((set) => ({
        exerciseMeasurementId: set.measurementId,
        completedDate: set.completedDate,
        setNumber: set.setNumber,
        name: makeLabel(set),
      }));
      const sortedSets = [...labelledSets].sort(firstBy("completedDate", "desc"));
      setOptions(sortedSets);
    })();

    return () => {
      completePromise = false;
    };
  }, [athleteId, exercise?.id, makeLabel]);

  return (
    <FormAutoComplete
      name={props.name}
      multiple
      options={options}
      disabled={!options.length}
      defaultValue={[]}
      disableCloseOnSelect
      renderTags={(tagValue: SetComparisonFilter[], getTagProps: any) =>
        tagValue.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return <Mui5.Chip label={option.name} {...tagProps} />;
        })
      }
      limitTags={4}
      getOptionDisabled={(opt: SetComparisonFilter) => {
        return sets.length >= 4 && !Boolean(sets.find((set) => set === opt));
      }}
      getOptionLabel={(option: SetComparisonFilter) => option.name || ""}
      renderInput={(params: any) => (
        <Mui5.TextField {...params} label="Sets" placeholder="Select Sets" variant="standard" />
      )}
      isOptionEqualToValue={(option: SetComparisonFilter, value: SetComparisonFilter) => option.name === value?.name}
    />
  );
}
export default AtheteSetComparisonChartModuleFormSetSelector;
