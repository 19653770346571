import { useMemo, useState } from "react";
import { SelectedWorkoutLabelsContextType } from "./SelectedWorkoutLabelsProvider.types";
import SelectedWorkoutLabelsContext from "./SelectedWorkoutLabelsContext";
import SetSelectedWorkoutLabelsContext from "./SetSelectedWorkoutLabelsContext";
import useWorkoutLabelOptions from "features/Workouts/providers/WorkoutLabelOptionsProvider/useWorkoutLabelOptions";

interface SelectedWorkoutLabelsProviderProps {
  children?: React.ReactNode;
}

function SelectedWorkoutLabelsProvider(props: SelectedWorkoutLabelsProviderProps) {
  const workoutLabelOptions = useWorkoutLabelOptions();
  const [selectedWorkoutLabels, setSelectedWorkoutLabels] = useState<SelectedWorkoutLabelsContextType>([]);
  const validSelectedWorkoutLabelOptions = useMemo(
    () =>
      workoutLabelOptions.filter((workoutLabelOption) =>
        selectedWorkoutLabels.find((selectedWorkoutLabel) => selectedWorkoutLabel.id === workoutLabelOption.id)
      ),
    [selectedWorkoutLabels, workoutLabelOptions]
  );

  return (
    <SetSelectedWorkoutLabelsContext.Provider value={setSelectedWorkoutLabels}>
      <SelectedWorkoutLabelsContext.Provider value={validSelectedWorkoutLabelOptions}>
        {props.children}
      </SelectedWorkoutLabelsContext.Provider>
    </SetSelectedWorkoutLabelsContext.Provider>
  );
}

export default SelectedWorkoutLabelsProvider;
