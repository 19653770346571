import { useMemo } from "react";
import WorkoutLabelOptionsContext from "./WorkoutLabelOptionsContext";
import useWorkoutsStore from "features/Workouts/providers/WorkoutsProvider/useWorkoutsStore";
import { WorkoutLabelOption } from "features/Workouts/Workouts.types";

interface WorkoutLabelOptionsProviderProps {
  children?: React.ReactNode;
}

function WorkoutLabelOptionsProvider(props: WorkoutLabelOptionsProviderProps) {
  const workouts = useWorkoutsStore();

  const allWorkoutsLabels = useMemo(
    () => workouts.map<string[]>((workout) => workout.labels).flatMap<string>((label) => label),
    [workouts]
  );
  const workoutLabelsCounts = useMemo(
    () =>
      allWorkoutsLabels.reduce(
        (acc, label) => {
          acc[label] = (acc[label] || 0) + 1;
          return acc;
        },
        {} as Record<string, number>
      ),
    [allWorkoutsLabels]
  );
  const workoutLabelOptions: WorkoutLabelOption[] = useMemo(
    () =>
      Array.from(new Set(allWorkoutsLabels)).map((label) => ({
        id: label,
        name: label,
        count: workoutLabelsCounts[label],
      })),
    [allWorkoutsLabels, workoutLabelsCounts]
  );
  return (
    <WorkoutLabelOptionsContext.Provider value={workoutLabelOptions}>
      {props.children}
    </WorkoutLabelOptionsContext.Provider>
  );
}

export default WorkoutLabelOptionsProvider;
