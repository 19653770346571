import { useState, useCallback, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import DustbinIcon from "components/IconsStandardized/DustbinIcon";
import RAGSettingsForm from "../../RAGSettingsForm";
import { RAGSettings } from "contexts/RAGSettingsContext/RAGSettingsContext";
import useRequest from "hooks/useRequest";
import useMonitoringTemplates from "hooks/useMonitoringTemplates";
import usePreferences from "hooks/usePreferences";
import ConfirmationDialog from "components/ConfirmationDialog";
import { ConfirmationDialogProps } from "./RagTemplate.config";
import * as Components from "./RAGTemplate.components";
import IconButton from "@mui/material/IconButton";
import Mui5 from "components/mui5";

interface RAGTemplateProps extends RAGSettings {}

function RAGTemplate(props: RAGTemplateProps) {
  const [dialogProps, setDialogProps] = useState<Omit<ConfirmationDialogProps, "open" | "setOpen"> | false>(false);
  const [editOpen, setEditOpen] = useState(false);
  const [, actions] = useMonitoringTemplates();
  const [preferences, preferenceActions] = usePreferences();

  function handleClose() {
    setEditOpen(false);
  }

  const onUpdate = useRequest(actions.update, true);
  const onDelete = useRequest(actions.delete);
  const onSetDefault = useRequest(preferenceActions.setDefaultRAGTemplate);
  const handleUpdate = useCallback(
    async (values: RAGSettings) => {
      await onUpdate(props.id, values);
      if (values.default) await onSetDefault(props.id);

      handleClose();
    },
    [onSetDefault, onUpdate, props.id]
  );
  const handleDelete = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      setDialogProps({
        title: "Delete Template",
        cancelText: "Cancel",
        confirmText: "Delete",
        color: "error",
        body: ["The Athlete Monitoring template", props.name, "will be permanently deleted. It cannot be undone."].join(
          " "
        ),
        onConfirm: () => onDelete(props.id),
      });
    },
    [onDelete, props.id, props.name]
  );

  const handleSetDefault = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      return onSetDefault(props.id);
    },
    [onSetDefault, props.id]
  );

  const conditionalPaperProps = useMemo(
    () =>
      !editOpen
        ? {
            role: "button",
            sx: { cursor: "pointer" },
            onClick: () => setEditOpen(true),
          }
        : {},
    [editOpen]
  );

  const isDefault = Boolean(preferences.defaultRagStatusTemplateId === props.id);
  const formInitialValues = useMemo(
    () => ({
      ...props,
      default: isDefault,
    }),
    [props, isDefault]
  );

  const confirmationDialog = Boolean(dialogProps) && (
    <ConfirmationDialog open={Boolean(dialogProps)} setOpen={setDialogProps} {...dialogProps} />
  );

  return (
    <Box pb={2}>
      <Components.CardPaper {...conditionalPaperProps} square>
        <Collapse in={!editOpen} timeout="auto" unmountOnExit>
          <Components.GridRow container alignItems="center">
            <Grid item xs={8}>
              <Typography variant="h4" noWrap>
                <Box component="span" fontWeight={500}>
                  {props.name}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs />
            <Grid item xs={2}>
              <Mui5.Button
                size="small"
                color="primary"
                square
                variant="outlined"
                disableElevation
                onClick={handleSetDefault}
                aria-label="Set as Default"
                fullWidth
                disabled={isDefault}
              >
                {isDefault ? "Default" : "Set as Default"}
              </Mui5.Button>
            </Grid>
            <Grid item xs>
              <Box fontSize={32} title={isDefault ? "The default cannot be deleted" : undefined}>
                <IconButton color="primary" onClick={handleDelete} aria-label="Delete Template" disabled={isDefault}>
                  <DustbinIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Grid>
          </Components.GridRow>
        </Collapse>
        <Collapse in={editOpen} timeout="auto" unmountOnExit>
          <Box p={3} pl={0}>
            <RAGSettingsForm initialValues={formInitialValues} onSubmit={handleUpdate} onCancel={handleClose} />
          </Box>
        </Collapse>
      </Components.CardPaper>
      {confirmationDialog}
    </Box>
  );
}

export default RAGTemplate;
