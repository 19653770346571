import MessageCard from "components/MessageCard";
import * as NoChartDataComponents from "./NoChartData.components";
import { Box } from "@mui/material";
import useChartSelections from "components/charting/hooks/useChartSelections";
import ChartHelpers from "features/Charting/helpers/ChartHelpers";
import { Trans, useTranslation } from "react-i18next";

interface NoChartDataProps {}

function NoChartData(props: NoChartDataProps) {
  const selections = useChartSelections();
  const { t } = useTranslation();

  return (
    <Box height="100%" flex={1} ml={2} mt={1.5}>
      <Trans
        t={t}
        i18nKey="Charting.noDataMessage"
        values={{ period: ChartHelpers.generateTimePhrase(selections.period.value) }}
      >
        <MessageCard title="Charting">
          No data to display{ChartHelpers.generateTimePhrase(selections.period.value)}. Please update{" "}
          <NoChartDataComponents.ImportantText component="span">Measurement</NoChartDataComponents.ImportantText> and{" "}
          <NoChartDataComponents.ImportantText component="span">Time</NoChartDataComponents.ImportantText> selections.
        </MessageCard>
      </Trans>
    </Box>
  );
}

export default NoChartData;
