import React from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import Paper from "components/mui5/Paper";
import Box from "@mui/material/Box";
import Mui5 from "components/mui5";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  clearError = () => this.setState({ error: null });

  renderError() {
    const { error } = this.state;

    return (
      <Paper square elevation={0}>
        <Box pt={16} pb={16} px={2}>
          <Alert
            severity="error"
            action={
              <Mui5.Button onClick={this.clearError} color="error" size="small">
                Retry
              </Mui5.Button>
            }
          >
            <AlertTitle>Error</AlertTitle>
            An Error has occured{" ("}
            <Typography component="code" variant="body2">
              {error.message}
            </Typography>
            {"). "}
            Change your settings and hit retry.
          </Alert>
        </Box>
      </Paper>
    );
  }

  render() {
    const { error } = this.state;
    if (error) {
      return this.renderError();
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
