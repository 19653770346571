import VerticalPageLayout from "components/VerticalPageLayout";
import Box from "@mui/material/Box";
import PageContent from "components/PageContent";
import GroupTitle from "./GroupTitle";
import { useParams } from "react-router-dom";
import { useLoadingContext } from "hooks";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore";
import useGetGroupById from "hooks/useGetGroupById";
import { useEffect, useMemo } from "react";
import NotFound from "features/NotFound";
import GroupAthleteTable from "./GroupAthleteTable";
import Loader from "components/Loader/Loader";
import logEvent from "utils/logEvent";
import MultiSelectProvider from "providers/MultiSelectProvider";
import SearchTextProvider from "providers/SearchTextProvider";
import SelectedEntitiesProvider from "providers/SelectedEntitiesProvider";
import TableSortProvider from "providers/TableSortProvider";
import { ALPHABETICAL_ASC } from "components/AthleteHub/AthleteTable/AthleteTableControls/AthleteTableControlSort/AthleteTableControlSort.helpers";
import { useTranslation } from "react-i18next";
import GroupOverviewInfoCard from "./GroupOverviewInfoCard";
import Constants from "config/Constants";

function Group() {
  const { id } = useParams<"id">();
  const group = useGetGroupById(id);
  const [loading] = useLoadingContext();

  const athletes = useAthleteStore();

  const groupAthletes = useMemo(
    () => athletes.filter((athlete) => !athlete.isArchived && group?.athleteIds.includes(athlete.id)),
    [group?.athleteIds, athletes]
  );

  const activeAthleteCount = groupAthletes.length;

  const { t } = useTranslation();

  useEffect(() => {
    logEvent("GROUPS_VIEW_ONE");
  }, [id]);

  if (loading && typeof group === "undefined") {
    return <Loader />;
  } else if (typeof group === "undefined") {
    return <NotFound link="/groups" buttonText={t("Group.Overview.notFoundButtonText")} />;
  }

  return (
    <MultiSelectProvider>
      <SearchTextProvider>
        <SelectedEntitiesProvider>
          <TableSortProvider initialSortKey={ALPHABETICAL_ASC}>
            <Box height="100%" ml={1} p={0} pb={0}>
              <VerticalPageLayout
                top={
                  <Box p={1} pr={0}>
                    <PageContent
                      pageHeadingLink="#"
                      pageHeading={Constants.routes.groupsSingle.title}
                      infoCardComponent={GroupOverviewInfoCard}
                    />
                  </Box>
                }
                title={
                  <Box p={0} pl={1} pr={2} pb={1}>
                    <GroupTitle {...group} activeCount={activeAthleteCount} />
                  </Box>
                }
                main={
                  <Box pt={1} pl={1} pb={3} pr={2} width="100%" height="100%" position="relative">
                    <GroupAthleteTable athletes={groupAthletes} />
                  </Box>
                }
              />
            </Box>
          </TableSortProvider>
        </SelectedEntitiesProvider>
      </SearchTextProvider>
    </MultiSelectProvider>
  );
}

export default Group;
