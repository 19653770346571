import axios from "axios";
import { firebaseApp } from "config/firebase";
import { getAuth } from "firebase/auth";

function getBaseUrl() {
  const url = process.env.REACT_APP_OUTPUT_HUB_API || "http://example.com/api/v1";

  if (!url) {
    /**
     * @func Error
     * Throw an error if the API URL is unset in development
     */
    throw new Error(`Environment Variable 'REACT_APP_OUTPUT_HUB_API' not set`);
  }

  return url;
}

const axiosInstance = axios.create({
  baseURL: getBaseUrl(),
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const jwt = await getAuth(firebaseApp).currentUser?.getIdToken();

    config.headers.set("Accept", "application/json");
    config.headers.set("Content-Type", "application/json");

    if (jwt) config.headers.set("Authorization", `Bearer ${jwt}`);

    return { ...config };
  },
  (error) => Promise.reject(error)
);

const http = axiosInstance;

export default http;
