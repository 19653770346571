import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "components/mui5/Paper";
import Typography from "@mui/material/Typography";
import useExercise from "hooks/useExercise";
import AnalyticsIcon from "components/Icons/AnalyticsIcon";
import { DateTime, Measurement } from "types";
import { useState } from "react";
import ActivityCardTable from "./ActivityCardTable";
import BroadDownArrow from "components/Icons/BroadDownArrow";
import BroadUpArrow from "components/Icons/BroadUpArrow";
import { Link as RouterLink } from "react-router-dom";
import ClickHelpers from "helpers/ClickHelpers";
import moment from "moment";
import MeasureHelpers from "features/Charting/helpers/MeasureHelpers";
import * as Components from "./ActivityCard.components";

interface ActivityCardProps {
  date: DateTime;
  athleteId: string;
  exerciseId: string;
  measurements: Measurement[];
}

function ActivityCard(props: ActivityCardProps) {
  const [open, setOpen] = useState(false);
  const { exerciseId, athleteId, measurements, date } = props;
  const exercise = useExercise(exerciseId);

  function toggleExpand() {
    setOpen((prev) => !prev);
  }

  const cardProps = {
    "aria-label": `${exerciseId} activity card`,
    role: Boolean(exercise) ? "button" : undefined,
    onClick: Boolean(exercise) ? toggleExpand : undefined,
    sx: {
      borderRadius: 1,
      cursor: Boolean(exercise) ? "pointer" : undefined,
      mb: 1,
    },
  };

  return (
    <Paper {...cardProps}>
      <Box pl={2} pr={2} pt={1.5} pb={2.1} position="relative">
        <Grid container alignItems="center" flexWrap="nowrap">
          <Grid item xs minWidth={0}>
            {exercise && (
              <Typography noWrap title={exercise?.category}>
                <Box component="span" color="grey.800" fontSize={12}>
                  {exercise?.category}
                </Box>
              </Typography>
            )}
            <Typography variant="h4" noWrap title={exercise?.name || exerciseId}>
              {exercise?.name || exerciseId}
            </Typography>
          </Grid>
          <Grid item>
            <Box color="primary.main">
              <IconButton
                component={RouterLink}
                aria-label={`chart ${exerciseId} data`}
                size="small"
                color="inherit"
                onClick={ClickHelpers.stopPropogation}
                to={MeasureHelpers.getAthleteProfileChartUrl(athleteId, {
                  metadataId: exerciseId,
                  interval: "custom",
                  start: moment(date).toISOString(),
                })}
              >
                <AnalyticsIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        {exercise && (
          <>
            <Collapse in={open} unmountOnExit={true}>
              <ActivityCardTable measurements={measurements} exercise={exercise} />
            </Collapse>
            <Components.ExpandIconDiv>
              {open ? (
                <BroadUpArrow fontSize="small" color="disabled" />
              ) : (
                <BroadDownArrow fontSize="small" color="disabled" />
              )}
            </Components.ExpandIconDiv>
          </>
        )}
      </Box>
    </Paper>
  );
}

export default ActivityCard;
