import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import useChartOptions from "features/Charting/hooks/useChartOptions";
import useChartSelections from "components/charting/hooks/useChartSelections";
import useSetChartSelections from "features/Charting/hooks/useSetChartSelections";
import { useCallback, useMemo } from "react";
import ExerciseMetadataService from "services/ExerciseMetadataService";
import { EntityType, ExerciseMetric, Metadata, MetadataType } from "types/index";
import MeasureSelector from "./MeasureSelector/MeasureSelector";
import * as Components from "./MeasureSelectors.components";
import { useTranslation } from "react-i18next";
import ExerciseHelpers from "helpers/ExerciseHelpers";

interface MeasureSelectorsProps {}

function MeasureSelectors(props: MeasureSelectorsProps) {
  const selections = useChartSelections();
  const options = useChartOptions();
  const setSelections = useSetChartSelections();
  const { t } = useTranslation();

  const setMeasureAtIndex = useCallback(
    (measureIndex: number) => (measure: Metadata | null) => {
      if (!measure) throw new Error("Should not be able to set null as a Measure value");

      setSelections((prev) => ({
        measures: prev.measures.map((prevMeasure, prevMeasureIndex) => {
          if (measureIndex !== prevMeasureIndex) {
            return prevMeasure;
          }

          if (measure.type === MetadataType.EXERCISE) {
            return {
              type: measure.type,
              id: measure.metadata.id,
              metadata: measure.metadata,
              metric: ExerciseHelpers.getMetricByFieldNameOrDefault(measure.metadata, prevMeasure.metric?.field),
              repMetric: ExerciseMetadataService.repMetricByFieldNameOrFirstOrNull(
                measure.metadata,
                prevMeasure.repMetric?.field
              ),
            };
          }

          if (measure.type === MetadataType.ANTHROPOMETRIC) {
            return {
              type: measure.type,
              id: measure.metadata.value,
              metadata: measure.metadata,
              metric: null,
              repMetric: null,
            };
          }

          return {
            type: measure.type,
            id: measure.metadata.id,
            metadata: measure.metadata,
            metric: null,
            repMetric: null,
          };
        }),
      }));
    },
    [setSelections]
  );

  const setMeasureMetricAtIndex = useCallback(
    (measureIndex: number) => (measureMetric: ExerciseMetric) => {
      setSelections((prev) => ({
        measures: prev.measures.map((prevMeasure, prevMeasureIndex) => {
          if (measureIndex !== prevMeasureIndex) {
            return prevMeasure;
          }

          if (prevMeasure.type !== MetadataType.EXERCISE) {
            throw new Error("You can only selet a metric on an Exercise");
          }

          return {
            ...prevMeasure,
            metric: measureMetric,
          };
        }),
      }));
    },
    [setSelections]
  );

  const removeMeasure = useCallback(
    (measureIndex: number) => () => {
      setSelections((prev) => ({
        measures: prev.measures.filter((_, index) => index !== measureIndex),
      }));
    },
    [setSelections]
  );

  const addMeasure = useCallback(() => {
    setSelections((prev) => ({
      measures: [...prev.measures, { type: null, id: null, metadata: null, metric: null, repMetric: null }],
    }));
  }, [setSelections]);

  const measureInputs = selections.measures.map((measure, measureIndex, messureArray) => (
    <MeasureSelector
      key={measureIndex}
      options={options.measures}
      setMeasure={setMeasureAtIndex(measureIndex)}
      measure={measure}
      removeMeasure={messureArray.length > 1 && removeMeasure(measureIndex)}
      setMeasureMetric={setMeasureMetricAtIndex(measureIndex)}
    />
  ));

  const showAddMeasurementButton = useMemo(() => {
    const areAnySourcesGroup = Boolean(selections.sources.find((source) => source.type === EntityType.GROUP));

    return !areAnySourcesGroup && selections.sources.length <= 1 && selections.measures.length <= 3;
  }, [selections.measures.length, selections.sources]);

  return (
    <Grid container spacing="0px" p={0}>
      <Grid item xs={12}>
        {measureInputs}
      </Grid>
      {showAddMeasurementButton && (
        <Grid item xs={12}>
          <Components.AddMeasureButton onClick={addMeasure} startIcon={<AddIcon />} disableElevation>
            {t("Charting.addMeasurementButtonText")}
          </Components.AddMeasureButton>
        </Grid>
      )}
    </Grid>
  );
}

export default MeasureSelectors;
