import useRequest from "hooks/useRequest";
import { weightMetric } from "../../AthleteProfilingChart.config";
import UnitHelper from "helpers/UnitHelper";
import useOneRepMaxActions from "providers/OneRepMaxProvider/useOneRepMaxActions";
import useInfoMessage from "hooks/useInfoMessage";
import useUnitFormatter from "hooks/useUnitFormatter";
import { useCallback } from "react";
import { Variants } from "types";
import { TooltipColorStrip, TooltipDataRow, TooltipPaper } from "components/TooltipCard/TooltipCard.components";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import OneRepMaxIcon from "components/Tables/icons/OneRepMaxIcon";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import useGetOneRepMaxFromStore from "hooks/useGetOneRepMaxFromStore";
import useColorRegistry from "features/Charting/hooks/useColorRegistry";

export interface OneRepMaxEstimateTooltipProps {
  athleteId: string;
  exerciseId: string;
  estimatedOneRepMax: number;
  minimumVelocityThreshold: number;
  onSuccess(): void;
  variants: Variants[];
  entityId: string;
}

function OneRepMaxEstimateTooltip(props: OneRepMaxEstimateTooltipProps) {
  const { athleteId, exerciseId, estimatedOneRepMax, minimumVelocityThreshold, variants, onSuccess, entityId } = props;
  const formattedEstimatedOneRepMax = useUnitFormatter()(estimatedOneRepMax, {
    ...weightMetric,
    displayMetadata: { imperial: { decimals: 0, unit: UnitHelper.units.Pound }, metric: { decimals: 0 } },
  });
  const oneRepMaxActions = useOneRepMaxActions();
  const updateOneRepMax = useRequest(oneRepMaxActions.updateByAthleteIds, true);
  const successMessage = useInfoMessage({ type: "success" });
  const errorMessage = useInfoMessage({ type: "error" });
  const oneRepMaxValue = useGetOneRepMaxFromStore({ athleteId, exerciseId });
  const entityColor = useColorRegistry()(entityId);

  const roundedEstimatedOneRepMax = Math.round(estimatedOneRepMax);

  const handleUpdateOneRepMax = useCallback(
    (variant: Variants | null) => async () => {
      try {
        await updateOneRepMax(exerciseId, [
          { athleteId, oneRepMaxes: [{ exerciseId, weight: roundedEstimatedOneRepMax, variant }] },
        ]);
        successMessage({ message: "1RM Updated Successfully" });
        onSuccess();
      } catch (error) {
        errorMessage({ message: "1RM Update Failed" });
      }
    },
    [athleteId, errorMessage, roundedEstimatedOneRepMax, exerciseId, onSuccess, successMessage, updateOneRepMax]
  );

  const renderUpdateButton = useCallback(
    (variant: Variants | null, disabled?: boolean) => (
      <Grid pl={1}>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={handleUpdateOneRepMax(variant)}
          disableElevation
          disabled={disabled}
          sx={{ textTransform: "capitalize" }}
        >
          Update 1RM{variant ? ` (${variant})` : ""}
        </Button>
      </Grid>
    ),
    [handleUpdateOneRepMax]
  );

  const updateDefaultOrLeftButtonVariant = variants.length ? Variants.Left : null;
  const isUpdateDefaultOrLeftDisabled = Boolean(
    (variants.length ? oneRepMaxValue?.Left : oneRepMaxValue?.default) === roundedEstimatedOneRepMax
  );
  const isUpdateRightDisabled = Boolean(oneRepMaxValue?.Right === roundedEstimatedOneRepMax);

  return (
    <TooltipPaper>
      <TooltipColorStrip entityColor={entityColor} />
      <TooltipDataRow>
        <Grid container alignItems="center">
          <Grid item lineHeight={0}>
            <OneRepMaxIcon />
          </Grid>
          <Grid item xs pl={1}>
            <Typography variant="h4" noWrap title={`Estimated 1RM: ${formattedEstimatedOneRepMax}`}>
              Estimated 1RM{" - "}
              <Box component="span" fontWeight={500}>
                {formattedEstimatedOneRepMax}
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </TooltipDataRow>
      <Divider />
      <TooltipDataRow>
        <Typography color="grey.800" fontWeight="fontWeightNormal" fontSize={12}>
          Estimate based on minimum velocity threshold of{" "}
          <Box fontWeight={500} component="span">
            {minimumVelocityThreshold}m/s
          </Box>
        </Typography>
      </TooltipDataRow>
      <Divider />
      <TooltipDataRow>
        <Grid container alignItems="center">
          <Grid item xs />
          {renderUpdateButton(updateDefaultOrLeftButtonVariant, isUpdateDefaultOrLeftDisabled)}
          {Boolean(variants.length) && renderUpdateButton(Variants.Right, isUpdateRightDisabled)}
        </Grid>
      </TooltipDataRow>
    </TooltipPaper>
  );
}

export default OneRepMaxEstimateTooltip;
