import { styled } from "@mui/material";
import Paper from "components/mui5/Paper";

export const ScrollBoxDiv = styled("div")(({ theme }) => ({
  height: "100%",
  overflowY: "scroll",
  paddingRight: theme.spacing(1),
  paddingBottom: theme.spacing(8),
}));

export const StepsListPaper = styled(Paper)(({ theme }) => {
  return {
    height: "100%",
    position: "relative",
    padding: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(2),
  };
});

export const PaperCard = styled(Paper, {
  shouldForwardProp: (propName: string) => !["superset"].includes(propName),
})<{ superset?: boolean; error?: boolean }>(({ theme, ...props }) => ({
  position: "relative",
  border: `1px solid ${theme.palette.grey[200]}`,
  minHeight: 56,
  borderRadius: 8,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(0),
  ...(props.superset && {
    backgroundColor: theme.palette.secondary.main,
    borderColor: "transparent",
    padding: theme.spacing(1),
  }),
}));
