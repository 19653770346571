import Mui5 from "components/mui5";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface InfoCardProps {
  title: string;
  icon?: JSX.Element;
  children?: React.ReactNode;
  onClose?(): void;
}

function InfoCard(props: InfoCardProps) {
  return (
    <Mui5.Paper sx={{ maxHeight: "100%", p: 3, overflowY: "scroll" }}>
      {props.onClose && (
        <IconButton sx={{ position: "absolute", top: 12, right: 12 }} onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      )}
      <Grid container flexDirection="column">
        <Grid container>
          <Grid item>
            <Typography variant="h1" gutterBottom>
              {props.title}
            </Typography>
          </Grid>
          {props.icon && (
            <Grid item height="24px" pl={0.5}>
              {props.icon}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 1 }} />
        </Grid>
        <Grid item xs={12} flex={1}>
          {props.children}
        </Grid>
      </Grid>
    </Mui5.Paper>
  );
}

export default InfoCard;
