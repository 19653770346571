import Grid from "@mui/material/Grid";
import Mui5 from "components/mui5";
import Tables from "components/Tables";
import { tableCells } from "../AthleteTable.config";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import CanDoAction from "components/CanDoAction";
import Hidden from "@mui/material/Hidden";
import AthleteTableIconButtonControls from "./AthleteTableIconButtonControls";
import { useTranslation } from "react-i18next";

interface AthleteTableControlsProps {}

function AthleteTableControls(props: AthleteTableControlsProps) {
  const navigate = useNavigate();

  const handleAddGroupClick = useCallback(() => {
    navigate("/athlete-hub/add");
  }, [navigate]);
  const { t } = useTranslation();

  const iconButtonsComponent = <AthleteTableIconButtonControls />;

  return (
    <>
      <Grid {...Tables.controlsContainerProps}>
        <Grid item {...tableCells.checkbox} width="32px" pl={1} textAlign="center" lineHeight={0}>
          <Tables.Icons.Search color="inherit" />
        </Grid>
        <Grid item {...tableCells.name} pr={1} minWidth={270} xs={6} lg={3}>
          <Tables.Components.SearchTextInput />
        </Grid>
        <Grid item xs>
          <Hidden lgDown>{iconButtonsComponent}</Hidden>
        </Grid>
        <CanDoAction action="athlete.bulkUpload">
          <Grid item minWidth={0} pr={0.5}>
            <Mui5.Button color="primary" disableElevation variant="contained" href="/bulk-upload" size="medium">
              {t("Athlete.bulkAddAthlete")}
            </Mui5.Button>
          </Grid>
        </CanDoAction>
        <Grid item pr={1}>
          <Mui5.Button color="primary" disableElevation variant="contained" onClick={handleAddGroupClick} size="medium">
            {t("Athlete.addAthlete")}
          </Mui5.Button>
        </Grid>
      </Grid>
      <Hidden lgUp>
        <Grid item>
          <Grid container>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-around">
          <Grid {...Tables.controlsContainerProps} container={false} item pl={1} justifyContent="center">
            {iconButtonsComponent}
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
}

export default AthleteTableControls;
