import FilteredWorkoutsContext from "./FilteredWorkoutsContext";
import useWorkoutsStore from "features/Workouts/providers/WorkoutsProvider/useWorkoutsStore";
import useWorkoutsFilter from "features/Workouts/hooks/useWorkoutsFilter";

interface FilteredWorkoutsProviderProps {
  children?: React.ReactNode;
}

function FilteredWorkoutsProvider(props: FilteredWorkoutsProviderProps) {
  const workouts = useWorkoutsStore();
  const filteredWorkouts = useWorkoutsFilter(workouts);

  return <FilteredWorkoutsContext.Provider value={filteredWorkouts}>{props.children}</FilteredWorkoutsContext.Provider>;
}

export default FilteredWorkoutsProvider;
