import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import RemoteConfigService from "services/RemoteConfigService";
import RemoteConfigContext from "./RemoteConfigContext";

interface RemoteConfigProviderProps {
  children?: React.ReactNode;
}

function RemoteConfigProvider(props: RemoteConfigProviderProps) {
  const [configStore, setConfigStore] = useState<{ [id: string]: boolean | undefined }>({
    HUB_DASHBOARDS_ENABLED: false,
    HUB_ENABLE_GROUP_DASHBOARDS: false,
    HUB_ENABLE_MICROSOFT_SSO: false,
  });
  const [queryParams] = useSearchParams();
  const queryParamsRef = useRef(queryParams);

  const getKeyValue = useCallback(async (key: string) => {
    let value = typeof queryParamsRef.current.get(key) === "string";

    if (!value) {
      value = await RemoteConfigService.getBooleanValue(key);
    }

    setConfigStore((prev) => ({ ...prev, [key]: Boolean(value) }));
  }, []);

  useEffect(() => {
    getKeyValue("HUB_DASHBOARDS_ENABLED");
    getKeyValue("HUB_ENABLE_GROUP_DASHBOARDS");
    getKeyValue("HUB_ENABLE_MICROSOFT_SSO");
  }, [getKeyValue]);

  const getConfigValue = useCallback(
    (key: string) => {
      const keyValue = configStore[key];
      return Boolean(keyValue);
    },
    [configStore]
  );

  return <RemoteConfigContext.Provider value={getConfigValue}>{props.children}</RemoteConfigContext.Provider>;
}

export default RemoteConfigProvider;
