import { useMemo } from "react";
import { ChartLine } from "components/charting/types/Chart.types";
import { MetadataType } from "types/metadata";
import ChartHelpers from "features/Charting/helpers/ChartHelpers";
import useXScale from "../useXScale";
import useYScaleById from "../useYScaleById";
import useChartPersonalBests from "components/charting/contexts/ChartPersonalBestsContext/hooks/useChartPersonalBests";

interface UseChartPbApiProps extends Pick<ChartLine, "metadataType" | "measure" | "source"> {}

function useChartPbApi(line: UseChartPbApiProps) {
  const { metadataType, measure, source } = line;
  const athleteId = source.id;
  const exerciseId = measure.id;
  const scaleId = ChartHelpers.getScaleId(measure);
  const xScale = useXScale();
  const yScale = useYScaleById(scaleId);

  const personalBests = useChartPersonalBests();

  const personalBest = useMemo(() => {
    if (metadataType !== MetadataType.EXERCISE) return null;
    return personalBests.find((pb) => pb.athleteId === athleteId && pb.exerciseId === exerciseId) || null;
  }, [athleteId, exerciseId, metadataType, personalBests]);

  return useMemo(() => {
    if (!personalBest) return [];

    const pbVariants = personalBest?.metrics.find((metric) => metric.metricField === measure.metricField)?.variants;

    return (pbVariants || []).map((pbVariant) => {
      const y = yScale(pbVariant.value);
      const xRange = xScale.range();

      return { ...pbVariant, pb: xRange.map((x) => ({ variant: pbVariant.variant, x, y })) };
    });
  }, [measure.metricField, personalBest, xScale, yScale]);
}

export default useChartPbApi;
