import { Bar } from "@visx/shape";
import { useUnitFormatter } from "hooks";
import useChartingColors from "features/Charting/hooks/useChartingColors";
import useXScale from "features/Charting/hooks/useXScale";
import useYScaleById from "features/Charting/hooks/useYScaleById";
import { SelectedChartMeasure } from "components/charting/types";
import { Fragment, useCallback } from "react";
import { Variants } from "types/exercise";

interface StandardDeviationAreaProps {
  width: number;
  average: number;
  standardDeviation: number;
  scaleId: string;
  variant: Variants | null;
  selectedMetric: SelectedChartMeasure["metric"];
}

function StandardDeviationArea(props: StandardDeviationAreaProps) {
  const { standardDeviation, average, variant, scaleId, selectedMetric, width } = props;
  const xScale = useXScale();
  const yScale = useYScaleById(scaleId);
  const unitFormatter = useUnitFormatter();
  const { lines, axis } = useChartingColors();
  const getX = useCallback((_: unknown, index: number) => xScale.range()[index], [xScale]);

  const setLineLabelPosition = useCallback(
    (yPosition: number, index: number, variant: Variants | null) => {
      const yPositionMod = variant === Variants.Left ? (index ? -4 : +12) : !index ? -4 : +12;
      const xPositionMod = variant === Variants.Left ? (index ? -1 : +1) : !index ? -1 : +1;

      return {
        y: yPosition + yPositionMod,
        x: getX(undefined, index) + xPositionMod,
      };
    },
    [getX]
  );

  if (!standardDeviation) return null;

  const formattedValue = unitFormatter(standardDeviation, selectedMetric);

  const startY = yScale(average + standardDeviation);
  const endY = yScale(average - standardDeviation);
  const height = endY - startY;

  return (
    <Fragment>
      {[startY, endY].map((position, index) => (
        <text
          key={["STD", variant, index].filter(Boolean).join("_")}
          {...setLineLabelPosition(position, index, variant)}
          textAnchor={index ? "end" : undefined}
          fontSize={12}
          fill={axis.line}
        >
          {["σ", `${formattedValue}`, variant && `(${variant.charAt(0)})`].filter(Boolean).join(" ")}
        </text>
      ))}
      <Bar x={0} y={startY} width={width} height={height} opacity={0.15} fill={lines.secondary} />
    </Fragment>
  );
}

export default StandardDeviationArea;
