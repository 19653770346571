import moment from "moment";
import LeaderboardFormHelpers from "features/Leaderboards/helpers/LeaderboardFormHelpers";
import { useCallback, useMemo } from "react";
import { firstBy } from "thenby";
import { DateTime, FireStoreMeasurement, Variants } from "types";

function usePercentageRankings(
  measurements: { [measurementId: string]: FireStoreMeasurement | undefined },
  metricField: string,
  sortOrder: 0 | 1,
  variant: Variants | null,
  timePeriodStartDate: DateTime
) {
  const compareMeasurementValues = useCallback(
    (currentMeasurement: FireStoreMeasurement, newMeasurement: FireStoreMeasurement) => {
      if (sortOrder === 0) {
        return currentMeasurement[metricField] > newMeasurement[metricField];
      }

      return currentMeasurement[metricField] < newMeasurement[metricField];
    },
    [metricField, sortOrder]
  );

  return useMemo(() => {
    const bestMeasurementsFromAllByAthleteId: { [athleteId: string]: FireStoreMeasurement } = {};
    const bestMeasurementsFromTimePeriodByAthleteId: { [athleteId: string]: FireStoreMeasurement } = {};

    Object.values(measurements).forEach((measurement) => {
      const isValidVariant = measurement?.variant ? variant === measurement.variant : true;

      if (typeof measurement === "undefined" || typeof measurement[metricField] !== "number" || !isValidVariant) {
        return;
      }

      const bestFromAll = bestMeasurementsFromAllByAthleteId[measurement.athleteId];

      if (typeof bestFromAll === "undefined" || compareMeasurementValues(bestFromAll, measurement)) {
        bestMeasurementsFromAllByAthleteId[measurement.athleteId] = measurement;
      }

      const bestFromTimePeriod = bestMeasurementsFromTimePeriodByAthleteId[measurement.athleteId];
      const isWithinTimePeriod = moment(measurement.completedDate).isAfter(timePeriodStartDate);

      if (
        isWithinTimePeriod &&
        (typeof bestFromTimePeriod === "undefined" || compareMeasurementValues(bestFromTimePeriod, measurement))
      ) {
        bestMeasurementsFromTimePeriodByAthleteId[measurement.athleteId] = measurement;
      }
    });

    const rankedMeasurementsFromTimePeriod = Object.values(bestMeasurementsFromTimePeriodByAthleteId).sort(
      firstBy<FireStoreMeasurement>(
        (measurement) => {
          const recentValue = measurement[metricField];
          const bestFromAll = bestMeasurementsFromAllByAthleteId[measurement.athleteId][metricField];
          const isSortDescending = sortOrder === 1;

          return LeaderboardFormHelpers.getPercentageComparisonForMeasurements(
            recentValue,
            bestFromAll,
            isSortDescending
          );
        },
        { direction: "desc" }
      ).thenBy((measurement) => measurement[metricField], { direction: sortOrder === 1 ? "desc" : "asc" })
    );

    function getComparativeMeasurement(athleteId: string) {
      return bestMeasurementsFromAllByAthleteId[athleteId];
    }

    return {
      measurements: rankedMeasurementsFromTimePeriod,
      getComparative: getComparativeMeasurement,
    };
  }, [compareMeasurementValues, measurements, metricField, sortOrder, timePeriodStartDate, variant]);
}

export default usePercentageRankings;
