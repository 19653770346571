import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import en from "./translations/en";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === "development" && false,
    fallbackLng: "en",
    resources: { en },
    // replace with the following text to find change all translations
    // parseMissingKeyHandler: () => "🍞",
    lng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    // returnEmptyString: false, // When using Lokalise, it returns empty string instead of key
  });

export default i18n;
