import { useEffect } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import Constants from "../../../config/Constants";
import PageContent from "components/PageContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import GroupForm from "../../Forms/GroupForm";
import Confirmation from "../../Confirmation_deprecated/Confirmation";
import { useInfoMessage } from "../../../hooks";
import useAllAthletes from "hooks/useAllAthletes";
import useGroupsContext from "hooks/useGroupsContext";
import Mui5 from "components/mui5";
import { useTranslation } from "react-i18next";

function EditGroup(props) {
  const groupId = useMatch("/groups/:id/edit")?.params?.id;
  const [groups, groupActions] = useGroupsContext();
  const athletes = useAllAthletes();
  const infoMessage = useInfoMessage({ type: "error" });
  const navigate = useNavigate();

  const handleGroupSave = (groupInfo) => {
    return groupActions
      .update(groupId, groupInfo)
      .then(() => {
        navigate(`${Constants.routes.groups.route}/${groupId}`);
      })
      .catch(infoMessage);
  };

  const confirmGroupDeletion = () => {
    groupActions
      .delete(groupId)
      .then(() => {
        navigate(`${Constants.routes.groups.route}`);
      })
      .catch(infoMessage);
  };

  function getGroupFromStore() {
    const group = groups.find((group) => group.id === groupId);

    if (!group) {
      return {};
    }

    const { name, colour, description, athleteIds } = group;

    return {
      name,
      colour,
      description,
      athleteIds: athletes.filter((athlete) => athleteIds.includes(athlete.id)),
    };
  }

  useEffect(() => {
    groupActions.fetchById(groupId);
  }, [groupActions, groupId]);

  const group = getGroupFromStore();

  const { t } = useTranslation();

  return (
    <PageContent
      pageHeadingLink={`${Constants.routes.groups.route}/${groupId}`}
      pageHeading={`${Constants.routes.groupsEdit.title}`}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <Mui5.Paper>
            <Box p={3}>
              <GroupForm
                initialValues={group}
                submitText={t("Group.Overview.editGroupPageSubmitButtonText")}
                onSubmit={handleGroupSave}
                athleteOptions={athletes}
              />
              <Grid container spacing={0} mt={1.5}>
                <Grid item xs={12}>
                  <Confirmation
                    confirmationMessage={t("Group.Overview.editGroupConfirmationPrompt", { groupName: group.name })}
                    positiveHandler={confirmGroupDeletion}
                    buttonText={t("Group.Overview.editGroupConfirmationButtonText")}
                  />
                </Grid>
              </Grid>
            </Box>
          </Mui5.Paper>
        </Grid>
      </Grid>
    </PageContent>
  );
}

export default EditGroup;
