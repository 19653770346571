import useDefinedContext from "../../../../hooks/useDefinedContext";
import LivePbCheckContext from "features/Live/providers/LivePbCheckProvider/LivePbCheckContext";
import { useEffect } from "react";

function useIsPbChecker(athleteId: string, exerciseId: string, measurementId: string, timeout = 1000) {
  const [pbMeasurementMap, testMeasurementForPb] = useDefinedContext(LivePbCheckContext);

  useEffect(() => {
    testMeasurementForPb(athleteId, exerciseId, measurementId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return pbMeasurementMap[athleteId]?.[exerciseId]?.[measurementId];
}
export default useIsPbChecker;
