import useMultiSelectIds from "providers/MultiSelectProvider/useMultiSelectIds";
import useWorkoutsStore from "features/Workouts/providers/WorkoutsProvider/useWorkoutsStore";
import useEntityFilterById from "hooks/useEntityFilterById";
import { useCallback, useMemo } from "react";
import IconPopperMenu from "components/IconPopperMenu";
import Tables from "components/Tables";
import TableWorkoutLabelAssignPopperContent from "components/Tables/TableWorkoutLabelAssignPopperContent";
import useWorkoutsActions from "features/Workouts/providers/WorkoutsProvider/useWorkoutsActions";
import useRequest from "hooks/useRequest";
import useIntersectingWorkoutLabels from "hooks/useIntersectingWorkoutLabels";
import useFilteredWorkouts from "features/Workouts/providers/FilteredWorkoutsProvider/useFilteredWorkouts";
import logEvent from "utils/logEvent";
import AnalyticsService from "services/AnalyticsService";

export interface AllWorkoutsBulkLabelAssignmentControlProps {
  tooltipTitle: string;
}

function AllWorkoutsBulkLabelAssignmentControl(props: AllWorkoutsBulkLabelAssignmentControlProps) {
  const selectedWorkoutIds = useMultiSelectIds();
  const workouts = useWorkoutsStore();
  const filteredWorkouts = useFilteredWorkouts();
  const workoutActions = useWorkoutsActions();
  const bulkUpdateLabel = useRequest(workoutActions.bulkUpdateLabel);

  const availableWorkouts = useEntityFilterById(selectedWorkoutIds, workouts);
  const intersectingWorkoutLabels = useIntersectingWorkoutLabels(availableWorkouts);

  const visibleSelectedWorkouts = useEntityFilterById(selectedWorkoutIds, filteredWorkouts);
  const visibleSelectedWorkoutIds = useMemo(() => visibleSelectedWorkouts.map((a) => a.id), [visibleSelectedWorkouts]);

  const handleLabelAssignment = useCallback(
    async (labelId: string, isAssignment: boolean) => {
      await bulkUpdateLabel(visibleSelectedWorkoutIds, labelId, isAssignment);
      if (isAssignment) {
        logEvent("WORKOUTS_LABEL_ASSIGN", {
          [AnalyticsService.PROPERTIES.WORKOUTS_COUNT]: visibleSelectedWorkoutIds.length,
        });
      } else {
        logEvent("WORKOUTS_LABEL_UNASSIGN", {
          [AnalyticsService.PROPERTIES.WORKOUTS_COUNT]: visibleSelectedWorkoutIds.length,
        });
      }
    },
    [bulkUpdateLabel, visibleSelectedWorkoutIds]
  );

  return (
    <IconPopperMenu
      tooltipTitle={props.tooltipTitle}
      icon={<Tables.Icons.AssignLabels />}
      disabled={!Boolean(visibleSelectedWorkoutIds.length)}
      popperContent={
        <TableWorkoutLabelAssignPopperContent
          commonLabels={intersectingWorkoutLabels}
          onChange={handleLabelAssignment}
        />
      }
    />
  );
}

export default AllWorkoutsBulkLabelAssignmentControl;
