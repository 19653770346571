import { useEffect, useState } from "react";
import PageContent from "components/PageContent";
import AthleteTable from "./AthleteTable";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AthleteMonitoringFetchWrapper from "components/AthleteMonitoringFetchWrapper";
import { LocalisedLoadingContext } from "components/LocalisedLoadingProvider";
import logEvent from "utils/logEvent";
import VerticalPageLayout from "components/VerticalPageLayout";
import MultiSelectProvider from "providers/MultiSelectProvider";
import Constants from "config/Constants";
import SearchTextProvider from "providers/SearchTextProvider";
import SelectedEntitiesProvider from "providers/SelectedEntitiesProvider";
import TableSortProvider from "providers/TableSortProvider";
import { ALPHABETICAL_ASC } from "./AthleteTable/AthleteTableControls/AthleteTableControlSort/AthleteTableControlSort.helpers";
import AthleteTableControls from "./AthleteTable/AthleteTableControls";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore";
import AthleteTableInfoCard from "./AthleteTable/AthleteTableInfoCard";

function AthleteHub(props) {
  const athletes = useAthleteStore();
  const loadingState = useState(false);

  useEffect(() => {
    logEvent("ATHLETES_VIEW_ALL");
  }, []);

  return (
    <LocalisedLoadingContext.Provider value={loadingState}>
      <AthleteMonitoringFetchWrapper athletes={athletes}>
        <MultiSelectProvider>
          <SearchTextProvider>
            <SelectedEntitiesProvider>
              <TableSortProvider initialSortKey={ALPHABETICAL_ASC}>
                <VerticalPageLayout
                  top={
                    <Box pt={2} pb={2} pl={2}>
                      <PageContent
                        pageHeading={Constants.routes.athletes.title}
                        infoCardComponent={AthleteTableInfoCard}
                      />
                    </Box>
                  }
                  main={
                    <Grid container width="100%" height={`calc(100% - 24px)`} mx={2}>
                      <Grid item width="100%" height="100%">
                        <AthleteTable TableControlsComponent={AthleteTableControls} />
                      </Grid>
                    </Grid>
                  }
                />
              </TableSortProvider>
            </SelectedEntitiesProvider>
          </SearchTextProvider>
        </MultiSelectProvider>
      </AthleteMonitoringFetchWrapper>
    </LocalisedLoadingContext.Provider>
  );
}

export default AthleteHub;
