import useChartSelections from "components/charting/hooks/useChartSelections";
import ChartColorRegistryProvider from "features/Charting/providers/ChartColorRegistryProvider/ChartColorRegistryProvider";
import { useMemo } from "react";

interface OneRepMaxChartEntityColorProviderProps {
  children?: React.ReactNode;
}

function OneRepMaxChartEntityColorProvider(props: OneRepMaxChartEntityColorProviderProps) {
  const selections = useChartSelections();

  const measures = useMemo(() => selections.measures.map((m) => m.id).filter(Boolean), [selections]) as string[];

  return <ChartColorRegistryProvider entityIds={measures}>{props.children}</ChartColorRegistryProvider>;
}

export default OneRepMaxChartEntityColorProvider;
