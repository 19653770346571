import Mui5 from "components/mui5";
import { useSourceSelectHandlers } from "./SourceSelector.hooks";
import useChartOptions from "../../../hooks/useChartOptions";
import useChartSelections from "../../../../../components/charting/hooks/useChartSelections";
import OpenWith from "@mui/icons-material/OpenWith";
import Collapse from "@mui/material/Collapse";
import ColorableChip from "components/ColorableChip";
import { sxGroupExpand } from "./SourceSelector.components";
import { EntityType } from "types/entity";
import useColorRegistry from "features/Charting/hooks/useColorRegistry";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { t } from "i18next";
import AutocompleteOptionColourable from "components/AutocompleteOptionColourable";

interface SourceSelectorProps {}

function SourceSelector(props: SourceSelectorProps) {
  const options = useChartOptions();
  const selections = useChartSelections();
  const getEntityColor = useColorRegistry();

  const { getFilterOptionLabel, getSourceOptionDisabled, handleSourcesChange, handleExpandGroups, showGroupExpand } =
    useSourceSelectHandlers();

  return (
    <>
      <Mui5.Autocomplete
        options={options.sources}
        value={selections.sources}
        multiple
        filterSelectedOptions
        renderInput={(params) => (
          <Mui5.TextField {...params} margin="dense" variant="outlined" color="primary" size="small" fullWidth />
        )}
        renderOption={(props, option) => (
          <AutocompleteOptionColourable
            {...props}
            key={option.id}
            colour={option.type === "GROUP" ? option.colour : undefined}
          >
            {getFilterOptionLabel(option)}
          </AutocompleteOptionColourable>
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });

            return (
              <ColorableChip
                key={option.id}
                label={option.type === EntityType.ATHLETE ? option.fullName : option.name}
                customColor={option.type === EntityType.ATHLETE ? getEntityColor(option.id) : option.colour}
                deleteIcon={<ClearOutlinedIcon color="primary" viewBox="-6 -6 36 36" />}
                {...tagProps}
              />
            );
          })
        }
        groupBy={(option) => option.type}
        getOptionLabel={getFilterOptionLabel}
        getOptionDisabled={getSourceOptionDisabled}
        onChange={handleSourcesChange}
        disabled={!options.sources.length}
      />
      <Collapse in={showGroupExpand}>
        <Mui5.Button
          size="small"
          variant="text"
          onClick={handleExpandGroups}
          startIcon={<OpenWith />}
          endIcon={<OpenWith />}
          sx={sxGroupExpand}
        >
          {t("Charting.sourceSelectorExpandGroupsButtonText")}
        </Mui5.Button>
      </Collapse>
    </>
  );
}

export default SourceSelector;
