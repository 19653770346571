import { styled } from "@mui/material";
import Paper, { paperClasses } from "components/mui5/Paper";

export const CardPaper = styled(Paper)({
  position: "relative",
  [`&.${paperClasses.root}`]: {
    flex: 1,
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    minHeight: 0,
  },
});
