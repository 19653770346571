import useGroups from "providers/GroupsProvider/useGroups";
import { useFormikContext } from "formik";
import { LeaderboardFormValues, LeaderboardTimePeriod } from "../LeaderboardEditable.types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { leaderboardTimePeriods } from "../LeaderboardEditable.config";
import { Group } from "contexts/GroupsContext/GroupsContext";
import Grid from "@mui/material/Grid";
import FormText from "components/Form/FormText";
import FormAutocomplete from "components/Form/FormAutocomplete/FormAutocomplete";
import Mui5 from "components/mui5";
import { Exercise, ExerciseMetric, Variants } from "types/exercise";
import useExercises from "providers/ExercisesProvider/useExercises";
import { exerciseAutocompleteFilterOptions } from "config/autocomplete";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "components/Icons/SettingsIcon";
import CloseIcon from "@mui/icons-material/Close";
import TrashIcon2 from "components/Icons/TrashIcon2";
import * as Components from "./LeaderboardEditableConfigPanel.components";
import LeaderboardHelpers from "features/Leaderboards/helpers/LeaderboardHelpers";
import Tooltip from "components/Tooltip";
import PercentageRankingConfig from "./PercentageRankingConfig";
import LeaderboardFormHelpers from "features/Leaderboards/helpers/LeaderboardFormHelpers";
import CanDoAction from "components/CanDoAction";
import { useTranslation } from "react-i18next";
import AutocompleteOptionColourable from "components/AutocompleteOptionColourable";

interface LeaderboardEditableConfigPanelProps {
  configPanelOpen?: boolean;
  onDelete(): void;
  isFullscreen?: boolean;
}

function LeaderboardEditableConfigPanel(props: LeaderboardEditableConfigPanelProps) {
  const { t } = useTranslation();
  const { configPanelOpen = false } = props;
  const [showConfig, setShowConfig] = useState(configPanelOpen);
  const groups = useGroups();
  const exercises = useExercises();

  const { values, setValues, isSubmitting, touched, isValid, dirty, submitForm } =
    useFormikContext<LeaderboardFormValues>();

  useEffect(() => {
    if (!(dirty && isValid && values.exercise && values.metric)) return;

    const timeout = setTimeout(() => {
      submitForm();
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [dirty, isValid, submitForm, values.exercise, values.metric]);

  const unmountingRef = useRef(false);

  useEffect(
    () => () => {
      unmountingRef.current = true;
    },
    []
  );
  useEffect(() => {
    return () => {
      if (!(unmountingRef.current && dirty && isValid && values.exercise && values.metric)) return;

      submitForm();
    };
  }, [dirty, isValid, submitForm, values.exercise, values.metric]);

  const handleGroupsChange = useCallback(
    (_: any, groups: Group[]) => setValues((values) => ({ ...values, groups })),
    [setValues]
  );

  const handlePeriodChange = useCallback(
    (_: React.MouseEvent<HTMLElement, MouseEvent>, timePeriod: LeaderboardTimePeriod) =>
      LeaderboardFormHelpers.onTimePeriodChange(setValues, timePeriod),
    [setValues]
  );

  const { handleExerciseChange, handleMetricChange } = useMemo(() => {
    return {
      handleExerciseChange: (_: any, exercise: Exercise | null = null) => {
        setValues((prevValues) => {
          const defaultOldName = LeaderboardHelpers.getDefaultName(prevValues);

          return {
            ...prevValues,
            name: defaultOldName && prevValues.name.indexOf(defaultOldName) !== -1 ? "" : prevValues.name,
            exercise,
            metric: exercise?.metrics.find((metric) => metric.isDefault) || exercise?.metrics[0] || null,
            variant: exercise?.variants.length ? prevValues.variant || exercise.variants[0] : null,
          };
        });
      },
      handleMetricChange: (_: any, metric: ExerciseMetric | null) => {
        setValues((prevValues) => {
          const defaultOldName = LeaderboardHelpers.getDefaultName(prevValues);

          return {
            ...prevValues,
            name: defaultOldName && prevValues.name.indexOf(defaultOldName) !== -1 ? "" : prevValues.name,
            metric,
          };
        });
      },
    };
  }, [setValues]);

  const handleVariantChange = useCallback(
    (_: any, variant: Variants | null = null) => {
      setValues((prevValues) => {
        const defaultOldName = LeaderboardHelpers.getDefaultName(prevValues);

        return {
          ...prevValues,
          name: defaultOldName && prevValues.name.indexOf(defaultOldName) !== -1 ? "" : prevValues.name,
          variant,
        };
      });
    },
    [setValues]
  );

  const toggleConfigPanel = useCallback(() => setShowConfig((prev) => !prev), []);

  const showVariantToggle = Boolean(values.exercise?.variants.length);

  return (
    <Grid
      container
      px={2}
      py={1.5}
      spacing={0}
      sx={{ flexDirection: "column", overflowY: "auto", overflowX: "hidden" }}
      columns={12}
      wrap="nowrap"
    >
      <Grid item xs={12}>
        <Collapse in={showConfig}>
          <Grid container>
            <Grid item xs={12} mt={1}>
              <Grid container alignItems="center" mb={1} columns={12}>
                <Grid item xs>
                  <FormText
                    name="name"
                    label={t("Leaderboard.configInputNameLabel")}
                    size="small"
                    margin="none"
                    placeholder={LeaderboardHelpers.getDefaultName(values)}
                    fullWidth
                  />
                </Grid>
                <Grid item pl={2} mt={0.5} pr={0.5} height={32}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item>
                  <Tooltip title={t("closeLabel")} placement="top" arrow>
                    <IconButton color="primary" size="small" onClick={toggleConfigPanel}>
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container columns={12} spacing={0}>
                <Grid item xs={6} pr={0.5}>
                  <FormAutocomplete
                    name="exercise"
                    disableClearable={!!values.exercise}
                    value={values.exercise}
                    options={exercises}
                    renderInput={(params: any) => (
                      <Mui5.TextField
                        {...params}
                        margin="dense"
                        label={t("Leaderboard.configInputExerciseLabel")}
                        variant="outlined"
                        color="primary"
                        error={touched.exercise && !values.exercise}
                        size="small"
                        fullWidth
                      />
                    )}
                    multiple={false}
                    groupBy={(option: Exercise) => option.category}
                    getOptionLabel={(option: Exercise) => option.name}
                    onChange={handleExerciseChange}
                    disabled={!exercises.length}
                    filterOptions={exerciseAutocompleteFilterOptions}
                  />
                </Grid>
                <Grid item xs={6} pl={0.5}>
                  <Grid container columns={12} spacing={0}>
                    <Grid item xs={!showVariantToggle ? 12 : true}>
                      <FormAutocomplete
                        name="metric"
                        disableClearable={!!values.metric}
                        value={values.metric}
                        options={values.exercise?.metrics || []}
                        renderInput={(params: any) => (
                          <Mui5.TextField
                            {...params}
                            margin="dense"
                            label={t("Leaderboard.configInputMetricLabel")}
                            variant="outlined"
                            color="primary"
                            error={touched.metric && !values.metric}
                            size="small"
                            fullWidth
                          />
                        )}
                        multiple={false}
                        getOptionLabel={(option: ExerciseMetric) => option.name}
                        onChange={handleMetricChange}
                        disabled={!values.exercise?.metrics}
                        filterOptions={exerciseAutocompleteFilterOptions}
                      />
                    </Grid>
                    {showVariantToggle && (
                      <Grid item minWidth={"60px"}>
                        <Box textAlign="center" pl={1} sx={Components.sxVariantToggleBox}>
                          <Mui5.ToggleButtonGroup
                            value={values.variant}
                            onChange={handleVariantChange}
                            exclusive
                            size="small"
                            color="primary"
                            fullWidth
                            sx={{
                              height: props.isFullscreen ? "40px" : "37px",
                              mt: 0.5,
                            }}
                          >
                            <Mui5.ToggleButton
                              color="primary"
                              value="Left"
                              aria-label="left variant"
                              size="small"
                              sx={{ px: 0.5 }}
                            >
                              L
                            </Mui5.ToggleButton>
                            <Mui5.ToggleButton
                              color="primary"
                              value="Right"
                              aria-label="right variant"
                              size="small"
                              sx={{ px: 0.5 }}
                            >
                              R
                            </Mui5.ToggleButton>
                          </Mui5.ToggleButtonGroup>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} mb={0.5}>
              <Typography
                variant="caption"
                color="rgba(0, 0, 0, 0.6)"
                fontWeight="fontWeightRegular"
                sx={Components.sxTimePeriodLabelTypography}
              >
                {t("Leaderboard.configInputTimePeriodLabel")}
              </Typography>
              <Mui5.ToggleButtonGroup
                value={values.type}
                onChange={handlePeriodChange}
                exclusive
                size="small"
                fullWidth
                sx={{ height: props.isFullscreen ? "40px" : "37px" }}
              >
                {leaderboardTimePeriods.map((period) => (
                  <Mui5.ToggleButton
                    key={period.value}
                    value={period}
                    aria-label={`${t("Leaderboard.configInputTimePeriodLabel")}: ${period.label}`}
                    size="small"
                    color="primary"
                    disabled={LeaderboardFormHelpers.isTimePeriodOptionDisabled(period, values)}
                  >
                    {period.label}
                  </Mui5.ToggleButton>
                ))}
              </Mui5.ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PercentageRankingConfig />
          </Grid>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs>
            <CanDoAction action="group.view">
              <FormAutocomplete
                multiple
                name="groups"
                value={values.groups}
                onChange={handleGroupsChange}
                getOptionLabel={(option) => option.name}
                options={groups}
                limitTags={2}
                renderOption={(optionProps, option) => (
                  <AutocompleteOptionColourable {...optionProps} key={option.id} colour={option.colour}>
                    {option.name}
                  </AutocompleteOptionColourable>
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return <Mui5.Chip key={option.id} label={option.name} customColor={option.colour} {...tagProps} />;
                  })
                }
                renderInput={(params) => (
                  <Mui5.TextField
                    {...params}
                    margin="dense"
                    label={t("Leaderboard.configInputGroupsFilterLabel")}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </CanDoAction>
          </Grid>
          <Grid item pl={2} mt={0.5} pr={0.5} height={32}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item mt={0.5}>
            <Tooltip title={t("deleteButtonText")} placement="top" arrow>
              <IconButton
                size="small"
                color="primary"
                onClick={props.onDelete}
                disabled={isSubmitting}
                aria-label={`delete-leaderboard-${values.name}`}
              >
                <TrashIcon2 />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item mt={0.5}>
            <Tooltip title={t("configurationLabel")} placement="top" arrow>
              <IconButton
                color="primary"
                size="small"
                onClick={toggleConfigPanel}
                aria-label={`open-leaderboard-config-panel-${values.name}`}
              >
                <SettingsIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LeaderboardEditableConfigPanel;
