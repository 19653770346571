import {
  WeightTypes,
  Workout,
  WorkoutTaskCustomExerciseType,
  WorkoutTaskExerciseType,
  WorkoutTaskNoteType,
  WorkoutTaskType,
} from "features/Workouts/Workouts.types";
import UserService from "services/UserService";
import { Exercise } from "types";
import { OrganisationRole } from "types/organisation";

interface WorkoutHelpersOptions {
  role: OrganisationRole;
  organisationId?: string;
}

class WorkoutHelpers {
  static isUserCreator(creatorId: string | undefined) {
    return creatorId === UserService.currentUser?.uid;
  }

  static isUserOrganisationOwner(organisationId: string | undefined) {
    return Boolean(organisationId === UserService.currentUser?.uid);
  }

  static isUserOrganisationOwnerOrCreator(creatorId: string | undefined, organisationId: string | undefined) {
    return WorkoutHelpers.isUserCreator(creatorId) || WorkoutHelpers.isUserOrganisationOwner(organisationId);
  }

  static isCreatedByOrganisationOwner(workout: Workout) {
    return typeof workout.organisationId !== "undefined" && workout.creatorId === workout.organisationId;
  }

  private static canModify(workout: Workout, { role }: WorkoutHelpersOptions) {
    /**
     * Can delete/edit/publish/assign workout when:
     * - The user is the org owner
     * - OR The workout wasn't created by the org owner
     */
    return role === OrganisationRole.OWNER || !WorkoutHelpers.isCreatedByOrganisationOwner(workout);
  }

  static canTogglePublished = WorkoutHelpers.canModify;

  static canDelete = WorkoutHelpers.canModify;

  static canMakeAssignments = WorkoutHelpers.canModify;

  static canEdit = WorkoutHelpers.canModify;

  static canMultiSelect = WorkoutHelpers.canModify;

  static isChangeAffectingUnknownAthletesOrGroups(workout: Workout) {
    return workout.isShared;
  }

  static willAccessBeRemoved(workout: Workout) {
    return (
      workout.groupIds.length === 1 &&
      !WorkoutHelpers.isUserOrganisationOwnerOrCreator(workout.creatorId, workout.organisationId)
    );
  }

  static convertCustomExerciseTaskToExercise(
    task: WorkoutTaskCustomExerciseType,
    options: { exercise: Exercise }
  ): WorkoutTaskExerciseType {
    const newTask: WorkoutTaskExerciseType = {
      type: WorkoutTaskType.EXERCISE,
      weightType: WeightTypes.ABSOLUTE,
      exercise: options.exercise,
      restPeriod: null,
      note: task.note,
      setMetadata: task.setMetadata,
      id: task.id,
      useSensor: null,
    };

    return newTask;
  }

  static convertCustomExerciseTaskToNote(task: WorkoutTaskCustomExerciseType): WorkoutTaskNoteType {
    const newTask: WorkoutTaskNoteType = {
      type: WorkoutTaskType.NOTE,
      title: task.title,
      note: task.note,
      id: task.id,
    };

    return newTask;
  }

  static canToggleUseSensor(task: WorkoutTaskExerciseType) {
    return ["Strength Endurance", "Strength Pathway", "Barbell Velocity", "Slams, Swings & Throws"].includes(
      task.exercise?.category
    );
  }

  static isUseSensorEnabled(useSensor?: boolean | null) {
    return typeof useSensor === "boolean" ? useSensor : true;
  }
}

export default WorkoutHelpers;
