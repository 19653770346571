import { useContext, useMemo } from "react";
import useUserContext from "hooks/useUserContext";
import useRole from "providers/RoleProvider/useRole";
import { Dashboard } from "types/Dashboard";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import SelectedDashboardContext from "features/Dashboards/providers/DashboardProvider/contexts/SelectedDashboardContext";

export interface DashboardPermissions {
  saveDisabled: boolean;
  toggleGlobalDisabled: boolean;
}

function useDashboardPermissions(passedInDashboard?: Dashboard | null): DashboardPermissions {
  const dashboardByContext = useContext(SelectedDashboardContext);
  const dashboard = dashboardByContext || passedInDashboard;
  const role = useRole();
  const org = useOrganisation();
  const [{ profile }] = useUserContext();

  return useMemo(
    () => ({
      saveDisabled: Boolean(dashboard && role !== "OWNER" && dashboard.creatorId !== profile?.id),
      toggleGlobalDisabled: Boolean(!(role === "OWNER" && org.id === dashboard?.creatorId)),
    }),
    [dashboard, org.id, profile?.id, role]
  );
}

export default useDashboardPermissions;
