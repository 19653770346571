import { Theme, alpha } from "@mui/material";

export const calendarPickerStyle = ({ theme, ...props }: { theme: Theme }) => {
  return {
    "& .MuiPickersDay-root": {
      color: theme.palette.grey[600],
      fontSize: theme.typography.h4.fontSize,
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.getContrastText(theme.palette.grey[300]),
      },
    },
    "& .MuiPickersDay-root.Mui-disabled": {
      color: theme.palette.grey[600],
      fontWeight: theme.typography.fontWeightLight,
    },
    "& .MuiPickersDay-root.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.getContrastText(theme.palette.secondary.main),
      "&:hover": {
        backgroundColor: alpha(theme.palette.secondary.light, 0.75),
        color: theme.palette.getContrastText(alpha(theme.palette.secondary.light, 0.75)),
      },
      "&:focus": {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.getContrastText(theme.palette.secondary.main),
      },
    },
  };
};
