import { useMemo } from "react";
import { firstBy } from "thenby";
import { Workout } from "features/Workouts/Workouts.types";
import useSelectedEntities from "providers/SelectedEntitiesProvider/useSelectedEntities";
import useSearchText from "providers/SearchTextProvider/useSearchText";
import useTableSort from "providers/TableSortProvider/useTableSort";
import useEntityTextSearch from "hooks/useEntityTextSearch";
import { Athlete } from "types/athlete";
import { Group } from "types/groups";
import useFilterByAthletesOrGroups from "hooks/useFilterByAthletesOrGroups";
import useSelectedWorkoutLabels from "providers/SelectedWorkoutLabelsProvider/useSelectedWorkoutLabels";
import naturalCompare from "config/sort/naturalSort";

const searchableProperties: (keyof Workout)[] = ["name"];

function useWorkoutsFilter(workouts: Workout[]) {
  const sortBy = useTableSort();
  const searchText = useSearchText();
  const selectedAthletesAndGroups = useSelectedEntities<Athlete | Group>();
  const selectedWorkoutLabels = useSelectedWorkoutLabels();
  const searchedWorkouts = useEntityTextSearch(searchText, workouts, searchableProperties);

  const workoutsFilteredByAthleteOrGroups = useFilterByAthletesOrGroups(selectedAthletesAndGroups, searchedWorkouts);
  const workoutsFilteredByLabels = useMemo(() => {
    if (!selectedWorkoutLabels.length) return workoutsFilteredByAthleteOrGroups;

    return workoutsFilteredByAthleteOrGroups.filter((workout) =>
      selectedWorkoutLabels.every((label) => workout.labels.includes(label.name))
    );
  }, [workoutsFilteredByAthleteOrGroups, selectedWorkoutLabels]);

  const sortedWorkouts = useMemo(() => {
    const naturalSort = (a: Workout, b: Workout) => naturalCompare(a.name, b.name);

    return workoutsFilteredByLabels
      .slice(0)
      .sort(
        sortBy.key === "name"
          ? firstBy(naturalSort, { ignoreCase: true, direction: sortBy.direction })
          : firstBy(sortBy.key, { ignoreCase: true, direction: sortBy.direction }).thenBy(naturalSort)
      );
  }, [workoutsFilteredByLabels, sortBy.direction, sortBy.key]);

  return sortedWorkouts;
}

export default useWorkoutsFilter;
