import { alpha, styled } from "@mui/material";
import Mui5 from "components/mui5";

export const TableHeader = styled("div")((props) => ({
  backgroundColor: props.theme.palette.primary.main,
  color: props.theme.palette.common.white,
}));

export const PaperCard = styled(Mui5.Paper)((props) => ({
  overflowY: "hidden",
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

export const RowsBox = styled("div")((props) => ({
  flex: 1,
  overflowY: "hidden",
}));

export const MultiSelectCheckbox = styled(Mui5.Checkbox)((props) => ({
  color: props.theme.palette.common.white,
  "&.Mui-checked": { color: props.theme.palette.common.white },
  "&.MuiCheckbox-indeterminate": { color: props.theme.palette.common.white },
  opacity: 0.85,
}));

export const TableRow = styled("div")<{ selected?: boolean }>((props) => ({
  minHeight: 56,
  borderBottom: `1px solid ${props.theme.palette.grey[400]}`,
  boxSizing: "border-box",
  backgroundColor: props.selected ? alpha(props.theme.palette.primary.main, 0.1) : undefined,
}));
