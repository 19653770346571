import { styled } from "@mui/material";
import MuiButtonBase /* , { buttonBaseClasses } */ from "@mui/material/ButtonBase";

const ButtonBase = styled(MuiButtonBase)({
  fontFamily: ["'Roboto'", "-apple-system", "BlinkMacSystemFont", "sans-serif"].join(", "),
});

// NOTE: ButtonBase immediate child should have flex: 1, so content stretches full width

export default ButtonBase as typeof MuiButtonBase;
