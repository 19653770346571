import MuiDialog from "@mui/material/Dialog";
import DialogTitle from "./components/DialogTitle";
import DialogContent from "./components/DialogContent";
import DialogActions from "./components/DialogActions";
import DialogContentText from "./components/DialogContentText";
import type { PaperProps } from "@mui/material";

function Dialog(props: {
  id: string;
  /**
   * set the maximum width of the dialog
   * @default 300px
   */
  minWidth?: number;
  maxWidth?: number;
  open: boolean;
  /**
   * To diable close on outside click don't pass this prop
   */
  onClose?(event: React.MouseEvent): void;
  title?: React.ReactNode;
  subTitle?: string;
  text?: React.ReactNode;
  /**
   * Alternative to setting `subTitle`, and `text`.
   * If more customization is needed, use this prop instead. */
  content?: React.ReactNode;
  /**
   * Slot for passing action buttons to the dialog. */
  confirmButton?: React.ReactNode;
  cancelButton?: React.ReactNode;
  PaperProps?: Partial<PaperProps>;
  severity?: "error" | "warning" | "info" | "success";
}) {
  const {
    id,
    maxWidth = 300,
    minWidth = undefined,
    title,
    subTitle,
    text,
    content,
    confirmButton,
    cancelButton,
    PaperProps,
    severity = "info",
    ...rest
  } = props;

  return (
    <MuiDialog
      aria-describedby={id}
      PaperProps={{ ...PaperProps, sx: { borderRadius: "20px", ...PaperProps?.sx } }}
      sx={{ "& .MuiDialogContent-root": { maxWidth, minWidth } }}
      {...rest}
    >
      {props.title && (
        <DialogTitle
          title={props.title}
          onClose={rest.onClose}
          sx={severity === "error" ? { bgcolor: "error.main" } : undefined}
        />
      )}
      <DialogContent>
        {Boolean(subTitle || text) && <DialogContentText id={id} subTitle={subTitle} text={text} />}
        {content}
      </DialogContent>
      {Boolean(confirmButton || cancelButton) && (
        <DialogActions>
          {confirmButton}
          {cancelButton}
        </DialogActions>
      )}
    </MuiDialog>
  );
}
export default Dialog;
