import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import useRequest from "hooks/useRequest";
import TrashIcon2 from "components/Icons/TrashIcon2";
import { useCallback, useState } from "react";
import OneRepMaxService from "services/OneRepMaxService";
import useOneRepMaxChartDataActions from "../../useOneRepMaxChartDataActions";
import { useInfoMessage } from "hooks";

interface OneRepMaxTooltipCardActionsProps {
  athleteId: string;
  oneRepMaxId: string;
}

function OneRepMaxTooltipCardActions(props: OneRepMaxTooltipCardActionsProps) {
  const { athleteId, oneRepMaxId } = props;
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const deleteByOneRepMaxId = useRequest(OneRepMaxService.deleteByOneRepMaxId, true);
  const oneRepMaxChartDataActions = useOneRepMaxChartDataActions();
  const successMessage = useInfoMessage({ type: "success" });

  const handleDelete = useCallback(() => {
    setShowConfirmDelete(true);
  }, []);

  const handleCancel = useCallback(() => {
    setShowConfirmDelete(false);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    try {
      await deleteByOneRepMaxId(athleteId, oneRepMaxId);
      oneRepMaxChartDataActions.deleteOneRepMaxById({ id: oneRepMaxId });
      successMessage({ message: "1RM Value Deleted" });
      handleCancel();
    } catch (e) {}
  }, [athleteId, oneRepMaxId, deleteByOneRepMaxId, handleCancel, oneRepMaxChartDataActions, successMessage]);

  return (
    <Grid container height={34}>
      <Grid item xs>
        {showConfirmDelete ? (
          <Grid container alignItems="center" spacing={0.5} justifyContent="flex-end">
            <Grid item>
              <Typography variant="caption">Would you like to delete this value?</Typography>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <Button
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={handleConfirmDelete}
                    disableElevation
                    sx={{ textTransform: "capitalize" }}
                  >
                    Confirm
                  </Button>
                </Grid>
                <Grid item>&nbsp;</Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleCancel}
                    disableElevation
                    sx={{ textTransform: "capitalize" }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent="flex-end">
            <Grid item>
              <IconButton color="error" size="small" onClick={handleDelete} aria-label="delete measurement">
                <TrashIcon2 />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default OneRepMaxTooltipCardActions;
