import { AxisScale, AxisLeft as VisxAxisLeft } from "@visx/axis";
import useUnitFormatter from "hooks/useUnitFormatter";
import useChartingColors from "features/Charting/hooks/useChartingColors";
import { useCallback, useMemo } from "react";
import { MetadataType } from "types/metadata";
import ChartHelpers from "../../helpers/ChartHelpers";
import useChartSelections from "../../../../components/charting/hooks/useChartSelections";
import useColorRegistry from "../../hooks/useColorRegistry";

interface AxisLeftProps {
  color?: string;
  left?: number;
  scaleId: string;
  scale: AxisScale;
  showLabel?: boolean;
}

function AxisLeft(props: AxisLeftProps) {
  const { left = 0, scale, scaleId, showLabel } = props;
  const { axis } = useChartingColors();
  const selections = useChartSelections();
  const formatUnit = useUnitFormatter();
  const measure = ChartHelpers.getMeasureByScaleId(scaleId, selections.measures);

  const color = useColorRegistry()([measure.id, measure.metric?.field].filter(Boolean).join("-") || "");

  const label = useMemo(() => {
    if (measure.type === MetadataType.ANTHROPOMETRIC) {
      return [measure.type, measure.metadata.label].join(" - ");
    } else if (measure.type === MetadataType.WELLNESS) {
      return [measure.type, measure.metadata.name].join(" - ");
    }
    return [measure.metadata?.name, measure.metric?.name].join(" - ");
  }, [measure]);

  const topColorCircle = color ? -7 : undefined;
  const leftColorCircle = left + 10 - 24;

  const topLabelText = color ? -2 : 4;
  const leftLabelText = 14 + left - 24;

  const formatValue = useCallback(
    (value: number) => {
      return formatUnit(value, measure.metric || measure.metadata);
    },
    [formatUnit, measure]
  );

  return (
    <>
      <VisxAxisLeft
        hideAxisLine
        hideTicks
        left={left - 24}
        scale={scale}
        // label={label}
        labelOffset={26}
        labelProps={{ fill: axis.text }}
        tickFormat={formatValue}
        tickLabelProps={() => ({
          fill: axis.line, // tick text color
          color: axis.line,
          fontSize: 12,
          textAnchor: "end",
          dy: "0.3em",
          dx: "-0.4em",
        })}
        stroke={axis.line} // line color
        tickStroke={axis.line} // tick line color
        tickLength={2}
        hideZero
      />
      {showLabel && (
        <>
          <text
            y={leftLabelText}
            x={topLabelText}
            textAnchor="end"
            transform="rotate(-90)"
            fontSize={14}
            fill={axis.text}
          >
            {label}
          </text>
          {color && <circle cy={topColorCircle} cx={leftColorCircle} r={5} fill={color} />}
        </>
      )}
    </>
  );
}

export default AxisLeft;
