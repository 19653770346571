import { useMemo } from "react";
import { Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import type { Athlete } from "types/athlete";
import Mui5 from "components/mui5";
import AthleteHelpers from "helpers/AthleteHelpers";
import useAthleteProfilePhotoURL from "hooks/useAthleteProfilePhotoURL";

interface AthleteNameProps {
  id: string;
  athlete: Athlete | undefined;
  avatarColor?: string;
  showGroups?: boolean;
}

/**
 * If athlete doesn't exist but we have an id, we can still display the id.
 * Otherwise, we display the athlete's details.
 */
function AthleteName(props: AthleteNameProps) {
  const { id, athlete, avatarColor } = props;

  const avatar = useAthleteProfilePhotoURL(athlete);

  const fullName = useMemo(() => (athlete ? AthleteHelpers.getFullName(athlete) : id), [athlete, id]);
  const avatarCustomColor = useMemo(
    () =>
      avatarColor
        ? (theme: Theme) => ({
            backgroundColor: avatarColor,
            color: theme.palette.getContrastText(avatarColor),
          })
        : undefined,
    [avatarColor]
  );

  return (
    <div>
      <Grid container alignItems="center" p={0} spacing="0px" flexWrap="nowrap">
        <Grid item zeroMinWidth>
          <Mui5.Avatar sx={avatarCustomColor} src={avatar.src} alt={avatar.alt}>
            {avatar.initials}
          </Mui5.Avatar>
        </Grid>
        <Grid item xs zeroMinWidth>
          <Grid container alignItems="center">
            <Grid item xs zeroMinWidth>
              <Box ml={2} minWidth={0}>
                <Typography color="textPrimary" variant="h4" noWrap pr={1} title={fullName}>
                  {fullName}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
export default AthleteName;
