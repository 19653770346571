import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import closeIcon from "assets/images/close-icon.svg";
import Popover from "@mui/material/Popover";
import DateTimePickers, {
  DateRange,
  DateRangeValidationError,
  MultiInputDateRangeFieldProps,
} from "components/mui5/DateTimePickers";
import Mui5 from "components/mui5";
import { useCallback, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";

interface PopoverDateRangePickerProps {
  anchorEl: Element | null;
  endDate: moment.Moment;
  open: boolean;
  setPeriod(start: moment.Moment | null, end: moment.Moment | null): void;
  setOpen(open: boolean): void;
  startDate: moment.Moment;
}

const initialErrors: DateRangeValidationError = [null, null];

function PopoverDateRangePicker(props: PopoverDateRangePickerProps) {
  const [[startDateError, endDateError], setErrors] = useState<DateRangeValidationError>(initialErrors);
  const { anchorEl, endDate, open, setPeriod, setOpen, startDate } = props;

  const handleUpdate = useCallback<Required<MultiInputDateRangeFieldProps<moment.Moment>>["onChange"]>(
    ([startDate, endDate], context) => {
      if (context.validationError[0] || context.validationError[1] || !(startDate && endDate)) {
        setErrors(context.validationError);
        return;
      }
      setErrors(initialErrors);
      setPeriod(moment(startDate).startOf("day"), moment(endDate).endOf("day"));
    },
    [setPeriod]
  );

  const handleClose = useCallback(
    function handleClose() {
      setOpen(false);
    },
    [setOpen]
  );

  const multiInputDateRangeFieldProps: MultiInputDateRangeFieldProps<moment.Moment> = useMemo(
    () => ({
      slots: { textField: Mui5.TextField, separator: () => <Typography textAlign="center">to</Typography> },
      slotProps: {
        textField: ({ position }) => ({
          variant: "outlined",
          size: "small",
          error: Boolean(position === "start" ? startDateError : endDateError),
        }),
      },
    }),
    [endDateError, startDateError]
  );

  const dateRangeValues = useMemo<DateRange<moment.Moment>>(() => [startDate, endDate], [endDate, startDate]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box m={2}>
        <Grid container spacing="8px" justifyContent="space-between">
          <Grid item>
            <DateTimePickers.MultiInputDateRangeField
              value={dateRangeValues}
              onChange={handleUpdate}
              slots={multiInputDateRangeFieldProps.slots}
              disableFuture
              slotProps={multiInputDateRangeFieldProps.slotProps}
              format="DD / MM / YYYY"
            />
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Grid>
        </Grid>
        <Box pt={2}>
          <DateTimePickers.StaticDateRangePicker
            displayStaticWrapperAs="desktop"
            value={dateRangeValues}
            onChange={handleUpdate}
            disableFuture
          />
        </Box>
      </Box>
    </Popover>
  );
}

export default PopoverDateRangePicker;
