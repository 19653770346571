import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import useExercises from "providers/ExercisesProvider/useExercises";
import { useEffect, useMemo, useState } from "react";
import { Exercise, ExerciseMetric, Variants } from "types";
import LeaderboardTable from "../../../components/Leaderboards/LeaderboardTableAbsolute";
import { exerciseAutocompleteFilterOptions } from "config/autocomplete";
import { TextFieldProps } from "@mui/material";
import { LocalisedLoadingContext } from "components/LocalisedLoadingProvider";
import MessageWithLogo from "components/MessageWithLogo";
import { useLocation } from "react-router-dom";
import logEvent from "utils/logEvent";
import AnalyticsService from "services/AnalyticsService";
import moment from "moment";
import { periods } from "config/periods";
import Mui5 from "components/mui5";
import * as Components from "./LiveLeaderboard.components";
import ButtonGroup from "components/ButtonGroup";

interface LiveLeaderboardProps {
  initialOptions?: { exercise: Exercise | null; metric: ExerciseMetric | null; variant: Variants | null };
}

function LiveLeaderboard(props: LiveLeaderboardProps) {
  const { initialOptions = { exercise: null, metric: null, variant: null } } = props;
  const exercises = useExercises();
  const loadingState = useState(false);
  const [periodType, setPeriodType] = useState<keyof typeof periods>(periods.month.value);
  const [{ exercise, metric, variant }, setOptions] =
    useState<Required<LiveLeaderboardProps>["initialOptions"]>(initialOptions);
  const location = useLocation();

  const handlers = useMemo(() => {
    return {
      onExerciseChange: (_: any, exercise: Exercise | null) => {
        setOptions((prev) => ({
          ...prev,
          exercise,
          variant: exercise?.variants.length ? prev.variant || exercise?.variants[0] : null,
          metric: exercise?.metrics.find((metric) => metric.isDefault) || exercise?.metrics[0] || null,
        }));
      },
      onMetricChange: (_: any, metric: ExerciseMetric | null) => setOptions((prev) => ({ ...prev, metric })),
      onVariantChange: (_: any, variant: Variants | null) => setOptions((prev) => ({ ...prev, variant })),
    };
  }, []);

  const startDate = useMemo(() => {
    const endDate = moment().endOf("day");

    if (periodType === "all") {
      return moment("2019-01-01").toISOString();
    }

    return endDate.clone().subtract(1, periodType).toISOString();
  }, [periodType]);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const exerciseId = search.get("exerciseId");

    if (!exerciseId) return;

    const exercise = exercises.find((exercise) => exercise.id === exerciseId);

    if (!exercise) return;
    handlers.onExerciseChange(undefined, exercise);
  }, [exercises, handlers, location.search]);

  const hasVariants = Boolean(exercise?.variants.length);

  return (
    <Box height="100%">
      <Grid container direction="column" wrap="nowrap" height="100%" spacing="0px" p={0}>
        <Grid item>
          <Grid container spacing="0px" p={0}>
            <Grid item xs={12} lg={6}>
              <Box p={1} py={0} pb={0} pt={0}>
                <Mui5.Autocomplete
                  disableClearable={!!exercise}
                  value={exercise}
                  options={exercises}
                  renderInput={(params: TextFieldProps) => (
                    <Mui5.TextField
                      {...params}
                      margin="dense"
                      size="small"
                      label="Exercise"
                      color="primary"
                      fullWidth
                    />
                  )}
                  groupBy={(option: Exercise) => option.category}
                  getOptionLabel={(option: Exercise) => option.name}
                  onChange={(_, exercise) => {
                    logEvent("HUB_LIVE_SET_LEADERBOARD_EXERCISE", {
                      [AnalyticsService.PROPERTIES.EXERCISE_ID]: exercise?.id,
                      [AnalyticsService.PROPERTIES.EVENT_CLICK_LOCATION]: "leaderboardExerciseSelector",
                    });
                    handlers.onExerciseChange(_, exercise);
                  }}
                  disabled={!exercises.length}
                  filterOptions={exerciseAutocompleteFilterOptions}
                />
              </Box>
            </Grid>
            {hasVariants && (
              <Grid width={68} textAlign="center">
                <Box p={1} py={0} pt={1}>
                  <Mui5.ToggleButtonGroup
                    value={variant}
                    onChange={handlers.onVariantChange}
                    exclusive
                    color="primary"
                    size="small"
                  >
                    <Mui5.ToggleButton
                      value="Left"
                      aria-label="left variant"
                      size="small"
                      sx={{ height: "37px" /* Height of sibling inputs */ }}
                    >
                      L
                    </Mui5.ToggleButton>
                    <Mui5.ToggleButton
                      value="Right"
                      aria-label="right variant"
                      size="small"
                      sx={{ height: "37px" /* Height of sibling inputs */ }}
                    >
                      R
                    </Mui5.ToggleButton>
                  </Mui5.ToggleButtonGroup>
                </Box>
              </Grid>
            )}
            <Grid item xs={hasVariants || 12} lg minWidth={0}>
              <Box p={1} pt={0}>
                <Mui5.Autocomplete
                  disableClearable={!!metric}
                  value={metric || null}
                  options={exercise?.metrics || []}
                  renderInput={(params) => (
                    <Mui5.TextField {...params} margin="dense" size="small" label="Metric" color="primary" fullWidth />
                  )}
                  getOptionLabel={(option) => option.name}
                  onChange={handlers.onMetricChange}
                  disabled={!exercise?.metrics}
                  filterOptions={exerciseAutocompleteFilterOptions}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Components.OverflowGrid flex={1} position="relative">
          <Divider />
          <LocalisedLoadingContext.Provider value={loadingState}>
            {exercise && metric ? (
              <LeaderboardTable exercise={exercise} metric={metric} startDate={startDate} variant={variant} />
            ) : (
              <MessageWithLogo>Please select an Exercise and a Metric.</MessageWithLogo>
            )}
          </LocalisedLoadingContext.Provider>
        </Components.OverflowGrid>
        <Divider />
        <Grid item>
          <Grid container justifyContent="center" spacing="0px" p={0}>
            <Grid item xs={12} lg={10}>
              <Box pl={1} pr={1} pt={1}>
                <ButtonGroup
                  setOption={(p) => setPeriodType(p.value)}
                  value={periods[periodType]}
                  options={Object.values(periods)}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LiveLeaderboard;
