import { WorkoutBase } from "features/Workouts/Workouts.types";
import * as Components from "./WorkoutOverviewPreviewStepList.components";
import WorkoutOverviewPreviewStepFactory from "./WorkoutOverviewPreviewStepFactory/WorkoutOverviewPreviewStepFactory";
import Box from "@mui/material/Box";

function WorkoutOverviewPreviewStepList(props: WorkoutBase) {
  return (
    <Components.StepsListPaper>
      <Components.ScrollBoxDiv>
        {props.steps.map((step, stepIndex) => (
          <Box mb={1} mt={0.5} key={step.id}>
            <Components.PaperCard key={step.id} superset={step.tasks.length > 1} elevation={0}>
              <WorkoutOverviewPreviewStepFactory superset={step.tasks.length > 1} stepIndex={stepIndex} {...step} />
            </Components.PaperCard>
          </Box>
        ))}
      </Components.ScrollBoxDiv>
    </Components.StepsListPaper>
  );
}

export default WorkoutOverviewPreviewStepList;
