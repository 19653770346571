import type { LineData } from "components/charting/types/Chart.types";
import { useMemo } from "react";
import useChartSelections from "../../../components/charting/hooks/useChartSelections";
import useGetManyPersonalBestQuery from "hooks/useGetManyPersonalBestQuery";

function useChartLinePersonalBest(lines: LineData[]) {
  const { showPersonalBest } = useChartSelections();

  const personalBestsArgs = useMemo(() => {
    return lines.map((lineData) => ({
      athleteId: lineData.source.id,
      exerciseId: lineData.measure.id,
      type: lineData.metadataType,
    }));
  }, [lines]);

  const personalBests = useGetManyPersonalBestQuery(personalBestsArgs, showPersonalBest);

  const composedData = useMemo(() => {
    if (!personalBests.data.length) {
      return lines.map((lineData) => ({ ...lineData, common: { ...lineData.common, best: null } })) as LineData[];
    }

    return lines.map((lineData) => {
      const pb = personalBests.data?.find(
        (pb) =>
          pb.athleteId === lineData.source.id &&
          pb.exerciseId === lineData.measure.id &&
          pb.metrics.find((m) => m.metricField === lineData.measure.metricField)
      );

      if (!pb) return lineData;

      const pbVariants = pb.metrics.find((m) => m.metricField === lineData.measure.metricField)?.variants;

      if (!pbVariants) return lineData;

      let best = null as null | number;

      if (lineData.sortOrder === "desc") {
        best = pbVariants.reduce(
          (acc, cur) => {
            const value = pb.metrics
              .find((m) => m.metricField === lineData.measure.metricField)
              ?.variants?.find((v) => v.variant === cur.variant)?.value;
            if (value === undefined) return acc;
            return acc === null ? value : Math.max(acc, value);
          },
          null as null | number
        );
      } else if (lineData.sortOrder === "asc") {
        best = pbVariants.reduce(
          (acc, cur) => {
            const value = pb.metrics
              .find((m) => m.metricField === lineData.measure.metricField)
              ?.variants?.find((v) => v.variant === cur.variant)?.value;
            if (value === undefined) return acc;
            return acc === null ? value : Math.min(acc, value);
          },
          null as null | number
        );
      }

      return { ...lineData, common: { ...lineData.common, best: best } } as LineData;
    });
  }, [lines, personalBests.data]);

  return { data: composedData, personalBests: personalBests.data };
}
export default useChartLinePersonalBest;
