import ChartLegend from "components/charting/ChartLegend";
import ChartCompareSetSelectContext from "components/charting/contexts/ChartCompareSetSelectContext";
import ChartSetSelectContext from "components/charting/contexts/ChartSetSelectContext";
import useDefinedContext from "hooks/useDefinedContext";
import usePreferences from "hooks/usePreferences";
import useColorRegistry from "features/Charting/hooks/useColorRegistry";
import { useCallback } from "react";
import { MeasurementSet } from "types";
import Grid from "@mui/material/Grid";
import { firstBy } from "thenby";
import moment from "moment";
import Typography from "@mui/material/Typography";
import * as Components from "./AthleteProfilingChartLegend.components";
import { ProfilingChartEntityId } from "components/charting/types";
import SetComparisonChartService from "services/SetComparisonChartService";

interface AthleteProfilingChartLegendProps {}

function AthleteProfilingChartLegend(props: AthleteProfilingChartLegendProps) {
  const { value: selectedPrimarySets, onChange: onChangePrimarySets } = useDefinedContext(ChartSetSelectContext);
  const { value: selectedComparativeSets, onChange: onChangeComparativeSets } =
    useDefinedContext(ChartCompareSetSelectContext);
  const getColor = useColorRegistry();
  const [{ imperialSystemEnabled }] = usePreferences();

  const makeLabel = useCallback(
    (set: MeasurementSet) => SetComparisonChartService.makeLabel(set, imperialSystemEnabled),
    [imperialSystemEnabled]
  );

  const makeLegendItems = useCallback(
    (sets: MeasurementSet[], onChange?: (sets: MeasurementSet[]) => void, entityColor?: string) =>
      sets.map((set) => ({
        id: set.measurementId,
        color: entityColor || getColor(set.measurementId),
        label: makeLabel(set),
        remove: onChange ? () => onChange(sets.filter((s) => s !== set)) : undefined,
      })),
    [getColor, makeLabel]
  );

  const primarySetItems = makeLegendItems(selectedPrimarySets, onChangePrimarySets);
  const comparativeSetItems = makeLegendItems(selectedComparativeSets, onChangeComparativeSets);

  const makeDateRangeLabel = useCallback((sets: MeasurementSet[]) => {
    const sortedSets = sets.slice(0).sort(firstBy("completedDate"));
    const startDate = moment(sortedSets[0]?.completedDate).format("MMM D, YYYY");
    const endDate = moment(sortedSets[sortedSets.length - 1]?.completedDate).format("MMM D, YYYY");

    if (startDate === endDate) return startDate;

    return `${startDate} - ${endDate}`;
  }, []);

  const renderTooltip = useCallback(
    (sets: MeasurementSet[], entityColor: string) => (
      <Grid container position="relative" textAlign="left">
        <Grid pl={1} width="100%">
          <ChartLegend items={makeLegendItems(sets, undefined, entityColor)} disableBorders />
        </Grid>
      </Grid>
    ),
    [makeLegendItems]
  );

  const primarySetsLabel = makeDateRangeLabel(selectedPrimarySets);
  const comparativeSetsLabel = makeDateRangeLabel(selectedComparativeSets);

  const primaryLineColor = getColor(ProfilingChartEntityId.PROFILING_LINE);
  const comparativeLineColor = getColor(ProfilingChartEntityId.COMPARATIVE_LINE);

  if (primarySetItems.length === 0 && comparativeSetItems.length === 0) {
    return null;
  }

  if (primarySetItems.length !== 0 && comparativeSetItems.length === 0) {
    return <ChartLegend items={primarySetItems} />;
  }

  if (primarySetItems.length === 0 && comparativeSetItems.length !== 0) {
    return <ChartLegend items={comparativeSetItems} />;
  }

  return (
    <Grid pt={0.5} container spacing={1} justifyContent="center">
      <Components.ComparativeProfilingChartTooltip arrow title={renderTooltip(selectedPrimarySets, primaryLineColor)}>
        <Grid
          item
          xs={6}
          lg={4}
          xl={4}
          maxWidth={280}
          borderRadius="4px"
          border={`1px solid`}
          borderColor={"grey.300"}
          spacing={"0px"}
          m="0px"
          p="0px"
        >
          <Grid item container alignItems="center" spacing={"0px"} m="0px" p="0px">
            <Grid item px={1}>
              <Components.ColorBlock color={primaryLineColor} />
            </Grid>
            <Grid item xs>
              <Typography fontWeight={500}>Baseline Sets</Typography> {primarySetsLabel}
            </Grid>
          </Grid>
        </Grid>
      </Components.ComparativeProfilingChartTooltip>
      <Grid item p={0.5} />
      <Components.ComparativeProfilingChartTooltip
        arrow
        title={renderTooltip(selectedComparativeSets, comparativeLineColor)}
      >
        <Grid
          item
          xs={6}
          lg={4}
          xl={4}
          maxWidth={280}
          borderRadius="4px"
          border={`1px solid`}
          borderColor={"grey.300"}
          m="0px"
          p="0px"
        >
          <Grid item container alignItems="center" spacing={"0px"} m="0px" p="0px">
            <Grid item px={1}>
              <Components.ColorBlock color={comparativeLineColor} />
            </Grid>
            <Grid item xs>
              <Typography fontWeight={500}>Comparison Sets</Typography> {comparativeSetsLabel}
            </Grid>
          </Grid>
        </Grid>
      </Components.ComparativeProfilingChartTooltip>
    </Grid>
  );
}

export default AthleteProfilingChartLegend;
