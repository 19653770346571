import Autocomplete from "@mui/material/Autocomplete";
import useChartOptions from "features/Charting/hooks/useChartOptions";
import { MetadataType } from "types/metadata";
import useSetChartSelections from "features/Charting/hooks/useSetChartSelections";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMatch } from "react-router-dom";
import Mui5 from "components/mui5";
import Grid from "@mui/material/Grid";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { ExerciseMetric, ExerciseRepetition } from "types";
import useChartTimePeriod from "components/charting/hooks/useChartTimePeriod";
import useChartAggregationPeriod from "components/charting/hooks/useChartAggregationPeriod";
import { ChartingSelections } from "components/charting/types";
import useChartMeasures from "components/charting/hooks/useChartMeasures";
import useChartMetrics from "components/charting/hooks/useChartMetrics";
import DataVisualizationShowInputHelper from "components/charting/helpers/DataVisualizationShowInputHelper";
import useDataVisualizationType from "components/charting/hooks/useDataVisualizationType";
import useChartRepMetrics from "components/charting/hooks/useChartRepMetrics";
import ChartHelpers from "features/Charting/helpers/ChartHelpers";
import PopoverDateRangePicker from "components/Inputs/PopoverDateRangePicker";
import useChartSelections from "components/charting/hooks/useChartSelections";
import moment from "moment";
import Tooltip from "components/Tooltip";
import AthleteProfileChartSetSelector from "./AthleteProfileChartSetSelector";
import AthleteProfileChartCompareSetSelector from "./AthleteProfileChartCompareSetSelector";
import { metadataAutocompleteFilterOptions } from "config/autocomplete";
import { Trans, useTranslation } from "react-i18next";

interface AthleteProfileChartControlsProps {}

function AthleteProfileChartControls(props: AthleteProfileChartControlsProps) {
  const { t } = useTranslation();
  const options = useChartOptions();
  const setSelections = useSetChartSelections();
  const athleteId = useMatch("/athlete-hub/:athleteId")?.params?.athleteId;
  const chartTimePeriod = useChartTimePeriod();
  const chartAggregationPeriod = useChartAggregationPeriod();
  const chartMeasures = useChartMeasures();
  const chartMetrics = useChartMetrics();
  const chartRepMetrics = useChartRepMetrics();
  const visType = useDataVisualizationType();
  const showInput = DataVisualizationShowInputHelper.show(visType);
  const timeSeletorRef = useRef<HTMLDivElement>(null);
  const [timePickerOpen, setPickerOpen] = useState(false);
  const selections = useChartSelections();

  useEffect(() => {
    const source = options.sources.find((athlete) => athlete.id === athleteId);

    if (!source) return;

    setSelections({ sources: [source] });
  }, [athleteId, options.sources, setSelections]);

  const exerciseOptions = useMemo(
    () => ChartHelpers.filterMeasuresByDataVisType(options.measures, { visType }),
    [options.measures, visType]
  );

  const chartMeasureValue = chartMeasures.values[0];

  const repMetricOptions = useMemo(() => {
    if (chartMeasureValue.type !== MetadataType.EXERCISE) {
      return [];
    }
    return ChartHelpers.filterProfilingRepMetrics(chartMeasureValue.metadata.repetitions, { visType });
  }, [chartMeasureValue.metadata, chartMeasureValue.type, visType]);

  const customPeriodOption = useMemo(() => ({ label: "Custom", value: null }), []);

  const setPeriod = useCallback(
    (period: ChartingSelections["period"]) => {
      if (period.value === null) {
        setPickerOpen(true);
        return;
      }

      const endDate = moment().endOf("day");
      const startDate = moment().endOf("day").clone().subtract(1, period.value);

      setSelections({
        period,
        startDate,
        endDate,
      });
    },
    [setSelections]
  );

  const exerciseOptionsValue = useMemo(
    () => exerciseOptions.find((option) => option.id === chartMeasureValue.id) || null,
    [exerciseOptions, chartMeasureValue]
  );

  return (
    <Grid container spacing="8px" pt={0} my={0.5}>
      {showInput.exercise && (
        <Grid item>
          <Autocomplete
            disablePortal
            filterOptions={metadataAutocompleteFilterOptions}
            disableClearable={!!chartMeasureValue.id}
            options={exerciseOptions}
            onChange={chartMeasures.onChangeAtIndex(0)}
            value={exerciseOptionsValue}
            getOptionLabel={chartMeasures.getOptionLabel}
            groupBy={chartMeasures.groupBy}
            renderInput={(params) => (
              <Tooltip
                title={t("AthleteProfileChartControls.measurementSelectInputTooltip", {
                  context: DataVisualizationShowInputHelper.generateTooltipI18nKeyContext(visType),
                })}
                arrow
                placement="top"
              >
                <Mui5.TextField
                  {...params}
                  sx={{ minWidth: 280 }}
                  label={t("AthleteProfileChartControls.measurementSelectInputLabel")}
                  fullWidth={false}
                  size="small"
                  color="primary"
                />
              </Tooltip>
            )}
          />
        </Grid>
      )}
      {showInput.metric && (
        <>
          {(chartMeasureValue.type === MetadataType.EXERCISE || !chartMeasureValue.type) && (
            <Grid item>
              <Tooltip title={t("AthleteProfileChartControls.metricSelectTooltip")} arrow placement="top">
                <Mui5.TextField
                  sx={(theme) => ({ minWidth: theme.spacing(14) })}
                  label={t("AthleteProfileChartControls.metricSelectLabel")}
                  fullWidth={false}
                  size="small"
                  color="primary"
                  select
                  SelectProps={{
                    onChange: (event) => chartMetrics.onChangeAtIndex(0)(event, event.target.value as ExerciseMetric),
                  }}
                  value={chartMeasureValue.metric || ""}
                  disabled={!chartMeasureValue?.metadata}
                >
                  {(chartMeasureValue.metadata?.metrics || []).map((metric) => (
                    <MenuItem key={metric.field} value={metric as unknown as MenuItemProps["value"]}>
                      {metric.name}
                    </MenuItem>
                  ))}
                </Mui5.TextField>
              </Tooltip>
            </Grid>
          )}
        </>
      )}
      {showInput.repMetric && (
        <>
          {(chartMeasureValue.type === MetadataType.EXERCISE || !chartMeasureValue.type) && (
            <Grid item>
              <Tooltip title={t("AthleteProfileChartControls.repMetricSelectTooltip")} arrow placement="top">
                <Mui5.TextField
                  sx={(theme) => ({ minWidth: theme.spacing(14) })}
                  label={t("AthleteProfileChartControls.repMetricSelectLabel")}
                  fullWidth={false}
                  size="small"
                  color="primary"
                  select
                  SelectProps={{
                    onChange: (event) =>
                      chartRepMetrics.onChangeAtIndex(0)(event, event.target.value as ExerciseRepetition),
                  }}
                  value={chartMeasureValue.repMetric || ""}
                  disabled={!chartMeasureValue?.metadata}
                >
                  {repMetricOptions.map((repMetric) => (
                    <MenuItem key={repMetric.field} value={repMetric as unknown as MenuItemProps["value"]}>
                      {repMetric.name}
                    </MenuItem>
                  ))}
                </Mui5.TextField>
              </Tooltip>
            </Grid>
          )}
        </>
      )}
      <Grid item xs px={0} minWidth="0px" />
      {showInput.sets && (
        <Grid item>
          <Grid container spacing={"0px"} p="0px">
            <Tooltip title={t("AthleteProfileChartControls.setSelectTooltip")} arrow placement="top">
              <Grid item>
                <AthleteProfileChartSetSelector />
              </Grid>
            </Tooltip>
            {showInput.comparableSets && (
              <Tooltip title={t("AthleteProfileChartControls.compareSetSelectTooltip")} arrow placement="top">
                <Grid pl={1}>
                  <AthleteProfileChartCompareSetSelector />
                </Grid>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      )}
      {showInput.aggregation && (
        <Grid item>
          <Tooltip
            title={
              <Trans i18nKey="AthleteProfileChartControls.aggregationSelectTooltip">
                Time period to aggregate by for available data.
                <br />
                Select the Aggregation Type (Best, Worst, Average) in advanced settings.
              </Trans>
            }
            arrow
            placement="top"
          >
            <Mui5.TextField
              sx={(theme) => ({ minWidth: theme.spacing(14) })}
              label={t("AthleteProfileChartControls.aggregationSelectLabel")}
              fullWidth={false}
              size="small"
              color="primary"
              select
              value={chartAggregationPeriod.value}
              SelectProps={{
                onChange: (event) =>
                  chartAggregationPeriod.onChange(event, event.target.value as ChartingSelections["aggregationPeriod"]),
              }}
            >
              {chartAggregationPeriod.options.map((option) => (
                <MenuItem key={option.value} value={option as unknown as MenuItemProps["value"]}>
                  {option.label}
                </MenuItem>
              ))}
            </Mui5.TextField>
          </Tooltip>
        </Grid>
      )}
      {showInput.period && (
        <Grid item>
          <Tooltip title={t("AthleteProfileChartControls.timeSelectTooltip")} arrow placement="top">
            <Mui5.TextField
              ref={timeSeletorRef}
              sx={(theme) => ({ minWidth: theme.spacing(14) })}
              label={t("AthleteProfileChartControls.timeSelectLabel")}
              fullWidth={false}
              size="small"
              color="primary"
              select
              value={selections.period}
              SelectProps={{
                onChange: (event) => setPeriod(event.target.value as ChartingSelections["period"]),
              }}
            >
              {chartTimePeriod.options.map((option) => (
                <MenuItem key={option.value} value={option as unknown as MenuItemProps["value"]}>
                  {option.label}
                </MenuItem>
              ))}
              <MenuItem
                key={customPeriodOption.value}
                value={customPeriodOption as unknown as MenuItemProps["value"]}
                onClick={() => setPeriod(customPeriodOption)}
              >
                {customPeriodOption.label}
              </MenuItem>
            </Mui5.TextField>
          </Tooltip>
          <PopoverDateRangePicker
            anchorEl={timeSeletorRef.current}
            endDate={selections.endDate}
            open={timePickerOpen}
            setPeriod={(startDate: moment.Moment, endDate: moment.Moment) => {
              setSelections({
                period: customPeriodOption,
                startDate,
                endDate,
              });
            }}
            setOpen={setPickerOpen}
            startDate={selections.startDate}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default AthleteProfileChartControls;
