import LeaderboardsContext, { LeaderboardsContextType } from "features/Leaderboards/contexts/LeaderboardsContext";
import { useMemo, useEffect, useState } from "react";
import LeaderboardService from "services/LeaderboardService";
import { Leaderboard, LeaderboardBase } from "types";

interface LeaderboardsProviderProps {
  children: React.ReactNode;
}

function LeaderboardsProvider(props: LeaderboardsProviderProps) {
  const [leaderboards, setLeaderboards] = useState<Leaderboard[]>([]);

  const leaderboardActions = useMemo(
    () => ({
      fetchAll: async () => {
        const result = await LeaderboardService.getAll();

        setLeaderboards(result);
      },
      delete: async (id: string) => {
        await LeaderboardService.delete(id);

        setLeaderboards((prevLeaderboards) => prevLeaderboards.filter((leaderboard) => leaderboard.id !== id));

        return;
      },
      create: async (leaderboard: LeaderboardBase) => {
        const result = await LeaderboardService.create(leaderboard);
        const newLeaderboard = { ...result, ...leaderboard };

        setLeaderboards((prevLeaderboards) => [...prevLeaderboards, newLeaderboard]);
      },
      update: async (id: string, leaderboard: LeaderboardBase) => {
        const result = await LeaderboardService.update(id, leaderboard);
        const updatedLeaderboard = { ...leaderboard, ...result, id };

        setLeaderboards((prevLeaderboards) =>
          prevLeaderboards.map((leaderboard) => {
            if (leaderboard.id === id) {
              return updatedLeaderboard;
            }
            return leaderboard;
          })
        );
      },
    }),
    []
  );

  useEffect(() => {
    leaderboardActions.fetchAll();
  }, [leaderboardActions]);
  const leaderboardContextValue: LeaderboardsContextType = useMemo(
    () => [leaderboards, leaderboardActions],
    [leaderboardActions, leaderboards]
  );

  return <LeaderboardsContext.Provider value={leaderboardContextValue}>{props.children}</LeaderboardsContext.Provider>;
}

export default LeaderboardsProvider;
