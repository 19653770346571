import useValidChartMeasures from "features/Charting/hooks/useValidChartMeasures";
import { useMemo } from "react";
import useChartSourceIds from "./useChartSourceIds";

function useChartEntityIds() {
  const sourceIds = useChartSourceIds();
  const validMeasures = useValidChartMeasures();

  return useMemo(() => {
    if (sourceIds.length > 1) {
      return sourceIds;
    }
    return validMeasures.map((m) => [m.id, m.metric?.field].filter(Boolean).join("-"));
  }, [validMeasures, sourceIds]);
}
export default useChartEntityIds;
