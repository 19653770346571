import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Group } from "contexts/GroupsContext/GroupsContext";
import NotePadIcon from "components/IconsStandardized/NotePadIcon/NotePadIcon";
import Mui5 from "components/mui5";
import { useNavigate } from "react-router-dom";
import Constants from "config/Constants";
import * as Components from "./GroupTitle.components";
import { useTranslation } from "react-i18next";

interface GroupTitleProps extends Group {
  activeCount: number;
}

function GroupTitle(props: GroupTitleProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid container>
      <Components.ColorBlockGrid bgcolor={props.colour} />
      <Grid item xs container alignItems="center">
        <Grid item>
          <Typography variant="h2" gutterBottom sx={{ display: "flex", alignItems: "center" }}>
            <Box component="span" fontWeight="fontWeightMedium">
              {props.name}
            </Box>
            <Box component="span" px={2}>
              <IconButton
                onClick={() => navigate(`${Constants.routes.groups.route}/${props.id}/edit`)}
                size="small"
                color="primary"
                aria-label="edit group"
              >
                <NotePadIcon fontSize="small" />
              </IconButton>
            </Box>
          </Typography>
          <Typography variant="h4">{t("Group.Overview.athletesCount", { count: props.activeCount })}</Typography>
        </Grid>
        <Grid item xs />
        <Grid item>
          <Mui5.Button sx={{ width: 320 }} href={`/charting?sourceId=${props.id}`}>
            {t("Group.Overview.chartGroupButtonText")}
          </Mui5.Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GroupTitle;
