import { DataVisualizationType } from "components/charting/config/chartTypes";
import {
  SelectedChartMeasure,
  SelectedChartMeasureEmpty,
  SelectedChartMeasureExercise,
} from "components/charting/types";
import Constants from "config/Constants";
import { MetadataType } from "types/metadata";
import { TimePeriod } from "types/period";

class MeasureHelpers {
  static getValidMeasures(measures: SelectedChartMeasure[]) {
    return measures.reduce<Exclude<SelectedChartMeasure, SelectedChartMeasureEmpty>[]>((acc, measure) => {
      if (measure.type === null) return acc;

      acc.push(measure);

      return acc;
    }, []);
  }

  static get1RMLineMeasures(measures: SelectedChartMeasure[]) {
    return measures.reduce<SelectedChartMeasureExercise[]>((acc, measure) => {
      const notCompatibleWith1RMLine =
        measure.metric == null ||
        measure.metric?.field !== "weight" ||
        measure.metadata.category !== "Barbell Velocity";

      if (notCompatibleWith1RMLine) return acc;

      acc.push(measure);

      return acc;
    }, []);
  }

  static getCommonProperties(measure: Exclude<SelectedChartMeasure, SelectedChartMeasureEmpty>) {
    if (measure.type === MetadataType.EXERCISE) {
      return {
        id: measure.id,
        entityId: [measure.id, measure.metric.field].join("-"),
        type: measure.type,
        label: measure.metadata.name,
        subLabel: measure.metric.name,
        metricField: measure.metric.field,
      };
    }

    if (measure.type === MetadataType.WELLNESS) {
      return {
        id: measure.id,
        entityId: measure.id,
        type: measure.type,
        label: measure.type,
        subLabel: measure.metadata.name,
      };
    }

    return {
      id: measure.id,
      entityId: measure.id,
      type: measure.type,
      label: measure.type,
      subLabel: measure.metadata.label,
    };
  }

  static getAthleteProfileChartUrl(
    athleteId: string,
    params: {
      visType?: DataVisualizationType;
      metadataId: string;
      start?: string;
      end?: string;
      interval?: TimePeriod | "custom";
    }
  ): string {
    const queryString = Object.entries(params)
      .filter(([value]) => value)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    return `${Constants.routes.athletes.route}/${athleteId}?${queryString}`;
  }
}
export default MeasureHelpers;
