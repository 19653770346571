import { useMemo } from "react";
import AthleteService from "services/AthleteService";
import useQuery from "hooks/useQuery";
import useQueryStatusBuilder from "hooks/useQueryStatusBuilder";

interface DateRange {
  startDate: string;
  endDate: string;
}

function useGetAthleteActivityQuery(athleteId: string, dateRange: DateRange) {
  const queryKey = useMemo(() => {
    return ["AthleteService.getActivity", athleteId, dateRange] as const;
  }, [athleteId, dateRange]);

  const { data, isLoading, error } = useQuery(queryKey, ([_, aid, range]) => AthleteService.getActivity(aid, range));

  const status = useQueryStatusBuilder({ isLoading, isError: !!error });

  return useMemo(() => ({ data: data || {}, status }), [data, status]);
}

export default useGetAthleteActivityQuery;
