import * as Components from "./GroupsTableRow.components";
import { tableCells } from "../GroupsTable.config";
import Grid from "@mui/material/Grid";
import { memo, useCallback, useMemo, useState } from "react";
import Mui5 from "components/mui5";
import EventHelpers from "helpers/EventHelpers/EventHelpers";
import { Group } from "contexts/GroupsContext/GroupsContext";
import { useArchivedAthletes } from "providers/ArchivedAthletesProvider";
import difference from "lodash/difference";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import NotePadIcon from "components/IconsStandardized/NotePadIcon/NotePadIcon";
import DustbinIcon from "components/IconsStandardized/DustbinIcon";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog, { ConfirmationDialogProps } from "components/ConfirmationDialog";
import useGroupActions from "providers/GroupsProvider/useGroupActions";
import { useTranslation } from "react-i18next";
// import Checkbox from "library/mui5/Checkbox";
// import CheckBoxOutlined from "@mui/icons-material/CheckBox";
// import { useMultiSelectIds, useSetMultiSelect } from "providers/MultiSelectProvider";

function GroupsTableRow(props: Group) {
  const [dialogProps, setDialogProps] = useState<Omit<ConfirmationDialogProps, "open" | "setOpen"> | false>(false);
  const navigate = useNavigate();
  const groupActions = useGroupActions();
  // const selectedIds = useMultiSelectIds();
  // const setSelectedIds = useSetMultiSelect();
  // const toggleSelected = useCallback(
  //   (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //     event.stopPropagation();
  //     setSelectedIds({ [props.id]: !selectedIds.includes(props.id) });
  //   },
  //   [props.id, selectedIds, setSelectedIds]
  // );
  // const workoutSelected = useMemo(() => selectedIds.includes(props.id), [props.id, selectedIds]);

  const eventPropogationPreventors = useMemo(
    () => ({
      onClick: EventHelpers.preventDefault,
      onMouseDown: EventHelpers.stopPropagation,
    }),
    []
  );
  const archivedAthleteIds = useArchivedAthletes(true);

  const activeMemberIds = useMemo(() => {
    return difference(props.athleteIds, archivedAthleteIds);
  }, [archivedAthleteIds, props.athleteIds]);
  const memberCount = activeMemberIds.length;

  const { t } = useTranslation();

  const goToGroupEdit = useCallback(() => {
    navigate(`/groups/${props.id}/edit`);
  }, [navigate, props.id]);

  const handleDelete = useCallback(() => {
    setDialogProps({
      title: t("Group.All.deleteGroupConfirmationHeader"),
      cancelText: t("cancelButtonText"),
      confirmText: t("deleteButtonText"),
      color: "error",
      body: t("Group.All.deleteGroupConfirmationWarning", { groupName: props.name }),
      onConfirm: () => groupActions.delete(props.id),
    });
  }, [groupActions, props.id, props.name, t]);

  const confirmationDialog = Boolean(dialogProps) && (
    <ConfirmationDialog open={Boolean(dialogProps)} setOpen={setDialogProps} {...dialogProps} />
  );

  return (
    <>
      <Mui5.Button size="small" fullWidth variant="text" href={`/groups/${props.id}`}>
        <Grid width="100%" container component={Components.TableRow} alignItems="center" justifyContent="space-between">
          <Grid item {...tableCells.checkbox} {...eventPropogationPreventors}>
            <Components.ColorBlockDiv color={props.colour} />
            {/* <Checkbox
              sx={{ color: "common.white" }}
              checkedIcon={<CheckBoxOutlined htmlColor="#FFF" />}
              inputProps={{ "aria-label": `select workout ${props.name}` }}
              checked={workoutSelected}
              onClick={toggleSelected}
            /> */}
          </Grid>
          <Grid item {...tableCells.name}>
            <Typography variant="h4" color="primary" noWrap>
              {props.name}
            </Typography>
            <Typography variant="body1" color="textSecondary" noWrap>
              {`${memberCount} member${Boolean(memberCount === 1) ? "" : "s"}`}
              {props.description && ` | ${props.description}`}
            </Typography>
          </Grid>
          <Grid item {...tableCells.actions} {...eventPropogationPreventors}>
            <Grid container justifyContent="space-around">
              <Grid item>
                <IconButton
                  color="primary"
                  title={t("Group.All.editButtonTitleText", { groupName: props.name })}
                  aria-label={t("Group.All.editButtonTitleText", { groupName: props.name })}
                  onClick={goToGroupEdit}
                >
                  <NotePadIcon color="primary" fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton aria-label={`delete workout ${props.name}`} color="primary" onClick={handleDelete}>
                  <DustbinIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Mui5.Button>
      {confirmationDialog}
    </>
  );
}

export default memo(GroupsTableRow);
