import ButtonBase from "@mui/material/ButtonBase";
import Grid from "@mui/material/Grid";
import useAthlete from "hooks/useAthlete";
import useUnitFormatter from "hooks/useUnitFormatter";
import AthleteName from "components/AthleteName";
import { useNavigate } from "react-router-dom";
import AnalyticsService from "services/AnalyticsService";
import { DateTime, ExerciseMetric, FireStoreMeasurement } from "types";
import logEvent from "utils/logEvent";
import * as LeaderboardsComponents from "../Leaderboards.components";
import Tooltip from "components/Tooltip";
import { LeaderboardComparePeriod } from "features/Leaderboards/LeaderboardTables/LeaderboardEditable/LeaderboardEditable.types";
import { useMemo } from "react";
import moment from "moment";
import LeaderboardFormHelpers from "features/Leaderboards/helpers/LeaderboardFormHelpers";

export const LeaderboardTablePercentageRow = (props: {
  position: number | null;
  measurement: FireStoreMeasurement;
  comparativeMeasurement: FireStoreMeasurement;
  metric: ExerciseMetric;
  comparePeriodType: LeaderboardComparePeriod;
  startDateComparePeriod: DateTime | null;
}) => {
  const { position, measurement, comparativeMeasurement, metric } = props;
  const athlete = useAthlete(measurement.athleteId, true);
  const formatUnit = useUnitFormatter();
  const navigate = useNavigate();

  const clickableProps = athlete && {
    component: ButtonBase,
    onClick: () => {
      logEvent("HUB_LIVE_ATHLETE_PROFILE_CHART_EXERCISE", {
        [AnalyticsService.PROPERTIES.EXERCISE_ID]: measurement.exerciseId,
        [AnalyticsService.PROPERTIES.EVENT_CLICK_LOCATION]: "liveLeaderboard",
      });
      navigate(`/athlete-hub/${athlete.id}?chartType=LINE&metadataId=${measurement.exerciseId}`);
    },
  };

  const percentageValue = useMemo(
    () =>
      LeaderboardFormHelpers.getPercentageComparisonForMeasurements(
        measurement[metric.field],
        comparativeMeasurement[metric.field],
        metric.sortType === 1
      ) * 100,
    [comparativeMeasurement, measurement, metric.field, metric.sortType]
  );

  const tooltipText = useMemo(() => {
    const formattedComparativeValue = formatUnit(comparativeMeasurement[metric.field], metric);

    const isCustomPeriod = props.comparePeriodType.value === "Custom";
    const isAllTime = props.comparePeriodType.value === "All";

    return [
      `${percentageValue.toFixed(0)}% of ${formattedComparativeValue}`,
      isCustomPeriod && `(Best since ${moment(props.startDateComparePeriod).format("MMM DD, YYYY")})`,
      isAllTime && `(${props.comparePeriodType.label} Best)`,
      !isCustomPeriod && !isAllTime && `(Best for ${props.comparePeriodType.label})`,
    ]
      .filter(Boolean)
      .join(" ");
  }, [
    comparativeMeasurement,
    formatUnit,
    metric,
    percentageValue,
    props.comparePeriodType.label,
    props.comparePeriodType.value,
    props.startDateComparePeriod,
  ]);

  const is100Percent = percentageValue.toFixed(0) === "100";

  return (
    <LeaderboardsComponents.TableRowStyledBox {...clickableProps}>
      <Grid container alignItems="center" p={0} spacing="0px">
        <LeaderboardsComponents.PositionCellGrid item>{position}</LeaderboardsComponents.PositionCellGrid>
        <Grid item xs zeroMinWidth>
          <Grid container alignItems="center" spacing={0} wrap="nowrap">
            <Grid item zeroMinWidth>
              <AthleteName athlete={athlete} id={measurement.athleteId} />
            </Grid>
            {is100Percent && (
              <Grid mr={1} fontSize={"0.77em"}>
                🏆
              </Grid>
            )}
          </Grid>
        </Grid>
        <LeaderboardsComponents.MetricCellGrid item mr={1} fontSize={"0.75em"} color="grey.800">
          {formatUnit(measurement[metric.field], metric)}
        </LeaderboardsComponents.MetricCellGrid>
        <Tooltip placement="right" arrow title={tooltipText}>
          <LeaderboardsComponents.MetricCellGrid item minWidth={"40px"}>
            {percentageValue.toFixed(0)}%
          </LeaderboardsComponents.MetricCellGrid>
        </Tooltip>
      </Grid>
    </LeaderboardsComponents.TableRowStyledBox>
  );
};
