import PropTypes from "prop-types";
import Form from "../../Form";
import Button from "components/mui5/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InviteEmailResendButton from "./InviteEmailResendButton";
import { getSchema } from "./validate";
import DuplicateAthleteAlert from "./DuplicateAthleteAlert";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ColorableChip from "components/ColorableChip";
import { grey } from "@mui/material/colors";
import { Field } from "formik";
import { useMemo, useState } from "react";
import Mui5 from "components/mui5";
import InputLabelWithOptional from "components/Inputs/InputLabelWithOptional";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import CanDoAction from "components/CanDoAction";
import useRole from "providers/RoleProvider/useRole";
import useCanDo from "hooks/useCanDo";
import FormDatePicker from "components/Form/DatePicker";
import { useTranslation } from "react-i18next";
import FormProfilePhoto from "components/Form/ProfilePhoto";
import AutocompleteOptionColourable from "components/AutocompleteOptionColourable";
export { getSchema } from "./validate";

function AthleteForm(props) {
  const [groupsInput, setGroupsInput] = useState("");
  const { groupOptions, initialValues, onSubmit, submitText, ...formikProps } = props;
  const role = useRole();
  const canDo = useCanDo();
  const schema = useMemo(() => getSchema({ groups: { min: role === "OWNER" ? 0 : 1 } }), [role]);
  const { t } = useTranslation();

  function handleSubmit(values, formikBag) {
    onSubmit(schema.cast(values), formikBag);
  }

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema} {...formikProps} disabled>
      <Form.Wrapper>
        <Grid container spacing="16px">
          <Grid item xs={12} textAlign="center">
            <FormProfilePhoto name="profilePhotoName" buttonText={props.photoChangeButtonText} />
          </Grid>
          <Grid item xs={12}>
            <Form.Text
              fullWidth
              variant="standard"
              color="secondary"
              name="firstName"
              label={t("Athlete.formLabelFirstName")}
              placeholder={t("Athlete.formPlaceholderFirstName")}
            />
          </Grid>
          <Grid item xs={12}>
            <Form.Text
              fullWidth
              variant="standard"
              color="secondary"
              name="lastName"
              label={t("Athlete.formLabelLastName")}
              placeholder={t("Athlete.formPlaceholderLastName")}
            />
            <DuplicateAthleteAlert submitText={submitText} />
          </Grid>
          <Grid item xs={12}>
            <Form.Text
              fullWidth
              variant="standard"
              color="secondary"
              label={<InputLabelWithOptional optional label={t("Athlete.formLabelExternalID")} />}
              name="externalId"
            />
          </Grid>
          <Grid item xs={6}>
            <FormDatePicker
              name="dateOfBirth"
              label={t("Athlete.formLabelDOB")}
              openTo="year"
              slotProps={{ textField: { color: "secondary" } }}
            />
          </Grid>
          <Grid item xs={6}>
            <Field name="sex">
              {(sexFieldProps) => (
                <Mui5.TextField
                  {...sexFieldProps.field}
                  value={[sexFieldProps.field.value].join("")}
                  label={t("Athlete.formLabelSex")}
                  select
                  placeholder={t("Athlete.formPlaceholderSex")}
                  fullWidth
                  variant="standard"
                  color="secondary"
                  aria-label="sex-selector"
                  error={Boolean(sexFieldProps.meta.touched && sexFieldProps.meta.error)}
                  helperText={sexFieldProps.meta.touched && sexFieldProps.meta.error}
                >
                  {["Male", "Female", "Prefer Not Say"].map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Mui5.TextField>
              )}
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Form.Height
              name="height"
              label={t("Athlete.formLabelHeight")}
              placeholder={t("Athlete.formPlaceholderHeight")}
              variant="standard"
              color="secondary"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Form.Weight
              name="weight"
              label={t("Athlete.formLabelWeight")}
              placeholder={t("Athlete.formPlaceholderWeight")}
              variant="standard"
              color="secondary"
              fullWidth
            />
          </Grid>
          <CanDoAction action="group.assign">
            <Grid item xs={12}>
              <Field name="groups">
                {({ form, field }) => (
                  <Mui5.Autocomplete
                    multiple
                    freeSolo={canDo("group.create")}
                    forcePopupIcon
                    autoHighlight
                    name="groups"
                    options={groupOptions}
                    filterSelectedOptions
                    getOptionLabel={(option) => option.name}
                    value={field.value}
                    onChange={(_, value) => {
                      form.setFieldValue("groups", value);
                      setGroupsInput("");
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    inputValue={groupsInput}
                    renderInput={(params) => (
                      <Mui5.TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="standard"
                        color="secondary"
                        onBlur={() => {
                          const stringValue = groupsInput;

                          if (!stringValue) return;

                          setGroupsInput("");

                          form.setFieldValue("groups", [...field.value, stringValue]);
                        }}
                        error={Boolean(form.touched[field.name] && form.errors[field.name])}
                        onChange={(event) => setGroupsInput(event.target.value)}
                        helperText={
                          form.touched[field.name] && form.errors[field.name]
                            ? form.errors[field.name]
                            : params.helperText || (
                                <CanDoAction action="group.create">{t("Athlete.formUnknownGroupsMessage")}</CanDoAction>
                              )
                        }
                        label={t("Athlete.formLabelGroups")}
                        placeholder={Boolean(field.value?.length) ? undefined : "Search Groups..."}
                      />
                    )}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index });

                        return (
                          <ColorableChip
                            key={option.id || index}
                            label={option.name || option}
                            customColor={typeof option === "string" ? grey[300] : option.colour}
                            deleteIcon={
                              <ClearOutlinedIcon title="Remove Item" color={"primary"} viewBox="-6 -6 36 36" />
                            }
                            {...tagProps}
                          />
                        );
                      })
                    }
                    renderOption={(optionProps, option) => (
                      <AutocompleteOptionColourable {...optionProps} key={option.id} colour={option.colour}>
                        {option.name}
                      </AutocompleteOptionColourable>
                    )}
                  />
                )}
              </Field>
            </Grid>
          </CanDoAction>
          <Grid item xs={12}>
            <Typography variant="h2" color="textSecondary" paragraph>
              {t("Athlete.formAthleteInviteMessage")}
            </Typography>
            <Field name="email">
              {({ form, field }) => (
                <>
                  <Mui5.TextField
                    label={<InputLabelWithOptional optional label={t("Athlete.formLabelAthleteInvite")} />}
                    id="athlete-email-invite"
                    type="text"
                    value={[field.value].filter(Boolean).join("")}
                    name="email"
                    color="secondary"
                    variant="standard"
                    onChange={field.onChange}
                    fullWidth
                    error={Boolean(form.touched[field.name] && form.errors[field.name])}
                    helperText={
                      form.touched[field.name] && form.errors[field.name]
                        ? form.errors[field.name]
                        : t("Athlete.formAthleteInviteHelperText")
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <InviteEmailResendButton />
                        </InputAdornment>
                      ),
                    }}
                  />
                </>
              )}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              role="button"
              variant="contained"
              color="primary"
              disableElevation
              disabled={props.submitDisabled || initialValues.isArchived}
              fullWidth
            >
              {submitText}
            </Button>
          </Grid>
        </Grid>
      </Form.Wrapper>
    </Form>
  );
}

AthleteForm.propTypes = {
  groupOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object).isRequired).isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    externalId: PropTypes.string,
    email: PropTypes.string,
    fileToUpload: PropTypes.instanceOf(Object),
    dateOfBirth: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    sex: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.instanceOf(Object).isRequired).isRequired,
    height: PropTypes.number,
    weight: PropTypes.number,
  }).isRequired,
  currentPhotoUrl: PropTypes.string,
  submitDisabled: PropTypes.bool,
  emailInput: PropTypes.bool,
};

export default AthleteForm;
