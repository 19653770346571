import useChartingColors from "features/Charting/hooks/useChartingColors";
import useChartSelections from "../hooks/useChartSelections";
import React, { useCallback, useMemo } from "react";
import { ChartLine, LinePointData } from "../types/Chart.types";
import useChartDimensions from "features/Charting/hooks/useChartDimensions";
import useTimeScale from "../hooks/useTimeScale";
import max from "lodash/max";
import min from "lodash/min";
import { scaleLinear } from "@visx/scale";
import { useUnitFormatter } from "hooks";
import NoDataWrapper from "components/NoDataWrapper";
import ChartScalesContext from "features/Charting/contexts/ChartScalesContext";
import ChartTooltipCardProvider from "../providers/ChartTooltipCardProvider";
import ChartHelpers from "features/Charting/helpers/ChartHelpers";
import ChartTooltipManagerProvider from "../providers/ChartTooltipManagerProvider";
import { Group } from "@visx/group";
import { GridRows } from "@visx/grid";
import { AxisLeft as VisxAxisLeft } from "@visx/axis";
import AxisBottom from "features/Charting/components/AxisBottom";
import Line from "features/Charting/components/Line";
import LineDots from "features/Charting/components/LineDots";
import { weightMetric } from "./OneRepMaxChart.config";
import OneRepMaxChartNoData from "./OneRepMaxChartNoData";
import useOneRepMaxChartData from "features/Charting/hooks/useOneRepMaxChartData";
import OneRepMaxTooltipCard from "./OneRepMaxTooltipCard";

interface OneRepMaxChartProps {}

function OneRepMaxChart(props: OneRepMaxChartProps) {
  const selections = useChartSelections();
  const { grid, axis } = useChartingColors();
  const chartLines = useOneRepMaxChartData();
  const allLineData = useMemo(
    () => chartLines.reduce<LinePointData[]>((acc, cur) => [...acc, ...cur.line], []),
    [chartLines]
  );
  const dimensions = useChartDimensions();
  const xScale = useTimeScale(allLineData);
  const yScale = useMemo(() => {
    const domainMin = selections.showPlotFromZero ? 0 : min(allLineData.map((d): number => d.y)) || 0;

    return scaleLinear({
      range: dimensions.ranges.y,
      domain: [domainMin, max(allLineData.map((d): number => d.y)) || 0],
      nice: true,
    });
  }, [allLineData, dimensions.ranges.y, selections.showPlotFromZero]);
  const scales = useMemo(
    () => ({ x: xScale, y: { [ChartHelpers.getScaleId(selections.measures[0])]: yScale } }),
    [xScale, yScale, selections.measures]
  );

  const coordinatedChartLines: ChartLine[] = useMemo(
    () =>
      chartLines.map((lineData) => ({
        ...lineData,
        line: lineData.line.map((linePoint) => ({
          ...linePoint,
          x: xScale(linePoint.x),
          y: yScale(linePoint.y),
        })),
      })),
    [chartLines, xScale, yScale]
  );

  const dataLines = coordinatedChartLines;
  const formatUnit = useUnitFormatter();
  const formatValue = useCallback(
    (value: { valueOf(): number }) => {
      return formatUnit(value, weightMetric);
    },
    [formatUnit]
  );

  const chartHasData = (dataLines: ChartLine[]) => {
    if (dataLines.length) {
      if (dataLines[0].line.length) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <NoDataWrapper hasData={Boolean(chartHasData(dataLines))} fallback={<OneRepMaxChartNoData />}>
      <ChartScalesContext.Provider value={scales}>
        <ChartTooltipCardProvider TooltipCard={OneRepMaxTooltipCard}>
          <ChartTooltipManagerProvider>
            <Group left={dimensions.margin.left} top={dimensions.margin.top}>
              <GridRows
                scale={yScale}
                width={dimensions.inner.width}
                height={dimensions.inner.height}
                strokeWidth={1}
                stroke={grid.line}
                strokeDasharray="4,6"
                strokeLinecap="round"
              />
              <VisxAxisLeft
                hideAxisLine
                hideTicks
                left={-24}
                scale={yScale}
                labelOffset={26}
                labelProps={{ fill: axis.text }}
                tickFormat={formatValue}
                tickLabelProps={() => ({
                  fill: axis.line,
                  color: axis.line,
                  fontSize: 12,
                  textAnchor: "end",
                  dy: "0.3em",
                  dx: "-0.4em",
                })}
                stroke={axis.line}
                tickStroke={axis.line}
                tickLength={2}
                hideZero
              />
              <AxisBottom scale={xScale} top={dimensions.inner.height} />
              {dataLines.map((lineData, lineIndex) => (
                <React.Fragment key={lineData.entityId + lineIndex}>
                  <Line {...lineData} />
                  <LineDots {...lineData} />
                </React.Fragment>
              ))}
            </Group>
          </ChartTooltipManagerProvider>
        </ChartTooltipCardProvider>
      </ChartScalesContext.Provider>
    </NoDataWrapper>
  );
}

export default OneRepMaxChart;
