import { useMemo } from "react";
import { mean } from "mathjs";
import { ChartLine } from "components/charting/types/Chart.types";
import { Variants } from "types/exercise";
import ChartHelpers from "features/Charting/helpers/ChartHelpers";
import MeasureHelpers from "features/Charting/helpers/MeasureHelpers";
import useChartSelections from "components/charting/hooks/useChartSelections";

function useChartAnnotations(chartLines: ChartLine[]) {
  const selections = useChartSelections();

  return useMemo(() => {
    const validMeasures = MeasureHelpers.getValidMeasures(selections.measures);

    if (validMeasures.length !== 1) return [];

    const [singleValidMeasure] = validMeasures;
    const scaleId = ChartHelpers.getScaleId(singleValidMeasure);

    function getStd(lines: ChartLine[]) {
      if (lines.length === 1) return (lines[0] && lines[0].common?.standardDeviation) || 0;

      return 0;
    }

    return Object.entries({
      default: chartLines.filter((line) => !line.variant),
      Left: chartLines.filter((line) => line.variant === "Left"),
      Right: chartLines.filter((line) => line.variant === "Right"),
    })
      .map(([variant, lines]) => ({
        average: lines.length
          ? mean(lines.map((line) => line.common?.average).filter((n) => typeof n === "number") as number[])
          : 0,
        standardDeviation: getStd(lines),
        scaleId,
        variant: { Left: Variants.Left, Right: Variants.Right }[variant] || null,
        selectedMetric: singleValidMeasure.metric,
      }))
      .filter((avgLine) => avgLine.average);
  }, [chartLines, selections.measures]);
}
export default useChartAnnotations;
