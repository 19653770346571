import { useCallback, useMemo } from "react";
import { schema } from "./AthleteKpiModuleForm.schema";
import DependantFormField from "components/Form/DependantFormField";
import CustomDateRange from "components/Form/CustomDateRange";
import {
  ErrorMessage,
  Formik,
  Form as FormWrapper,
  FormikContextType,
  FieldArray,
  FormikProps,
  FieldArrayRenderProps,
  FormikHelpers,
} from "formik";
import Button from "components/mui5/Button";
import Grid from "@mui/material/Grid";
import useFilteredExercises from "hooks/useFilteredExercises";
import CustomDateRangeFullWidthFix from "components/Forms/CustomDateRangeFullWidthFix";
import FormAutoComplete from "components/Form/FormAutocomplete";
import RadioGroup from "components/Form/RadioGroup";
import useExercises from "providers/ExercisesProvider/useExercises";
import { Exercise, ExerciseMetric } from "types";
import {
  AthleteKpiModuleFormInitialValues,
  AthleteKpiModuleFormProps,
  KpiType,
  AthleteKpiModule,
} from "./AthleteKpiModuleForm.types";
import { defaultInitialValues } from "./AthleteKpiModuleForm.config";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import { exerciseAutocompleteFilterOptions } from "config/autocomplete";
import { RangeType } from "components/ReportGeneration/CreateReport/ModuleModal.types";
import Mui5 from "components/mui5";
import * as Components from "./AthleteKpiModuleForm.components";

function AthleteKpiModuleForm(props: AthleteKpiModuleFormProps) {
  const exercises = useExercises();

  const { initialValues, onSubmit, onCancel } = props;
  const { exerciseMetrics } = useFilteredExercises(exercises);

  const onMetricDependencyChange = useCallback(
    ({ setFieldValue, dependencyValue }: FormikHelpers<typeof initialValues> & { dependencyValue: Exercise }) => {
      const exercise = dependencyValue;
      const defaultMetric = exercise?.metrics.find((metric) => metric.isDefault) || null;
      return setFieldValue("metricField", defaultMetric);
    },
    []
  );

  const onCustomDateDependencyChange = useCallback(
    ({ setFieldValue }: FormikHelpers<typeof initialValues>) => setFieldValue("rangeType", RangeType.Other),
    []
  );

  function handleSubmit(values: AthleteKpiModule<Exercise>) {
    const { exercise, metricField, ...castValues } = schema.cast(values);

    return onSubmit({
      ...castValues,
      moduleType: 0,
      exercises: castValues.exercises.map((exercise) => ({
        exerciseId: exercise.exerciseId.id,
        metricField: exercise.metricField.field,
      })),
    });
  }

  const memoizedInitialValues: AthleteKpiModuleFormInitialValues = useMemo(
    () => ({
      ...defaultInitialValues,
      ...initialValues,
      exercises: (initialValues?.exercises || []).map(({ exerciseId, metricField }) => {
        const exercise = exercises.find((ex) => ex.id === exerciseId) as Exercise;
        const metric = exercise?.metrics.find((metric) => metric.field === metricField) as ExerciseMetric;

        return {
          exerciseId: exercise,
          metricField: metric,
        };
      }),
      exercise: null,
      metricField: null,
    }),
    [exercises, initialValues]
  );

  return (
    <Formik
      initialValues={memoizedInitialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, setFieldValue, setFieldError }) => (
        <FormWrapper>
          <FieldArray name="exercises">
            {(arrayHelpers: FieldArrayRenderProps & { form: FormikProps<AthleteKpiModuleFormInitialValues> }) => {
              const { exercise, metricField } = values;
              function addMetric() {
                if (!(exercise && metricField)) {
                  return;
                }

                arrayHelpers.push({
                  exerciseId: { ...exercise },
                  metricField: { ...metricField },
                });

                setFieldValue("exercise", null);
                setFieldError("exercise", undefined);
                setFieldValue("metricField", null);
                setFieldError("metricField", undefined);
              }

              return (
                <Grid container spacing="24px">
                  <Grid item xs={12}>
                    <FormAutoComplete
                      name="exercise"
                      options={exercises}
                      groupBy={(option: Exercise) => option.category}
                      getOptionLabel={(option: Exercise) => option.name || ""}
                      renderInput={(params: any) => (
                        <Mui5.TextField {...params} label="Exercise" placeholder="Select Exercise" variant="standard" />
                      )}
                      filterOptions={exerciseAutocompleteFilterOptions}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DependantFormField dependencyName="exercise" onDependencyChange={onMetricDependencyChange}>
                      {({ values, errors }: FormikContextType<AthleteKpiModuleFormInitialValues>) => {
                        const options = exerciseMetrics(values.exercise);
                        return (
                          <FormAutoComplete
                            name="metricField"
                            noOptionsText="No Options (Select an Exercise)"
                            options={options}
                            disabled={!options.length}
                            defaultValue={null}
                            getOptionLabel={(option: ExerciseMetric) => option.name || ""}
                            renderInput={(params: any) => (
                              <Mui5.TextField
                                {...params}
                                label="Metric"
                                placeholder="Select Metric"
                                variant="standard"
                              />
                            )}
                            isOptionEqualToValue={(option: ExerciseMetric, value: ExerciseMetric) =>
                              option.field === value?.field
                            }
                          />
                        );
                      }}
                    </DependantFormField>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="button"
                      onClick={addMetric}
                      variant="outlined"
                      fullWidth
                      disabled={!(exercise && metricField)}
                      color="primary"
                      disableElevation
                    >
                      Add Metric to KPI Module
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <RadioGroup
                      label="Date Range"
                      name="rangeType"
                      color="primary"
                      options={[
                        { label: "Today", value: RangeType.Day },
                        { label: "Week", value: RangeType.Week },
                        { label: "Month", value: RangeType.Month },
                        { label: "Year", value: RangeType.Year },
                        {
                          label: "Custom",
                          value: RangeType.Other,
                          component: (
                            <CustomDateRangeFullWidthFix>
                              <DependantFormField
                                dependencyName="startDate"
                                onDependencyChange={onCustomDateDependencyChange}
                              />
                              <DependantFormField
                                dependencyName="endDate"
                                onDependencyChange={onCustomDateDependencyChange}
                              />
                              <CustomDateRange startInputName="startDate" endInputName="endDate" />
                            </CustomDateRangeFullWidthFix>
                          ),
                        },
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <RadioGroup
                      label="KPI Type"
                      name="kpiType"
                      color="primary"
                      inverse={false}
                      options={[
                        { label: "Latest", value: KpiType.Latest },
                        { label: "Average", value: KpiType.Average },
                        { label: "Best", value: KpiType.Best },
                      ]}
                    />
                  </Grid>

                  <Grid item container xs={12} spacing="8px">
                    {arrayHelpers.form.values.exercises.map(
                      (
                        moduleExercise: {
                          exerciseId: Exercise;
                          metricField?: ExerciseMetric | string;
                          metricName?: string;
                        },
                        moduleExerciseIndex: number
                      ) => {
                        const chipLabel = [
                          moduleExercise.exerciseId.category,
                          moduleExercise.exerciseId.name,
                          typeof moduleExercise.metricField === "string"
                            ? moduleExercise.metricName
                            : moduleExercise.metricField?.name,
                        ].join(" / ");

                        return (
                          <Grid item key={moduleExerciseIndex}>
                            <Components.MaxWidthChip
                              title={chipLabel}
                              label={chipLabel}
                              onDelete={() => arrayHelpers.remove(moduleExerciseIndex)}
                              color="primary"
                            />
                          </Grid>
                        );
                      }
                    )}
                  </Grid>

                  <ErrorMessage name="exercises">
                    {(message) =>
                      typeof message === "string" ? (
                        <Grid item xs={12}>
                          <Box margin="auto" display="inline-block">
                            <FormHelperText error>{message}</FormHelperText>
                          </Box>
                        </Grid>
                      ) : (
                        JSON.stringify(message)
                      )
                    }
                  </ErrorMessage>

                  <Grid item xs={8}>
                    <Button disableElevation fullWidth type="submit" variant="contained" color="primary">
                      Confirm
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button fullWidth variant="outlined" onClick={onCancel}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              );
            }}
          </FieldArray>
        </FormWrapper>
      )}
    </Formik>
  );
}

export default AthleteKpiModuleForm;
