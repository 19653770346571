import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useRequest } from "hooks";
import CoachForm from "components/Forms/CoachForm";
import Mui5 from "components/mui5";
import useCoachesActions from "providers/CoachesProvider/useCoachesActions";
import Typography from "@mui/material/Typography";
import { GridBorderBottomGrey } from "../Settings.components";
import LocalisedLoadingProvider from "components/LocalisedLoadingProvider";
import PageContentTitle from "components/PageContent/PageContentTitle";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CoachBase } from "types/coach";
import { useNavigate, useParams } from "react-router-dom";
import useCoach from "providers/CoachesProvider/useCoach";
import useLoading from "hooks/useLoading";
import NotFound from "features/NotFound";
import ConfirmationDialog from "components/ConfirmationDialog";
import CoachHelpers from "helpers/CoachHelpers";
import useMakeProfileImageURL from "hooks/useMakeProfileImageURL";

interface SettingsCoachEditProps {}

function SettingsCoachEdit(props: SettingsCoachEditProps) {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const { update, getById, delete: deleteCoach } = useCoachesActions();
  const updateCoach = useRequest(update, true);
  const getCoachById = useRequest(getById, true);
  const deleteCoachById = useRequest(deleteCoach, true);
  const navigate = useNavigate();
  const coachId = useParams<{ coachId: string }>().coachId!;
  const coach = useCoach(coachId);
  const loading = useLoading();

  const handleUpdate = useCallback(
    async (coach: CoachBase) => {
      await updateCoach(coachId, coach);
      navigate(-1);
    },
    [updateCoach, coachId, navigate]
  );

  const handleDelete = useCallback(async () => {
    await deleteCoachById(coachId);
    navigate(-1);
  }, [coachId, deleteCoachById, navigate]);

  useEffect(() => {
    getCoachById(coachId);
  }, [coachId, getCoachById]);

  const firebaseStorageProfilePhotoUrl = useMakeProfileImageURL(CoachHelpers.getProfileImagePath(coach));
  const initialValues = useMemo(() => {
    if (!coach) return null;

    const { email, name, groupIds } = coach;

    return { email, name, groupIds, profilePhotoName: firebaseStorageProfilePhotoUrl };
  }, [coach, firebaseStorageProfilePhotoUrl]);

  const handleDeleteConfirmOpen = useCallback(() => {
    setDeleteConfirmOpen(true);
  }, []);

  if (!(loading && initialValues)) {
    return <NotFound buttonText="Manage Coaches" link="/settings/coaches" />;
  }

  return (
    <LocalisedLoadingProvider>
      <Grid container>
        <GridBorderBottomGrey item xs={12}>
          <Box p={6}>
            <PageContentTitle
              title={
                <Typography component="span" variant="h1">
                  <Box component="span" fontWeight="fontWeightRegular">
                    Update Coach
                  </Box>
                </Typography>
              }
              titleLink="/settings/coaches"
            />
          </Box>
          <Box pt={0} p={6} maxWidth={720}>
            <Mui5.Paper>
              <Box px={8} py={5}>
                <CoachForm
                  FormikProps={{ enableReinitialize: true }}
                  initialValues={initialValues}
                  onSubmit={handleUpdate}
                  submitButtonText={"Update Coach"}
                  photoChangeButtonText={"Update Coach Photo"}
                  secondaryAction={
                    <>
                      <Mui5.Button variant="outlined" color="error" onClick={handleDeleteConfirmOpen}>
                        Delete Coach
                      </Mui5.Button>
                      <ConfirmationDialog
                        open={deleteConfirmOpen}
                        onConfirm={handleDelete}
                        setOpen={setDeleteConfirmOpen}
                        title="Delete Coach"
                        body={`Are you sure you want to delete coach ${initialValues.name}? This action cannot be undone.`}
                        confirmText="Confirm"
                        cancelText="Cancel"
                      />
                    </>
                  }
                />
              </Box>
            </Mui5.Paper>
          </Box>
        </GridBorderBottomGrey>
      </Grid>
    </LocalisedLoadingProvider>
  );
}

export default SettingsCoachEdit;
