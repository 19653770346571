import DateTimePickers from "components/mui5/DateTimePickers";
import moment from "moment";
import { useCallback } from "react";
import Mui5 from "components/mui5";
import { useField } from "formik";
import { DatePickerProps } from "@mui/x-date-pickers-pro";

function FormDatePicker(props: DatePickerProps<moment.Moment> & { name: string }) {
  const [field, meta, helpers] = useField<string | null>(props.name);

  const hasError = !!Boolean(meta.touched && meta.error);
  const errorText = hasError ? meta.error : null;

  return (
    <DateTimePickers.DatePicker
      {...props}
      {...field}
      format={"DD / MM / YYYY"}
      value={field.value ? moment(field.value) : null}
      onChange={useCallback((v: string | moment.Moment | null) => helpers.setValue(moment(v).toISOString()), [helpers])}
      slots={{
        textField: Mui5.TextField,
        ...props?.slots,
      }}
      slotProps={{
        ...props?.slotProps,
        textField: {
          helperText: hasError ? errorText : null,
          error: hasError,
          variant: "standard",
          fullWidth: true,
          ...props?.slotProps?.textField,
        },
      }}
    />
  );
}

export default FormDatePicker;
