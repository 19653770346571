import { MeasureRequestParams } from "features/Charting/services/MeasureService/MeasureService.types";

class MeasureServiceHelper {
  static getRequestId(request: MeasureRequestParams) {
    return [request.sourceId, request.measure.id, request.startDate.toISOString(), request.endDate.toISOString()].join(
      "_"
    );
  }
}
export default MeasureServiceHelper;
