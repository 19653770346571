import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import RagMonitoringTemplateSelectorMenu from "components/RagMonitoringTemplateSelectorMenu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MonitoringSelectorButton } from "./AthleteTableHeader.components";
import MonitoringLabelsRow from "components/Monitoring/MonitoringLabelsRow";
import { useMultiSelectAll } from "providers/MultiSelectProvider";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore";
import Tables from "components/Tables";
import { tableCells } from "../AthleteTable.config";

interface AthleteTableHeaderProps {}

function AthleteTableHeader(props: AthleteTableHeaderProps) {
  const athletes = useAthleteStore();
  const selectAll = useMultiSelectAll(athletes);

  return (
    <>
      <Grid {...Tables.headerContainerProps}>
        <Grid item {...tableCells.groupColorBlocks} />
        <Grid item {...tableCells.checkbox}>
          <Tables.Components.MultiSelectCheckbox
            inputProps={{ "aria-label": "select all athletes" }}
            checked={selectAll.value}
            indeterminate={selectAll.indeterminate}
            onChange={selectAll.toggle}
          />
        </Grid>
        <Grid item {...tableCells.name} color="#FFF">
          <RagMonitoringTemplateSelectorMenu id="athlete-hub-rag-template-selector">
            {({ open, anchorProps, currentTemplate }) => (
              <MonitoringSelectorButton
                {...anchorProps}
                variant="text"
                size="small"
                color="inherit"
                endIcon={
                  <ExpandMoreIcon
                    color="inherit"
                    fontSize="large"
                    sx={{
                      transform: !open ? "rotate(0deg)" : "rotate(-180deg)",
                      transition: "transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    }}
                  />
                }
              >
                <Box component="span" fontSize="h4.fontSize" fontWeight="fontWeightMedium">
                  {currentTemplate?.name || "Athlete Monitoring"}
                </Box>
              </MonitoringSelectorButton>
            )}
          </RagMonitoringTemplateSelectorMenu>
        </Grid>
        <Grid item {...tableCells.monitoringIndicators} color="#FFF">
          <Box height="100%" py={0}>
            <MonitoringLabelsRow />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default AthleteTableHeader;
