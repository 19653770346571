import { Component } from "react";
import Loader from "../../../Loader/Loader";
import PageContent from "components/PageContent";
import Constants from "../../../../config/Constants";
import ModuleItemMenu from "../ModuleItemMenu/ModuleItemMenu";
import errorIcon from "../../../../assets/images/error-icon.svg";
import warningIcon from "../../../../assets/images/warning-icon.svg";
import expandButton from "../../../../assets/images/Expand Button.svg";
import ModuleModal from "./ModuleModal/ModuleModal";
import whiteCloseIcon from "../../../../assets/images/plus-icon.svg";
import Button from "../../../Button_deprecated/Button";
import moment from "moment";
import { isEmailValid } from "../../../../config/utils";
import "./GroupReport.scss";
import Confirmation from "../../../Confirmation_deprecated/Confirmation";
import closeIcon from "../../../../assets/images/close-icon.svg";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import GroupsContext from "contexts/GroupsContext";
import ReportMetadataHOC from "../ReportMetadataHOC";
import RAGSettingsContext from "contexts/RAGSettingsContext";
import ExerciseMetadataService from "services/ExerciseMetadataService";
import { RangeType, ReportModuleType } from "../ModuleModal.types";
import FormHelperText from "@mui/material/FormHelperText";
import ReportsContext from "contexts/ReportsContext";
import withNavigate from "utils/withNavigate";
import Mui5 from "components/mui5";
import { firstBy } from "thenby";
import * as PropTypes from "prop-types";
import ExercisesContext from "providers/ExercisesProvider/ExercisesContext";

class CreateGroupReport extends Component {
  static contextType = ReportsContext;

  state = {
    // added after defactoring redux
    loadingReport: false,
    generatingReport: false,
    reportGenerationError: null,
    // Pre-existing
    reportNotes: "",
    reportName: "",
    autocompleteEmailValue: "",
    reportEmails: [],
    isSaveAsChecked: false,
    isOpenModal: false,
    metricOptions: [],
    exerciseOptions: [],
    isMetricDropDownDisabled: true,
    isExerciseDropDownDisabled: true,
    reportSelectedGroups: [],
    athleteComparisonModule: {
      moduleType: 5,
      groupComparisonFilters: [],
      exerciseId: null,
      metricField: null,
      rangeType: 0,
      category: null,
      exercise: null,
      metricObject: null,
      startDate: new Date(moment().subtract(24, "hours").format()),
      endDate: new Date(moment().endOf("day").toISOString()),
      customStartDate: null,
      customEndDate: null,
      needsEdits: false,
    },
    leaderBoardModule: {
      moduleType: 7,
      leaderboardName: "",
      exerciseId: null,
      metricField: null,
      rangeType: 0,
      category: null,
      exercise: null,
      metricObject: null,
      startDate: new Date(moment().subtract(24, "hours").format()),
      endDate: new Date(moment().endOf("day").toISOString()),
      customStartDate: null,
      customEndDate: null,
    },
    modalErrors: {
      hasCategoryErrors: false,
      hasExerciseErrors: false,
      hasMetricErrors: false,
      hasTypeErrors: false,
      hasSelectedAthletesErrors: false,
      hasLeaderBoardNameErrors: false,
    },
    templateFormErrors: {
      hasGroupErrors: false,
      hasModulesErrors: false,
      hasNameErrors: false,
      hasNotesErrors: false,
      hasAthleteComparisonErrors: false,
    },
    moduleTemplates: [],
    isConfirmationOpen: false,
    moduleToEdit: null,
    moduleToDelete: null,
    isEditAndUse: false,
    selectedModuleType: null,
  };

  /** @returns {import("contexts/ReportsContext").ReportsContextActions} */
  get reportActions() {
    if (typeof this.context === "undefined") {
      throw new Error("ReportsContext.Provider is not defined");
    }

    return this.context[1];
  }

  handleGroupChange = (_, selectedGroups) => {
    this.setState((prevState) => ({
      reportSelectedGroups: selectedGroups || [],
      moduleTemplates: prevState.moduleTemplates.map((module) =>
        module.moduleType === 5 ? { ...module, needsEdits: true, groupComparisonFilters: [] } : module
      ),
      templateFormErrors: { ...prevState.templateFormErrors, hasGroupErrors: false },
    }));
  };

  modalFormHandlers = {
    onCloseModalHandler: () => {
      this.setState({
        athleteComparisonModule: {
          moduleType: 5,
          groupComparisonFilters: [],
          exerciseId: null,
          metricField: null,
          rangeType: 0,
          category: null,
          exercise: null,
          metricObject: null,
          startDate: new Date(moment().subtract(24, "hours").format()),
          endDate: new Date(moment().endOf("day").toISOString()),
          customStartDate: null,
          customEndDate: null,
          needsEdits: false,
        },
        leaderBoardModule: {
          moduleType: 7,
          leaderboardName: "",
          exerciseId: null,
          metricField: null,
          rangeType: 0,
          category: null,
          exercise: null,
          metricObject: null,
          startDate: new Date(moment().subtract(24, "hours").format()),
          endDate: new Date(moment().endOf("day").toISOString()),
          customStartDate: null,
          customEndDate: null,
        },
        metricOptions: [],
        exerciseOptions: [],
        isMetricDropDownDisabled: true,
        isExerciseDropDownDisabled: true,
        moduleToEdit: null,
        modalErrors: {
          hasCategoryErrors: false,
          hasExerciseErrors: false,
          hasMetricErrors: false,
          hasTypeErrors: false,
          hasExerciseSetsErrors: false,
        },
        selectedModuleType: null,
      });
    },
    submitModule_V2: (values) => {
      if (this.state.moduleToEdit === null) {
        // Create Monitoring Module
        this.setState((prevState) => ({
          ...prevState,
          moduleToEdit: null,
          moduleTemplates: [...prevState.moduleTemplates, { ...values }],
        }));
      } else {
        // Edit Monitoring Module
        this.setState((prevState) => {
          const moduleIndex = this.state.moduleTemplates.findIndex((module) => module === this.state.moduleToEdit);
          const moduleTemplatesCopy = this.state.moduleTemplates.slice();
          const editableModule = moduleTemplatesCopy[moduleIndex] || {};
          moduleTemplatesCopy[moduleIndex] = { ...editableModule, ...values };

          return {
            ...prevState,
            moduleToEdit: null,
            moduleTemplates: [...moduleTemplatesCopy],
          };
        });
      }
      this.handleCloseModal();
    },
  };

  componentDidUpdate(_, prevState) {
    if (prevState.reportSelectedGroups !== this.state.reportSelectedGroups) {
      this.setState((prevState) => ({
        /**
         * @author Carden
         * Clears selected athletes from athleteComparison
         * module when the groups selected change */
        moduleTemplates: prevState.moduleTemplates.map((moduleTemplate) => {
          if (moduleTemplate.moduleType !== 5) {
            return moduleTemplate;
          }
          return { ...moduleTemplate, groupComparisonFilters: [] };
        }),
      }));
    }
  }

  async getByTemplateId() {
    this.setState({ loadingReport: true });
    try {
      const template = await this.reportActions.getById(this.props.match.params.id);

      const modulesWithModifiedDates = template.modules
        /**
         * @densk1
         * This code filters out references to Archived Custom Exercises
         */
        .map((module) => ({
          ...module,
          exerciseId: this.props.archivedCustomExerciseIds.includes(module.exerciseId) ? null : module.exerciseId,
          exercises: module.exercises.filter(
            ({ exerciseId }) => !this.props.archivedCustomExerciseIds.includes(exerciseId)
          ),
        }))
        .map((module) => {
          const range = ["day", "week", "month", "year"][module.rangeType];

          if (range) {
            const endDate = moment().endOf("day");
            const startDate = range === "day" ? endDate.clone().startOf("day") : endDate.clone().subtract(1, range);

            return {
              ...module,
              startDate: startDate.toISOString(),
              endDate: endDate.toISOString(),
            };
          }

          return module;
        });

      this.setState({
        reportNotes: template.notes,
        reportName: template.name,
        moduleTemplates: modulesWithModifiedDates,
        isEditAndUse: true,
      });
    } catch (e) {}
    this.setState({ loadingReport: false });
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.getByTemplateId();
    }
  }

  handleEmailAutocompleteChange = ({ target }) => {
    this.setState({ autocompleteEmailValue: target.value });
  };

  addReportEmail = ({ target }) => {
    if (target.value.length) {
      let emailList = this.state.autocompleteEmailValue.split(",");
      let validEmails = [];
      emailList.forEach((email) => {
        if (isEmailValid(email.trim())) {
          validEmails.push(email.trim());
        }
      });
      this.setState((prevState) => ({
        reportEmails: [...prevState.reportEmails, ...validEmails],
        autocompleteEmailValue: "",
      }));
    }
  };

  handleRemoveEmailClick = (email) => {
    this.setState({ reportEmails: this.state.reportEmails.filter((selectedEmail) => selectedEmail !== email) });
  };

  handleReportNotesChange = ({ target }) => {
    this.setState((prevState) => ({
      templateFormErrors: { ...prevState.templateFormErrors, hasNotesErrors: false },
      reportNotes: target.value,
    }));
  };

  reportNameChange = ({ target }) => {
    this.setState((prevState) => ({
      templateFormErrors: { ...prevState.templateFormErrors, hasNameErrors: false },
      reportName: target.value,
    }));
  };

  changeStatus = () => {
    this.setState({ isSaveAsChecked: !this.state.isSaveAsChecked });
  };

  handleOpenModal = () => {
    this.setState((prevState) => ({
      templateFormErrors: { ...prevState.templateFormErrors, hasModulesErrors: false },
      isOpenModal: true,
    }));
  };

  handleCloseModal = () => {
    this.setState(
      {
        isOpenModal: false,
      },
      () => this.modalFormHandlers.onCloseModalHandler()
    );
  };

  handleOpenDeleteConfirmation = (modulePanel) => {
    this.setState({
      isConfirmationOpen: true,
      moduleToDelete: modulePanel,
    });
  };

  confirmModuleDeletionHandler = () => {
    const allModulesUpdated = this.state.moduleTemplates;
    allModulesUpdated.splice(allModulesUpdated.indexOf(this.state.moduleToDelete), 1);

    this.setState({ moduleTemplates: allModulesUpdated });
    this.handleCloseDeleteConfirmation();
  };

  handleCloseDeleteConfirmation = () => {
    this.setState({ isConfirmationOpen: false });
  };

  editModule = (item) => {
    this.setState({
      moduleToEdit: item,
    });
    this.handleOpenModal();
  };

  saveReport = () => {
    const templateFormErrors = {};

    if (this.state.reportSelectedGroups.length < 1) {
      templateFormErrors["hasGroupErrors"] = true;
    }
    if (this.state.moduleTemplates.length < 1) {
      templateFormErrors["hasModulesErrors"] = true;
    }
    if (this.state.isSaveAsChecked && (this.state.reportName.length < 1 || this.state.reportName.length > 100)) {
      templateFormErrors["hasNameErrors"] = true;
    }
    if (this.state.reportNotes.length > 1000) {
      templateFormErrors["hasNotesErrors"] = true;
    }

    const athleteComparisonModules = this.state.moduleTemplates.filter(
      (module) => module.moduleType === ReportModuleType.ATHLETE_COMPARISON
    );
    if (this.state.reportSelectedGroups.length !== 1 && athleteComparisonModules.length) {
      templateFormErrors["hasAthleteComparisonErrors"] = true;
    } else if (
      athleteComparisonModules.length &&
      athleteComparisonModules.find((m) => !m?.groupComparisonFilters || m?.groupComparisonFilters?.length === 0)
    ) {
      templateFormErrors["hasAthleteComparisonErrors"] = true;
    }

    if (Object.values(templateFormErrors).find((x) => x)) {
      return this.setState((prevState) => ({
        templateFormErrors: { ...templateFormErrors },
      }));
    }

    const reportData = {
      reportTemplate: {
        name: this.state.reportName,
        notes: this.state.reportNotes,
        modules: this.state.moduleTemplates,
        reportType: 1,
      },
      groupIds: this.state.reportSelectedGroups
        .filter((group) => group.athleteIds?.length > 0)
        .map((group) => group.id),
      emailAddresses: this.state.reportEmails,
    };

    (async () => {
      this.setState({ reportGenerationError: null, generatingReport: true });
      const actions = this.reportActions;
      try {
        if (this.state.isSaveAsChecked) await actions.create(reportData.reportTemplate);
        await actions.generateGroupReport(reportData);
        this.setState({ generatingReport: false }, () => {
          this.props.navigate(Constants.routes.reportGeneration.route);
        });
      } catch (error) {
        this.setState({ reportGenerationError: error, generatingReport: false });
      }
    })();
  };

  renderDateRange(startDate, endDate, rangeType) {
    if (rangeType !== RangeType.Other) {
      return ["Today", "Past Week", "Past Month", "Past Year"][rangeType];
    }

    const startString = new Date(startDate).toDateString();
    const endString = new Date(endDate).toDateString();

    return startDate && endDate ? `${startString} - ${endString}` : null;
  }

  getModuleCardWrapperProps = (item) => {
    if (this.state.reportSelectedGroups.length !== 1 && item.moduleType === 5) {
      return {
        className: "module-card-wrapper",
      };
    }
    return {
      className: "module-card-wrapper module-card-wrapper-clickable",
      onClick: () => this.editModule(item),
    };
  };

  render() {
    const {
      leaderBoardModule,
      athleteComparisonModule,
      isSaveAsChecked,
      isEditAndUse,
      isOpenModal,
      moduleTemplates,
      exerciseOptions,
      metricOptions,
      reportNotes,
      reportName,
      moduleToEdit,
      templateFormErrors,
      modalErrors,
      isExerciseDropDownDisabled,
      isMetricDropDownDisabled,
      reportSelectedGroups,
      reportEmails,
      selectedModuleType,
    } = this.state;

    return (
      <div className="oh-athlete-report group">
        {this.state.loadingReport && <Loader />}
        <PageContent
          pageHeadingLink={isEditAndUse ? Constants.routes.reportGeneration.route : Constants.routes.createReport.route}
          pageHeading={isEditAndUse ? "Create Group Report from template" : "Create a Group Report"}
        >
          <Grid container justifyContent="center">
            <Grid item md={10} lg={8} xl={5}>
              <Mui5.Paper>
                <Box p={3}>
                  <div className="oh-add-athlete-report-form">
                    <Box pb={2}>
                      <GroupsContext.Consumer>
                        {([groups]) => (
                          <Mui5.Autocomplete
                            options={groups.slice(0).sort(firstBy("name", { ignoreCase: true }).thenBy("id"))}
                            value={reportSelectedGroups}
                            onChange={this.handleGroupChange}
                            getOptionLabel={(option) => option.name}
                            multiple
                            disableCloseOnSelect
                            renderTags={(tagValue, getTagProps) =>
                              tagValue.map((option, index) => {
                                const { key, ...tagProps } = getTagProps({ index });

                                return (
                                  <Mui5.Chip
                                    key={option.id}
                                    label={option.name}
                                    customColor={option.colour}
                                    {...tagProps}
                                  />
                                );
                              })
                            }
                            renderInput={(params) => (
                              <Mui5.TextField
                                {...params}
                                InputLabelProps={{
                                  ...params.InputLabelProps,
                                  shrink: true,
                                  focused: false,
                                }}
                                className="oh-athlete-report-athlete-selector"
                                color="secondary"
                                margin="normal"
                                placeholder="Start typing group name"
                                label="Add Group to Report"
                                error={this.state.hasGroupErrors || templateFormErrors.hasGroupErrors}
                                helperText={
                                  this.state.hasGroupErrors || templateFormErrors.hasGroupErrors
                                    ? "At least 1 group should be chosen"
                                    : undefined
                                }
                              />
                            )}
                          />
                        )}
                      </GroupsContext.Consumer>
                    </Box>
                    {(moduleTemplates || []).map((item, index) => (
                      <div key={index} {...this.getModuleCardWrapperProps(item)}>
                        <div
                          className={`module-card ${
                            reportSelectedGroups.length !== 1 && item.moduleType === 5 && "is-disabled"
                          }`}
                        >
                          <div className={`module-image icon-${item.moduleType}`} />
                          <div className="module-text">
                            <Box>
                              {item.moduleType === 6 ? (
                                <Box>
                                  <span>Athlete Monitoring Indicators</span>
                                  <Typography variant="subtitle2" color="textPrimary">
                                    <Box component="span" fontWeight="fontWeightLight">
                                      <RAGSettingsContext.Consumer>
                                        {([templates]) => {
                                          const name = templates.find(
                                            ({ id }) => id === item.ragStatusTemplateId
                                          )?.name;

                                          return name || "Default Template";
                                        }}
                                      </RAGSettingsContext.Consumer>
                                    </Box>
                                  </Typography>
                                </Box>
                              ) : item.moduleType === 7 ? (
                                <Box>
                                  <span>
                                    Leaderboard{" "}
                                    <Box component="span" fontSize="subtitle2.fontSize" fontWeight="fontWeightLight">
                                      ({item.leaderboardName})
                                    </Box>
                                  </span>
                                  <ExercisesContext.Consumer>
                                    {(exercises) => {
                                      const exercise = exercises.find((exercise) => exercise.id === item.exerciseId);
                                      const metric = ExerciseMetadataService.metric(exercise, item.metricField);

                                      return (
                                        <Typography variant="subtitle2" color="textPrimary">
                                          <Box component="span" fontWeight="fontWeightLight">
                                            {exercise?.category} / {exercise?.name}{" "}
                                            {Boolean(metric?.name) && `/ ${metric?.name}`}
                                          </Box>
                                        </Typography>
                                      );
                                    }}
                                  </ExercisesContext.Consumer>
                                  <Typography variant="subtitle2" color="textSecondary">
                                    <Box component="span" fontWeight="fontWeightLight" fontSize={12}>
                                      {this.renderDateRange(item.startDate, item.endDate, item.rangeType)}
                                    </Box>
                                  </Typography>
                                </Box>
                              ) : (
                                <Box>
                                  <span>
                                    Athlete Comparison{" "}
                                    <Box component="span" fontSize="subtitle2.fontSize" fontWeight="fontWeightLight">
                                      ({`${item?.groupComparisonFilters?.length || 0} athletes selected`})
                                    </Box>
                                  </span>
                                  <ExercisesContext.Consumer>
                                    {(exercises) => {
                                      const exercise = exercises.find((exercise) => exercise.id === item.exerciseId);
                                      const metric = ExerciseMetadataService.metric(exercise, item.metricField);

                                      return (
                                        <Typography variant="subtitle2" color="textPrimary">
                                          <Box component="span" fontWeight="fontWeightLight">
                                            {exercise?.category} / {exercise?.name}{" "}
                                            {Boolean(metric?.name) && `/ ${metric?.name}`}
                                          </Box>
                                        </Typography>
                                      );
                                    }}
                                  </ExercisesContext.Consumer>
                                  <Typography variant="subtitle2" color="textSecondary">
                                    <Box component="span" fontWeight="fontWeightLight" fontSize={12}>
                                      {this.renderDateRange(item.startDate, item.endDate, item.rangeType)}
                                    </Box>
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </div>
                          <div className="action-buttons">
                            <ModuleItemMenu
                              deleteHandler={() => this.handleOpenDeleteConfirmation(item)}
                              moduleTemplates={item}
                              editModule={this.editModule}
                            />
                          </div>
                        </div>
                        <div className="module-notices">
                          {reportSelectedGroups.length === 1 &&
                          item.moduleType === 5 &&
                          !item?.groupComparisonFilters?.length ? (
                            <>
                              <img src={warningIcon} alt="error" />
                              <p>Edit module to select comparable sets</p>
                            </>
                          ) : reportSelectedGroups.length !== 1 && item.moduleType === 5 ? (
                            <>
                              <img src={errorIcon} alt="error" />
                              <p>This module is only available for single group reports</p>
                            </>
                          ) : null}
                        </div>
                      </div>
                    ))}
                    <Mui5.Paper
                      style={{
                        width: "100%",
                        borderRadius: "16px",
                      }}
                      component={ButtonBase}
                      onClick={this.handleOpenModal}
                    >
                      <Box display="flex" height={96} alignItems="center">
                        <img className="plus-icon" src={expandButton} alt="add-logo" />
                        <Typography component="span" variant="h3">
                          Add a new module
                        </Typography>
                      </Box>
                    </Mui5.Paper>
                    {templateFormErrors.hasModulesErrors ? (
                      <p className="oh-form-error">At least 1 module should be chosen</p>
                    ) : null}
                    {isOpenModal && (
                      <ModuleModal
                        reportSelectedGroups={reportSelectedGroups}
                        {...this.props}
                        athleteComparisonModule={athleteComparisonModule}
                        leaderBoardModule={leaderBoardModule}
                        handleCloseModal={this.handleCloseModal}
                        metricOptions={metricOptions}
                        exerciseOptions={exerciseOptions}
                        {...this.modalFormHandlers}
                        isMetricDropDownDisabled={isMetricDropDownDisabled}
                        isExerciseDropDownDisabled={isExerciseDropDownDisabled}
                        isEditingModule={moduleToEdit !== null}
                        moduleToEdit={moduleToEdit}
                        selectedModuleType={selectedModuleType}
                        errors={modalErrors}
                      />
                    )}
                    <Box p={1} />
                    <Mui5.TextField
                      InputLabelProps={{
                        shrink: true,
                        focused: false,
                      }}
                      label={
                        <>
                          Report notes
                          <Box component="span" fontWeight="fontWeightLight">
                            &nbsp;(Optional)
                          </Box>
                        </>
                      }
                      name="report-notes"
                      id="report-notes"
                      className="mui-text-field"
                      color="secondary"
                      margin="normal"
                      fullWidth
                      value={reportNotes}
                      onChange={this.handleReportNotesChange}
                      error={this.state.templateFormErrors.hasNotesErrors}
                      helperText={templateFormErrors.hasNotesErrors ? "Note max length is 1000 characters" : undefined}
                    />

                    <Mui5.TextField
                      InputLabelProps={{
                        shrink: true,
                        focused: false,
                      }}
                      label={
                        <>
                          Forward Report to Emails
                          <Box component="span" fontWeight="fontWeightLight">
                            &nbsp;(Optional)
                          </Box>
                        </>
                      }
                      className="mui-text-field"
                      name="report-emails"
                      id="report-emails"
                      placeholder="Enter email addresses separated by a comma..."
                      color="secondary"
                      margin="normal"
                      fullWidth
                      onChange={this.handleEmailAutocompleteChange}
                      value={this.state.autocompleteEmailValue}
                      onBlur={this.addReportEmail}
                    />
                    <div className="report-email-list">
                      {reportEmails.map((email, index) => (
                        <div className="oh-value-wrapper" key={`selected-${index}`}>
                          <span className="oh-value">{email}</span>
                          <button type="button" onClick={(e) => this.handleRemoveEmailClick(email)}>
                            <img src={whiteCloseIcon} alt="close" />
                          </button>
                        </div>
                      ))}
                    </div>
                    <Mui5.TextField
                      InputLabelProps={{
                        shrink: true,
                        focused: false,
                      }}
                      label={
                        <>
                          Report Template Name
                          <Box component="span" fontWeight="fontWeightLight">
                            &nbsp;(Optional)
                          </Box>
                        </>
                      }
                      placeholder="Add a template name..."
                      name="template-name"
                      id="template-name"
                      onChange={this.reportNameChange}
                      value={reportName}
                      color="secondary"
                      margin="normal"
                      className="mui-text-field"
                      fullWidth
                      error={this.state.templateFormErrors.hasNameErrors}
                      helperText={
                        templateFormErrors.hasNameErrors
                          ? "Template name length must be between 1 and 100 characters"
                          : undefined
                      }
                    />
                    <div className="input-wrapper">
                      <label className="custom-checkbox">
                        <input type="checkbox" onChange={this.changeStatus} />
                        <span className="checkmark" />
                        Save this Report as a template
                      </label>
                    </div>
                    <Button handleClick={this.saveReport} classNames="add-report btn-cyan">
                      {isSaveAsChecked ? "Save template and Generate report" : "Generate Report"}
                    </Button>
                    <Box pl={3}>
                      {(this.state.hasGroupErrors || templateFormErrors.hasGroupErrors) && (
                        <FormHelperText error>- At least 1 group should be chosen</FormHelperText>
                      )}
                      {this.state.templateFormErrors.hasAthleteComparisonErrors && (
                        <FormHelperText error>- An Athlete Comparison module needs editing</FormHelperText>
                      )}
                    </Box>
                  </div>
                </Box>
              </Mui5.Paper>
              <Confirmation
                confirmationMessage={`Are you sure you want to delete this Module? `}
                positiveHandler={this.confirmModuleDeletionHandler}
                negativeHandler={this.handleCloseDeleteConfirmation}
                buttonText="Delete Module"
                hasOwnButton={false}
                isConfirmationOpen={this.state.isConfirmationOpen}
              />
            </Grid>
          </Grid>
          <div
            className={
              "oh-report-gen-overlay" +
              (this.state.generatingReport || this.state.reportGenerationError ? " is-active" : "")
            }
          >
            <div className="oh-report-gen-popup">
              {this.state.reportGenerationError && (
                <button
                  type="button"
                  className="oh-report-gen-close"
                  onClick={() => {
                    this.setState({ reportGenerationError: null });
                  }}
                >
                  <img src={closeIcon} alt="close" />
                </button>
              )}
              <div className="oh-rep-gen-progress">
                {this.state.reportGenerationError ? (
                  <>
                    <img className="error-icon" src={errorIcon} alt="error" />
                    <h1 className="title has-error">
                      An error occurred while generating the report. Please check each of your modules configuration.
                    </h1>
                  </>
                ) : (
                  <>
                    <div className="oh-rep-gen-loader" />
                    <h1 className="title">Report Generation in progress...</h1>
                  </>
                )}
              </div>
            </div>
          </div>
        </PageContent>
      </div>
    );
  }
}

CreateGroupReport.propTypes = {
  archivedCustomExerciseIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default ReportMetadataHOC(withNavigate(CreateGroupReport));
