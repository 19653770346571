import { EntityType } from "types/entity";
import { AthleteOrGroup } from "types/AthleteOrGroup";
import {
  SelectedChartMeasureExercise,
  SelectedChartMeasureWellness,
  SelectedChartMeasureAnthropometric,
} from "components/charting/types";
import MeasureHelpers from "features/Charting/helpers/MeasureHelpers";

export function makeLegendObject(
  base:
    | SelectedChartMeasureExercise
    | SelectedChartMeasureWellness
    | SelectedChartMeasureAnthropometric
    | AthleteOrGroup
): {
  id: string;
  entityId: string;
  label: string;
} {
  if (base.type === "GROUP" || base.type === "ATHLETE") {
    return {
      id: base.id,
      entityId: base.id,
      label: base.type === EntityType.ATHLETE ? [base.firstName, base.lastName].filter(Boolean).join(" ") : base.name,
    };
  }
  const { id, entityId, label, subLabel } = MeasureHelpers.getCommonProperties(base);

  return {
    id,
    entityId,
    label: [label, subLabel].filter(Boolean).join(" - "),
  };
}
