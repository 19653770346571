import Grid from "@mui/material/Grid";
import * as Components from "./AthleteProfile1RMEdit.components";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import NoDataWrapper from "components/NoDataWrapper/NoDataWrapper";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import MessageWithLogo from "components/MessageWithLogo";
import Mui5 from "components/mui5";
import useExercises from "providers/ExercisesProvider/useExercises";
import { useCallback, useMemo, useReducer } from "react";
import ExerciseHelpers from "helpers/ExerciseHelpers";
import { Exercise } from "types";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AthleteProfile1RMEditRow from "./AthleteProfile1RMEditRow";
import { FieldArray, Form, Formik } from "formik";
import { ACTIONS, initialState, reducer } from "./reducer";
import useAthleteProfile1RMFormInitialValues from "./hooks/useAthleteProfile1RMFormInitialValues";
import useAthleteProfileBulkOneRepMaxUpdate from "./hooks/useAthleteProfileBulkOneRepMaxUpdate";
import { FormOneRepMax } from "components/AthleteBulkEdit/hooks/useOneRepMaxFormInitialValues/useOneRepMaxFormInitialValues.types";
import { firstBy } from "thenby";
import { generateAthleteProfile1RMEditSchema } from "./AthleteProfile1RMEdit.schema";
import usePreferences from "hooks/usePreferences";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import { exerciseAutocompleteFilterOptions } from "config/autocomplete";
import { useTranslation } from "react-i18next";

interface AthleteProfile1RMEditProps {
  onClose(): void;
  athleteId: string;
}

function AthleteProfile1RMEdit(props: AthleteProfile1RMEditProps) {
  const { t } = useTranslation();
  const { onClose, athleteId } = props;
  const [selectedExercises, dispatchToSelectedExercises] = useReducer(reducer, initialState);
  const selectedExercisesIds = useMemo(
    () => selectedExercises.map((selectedExercise) => selectedExercise.exerciseId),
    [selectedExercises]
  );
  const exercises = useExercises();
  const oneRepMaxExercises = useMemo(() => exercises.filter(ExerciseHelpers.isOneRepMaxExercise), [exercises]);
  const sortedOneRepMaxExercises = useMemo(
    () => oneRepMaxExercises.slice().sort(firstBy("name")),
    [oneRepMaxExercises]
  );
  const formInitialValues: FormOneRepMax[] = useAthleteProfile1RMFormInitialValues({
    selectedExercises,
    athleteId: athleteId,
    dispatchToSelectedExercises,
  });
  const updateOneRepMaxes = useAthleteProfileBulkOneRepMaxUpdate({ athleteId: athleteId });
  const [{ imperialSystemEnabled }] = usePreferences();

  const spacingY = 2;
  const spacingX = 3;
  const modalHeaderHeightMultiplier = 7;
  const tableHeaderHeightMultiplier = 7;
  const tableDividerHeightMultipier = 1;
  const tableButtonsHeightMultiplier = 9;

  const renderInputValue = useCallback(
    (params: any) => (
      <Mui5.TextField
        {...params}
        margin="dense"
        label={t("AthleteProfile1RMEdit.exercisesInputLabel")}
        variant="outlined"
        color="primary"
        size="small"
        fullWidth
      />
    ),
    [t]
  );

  const renderTagsValue = useCallback(() => <Box />, []);

  const renderOptionValue = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: Exercise, { selected }: { selected: boolean }) => (
      <MenuItem {...props} key={option.id} aria-label={`Select ${option.name}`}>
        <ListItemIcon sx={{ minWidth: 32 }}>
          {selected ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />}
        </ListItemIcon>
        {option.name}
      </MenuItem>
    ),
    []
  );

  const autocompleteOnChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, autocompleteOptions: Exercise[]) => {
      autocompleteOptions.forEach((autocompleteOption) => {
        const valueMatch = selectedExercises.find(
          (selectedExercise) => selectedExercise.exerciseId === autocompleteOption.id
        );
        if (!valueMatch)
          dispatchToSelectedExercises({ type: ACTIONS.SET_STATUS_PENDING, payload: autocompleteOption.id });
      });
      dispatchToSelectedExercises({
        type: ACTIONS.UPDATE_ITEMS_IN_STATE,
        payload: autocompleteOptions.map((option) => option.id),
      });
    },
    [selectedExercises]
  );

  const getOptionLabelValue = useCallback((option: Exercise) => option.name, []);

  const groupByValue = useCallback((option: Exercise) => option.category, []);

  const validationSchema = useMemo(
    () => generateAthleteProfile1RMEditSchema(imperialSystemEnabled),
    [imperialSystemEnabled]
  );

  const handleFormSubmit = useCallback(
    async (values: { selectedOneRepMaxes: FormOneRepMax[] }) => {
      await updateOneRepMaxes(values);
      onClose();
    },
    [onClose, updateOneRepMaxes]
  );

  return (
    <Components.Paper>
      <Grid container pt={spacingY} pb={1} width="100%" height="100%">
        <Grid item xs={12} height={(theme) => theme.spacing(modalHeaderHeightMultiplier)} width="100%" px={spacingX}>
          <Grid container alignItems="center" width="100%" height="100%" columns={12}>
            <Grid item xs={5}>
              <Typography variant="h2" fontWeight={400}>
                {t("AthleteProfile1RMEdit.modalTitle")}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Grid container alignItems="center" justifyContent="space-around">
                <Grid item xs={11}>
                  <Mui5.Autocomplete
                    multiple
                    limitTags={0}
                    disableCloseOnSelect
                    disableClearable
                    options={oneRepMaxExercises}
                    value={oneRepMaxExercises.filter((exercise) => selectedExercisesIds.includes(exercise.id))}
                    renderInput={renderInputValue}
                    renderTags={renderTagsValue}
                    renderOption={renderOptionValue}
                    getOptionLabel={getOptionLabelValue}
                    groupBy={groupByValue}
                    onChange={autocompleteOnChange}
                    filterOptions={exerciseAutocompleteFilterOptions}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={props.onClose} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          height={(theme) => `calc(100% - ${theme.spacing(modalHeaderHeightMultiplier)})`}
          width="100%"
        >
          <NoDataWrapper
            hasData={Boolean(Object.keys(selectedExercises).length)}
            fallback={
              <>
                <Divider />
                <Box textAlign="center" px={spacingX} pt={12}>
                  <MessageWithLogo>{t("AthleteProfile1RMEdit.noSelectedExerciseMessage")}</MessageWithLogo>
                </Box>
              </>
            }
          >
            <Formik
              enableReinitialize
              initialValues={{ selectedOneRepMaxes: formInitialValues }}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}
            >
              {({ values, isValid, isSubmitting, dirty }) => {
                const validWeightValues = values.selectedOneRepMaxes
                  .flatMap((oneRepMax) => oneRepMax.oneRepMaxes.map((oneRepMax) => oneRepMax.weight))
                  .filter((weight) => Boolean(weight));

                const isFormDisabled = validWeightValues.length === 0 || !dirty || !isValid || isSubmitting;

                return (
                  <Box component={Form} height="100%">
                    <Grid container height="100%" width="100%">
                      <Grid item xs={12} height={(theme) => theme.spacing(tableHeaderHeightMultiplier)}>
                        <Grid
                          container
                          bgcolor="primary.main"
                          color="white"
                          justifyContent="space-between"
                          alignItems="center"
                          pr={2}
                          pl={4}
                          height="100%"
                          width="100%"
                        >
                          <Grid item xs={7}>
                            <Typography variant="h4" color="inherit">
                              {t("AthleteProfile1RMEdit.exerciseNameColumnHeader")}
                            </Typography>
                          </Grid>
                          <Grid item xs={5} pr={3} textAlign="right">
                            <Typography variant="h4" color="inherit">
                              {t("AthleteProfile1RMEdit.measurementSystemLabel", {
                                context: imperialSystemEnabled ? "imperial" : "metric",
                              })}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        height={(theme) =>
                          `calc(100% - ${theme.spacing(
                            tableHeaderHeightMultiplier + tableDividerHeightMultipier + tableButtonsHeightMultiplier
                          )})`
                        }
                        sx={{ overflowY: "auto" }}
                        width="100%"
                      >
                        <FieldArray
                          name="selectedOneRepMaxes"
                          render={(arrayHelpers) => {
                            const sortedOneRepMaxes: FormOneRepMax[] = [];
                            sortedOneRepMaxExercises.forEach((exercise: Exercise) => {
                              const oneRepMax = values.selectedOneRepMaxes.find(
                                (oneRepMax) => oneRepMax.exerciseId === exercise.id
                              );

                              if (oneRepMax) sortedOneRepMaxes.push(oneRepMax);
                            });

                            return (
                              <>
                                {sortedOneRepMaxes.map((oneRepMax) => (
                                  <AthleteProfile1RMEditRow
                                    key={oneRepMax.exerciseId}
                                    exerciseIndex={values.selectedOneRepMaxes.indexOf(oneRepMax)}
                                    arrayHelpers={arrayHelpers}
                                    exercise1rmValue={oneRepMax}
                                    dispatchToSelectedExercises={dispatchToSelectedExercises}
                                  />
                                ))}
                              </>
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} height={(theme) => theme.spacing(tableDividerHeightMultipier)}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} height={(theme) => theme.spacing(tableButtonsHeightMultiplier)} width="100%">
                        <Grid
                          container
                          width="100%"
                          height="100%"
                          columns={12}
                          justifyContent="space-around"
                          alignItems={"center"}
                          px={spacingX}
                        >
                          <Grid item xs mr={3}>
                            <Mui5.Button onClick={props.onClose} variant="outlined">
                              {t("cancelButtonText")}
                            </Mui5.Button>
                          </Grid>
                          <Grid item xs>
                            <Tooltip
                              title={isFormDisabled ? t("AthleteProfile1RMEdit.formDisabledMessage") : undefined}
                              placement="top"
                            >
                              <div>
                                <Mui5.Button type="submit" disabled={isFormDisabled}>
                                  {t("submitButtonText")}
                                </Mui5.Button>
                              </div>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                );
              }}
            </Formik>
          </NoDataWrapper>
        </Grid>
      </Grid>
    </Components.Paper>
  );
}

export default AthleteProfile1RMEdit;
