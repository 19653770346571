import { useMemo } from "react";
import useGroupsContext from "hooks/useGroupsContext";
import type { Athlete } from "types/athlete";
import Grid from "@mui/material/Grid";
import Tooltip from "components/Tooltip_deprecated";
import GroupTooltipTitle from "./GroupTooltipTitle";

function GroupColorBlocks(props: Athlete) {
  const [groups] = useGroupsContext();

  const athleteGroups = useMemo(
    () => groups.filter((group) => props.groupIds.includes(group.id)),
    [groups, props.groupIds]
  );

  return (
    <Grid container height="100%">
      {athleteGroups.map((group) => (
        <Tooltip
          key={group.id}
          disableInteractive
          title={<GroupTooltipTitle group={group} />}
          placement="top"
          size="small"
          slotProps={{ tooltip: { sx: { padding: "4px 8px" } } }}
        >
          <Grid
            item
            aria-label={`${group.name} Colour Indicator`}
            key={group.id}
            bgcolor={group.colour}
            maxWidth={8}
            flex={1}
          />
        </Tooltip>
      ))}
    </Grid>
  );
}

export default GroupColorBlocks;
