import useChartOptions from "features/Charting/hooks/useChartOptions";
import useChartSelections from "components/charting/hooks/useChartSelections";
import useSetChartSelections from "features/Charting/hooks/useSetChartSelections";
import { useCallback, useMemo } from "react";
import { ChartingSelections } from "../types";

function useChartAggregationPeriod() {
  const options = useChartOptions();
  const selections = useChartSelections();
  const setSelections = useSetChartSelections();

  const setAggregationPeriod = useCallback(
    (event: unknown, aggregationPeriod: ChartingSelections["aggregationPeriod"]) => {
      setSelections({ aggregationPeriod });
    },
    [setSelections]
  );

  return useMemo(
    () => ({
      options: options.aggregationPeriod,
      value: selections.aggregationPeriod,
      onChange: setAggregationPeriod,
    }),
    [options.aggregationPeriod, selections.aggregationPeriod, setAggregationPeriod]
  );
}
export default useChartAggregationPeriod;
