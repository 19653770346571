import useDefinedContext from "hooks/useDefinedContext";
import ChartTooltipManagerContext from "../contexts/ChartTooltipManagerContext";
import useChartDimensions from "features/Charting/hooks/useChartDimensions";
import { defaultStyles } from "@visx/tooltip";
import { useCallback, useEffect, useMemo, useState } from "react";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useTheme } from "@mui/material";

interface UseChartTooltipManagerProps {
  x: number;
  y: number;
}

function useChartTooltipManager<T extends UseChartTooltipManagerProps>(props: T, tooltipWidth = 400) {
  const { x, y } = props;

  const dimensions = useChartDimensions();
  const marginTop = dimensions.margin.top;
  const marginLeft = dimensions.margin.left;
  const containerWidth = dimensions.inner.width;
  const theme = useTheme();

  const { tooltipData, showTooltip, hideTooltip, TooltipInPortal } = useDefinedContext(ChartTooltipManagerContext);

  const open = tooltipData === props;

  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (!open && clicked) setClicked(false);
  }, [clicked, open]);

  const handleMouseEnter = useCallback(() => {
    showTooltip({
      tooltipData: props,
    });
  }, [props, showTooltip]);

  const handleClick = useCallback(() => {
    showTooltip({
      tooltipData: props,
    });
    setClicked(true);
  }, [props, showTooltip]);

  const handleClose = useCallback(() => {
    hideTooltip();
    setClicked(false);
  }, [hideTooltip]);

  const handleMouseLeave = useCallback(() => {
    if (clicked) return;

    handleClose();
  }, [clicked, handleClose]);

  const onClickAway = useCallback(() => {
    hideTooltip();
    setClicked(false);
  }, [hideTooltip]);

  const top = y + marginTop;

  const left = useMemo((): number => {
    const isTooltipWithinContainer = x + tooltipWidth < containerWidth;

    return isTooltipWithinContainer ? x + marginLeft : containerWidth - tooltipWidth + marginLeft;
  }, [containerWidth, marginLeft, tooltipWidth, x]);

  const ChartTooltipPortal = useCallback(
    ({ children }: { children?: React.ReactNode }) =>
      open ? (
        <TooltipInPortal
          key={Math.random()}
          top={top}
          left={left}
          style={{
            ...defaultStyles,
            backgroundColor: "transparent",
            boxShadow: "none",
            padding: 0,
            pointerEvents: undefined,
            zIndex: theme.zIndex.tooltip + 1,
          }}
        >
          <ClickAwayListener onClickAway={onClickAway}>
            <div>{children}</div>
          </ClickAwayListener>
        </TooltipInPortal>
      ) : null,
    [open, TooltipInPortal, top, left, onClickAway, theme]
  );

  return useMemo(
    () => ({
      open,
      clicked,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
      onClose: handleClose,
      onClick: handleClick,
      ChartTooltipPortal,
    }),
    [ChartTooltipPortal, clicked, handleClick, handleClose, handleMouseEnter, handleMouseLeave, open]
  );
}
export default useChartTooltipManager;
