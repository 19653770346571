import useParentDimensions from "components/charting/hooks/useParentDimensions";
import { useMemo } from "react";
import useChartSelections from "../../../components/charting/hooks/useChartSelections";

function useChartDimensions() {
  const { width, height } = useParentDimensions();

  const selections = useChartSelections();
  const validMeasuresCount = selections.measures.filter((m) => m.id).length;
  const margin = useMemo(
    () => ({
      // right/eft must be changed here and on axis render `left` value
      top: 48,
      right: Boolean(validMeasuresCount > 3) ? 16 + 24 + 48 + 48 : Boolean(validMeasuresCount > 1) ? 16 + 24 + 24 : 16,
      bottom: 24,
      left: Boolean(validMeasuresCount > 2) ? 40 + 60 + 48 : 40 + 36,
    }),
    [validMeasuresCount]
  );

  const dimensions = useMemo(() => {
    const inner = {
      width: width - margin.left - margin.right,
      height: height - margin.top - margin.bottom,
    };

    const ranges = {
      x: [margin.left, inner.width + margin.left] as [number, number],
      y: [inner.height, 0] as [number, number],
    };

    return {
      inner,
      ranges,
      margin: {
        top: margin.top,
        right: margin.right,
        bottom: margin.bottom,
        left: margin.left,
      },
    };
  }, [height, margin.bottom, margin.left, margin.right, margin.top, width]);

  return dimensions;
}
export default useChartDimensions;
