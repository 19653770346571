import { AlertProps, styled } from "@mui/material";
import MuiTooltip, { tooltipClasses, TooltipProps as MuiTooltipProps } from "@mui/material/Tooltip";

const enterDelay = 250;
const leaveDelay = 100;

/**
 *
 * Demos:
 *
 * - [Tooltips](https://mui.com/material-ui/react-tooltip/)
 *
 * API:
 *
 * - [Tooltip API](https://mui.com/material-ui/api/tooltip/)
 */
export interface TooltipProps extends MuiTooltipProps {
  /**
   * The severity of the alert. This defines the color and icon used.
   */
  severity?: AlertProps["severity"];
  /**
   * Small (400px) or medium size (480px)
   */
  size?: "small" | "medium";
  /**
   * @description How close the tooltip is to the element it is pointing to. Margin for the tooltip placement.
   * @default { top: "4px" }
   */
  placementMargin?: {
    top?: number | string;
    rightStart?: number | string;
  };
}
const Tooltip = styled(({ className, ...props }: TooltipProps) => {
  const { placementMargin, ...rest } = props;
  return (
    <MuiTooltip
      disableFocusListener
      TransitionProps={{ timeout: { enter: enterDelay, exit: leaveDelay } }}
      {...rest}
      classes={{ popper: className, arrow: className }}
    />
  );
})<{ severity?: AlertProps["severity"] }>(({ theme, placementMargin, ...props }) => ({
  userSelect: "none",
  cursor: "default",

  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: props.severity ? theme.palette[props.severity].light : theme.palette.common.white,
    maxWidth: props.size === "small" ? 400 : props.size === "medium" ? 480 : 480,
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[1],
    borderRadius: props.size === "small" ? "4px" : "8px",
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
  },

  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
    marginBottom: placementMargin?.top || "4px",
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="right-start"] .${tooltipClasses.tooltip}`]: {
    marginLeft: placementMargin?.rightStart,
  },
}));
export default Tooltip;
