import Grid from "@mui/material/Grid";
import Mui5 from "components/mui5";
import { Workout } from "features/Workouts/Workouts.types";
import Divider from "@mui/material/Divider";
import Tables from "components/Tables";
import AllWorkoutsBulkAthleteAssignmentControl from "features/Workouts/AllWorkouts/AllWorkoutsTable/AllWorkoutsBulkAthleteAssignmentControl";
import AllWorkoutsBulkGroupAssignmentControl from "features/Workouts/AllWorkouts/AllWorkoutsTable/AllWorkoutsBulkGroupAssignmentControl";
import Typography from "@mui/material/Typography";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore";
import useGroups from "providers/GroupsProvider/useGroups";
import WorkoutHelpers from "helpers/WorkoutHelpers";
import useRole from "providers/RoleProvider/useRole";
import workoutOverviewConfig from "../WorkoutOverview.config";
import WorkoutOverviewAssignmentsAthletes from "./WorkoutOverviewAssignmentsAthletes/WorkoutOverviewAssignmentsAthletes";
import WorkoutOverviewAssignmentsGroups from "./WorkoutOverviewAssignmentsGroups";
import WorkoutOverviewAssignmentsLabels from "./WorkoutOverviewAssignmentsLabels";
import CanDoAction from "components/CanDoAction";
import AllWorkoutsBulkLabelAssignmentControl from "features/Workouts/AllWorkouts/AllWorkoutsTable/AllWorkoutsBulkLabelAssignmentControl";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";

function WorkoutOverviewControls(props: Workout) {
  const role = useRole();
  const assignedGroups = useGroups(props.groupIds);
  const assignedAthletes = useAthleteStore(props.athleteIds);
  const assignedLabels = props.labels;
  const { t } = useTranslation();

  const canMakeAssignments = WorkoutHelpers.canMakeAssignments(props, { role });
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const maxDisplayedAssignments = smallScreen ? 1 : 8;

  return (
    <Mui5.Paper>
      <Grid
        container
        spacing={0}
        px={2}
        pt={1}
        pb={1.5}
        {...Tables.controlsContainerProps}
        alignItems="stretch"
        flexWrap="nowrap"
        minHeight={1}
        position="relative"
      >
        <Grid item xs>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <Typography variant="body2">{t("Workouts.workoutOverviewAssignedAthletesPanelTitle")}</Typography>
            </Grid>
            {assignedAthletes.length > 0 ? (
              <WorkoutOverviewAssignmentsAthletes athletes={assignedAthletes} maxDisplayed={maxDisplayedAssignments} />
            ) : (
              <Grid item>
                <Typography variant="subtitle2" color="grey.400">
                  {t("Workouts.workoutOverviewAssignedAthletesPanelNoAthletes")}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        {canMakeAssignments && (
          <Grid item minHeight={0} pr={1} alignItems="center" display="flex">
            <AllWorkoutsBulkAthleteAssignmentControl tooltipTitle={t("Workouts.assignAthleteTooltipTitle")} />
          </Grid>
        )}
        <CanDoAction action="group.assign">
          <Grid item px={1} minHeight={0}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item xs>
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <Typography variant="body2">{t("Workouts.workoutOverviewAssignedGroupsPanelTitle")}</Typography>
              </Grid>
              {assignedGroups.length > 0 ? (
                <WorkoutOverviewAssignmentsGroups groups={assignedGroups} maxDisplayed={maxDisplayedAssignments} />
              ) : (
                <Grid item>
                  <Typography variant="subtitle2" color="grey.400">
                    {t("Workouts.workoutOverviewAssignedGroupsPanelNoGroups")}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          {canMakeAssignments && (
            <Grid item minHeight={0} pr={1} alignItems="center" display="flex">
              <AllWorkoutsBulkGroupAssignmentControl
                tooltipTitle={t("Workouts.assignGroupTooltipTitle")}
                accessRemovedWarning={workoutOverviewConfig.assignment.groups.accessRemoved}
              />
            </Grid>
          )}
        </CanDoAction>
        <Grid item px={1} minHeight={0}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <Typography variant="body2">{t("Workouts.workoutOverviewAssignedLabelsPanelTitle")}</Typography>
            </Grid>
            {assignedLabels.length > 0 ? (
              <WorkoutOverviewAssignmentsLabels labels={assignedLabels} maxDisplayed={maxDisplayedAssignments} />
            ) : (
              <Grid item>
                <Typography variant="subtitle2" color="grey.400">
                  {t("Workouts.workoutOverviewAssignedLabelsPanelNoLabels")}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        {canMakeAssignments && (
          <Grid item minHeight={0} pr={1} alignItems="center" display="flex">
            <AllWorkoutsBulkLabelAssignmentControl tooltipTitle={t("Workouts.labelWorkoutTooltipTitle")} />
          </Grid>
        )}
      </Grid>
    </Mui5.Paper>
  );
}

export default WorkoutOverviewControls;
