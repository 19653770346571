import useChartSelections from "components/charting/hooks/useChartSelections";
import useChartAnnotations from "components/charting/annotations/hooks/useChartAnnotations";
import StandardDeviationArea from "components/charting/LineChart/LineChartStandardDeviation/StandardDeviationArea";
import useChartDimensions from "features/Charting/hooks/useChartDimensions";

interface LineChartStandardDeviationProps {
  annotations: ReturnType<typeof useChartAnnotations>;
}

function LineChartStandardDeviation(props: LineChartStandardDeviationProps) {
  const selections = useChartSelections();
  const dimensions = useChartDimensions();

  if (!selections.showStandardDeviation) {
    return null;
  }

  return (
    <>
      {props.annotations.map((annotation) => (
        <StandardDeviationArea
          key={["STD", annotation.variant, annotation.scaleId].filter(Boolean).join(" ")}
          width={dimensions.inner.width}
          {...annotation}
        />
      ))}
    </>
  );
}

export default LineChartStandardDeviation;
