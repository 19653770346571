import Paper, { paperClasses } from "components/mui5/Paper";
import Skeleton from "@mui/material/Skeleton";
import { useLoadingContext } from "hooks";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const SkeletonCardPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F5F6F9",
  [`&.${paperClasses.rounded}`]: {
    borderRadius: theme.spacing(1),
  },
}));

const ActivityFeedSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
}));

export function Label() {
  const [loading] = useLoadingContext();
  const animation = loading ? "wave" : false;

  return (
    <Box pb={0.5}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12}>
          <ActivityFeedSkeleton animation={animation} variant="text" height={24} width="30%" />
        </Grid>
      </Grid>
    </Box>
  );
}

export function ActivityCard() {
  const [loading] = useLoadingContext();
  const animation = loading ? "wave" : false;

  return (
    <Box pb={1}>
      <SkeletonCardPaper elevation={0}>
        <Box height={56} display="flex" alignItems="center" p={2}>
          <Box width="100%">
            <ActivityFeedSkeleton animation={animation} variant="text" width="25%" height={14} />
            <ActivityFeedSkeleton animation={animation} variant="text" height={24} width="60%" />
          </Box>
        </Box>
      </SkeletonCardPaper>
    </Box>
  );
}
export function WellnessCircle() {
  const [loading] = useLoadingContext();
  const animation = loading ? "wave" : false;

  return (
    <Box pt={0.5} pb={2}>
      <Grid item>
        <Box pb={0.5} display="flex" justifyContent="center">
          <ActivityFeedSkeleton animation={animation} variant="text" height={8} width={28} />
        </Box>
        <ActivityFeedSkeleton animation={animation} variant="circular" width={48} height={48} />
      </Grid>
    </Box>
  );
}

export function WorkoutCard() {
  const [loading] = useLoadingContext();
  const animation = loading ? "wave" : false;

  return (
    <Box pb={2}>
      <SkeletonCardPaper elevation={0}>
        <Box height={56} display="flex" alignItems="center" p={2}>
          <ActivityFeedSkeleton animation={animation} variant="text" height={24} width="60%" />
        </Box>
      </SkeletonCardPaper>
    </Box>
  );
}

export const NoScrollBox = styled(Box)(() => ({
  overflow: "clip",
}));

export const HiddenOverflowBox = styled(Box)(() => ({
  overflow: "hidden",
}));
