import { Container, TitleText, MessageText, MessageContainer, LogoContainer, LogoImg } from "./MessageCard.components";
import Loader20 from "components/Loader20";
import outputLogoStripes from "components/DrawerMenu/outputLogoStripes.svg";

interface MessageCardProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
}

function MessageCard(props: MessageCardProps) {
  return (
    <>
      <Container>
        <MessageContainer>
          {props.title && (
            <TitleText variant="h1" pb={2}>
              {props.title}
            </TitleText>
          )}
          <MessageText variant="h3">
            <span>{props.children}</span>
          </MessageText>
        </MessageContainer>
        <LogoContainer>
          <LogoImg src={outputLogoStripes} alt="Output Sports Stripes Logo" />
        </LogoContainer>
      </Container>
      <Loader20 />
    </>
  );
}

export default MessageCard;
