import useMuiField from "../../../components/Form/hooks/useMuiField";
import PropTypes from "prop-types";
import MuiRadioButtons from "../../../components/Inputs/MuiRadioButtons";

function RadioGroup({ name, label, options, ...props }) {
  const [field] = useMuiField(name);

  return <MuiRadioButtons name={name} label={label} options={options} {...props} {...field} />;
}

RadioGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
};

export default RadioGroup;
