import Grid from "@mui/material/Grid";
import { useCallback, useMemo } from "react";
import { MetadataType } from "types/metadata";
import MeasureHelpers from "../../helpers/MeasureHelpers";
import useChartSelections from "../../../../components/charting/hooks/useChartSelections";
import useColorRegistry from "../../hooks/useColorRegistry";
import useSetChartSelections from "../../hooks/useSetChartSelections";
import { AthleteWithType, GroupWithType } from "types/AthleteOrGroup";
import ChartLegendItem from "./ChartLegendItem";
import { makeLegendObject } from "./ChartLegendItem/ChartLegendItem.helpers";
import intersection from "lodash/intersection";
import useChartOptions from "features/Charting/hooks/useChartOptions";

function LineChartLegend() {
  const selections = useChartSelections();
  const options = useChartOptions();
  const setSelections = useSetChartSelections();
  const getColor = useColorRegistry();

  const measureLegendItems = useMemo(() => {
    const validMeasures = MeasureHelpers.getValidMeasures(selections.measures);
    if (validMeasures.length > 1) {
      return validMeasures;
    }

    return [];
  }, [selections.measures]);

  const sourceLegendItems = useMemo(() => {
    const sourceAthletes: AthleteWithType[] = [];

    const sourceGroups: GroupWithType[] = [];

    selections.sources.forEach((source) => {
      if (source.type === "GROUP") {
        sourceGroups.push(source);
      } else {
        sourceAthletes.push(source);
      }
    });

    const groupAthleteIds = intersection(...sourceGroups.map((group) => group.athleteIds));

    return {
      // This filter excludes athletes that will appear as part of a group legend
      athletes: sourceAthletes.filter((athlete) => !groupAthleteIds.includes(athlete.id)),
      groupIntersectionAthletes: options.sources.filter((source) => groupAthleteIds.includes(source.id)),
    };
  }, [options.sources, selections.sources]);

  const removeItem = useCallback(
    (id: string, metricField?: string) => {
      return () => {
        setSelections((prevSelections) => ({
          sources: prevSelections.sources.filter((source) => id !== source.id),
          measures: prevSelections.measures.filter((measure) => {
            if (measure.type === MetadataType.EXERCISE) {
              return measure.id !== id && measure.metric.field !== metricField;
            }
            return measure.id !== id;
          }),
        }));
      };
    },
    [setSelections]
  );

  const singleItems = measureLegendItems.length ? measureLegendItems : sourceLegendItems.athletes;

  if (singleItems.length <= 1 && !sourceLegendItems.groupIntersectionAthletes.length) {
    return null;
  }

  return (
    <>
      <Grid container spacing="2px" px={0.5} py={0} border="1px solid #FFF">
        {singleItems.map((item, itemIndex) => {
          const { id, entityId, label } = makeLegendObject(item);
          return (
            <Grid key={id + itemIndex} xs={6} lg={3} item>
              <ChartLegendItem color={getColor(entityId)} label={label} removeItem={removeItem(id)} />
            </Grid>
          );
        })}
        {sourceLegendItems.groupIntersectionAthletes.map((item, itemIndex) => {
          const { id, entityId, label } = makeLegendObject(item);
          return (
            <Grid key={id + itemIndex} xs={6} lg={3} item>
              <ChartLegendItem color={getColor(entityId)} label={label} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default LineChartLegend;
