import http from "axiosSetup";
import { CompletedWorkout, CompletedWorkoutGetAllQueryParams } from "features/Workouts/Workouts.types";

class CompletedWorkoutService {
  static getAll(params: CompletedWorkoutGetAllQueryParams): Promise<CompletedWorkout[]> {
    return http.get("/completed-workouts", { params }).then((response) => response.data);
  }

  static getById(id: string): Promise<CompletedWorkout> {
    return http.get(`/completed-workouts/${id}`).then((response) => response.data);
  }
}
export default CompletedWorkoutService;
