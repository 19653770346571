import PropTypes from "prop-types";
import useImperialMuiField from "../hooks/useImperialMuiField";
import NumberFormat from "react-number-format";
import UnitHelper from "../../../helpers/UnitHelper";
import Mui5 from "components/mui5";

const imperialConverterOptions = {
  convertToMetric: (feetInches) => {
    const inches = feetInches.split("'").reduce((total, current, index) => {
      const multiplier = [12, 1][index];
      const value = parseInt(current) * multiplier;

      return value + total;
    }, 0);

    return UnitHelper.convert(inches, UnitHelper.units.Inch, UnitHelper.units.Centimetre).toFixed(
      UnitHelper.decimals.Centimetre
    );
  },
  convertToImperial: (centimetre) => {
    const [feet, inches] = UnitHelper.convert(centimetre, UnitHelper.units.Centimetre, "FeetInches");

    return `${feet}'${inches}"`;
  },
  metricAdornmentLabel: "CM",
  imperialAdornmentLabel: "FT",
};

function HeightInput(props) {
  const [field, meta] = useImperialMuiField(props, imperialConverterOptions);

  return (
    <NumberFormat
      customInput={Mui5.TextField}
      format={meta.isImperial ? `#'##"` : null}
      mask={meta.isImperial ? " " : null}
      {...props}
      {...field}
    />
  );
}

HeightInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  inverse: PropTypes.bool,
};

export default HeightInput;
