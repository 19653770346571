import { Component } from "react";
import PropTypes from "prop-types";
import "./Button.scss";

/**
 * @deprecated
 */
class Button extends Component {
  render() {
    return (
      <button
        style={this.props.styles}
        onClick={this.props.handleClick}
        type={this.props.type || "button"}
        className={"oh-button " + this.props.classNames}
        disabled={this.props.isDisabled}
      >
        {this.props.children}
      </button>
    );
  }
}

export default Button;

Button.propTypes = {
  classNames: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
};
