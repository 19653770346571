import { useContext, useState } from "react";
import ConfirmationDialog from "../../../../../ConfirmationDialog";
import Box from "@mui/material/Box";
import MeasurementsContext from "contexts/MeasurementsContext";
import Mui5 from "components/mui5";

function TableDeleteMeasurementButton(props) {
  const { id } = props;
  const [open, setOpen] = useState(false);
  const measurementsContextValue = useContext(MeasurementsContext);

  if (typeof measurementsContextValue === "undefined") {
    return null;
  }

  function handleDelete() {
    const [, { deleteById }] = measurementsContextValue;
    deleteById(id);
  }

  return (
    <>
      <Box color="error.main" pt={2}>
        <Mui5.Button
          square
          size="medium"
          fullWidth={false}
          variant="outlined"
          color="error"
          onClick={() => setOpen((open) => !open)}
          aria-label="delete measurement button"
        >
          Delete This Activity
        </Mui5.Button>
      </Box>
      <ConfirmationDialog
        open={open}
        setOpen={setOpen}
        title="Delete Exercise Measurement"
        body="This action will permanently delete the specified measurement. It cannot be undone."
        cancelText="Cancel"
        confirmText="Delete"
        onConfirm={handleDelete}
        color="error"
      />
    </>
  );
}

export default TableDeleteMeasurementButton;
