import Grid from "@mui/material/Grid";
import { MultiFactorInfo } from "firebase/auth";
import { Formik, Form } from "formik";
import { useCallback } from "react";
import SmsCodeSchema from "./MfaCodeInput.schema";
import Mui5 from "components/mui5";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import FormText from "components/Inputs/Form/FormText";
import { useTranslation } from "react-i18next";
import SubmitAndCancelButtons from "../SubmitAndCancelButtons";

interface MfaCodeInputProps {
  hint?: MultiFactorInfo;
  verifyCode(smsCode: string): Promise<void>;
  resendCode(): Promise<void>;
  onCancel(): void;
  errorCode?: string;
}

function MfaCodeInput(props: MfaCodeInputProps) {
  const { t } = useTranslation();
  const { verifyCode, hint, onCancel, errorCode } = props;

  const handleVerify = useCallback(({ code }: { code: string }) => verifyCode(code), [verifyCode]);

  return (
    <Formik initialValues={{ code: "" }} onSubmit={handleVerify} validationSchema={SmsCodeSchema}>
      {({ isSubmitting, values }) => (
        <Form>
          <Grid container spacing="24px" maxWidth={480}>
            <Grid item xs={12}>
              <FormText
                name="code"
                label={t("ReauthenticationProvider.smsCodeLabel")}
                placeholder={t("ReauthenticationProvider.smsCodePlaceholder")}
                variant="standard"
                color="secondary"
                fullWidth
                error={!!errorCode}
                helperText={
                  <Box component="span" color={errorCode && "error.main"}>
                    {errorCode
                      ? t(errorCode)
                      : t("ReauthenticationProvider.smsCodeHelperText", { phoneNumber: (hint as any)?.phoneNumber })}
                  </Box>
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Mui5.Button
                        color="primary"
                        size="small"
                        onClick={props.resendCode}
                        sx={{
                          mb: 1.5,
                          "&.Mui-disabled": { backgroundColor: "secondary.main" },
                        }}
                      >
                        {t("resendButtonText")}
                      </Mui5.Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <SubmitAndCancelButtons
                submitText={t("finishButtonText")}
                submitDisabled={values.code.length !== 6 || isSubmitting}
                onCancel={onCancel}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default MfaCodeInput;
