import { useTheme } from "@mui/material";
import { colors } from "components/MuiThemeProvider/muiTheme";
import { useMemo } from "react";

function useChartingColors() {
  const theme = useTheme();

  return useMemo(
    () => ({
      theme,
      axis: {
        line: theme.palette.grey[700],
        text: theme.palette.grey[800],
      },
      grid: {
        line: theme.palette.grey[300],
      },
      lines: colors,
      getContrastText: theme.palette.getContrastText,
      fontFamily: theme.typography.fontFamily,
    }),
    [theme]
  );
}
export default useChartingColors;
