import { useState, useCallback } from "react";
import Tooltip from "components/Tooltip";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Popover from "@mui/material/Popover";

interface IconPopperMenuProps {
  icon: JSX.Element;
  id?: string;
  tooltipTitle?: string;
  popperContent: JSX.Element;
  badgeContent?: React.ReactNode;
  disabled?: boolean;
  alert?: React.ReactNode;
  handleClose?: () => void;
}

function IconPopperMenu(props: IconPopperMenuProps) {
  const { id: idProp, icon, tooltipTitle, popperContent, badgeContent, disabled, handleClose } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl((prev) => (!prev ? event.currentTarget : null));
    setOpen((previousOpen) => !previousOpen);
  }, []);

  const handleClickOnOutsideClose = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      handleClick(event);
      handleClose?.();
    },
    [handleClick, handleClose]
  );

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? idProp : undefined;

  return (
    <>
      <Tooltip title={!open ? tooltipTitle : ""} arrow placement="top">
        <span>
          <IconButton aria-describedby={id} size="small" color="primary" onClick={handleClick} disabled={disabled}>
            <Badge badgeContent={badgeContent} color="secondary">
              {icon}
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClickOnOutsideClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{ paper: { sx: { width: "100%", maxWidth: 320 } } }}
      >
        <Box position="relative" width="100%">
          {popperContent}
        </Box>
        {props.alert}
      </Popover>
    </>
  );
}

export default IconPopperMenu;
