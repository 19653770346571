import React, { useCallback, useMemo } from "react";
import { Group } from "@visx/group";
import { GridRows } from "@visx/grid";
import useChartingColors from "features/Charting/hooks/useChartingColors";
import LineDots from "features/Charting/components/LineDots";
import Line from "features/Charting/components/Line";
import useChartDimensions from "features/Charting/hooks/useChartDimensions";
import { ChartLine, LineData, LinePointData } from "components/charting/types/Chart.types";
import Loader20 from "components/Loader20";
import ChartScalesContext from "features/Charting/contexts/ChartScalesContext";
import { scaleLinear } from "@visx/scale";
import useSetComparisonChartData from "./useSetComparisonChartData";
import max from "lodash/max";
import { useUnitFormatter } from "hooks";
import { AxisLeft as VisxAxisLeft } from "@visx/axis";
import useChartSelections from "../hooks/useChartSelections";
import { AxisBottom as VisxAxisBottom } from "@visx/axis";
import ChartHelpers from "features/Charting/helpers/ChartHelpers";
import SetComparisonTooltipCard from "./SetComparisonTooltipCard";
import NoDataWrapper from "components/NoDataWrapper";
import SetComparisonChartNoData from "./SetComparisonChartNoData";
import ChartTooltipCardProvider from "../providers/ChartTooltipCardProvider";
import ChartTooltipManagerProvider from "../providers/ChartTooltipManagerProvider";

interface SetComparisonChartProps {}

function SetComparisonChart(props: SetComparisonChartProps) {
  const { grid, axis } = useChartingColors();
  const selections = useChartSelections();
  const chartLines: LineData[] = useSetComparisonChartData();

  const allLineData = useMemo(
    () => chartLines.reduce<LinePointData[]>((acc, cur) => [...acc, ...cur.line], []),
    [chartLines]
  );
  const dimensions = useChartDimensions();

  const xScale = useMemo(() => {
    const domainMax = max(chartLines.map((chartLine) => chartLine.line.length)) || 0;
    return scaleLinear({
      range: [0, dimensions.inner.width],
      domain: [1, domainMax],
      nice: true,
      round: true,
    });
  }, [chartLines, dimensions.inner.width]);

  const yScale = useMemo(() => {
    return scaleLinear({
      range: dimensions.ranges.y,
      domain: [0, max(allLineData.map((d): number => d.y)) || 0],
      nice: true,
    });
  }, [allLineData, dimensions.ranges.y]);

  const coordinatedChartLines: ChartLine[] = useMemo(
    () =>
      chartLines.map((lineData) => ({
        ...lineData,
        line: lineData.line.map((linePoint) => ({
          ...linePoint,
          x: xScale(linePoint.x),
          y: yScale(linePoint.y),
        })),
      })),
    [chartLines, xScale, yScale]
  );

  const dataLines = coordinatedChartLines;

  const formatUnit = useUnitFormatter();
  const formatValue = useCallback(
    (value: { valueOf(): number }) => {
      return formatUnit(value, selections.measures[0]?.repMetric);
    },
    [formatUnit, selections.measures]
  );

  const scales = useMemo(
    () => ({ x: xScale, y: { [ChartHelpers.getScaleId(selections.measures[0])]: yScale } }),
    [selections.measures, xScale, yScale]
  );

  const xAxisTickValues = useMemo(() => new Array(xScale.domain()[1]).fill(0).map((_, i) => i + 1), [xScale]);

  return (
    <NoDataWrapper hasData={Boolean(dataLines.length)} fallback={<SetComparisonChartNoData />}>
      <ChartScalesContext.Provider value={scales}>
        <ChartTooltipCardProvider TooltipCard={SetComparisonTooltipCard}>
          <ChartTooltipManagerProvider>
            <Group left={dimensions.margin.left} top={dimensions.margin.top}>
              <GridRows
                scale={yScale}
                width={dimensions.inner.width}
                height={dimensions.inner.height}
                strokeWidth={1}
                stroke={grid.line}
                strokeDasharray="4,6"
                strokeLinecap="round"
              />
              <VisxAxisLeft
                hideAxisLine
                hideTicks
                left={-24}
                scale={yScale}
                labelOffset={26}
                labelProps={{ fill: axis.text }}
                tickFormat={formatValue}
                tickLabelProps={() => ({
                  fill: axis.line,
                  color: axis.line,
                  fontSize: 12,
                  textAnchor: "end",
                  dy: "0.3em",
                  dx: "-0.4em",
                })}
                stroke={axis.line}
                tickStroke={axis.line}
                tickLength={2}
                hideZero
              />
              <VisxAxisBottom
                left={0}
                top={dimensions.inner.height}
                scale={xScale}
                stroke={axis.line}
                tickStroke={axis.line}
                tickFormat={(v) => Number(v).toFixed(0)}
                tickValues={xAxisTickValues}
                tickLabelProps={() => ({
                  fill: axis.line,
                  fontSize: 12,
                  textAnchor: "middle",
                })}
                strokeWidth={1}
              />
              {dataLines.map((lineData, lineIndex) => (
                <React.Fragment key={lineData.entityId + lineIndex}>
                  <Line {...lineData} />
                  <LineDots {...lineData} />
                </React.Fragment>
              ))}
            </Group>
          </ChartTooltipManagerProvider>
        </ChartTooltipCardProvider>
        <Loader20 disableBackdrop />
      </ChartScalesContext.Provider>
    </NoDataWrapper>
  );
}

export default SetComparisonChart;
