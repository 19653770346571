import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import longLeftArrow from "../../../assets/images/long-left-arrow.svg";

function PageContentTitle(props) {
  const navigate = useNavigate();

  if (props.titleLink) {
    return (
      <Link to="" onClick={() => navigate(-1)}>
        <Typography
          sx={{
            textDecoration: "none",
          }}
          variant="h1"
          gutterBottom={false}
          color="primary.main"
        >
          <img src={longLeftArrow} alt="left-arrow" /> {props.title}
        </Typography>
      </Link>
    );
  }

  return (
    <Typography color="primary.main" variant="h1">
      {props.title}
    </Typography>
  );
}

PageContentTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.string.isRequired]).isRequired,
  titleLink: PropTypes.string,
};
export default PageContentTitle;
