import LocalisedLoadingProvider from "components/LocalisedLoadingProvider";
import NotFound from "features/NotFound";
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import CompletedWorkoutsProvider from "./providers/CompletedWorkoutsProvider";
import WorkoutsProvider from "./providers/WorkoutsProvider";
import WorkoutAthleteReview from "./WorkoutAthleteReview";
import WorkoutOverview from "./WorkoutOverview";
import SearchTextProvider from "providers/SearchTextProvider/SearchTextProvider";
import TableSortProvider from "providers/TableSortProvider/TableSortProvider";
import SelectedEntitiesProvider from "providers/SelectedEntitiesProvider/SelectedEntitiesProvider";
import FilteredWorkoutsProvider from "./providers/FilteredWorkoutsProvider";
import SelectedWorkoutLabelsProvider from "providers/SelectedWorkoutLabelsProvider";
import WorkoutLabelOptionsProvider from "./providers/WorkoutLabelOptionsProvider";

const CreateWorkout = lazy(() => import("./CreateWorkout"));
const EditWorkout = lazy(() => import("./EditWorkout"));
const ReviewAllWorkouts = lazy(() => import("./AllWorkouts"));

function WorkoutsRoutes() {
  return (
    <WorkoutsProvider>
      <CompletedWorkoutsProvider>
        <SearchTextProvider>
          <SelectedEntitiesProvider>
            <WorkoutLabelOptionsProvider>
              <SelectedWorkoutLabelsProvider>
                <TableSortProvider initialSortKey="createdAt" initialDirection="desc">
                  <FilteredWorkoutsProvider>
                    <LocalisedLoadingProvider>
                      <Routes>
                        <Route
                          path={"create"}
                          element={
                            <Suspense fallback={<div>Loading</div>}>
                              <CreateWorkout />
                            </Suspense>
                          }
                        />
                        <Route
                          path={"/"}
                          element={
                            <Suspense fallback={<div>Loading</div>}>
                              <ReviewAllWorkouts />
                            </Suspense>
                          }
                        />
                        <Route
                          path={":workoutId"}
                          element={
                            <Suspense fallback={<div>Loading</div>}>
                              <WorkoutOverview />
                            </Suspense>
                          }
                        />
                        <Route
                          path={"/completed/:completedWorkoutId"}
                          element={
                            <Suspense fallback={<div>Loading</div>}>
                              <WorkoutAthleteReview />
                            </Suspense>
                          }
                        />
                        <Route
                          path={":workoutId/edit"}
                          element={
                            <Suspense fallback={<div>Loading</div>}>
                              <EditWorkout />
                            </Suspense>
                          }
                        />
                        <Route path="*" element={<NotFound buttonText="Return to All Workouts" link="/workouts" />} />
                      </Routes>
                    </LocalisedLoadingProvider>
                  </FilteredWorkoutsProvider>
                </TableSortProvider>
              </SelectedWorkoutLabelsProvider>
            </WorkoutLabelOptionsProvider>
          </SelectedEntitiesProvider>
        </SearchTextProvider>
      </CompletedWorkoutsProvider>
    </WorkoutsProvider>
  );
}
export default WorkoutsRoutes;
