import { styled } from "@mui/material";
import Mui5 from "components/mui5";

export const Paper = styled(Mui5.Paper)({
  height: "100%",
  margin: "auto",
  maxWidth: 720,
  display: "flex",
  flexDirection: "column",
  position: "relative",
});
