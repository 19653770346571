import { styled } from "@mui/material";
import Mui5 from "components/mui5";

export { default as TooltipColorStrip } from "./TooltipColorStrip";

export const TooltipPaper = styled(Mui5.Paper)(({ theme }) => ({
  width: theme.spacing(50),
  position: "relative",
  paddingLeft: theme.spacing(1),
  overflow: "hidden",
}));

export const TooltipDataRow = styled("div")((props) => ({
  padding: props.theme.spacing(1, 2),
}));
