import { styled } from "@mui/material";
import MuiAvatar from "@mui/material/Avatar";
import type { AvatarProps as MuiAvatarProps } from "@mui/material/Avatar";
import v2Theme from "components/MuiThemeProvider/theme";

interface AvatarProps extends MuiAvatarProps {
  size?: "small";
}

const StyledMuiAvatar = styled(MuiAvatar, { shouldForwardProp: (key: string) => !["size"].includes(key) })<AvatarProps>(
  ({ theme, size }) => ({
    backgroundColor: v2Theme.palette.grey[100],
    color: v2Theme.palette.grey[20],
    height: theme.spacing(size === "small" ? 2 : 4),
    width: theme.spacing(size === "small" ? 2 : 4),
    fontSize: size === "small" ? "8px" : theme.typography.body1.fontSize,
    fontWeight: 400,
    textAlign: "center",
    "&.MuiAvatar-root": {
      lineHeight: "23px",
    },
  })
);

function Avatar({ size, ...props }: AvatarProps) {
  return <StyledMuiAvatar className="ph-no-capture" size={size} {...props} />;
}

export default Avatar;
