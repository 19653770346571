import http from "axiosSetup";
import { Workout, WorkoutBase } from "features/Workouts/Workouts.types";

class WorkoutService {
  static async getAll(): Promise<Workout[]> {
    return http.get("/workouts").then((response) => response.data);
  }

  static getById(id: string): Promise<Workout> {
    return http.get(`/workouts/${id}`).then((response) => response.data);
  }

  static delete(id: string): Promise<void> {
    return http.delete(`/workouts/${id}`);
  }

  static create(workout: WorkoutBase) {
    const convertedShape = WorkoutService.serialize(workout);

    return http.post<{ id: string }>("/workouts", convertedShape).then((response) => response.data);
  }

  static update(id: string, workout: WorkoutBase) {
    const convertedShape = WorkoutService.serialize(workout);

    return http.put(`/workouts/${id}`, convertedShape).then(() => ({ id, ...workout }));
  }

  static bulkUpdateAthleteId(ids: string[], athleteId: string, isAssignment: boolean) {
    return http.patch("workouts/athlete-assignment", { isAssignment, athleteId, workoutIds: ids });
  }

  static updateAthleteIds(id: string, athleteIds: string[]) {
    return http.patch(`/workouts/${id}/athlete-assignment`, { athleteIds }).then(() => ({ id, athleteIds }));
  }

  static bulkUpdateGroupId(ids: string[], groupId: string, isAssignment: boolean) {
    return http.patch("workouts/group-assignment", { isAssignment, groupId, workoutIds: ids });
  }

  static updateGroupIds(id: string, groupIds: string[]) {
    return http.patch(`/workouts/${id}/group-assignment`, { groupIds }).then(() => ({ id, groupIds }));
  }

  static publishById(id: string, isPublished: boolean) {
    return http.patch(`/workouts/${id}/published`, { isPublished }).then(() => ({ id }));
  }

  static publishByIds(ids: string[], isPublished: boolean) {
    return http.patch(`/workouts/published`, { workoutIds: ids, isPublished });
  }

  static bulkUpdateLabel(ids: string[], label: string, isAssignment: boolean) {
    return http.patch("/workouts/label-assignment", { isAssignment, label, workoutIds: ids });
  }

  static serialize(workout: WorkoutBase) {
    return {
      ...workout,
      steps: workout.steps.map((step) => ({
        ...step,
        tasks: step.tasks.map((task) => {
          if (task.type === "Exercise") {
            return {
              ...task,
              setMetadata: task.setMetadata.map((set, setIndex, setArray) => ({
                ...set,
                configuration: setArray[0].configuration,
              })),
            };
          }

          return task;
        }),
      })),
    };
  }

  static deserialize(workout: WorkoutBase): WorkoutBase {
    return {
      ...workout,
      steps: workout.steps.map((step) => ({
        ...step,
        tasks: step.tasks.map((task) => {
          if (task.type === "Exercise") {
            return {
              ...task,
              setMetadata: task.setMetadata.map((set) => ({
                ...set,
                weight: set.weight || NaN,
                percentage1Rm: set.percentage1Rm ? Math.min((set.percentage1Rm + Number.EPSILON) * 100, 100) : NaN,
              })),
            };
          }

          if (task.type === "CustomExercise") {
            return {
              ...task,
              setMetadata: task.setMetadata.map((set) => ({
                ...set,
                weight: set.weight || NaN,
                percentage1Rm: set.percentage1Rm ? Math.min((set.percentage1Rm + Number.EPSILON) * 100, 100) : NaN,
              })),
              options: {
                withReps: Boolean(task.setMetadata.find((set) => set.repCount)),
                withVariants: Boolean(task.setMetadata.find((set) => set.variant)),
              },
            };
          }

          return task;
        }),
      })),
    };
  }
}
export default WorkoutService;
