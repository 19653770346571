import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { TooltipColorStrip, TooltipDataRow, TooltipPaper } from "components/TooltipCard/TooltipCard.components";
import useColorRegistry from "../../../../features/Charting/hooks/useColorRegistry";
import useChartSelections from "../../hooks/useChartSelections";
import TooltipCardHeader from "components/TooltipCard/TooltipCardHeader";
import useChartMeasureData from "../../../../features/Charting/hooks/useChartMeasureData";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MetadataType } from "types/metadata";
import { alpha } from "@mui/material";
import { LinePointData } from "components/charting/types/Chart.types";
import TooltipCardNote from "components/TooltipCardNote";

function SetComparisonTooltipCard(props: LinePointData) {
  const tooltipData = props;
  const entityColor = useColorRegistry()(tooltipData.entityId);
  const selections = useChartSelections();
  const letter = props.variant?.charAt(0);

  const measure = useChartMeasureData(tooltipData);

  const tooltipCardHeaderProps =
    selections.measures.length <= 1
      ? {
          athleteId: tooltipData.source.id,
          color: entityColor,
          completedDate: measure.completedDate,
          variant: props.variant,
        }
      : measure;

  const computedAggregationPeriodMessage = `*Computed value. Data aggregated by ${selections.aggregationPeriod.label}.`;

  return (
    <TooltipPaper>
      <TooltipColorStrip entityColor={entityColor} letter={letter} />
      <TooltipDataRow>
        <TooltipCardHeader {...tooltipCardHeaderProps} />
      </TooltipDataRow>
      <Divider />
      <TooltipDataRow>
        <Grid container alignItems="center" columns={12}>
          <Grid item xs={12} container>
            {measure.repMetrics?.map((metric) => (
              <Grid
                item
                xs={measure.type === MetadataType.EXERCISE ? 4 : 6}
                key={metric.id}
                borderRadius={1}
                bgcolor={metric.field === tooltipData.measure.repMetricField ? alpha(entityColor, 0.1) : undefined}
              >
                <Box p={0.5}>
                  <Typography title={metric.label} align="center" style={{ lineHeight: 1.2 }}>
                    <Box component="span" color="grey.800" fontWeight={400} fontSize={12}>
                      {[tooltipData.computed && "*", metric.label].filter(Boolean).join("")}
                    </Box>
                  </Typography>
                  <Typography variant="h4" title={`${metric.value}`} align="center">
                    {metric.value}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </TooltipDataRow>
      <TooltipCardNote {...tooltipData} />
      {tooltipData.computed && (
        <>
          <Divider />
          <TooltipDataRow>
            <Typography variant="caption" title={computedAggregationPeriodMessage} align="center">
              {computedAggregationPeriodMessage}
            </Typography>
          </TooltipDataRow>
        </>
      )}
    </TooltipPaper>
  );
}

export default SetComparisonTooltipCard;
