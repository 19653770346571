import { WorkoutTaskType } from "features/Workouts/Workouts.types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as Components from "./WorkoutOverviewStepPreviewContent.components";
import Tooltip from "components/Tooltip";
import { useTranslation } from "react-i18next";
import Warning from "@mui/icons-material/Warning";
import useCanDo from "hooks/useCanDo";

interface WorkoutOverviewStepPreviewContentProps {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  open?: boolean;
  stepIndex: number;
  taskIndex: number;
  superset: boolean;
  type: WorkoutTaskType;
  icon?: React.ReactElement;
}

const alphabet = "abcdefghijklmnopqrstuvwxyz";

function WorkoutOverviewStepPreviewContent(props: WorkoutOverviewStepPreviewContentProps) {
  const { t } = useTranslation();
  const customExercisesEnabled = useCanDo()("library.any");

  return (
    <Grid container alignItems="center" width="100%" spacing="0px" p={0}>
      <Grid item ml={1.5} px={1.5}>
        <Components.StepNumberBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          workoutTask={props.type}
          m={0}
        >
          <Box color="inherit" fontSize="h3.fontSize" fontWeight="fontWeightBold">
            {props.stepIndex + 1}
            {props.superset && alphabet[props.taskIndex % alphabet.length]}
          </Box>
        </Components.StepNumberBox>
      </Grid>
      <Grid item xs lineHeight={0} p={1} pl={0} mt={1}>
        <Typography variant="h4" gutterBottom={false} paragraph={false} component="span">
          {props.title}
        </Typography>
        {props.subTitle && (
          <>
            <Box pb={0.5} />
            {props.subTitle}
          </>
        )}
      </Grid>
      {Boolean(customExercisesEnabled && props.type === WorkoutTaskType.CUSTOM_EXERCISE) && (
        <Grid item pr={2} lineHeight={0}>
          <Tooltip arrow placement="right" title={t("Library.workoutTaskMigrationAlertBody", { context: "common" })}>
            <Warning color="warning" />
          </Tooltip>
        </Grid>
      )}
      {typeof props.icon !== "undefined" && (
        <Grid item pr={2} lineHeight={0}>
          {props.icon}
        </Grid>
      )}
    </Grid>
  );
}

export default WorkoutOverviewStepPreviewContent;
