import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import NoDataWrapper from "components/NoDataWrapper";
import { exerciseAutocompleteFilterOptions } from "config/autocomplete";
import { Formik } from "formik";
import ExerciseHelpers from "helpers/ExerciseHelpers";
import useExercises from "providers/ExercisesProvider/useExercises";
import Mui5 from "components/mui5";
import { useMemo, useState } from "react";
import * as Components from "./AthleteBulkEdit.components";
import useOneRepMaxFormInitialValues from "./hooks/useOneRepMaxFormInitialValues";
import MessageWithLogo from "components/MessageWithLogo";
import useBulkOneRepMaxUpdate from "./hooks/useBulkOneRepMaxUpdate";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { Exercise } from "types/exercise";
import AthleteBulkEditFormFields from "./AthleteBulkEditFormFields";
import { useTranslation } from "react-i18next";

interface AthleteBulkEditProps {
  athleteIds: string[];
  onCancel(): void;
}

function AthleteBulkEdit(props: AthleteBulkEditProps) {
  const [exercise, setExercise] = useState<Exercise | null>(null);
  const exercises = useExercises();
  const visibleAthletes = useAthleteStore();
  const visibleAthleteIds = useMemo(() => {
    const idsOfVisibleAthletes = visibleAthletes.map(({ id }) => id);
    return props.athleteIds.filter((athleteId) => idsOfVisibleAthletes.includes(athleteId));
  }, [props.athleteIds, visibleAthletes]);

  const oneRepMaxExercises = useMemo(() => exercises.filter(ExerciseHelpers.isOneRepMaxExercise), [exercises]);
  const athletesOneRepMaxesInitialValues = useOneRepMaxFormInitialValues({
    exercise,
    athleteIds: visibleAthleteIds,
  });
  const updateOneRepMaxes = useBulkOneRepMaxUpdate({ exercise });

  const spacingY = 2;
  const spacingX = 3;
  const { t } = useTranslation();

  return (
    <Components.Paper>
      <Grid
        container
        alignItems="center"
        pt={spacingY}
        pb={1}
        px={spacingX}
        columns={12}
        minHeight={(theme) => theme.spacing(9)}
      >
        <Grid item xs={7}>
          <Typography variant="h2" fontWeight={400}>
            {t("Athlete.bulkEdit1RMHeaderTitle")}
          </Typography>
        </Grid>
        <Grid item xs={5} pr={2.5}>
          <Mui5.Autocomplete
            disableClearable={!!exercise}
            value={exercise}
            options={oneRepMaxExercises}
            renderInput={(params: any) => (
              <Mui5.TextField
                {...params}
                margin="dense"
                label={t("Athlete.bulkEdit1RMAutocompleteTitle")}
                variant="outlined"
                color="primary"
                size="small"
                fullWidth
              />
            )}
            groupBy={(option: Exercise) => option.category}
            getOptionLabel={(option: Exercise) => option.name}
            onChange={(_: any, exercise: Exercise | null) => setExercise(exercise)}
            disabled={!oneRepMaxExercises.length}
            filterOptions={exerciseAutocompleteFilterOptions}
          />
        </Grid>
      </Grid>
      <IconButton sx={{ position: "absolute", top: 20, right: 4 }} onClick={props.onCancel}>
        <CloseIcon />
      </IconButton>
      <NoDataWrapper
        hasData={!!exercise}
        fallback={
          <>
            <Divider />
            <Box textAlign="center" px={spacingX} pt={12}>
              <MessageWithLogo>{t("Athlete.bulkEdit1RMSelectExercisePrompt")}</MessageWithLogo>
            </Box>
          </>
        }
      >
        <Formik
          enableReinitialize
          initialValues={{ athletesOneRepMaxes: athletesOneRepMaxesInitialValues }}
          onSubmit={updateOneRepMaxes}
        >
          <AthleteBulkEditFormFields spacingX={spacingX} exercise={exercise} onCancel={props.onCancel} />
        </Formik>
      </NoDataWrapper>
    </Components.Paper>
  );
}

export default AthleteBulkEdit;
