import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import useChartSelections from "components/charting/hooks/useChartSelections";
import { LineData } from "components/charting/types/Chart.types";
import OneRepMaxService from "services/OneRepMaxService/OneRepMaxService";
import { MetadataType } from "types/metadata";
import { OneRepMax } from "types/onerepmax";
import { firstBy } from "thenby";
import useChartLineVariantSeparator from "features/Charting/hooks/useChartLineVariantSeparator";

interface UseOneRepMaxChartDataApiProps {}

function useOneRepMaxChartDataApi(props?: UseOneRepMaxChartDataApiProps) {
  const [oneRepMaxes, setOneRepMaxes] = useState<OneRepMax[]>([]);
  const selections = useChartSelections();
  const measure = selections.measures[0];
  const source = selections.sources[0];
  const startDate = selections.startDate;
  const endDate = selections.endDate;

  useEffect(() => {
    if (measure.type !== MetadataType.EXERCISE) return;

    (async () => {
      const oneRepMaxes: OneRepMax[] = await OneRepMaxService.getByAthleteIds(measure.metadata.id, [source.id], {
        mostRecent: false,
      });

      setOneRepMaxes(oneRepMaxes);
    })();
  }, [measure, source]);

  const deleteOneRepMaxById = useCallback(({ id }: { id: string }) => {
    setOneRepMaxes((prevOneRepMaxes) => {
      return prevOneRepMaxes.map((oneRepMax) => {
        return {
          ...oneRepMax,
          oneRepMaxes: oneRepMax.oneRepMaxes.filter((oneRepMaxValue) => oneRepMaxValue.id !== id),
        };
      });
    });
  }, []);

  const oneRepMaxChartData = useMemo(() => {
    const oneRepMaxChartData: LineData[] = oneRepMaxes.map((oneRepMax) => {
      const repeatedValues = {
        measure: {
          id: oneRepMax.exerciseId,
          metricField: "weight",
        },
        source: { id: oneRepMax.athleteId },
        entityId: oneRepMax.exerciseId,
        metadataType: MetadataType.ONE_REP_MAX,
      };

      return {
        ...repeatedValues,
        line: oneRepMax.oneRepMaxes
          .filter((oneRepMaxValue) => {
            const createdDate = moment(oneRepMaxValue.createdDate);
            return createdDate.isAfter(startDate) && createdDate.isBefore(endDate);
          })
          .sort(firstBy("createdDate"))
          .map((oneRepMaxValue) => ({
            ...repeatedValues,
            metadataType: MetadataType.ONE_REP_MAX,
            x: moment(oneRepMaxValue.createdDate).toDate(),
            y: oneRepMaxValue.weight,
            measurement: oneRepMaxValue,
            variant: oneRepMaxValue.variant ? oneRepMaxValue.variant : undefined,
          })),
      };
    });

    return oneRepMaxChartData;
  }, [oneRepMaxes, startDate, endDate]);

  const entityIds = useMemo<string[]>(() => {
    if (measure.type !== MetadataType.EXERCISE) return [];
    return [measure.metadata.id];
  }, [measure]);

  const separatedVariantChartLines = useChartLineVariantSeparator(oneRepMaxChartData, entityIds);
  const oneRepMaxChartDataApi: [LineData[], { deleteOneRepMaxById: ({ id }: { id: string }) => void }] = useMemo(
    () => [separatedVariantChartLines, { deleteOneRepMaxById }],
    [separatedVariantChartLines, deleteOneRepMaxById]
  );

  return oneRepMaxChartDataApi;
}

export default useOneRepMaxChartDataApi;
