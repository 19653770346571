import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import FloatingActionMenu from "../FloatingActionMenu";
import GlobalSearch from "../GlobalSearch";
import PageContentTitle from "./PageContentTitle";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { useCallback, useState } from "react";
import Modal from "components/Modal";
import logEvent from "utils/logEvent";

interface PageContentProps {
  children?: React.ReactNode;
  enableFAB?: boolean;
  pageHeading: string;
  pageHeadingLink?: string;
  infoCardComponent?: (props: any) => JSX.Element;
}

function PageContent(props: PageContentProps) {
  const {
    enableFAB = true,
    pageHeading,
    pageHeadingLink = null,
    infoCardComponent: InfoCardComponent,
    children,
  } = props;
  const [showInfoCard, setShowInfoCard] = useState(false);

  const toggleInfoCard = useCallback(() => {
    setShowInfoCard((prev) => {
      const updatedValue = !prev;

      if (updatedValue) logEvent("INFO_CARD_VIEW", { page: pageHeading });

      return updatedValue;
    });
  }, [pageHeading]);

  return (
    <>
      <Box p={children ? 4 : 0}>
        <Grid container spacing="0px" width="100%" alignItems="center">
          <Grid item>
            <PageContentTitle title={pageHeading} titleLink={pageHeadingLink} />
          </Grid>
          <Grid item xs>
            {InfoCardComponent && (
              <IconButton size="small" color="primary" onClick={toggleInfoCard}>
                <InfoOutlined fontSize="small" />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={false}>
            <Grid container alignItems="center" spacing="8px" width="calc(100% + 8px)" p={0}>
              <Grid item>
                <Box>
                  <GlobalSearch />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {children && (
            <Grid item xs={12}>
              {props.children}
            </Grid>
          )}
        </Grid>
        {enableFAB && <FloatingActionMenu />}
      </Box>
      {InfoCardComponent && (
        <Modal open={showInfoCard} toggleOpen={toggleInfoCard}>
          <InfoCardComponent onClose={toggleInfoCard} />
        </Modal>
      )}
    </>
  );
}

PageContent.propTypes = {
  pageHeading: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.string.isRequired]).isRequired,
  pageHeadingLink: PropTypes.string,
  enableFAB: PropTypes.bool,
};

export default PageContent;
