import { useState, useMemo, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import ThemeProvider2024 from "components/MuiThemeProvider/ThemeProvider2024";
import ReauthenticationActionsContext, { ReauthenticationContextType } from "./contexts/ReauthenticationContext";
import { ReauthentiationFlowOptions } from "./contexts/ReauthenticationContext/ReauthenticationContext";
import ReauthenticationDialogContent from "./components/ReauthenticationDialogContent";

export interface ReauthentiationProviderProps {
  children?: React.ReactNode;
}

function ReauthentiationProvider(props: ReauthentiationProviderProps) {
  const { children } = props;
  const [options, setOptions] = useState<null | ReauthentiationFlowOptions>(null);
  const open = Boolean(options);

  const actions: ReauthenticationContextType = useMemo(() => {
    let safeOnCancel: undefined | (() => void);

    return {
      onStart: (opts) => {
        safeOnCancel = opts.onCancel;
        setOptions(opts);
      },
      onCancel: () => {
        setOptions(null);

        if (safeOnCancel) {
          safeOnCancel();
        }
      },
    };
  }, []);

  const handleSuccess = useCallback(async () => {
    await options!.onSuccess();
    /**
     * Needs to be second as otherwise the Recaptcha
     * is removed before the onSuccess is called. */
    setOptions(null);
  }, [options]);

  return (
    <ReauthenticationActionsContext.Provider value={actions}>
      {children}
      <ThemeProvider2024>
        <Dialog
          aria-describedby="reauthentication-dialog"
          open={open}
          onClose={actions.onCancel}
          PaperProps={{ sx: { borderRadius: "20px", maxWidth: 300, p: 3 } }}
        >
          <ReauthenticationDialogContent onSuccess={handleSuccess} onCancel={actions.onCancel} />
        </Dialog>
      </ThemeProvider2024>
    </ReauthenticationActionsContext.Provider>
  );
}

export default ReauthentiationProvider;
