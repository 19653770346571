import Grid from "@mui/material/Grid";
import DependantFormField from "components/Form/DependantFormField";
import FormAutoComplete from "components/Form/FormAutocomplete";
import { Formik, Form, FormikContextType, FormikHelpers } from "formik";
import { Exercise, ExerciseMetric, ExerciseRepetition } from "types";
import { useFilteredExercises } from "hooks";
import { exerciseAutocompleteFilterOptions } from "config/autocomplete";
import { useCallback, useMemo } from "react";
import Button from "components/mui5/Button";
import { ReportModuleType, SetComparisonFilter } from "../../../../ModuleModal.types";
import SetSelector from "./AtheteSetComparisonChartModuleFormSetSelector";
import { schema } from "./AthleteSetComparisonChartModuleForm.schema";
import Mui5 from "components/mui5";
import useExercises from "providers/ExercisesProvider/useExercises";

interface AthleteSetComparisonChartModuleFormInitialValues<T = string> {
  exerciseId: null | T;
  repetitionField: null | (T extends string ? string : ExerciseRepetition);
  setComparisonFilters?: SetComparisonFilter[];
  moduleType: ReportModuleType.SET_COMPARISON;
}

interface AthleteSetComparisonChartModuleFormProps {
  athleteId: string;
  onSubmit(values: AthleteSetComparisonChartModuleFormInitialValues): void;
  onCancel(): void;
  initialValues?: AthleteSetComparisonChartModuleFormInitialValues;
}

function AthleteSetComparisonChartModuleForm(props: AthleteSetComparisonChartModuleFormProps) {
  const { onSubmit, initialValues, onCancel, athleteId } = props;
  const allExercises = useExercises();
  const exercises = useMemo(
    () => allExercises.filter((exercise) => Boolean(exercise.repetitions.length)),
    [allExercises]
  );
  const { exerciseRepMetrics } = useFilteredExercises(exercises, true);

  const onMetricDependencyChange = useCallback(
    ({ setFieldValue, dependencyValue }: FormikHelpers<typeof initialValues> & { dependencyValue: Exercise }) => {
      const exercise: Exercise = dependencyValue;
      const defaultMetric = exercise?.repetitions.find((metric) => metric) || null;
      return setFieldValue("repetitionField", defaultMetric);
    },
    []
  );

  const onSetsDependencyChange = useCallback(
    ({ setFieldValue }: FormikHelpers<typeof initialValues>) => setFieldValue("setComparisonFilters", []),
    []
  );

  const memoizedInitialValues = useMemo(() => {
    const { exerciseId, repetitionField, setComparisonFilters = [] } = initialValues || {};
    const exercise = exercises.find((exercise) => exercise.id === exerciseId);

    return {
      exerciseId: exercise || null,
      repetitionField: exercise?.repetitions.find((metric) => metric.field === repetitionField) || null,
      setComparisonFilters,
      moduleType: ReportModuleType.SET_COMPARISON as const,
    };
  }, [exercises, initialValues]);

  function handleSubmit(values: AthleteSetComparisonChartModuleFormInitialValues<Exercise>) {
    return onSubmit({
      ...values,
      exerciseId: values.exerciseId?.id || null,
      repetitionField: values.repetitionField?.field || null,
    });
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={memoizedInitialValues} validationSchema={schema}>
      <Form>
        <Grid container spacing="24px">
          <Grid item xs={12}>
            <FormAutoComplete
              name="exerciseId"
              options={exercises}
              groupBy={(option: Exercise) => option.category}
              getOptionLabel={(option: Exercise) => option.name || ""}
              renderInput={(params: any) => (
                <Mui5.TextField {...params} label="Exercise" placeholder="Select Exercise" variant="standard" />
              )}
              isOptionEqualToValue={(option: Exercise, value: Exercise | undefined) => option.id === value?.id}
              filterOptions={exerciseAutocompleteFilterOptions}
            />
          </Grid>

          <Grid item xs={12}>
            <DependantFormField dependencyName="exerciseId" onDependencyChange={onMetricDependencyChange}>
              {({ values }: FormikContextType<AthleteSetComparisonChartModuleFormInitialValues<Exercise>>) => {
                const options = exerciseRepMetrics(values.exerciseId);

                return (
                  <FormAutoComplete
                    name="repetitionField"
                    options={options}
                    disabled={!options.length}
                    defaultValue={null}
                    getOptionLabel={(option: ExerciseMetric) => option.name || ""}
                    renderInput={(params: any) => (
                      <Mui5.TextField
                        {...params}
                        label="Rep Metric"
                        placeholder="Select Rep Metric"
                        variant="standard"
                      />
                    )}
                    isOptionEqualToValue={(option: ExerciseMetric, value: ExerciseMetric) =>
                      option.field === value?.field
                    }
                  />
                );
              }}
            </DependantFormField>
          </Grid>

          <Grid item xs={12}>
            <DependantFormField dependencyName="exerciseId" onDependencyChange={onSetsDependencyChange}>
              {({ values }: FormikContextType<AthleteSetComparisonChartModuleFormInitialValues<Exercise>>) => (
                <SetSelector
                  name="setComparisonFilters"
                  athleteId={athleteId}
                  exercise={values.exerciseId}
                  sets={values.setComparisonFilters || []}
                />
              )}
            </DependantFormField>
          </Grid>

          <Grid item xs={8}>
            <Button disableElevation fullWidth type="submit" variant="contained" color="primary">
              Confirm
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}

export default AthleteSetComparisonChartModuleForm;
