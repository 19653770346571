import ChartSetSelectContext from "components/charting/contexts/ChartSetSelectContext";
import useDefinedContext from "hooks/useDefinedContext";
import ChartColorRegistryProvider from "features/Charting/providers/ChartColorRegistryProvider";
import { useMemo } from "react";

interface SetComparisonChartEntityColorProviderProps {
  children?: React.ReactNode;
}

function SetComparisonChartEntityColorProvider(props: SetComparisonChartEntityColorProviderProps) {
  const { value: values } = useDefinedContext(ChartSetSelectContext);

  const entityIds = useMemo(() => values.map((v) => v.measurementId), [values]);

  return <ChartColorRegistryProvider entityIds={entityIds}>{props.children}</ChartColorRegistryProvider>;
}

export default SetComparisonChartEntityColorProvider;
