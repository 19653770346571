import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { TooltipColorStrip, TooltipDataRow, TooltipPaper } from "components/TooltipCard/TooltipCard.components";
import useChartSelections from "../../hooks/useChartSelections";
import TooltipCardHeader from "../../../TooltipCard/TooltipCardHeader";
import useChartMeasureData from "../../../../features/Charting/hooks/useChartMeasureData";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { weightMetric } from "../AthleteProfilingChart.config";
import { useUnitFormatter } from "hooks";
import { LinePointData } from "components/charting/types/Chart.types";
import ExerciseHelpers from "helpers/ExerciseHelpers";
import LineEquation from "components/LineEquation";
import TooltipCardNote from "components/TooltipCardNote";
import useChartEntityColor from "features/Charting/components/LineDots/LineDot/useChartEntityColor";

function AthleteProfilingTooltipCard(props: LinePointData) {
  const tooltipData = props;
  const entityColor = useChartEntityColor(tooltipData.entityId);
  const selections = useChartSelections();
  const unitFormatter = useUnitFormatter();
  const letter = props.variant?.charAt(0);

  const measure = useChartMeasureData(tooltipData);

  const repMetricMetadata = selections.measures[0].repMetric;

  const tooltipCardHeaderProps = {
    athleteId: tooltipData.source.id,
    color: entityColor,
    completedDate: measure.completedDate,
    variant: props.variant,
  };

  return (
    <TooltipPaper>
      <TooltipColorStrip entityColor={entityColor} letter={letter} />
      <TooltipDataRow>
        <TooltipCardHeader {...tooltipCardHeaderProps} />
      </TooltipDataRow>
      <Divider />
      <TooltipDataRow>
        <Grid container spacing={0} alignItems="center" columns={12}>
          {"profiling" in tooltipData && tooltipData.profiling && (
            <>
              <Grid item xs={6}>
                <Box p={0.5}>
                  <Typography title={weightMetric.name} align="center" style={{ lineHeight: 1.2 }}>
                    <Box component="span" color="grey.800" fontWeight={400} fontSize={12}>
                      {weightMetric.name}
                    </Box>
                  </Typography>
                  <Typography
                    variant="h4"
                    title={`${unitFormatter(tooltipData.profiling.x, weightMetric)}`}
                    align="center"
                  >
                    {unitFormatter(tooltipData.profiling.x, weightMetric)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box p={0.5}>
                  <Typography title={`${repMetricMetadata?.name}`} align="center" style={{ lineHeight: 1.2 }}>
                    <Box component="span" color="grey.800" fontWeight={400} fontSize={12}>
                      {[tooltipData.computed && "*", repMetricMetadata?.name].filter(Boolean).join("")}
                    </Box>
                  </Typography>
                  <Typography
                    variant="h4"
                    title={`${unitFormatter(tooltipData.profiling.y, repMetricMetadata)}`}
                    align="center"
                  >
                    {unitFormatter(tooltipData.profiling.y, repMetricMetadata)}
                  </Typography>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </TooltipDataRow>
      <TooltipCardNote {...tooltipData} />
      <Divider />
      {repMetricMetadata && (
        <Box ml={3} p={1}>
          <LineEquation
            xLabel={weightMetric.name}
            yLabel={repMetricMetadata.name}
            equation={ExerciseHelpers.isPowerMetric(repMetricMetadata.field) ? "quadratic" : "linear"}
          />
        </Box>
      )}
    </TooltipPaper>
  );
}

export default AthleteProfilingTooltipCard;
