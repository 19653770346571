import { Workout } from "features/Workouts/Workouts.types";

export enum Actions {
  ADD_ALL = "ADD_ALL",
  ADD_ONE = "ADD_ONE",
  UPDATE_ONE = "UPDATE_ONE",
  UPDATE_MANY_ATHLETES = "UPDATE_MANY",
  UPDATE_MANY_GROUPS = "UPDATE_MANY_GROUPS",
  UPDATE_MANY_LABELS = "UPDATE_MANY_LABELS",
  REMOVE_ONE = "REMOVE_ONE",
}

type ActionsTypes =
  | { type: Actions.ADD_ALL; payload: Workout[] }
  | { type: Actions.ADD_ONE; payload: Workout }
  | { type: Actions.REMOVE_ONE; payload: { id: string } }
  | { type: Actions.UPDATE_ONE; payload: Partial<Workout> & { id: string } }
  | {
      type: Actions.UPDATE_MANY_ATHLETES;
      payload: { workoutIds: string[]; athleteId: string; isAssignment: boolean };
    }
  | {
      type: Actions.UPDATE_MANY_GROUPS;
      payload: { workoutIds: string[]; groupId: string; isAssignment: boolean };
    }
  | {
      type: Actions.UPDATE_MANY_LABELS;
      payload: { workoutIds: string[]; label: string; isAssignment: boolean };
    };

export function reducer(state: Workout[], action: ActionsTypes): Workout[] {
  switch (action.type) {
    case Actions.ADD_ALL:
      return action.payload;

    case Actions.ADD_ONE:
      const existing = state.filter((workout) => workout.id !== action.payload.id);
      return [...existing, { ...action.payload }];

    case Actions.REMOVE_ONE:
      return state.filter((workout) => workout.id !== action.payload.id);

    case Actions.UPDATE_ONE:
      return state.map((workout) => {
        if (workout.id === action.payload.id) {
          return { ...workout, ...action.payload };
        }
        return workout;
      });

    case Actions.UPDATE_MANY_ATHLETES:
      return state.map((workout) => {
        if (!action.payload.workoutIds.includes(workout.id)) return workout;

        let updatedAthleteIds = [...workout.athleteIds];
        if (action.payload.isAssignment) {
          updatedAthleteIds.push(action.payload.athleteId);
        } else {
          updatedAthleteIds = updatedAthleteIds.filter((id) => id !== action.payload.athleteId);
        }
        return {
          ...workout,
          athleteIds: updatedAthleteIds,
        };
      });

    case Actions.UPDATE_MANY_GROUPS:
      return state.map((workout) => {
        if (!action.payload.workoutIds.includes(workout.id)) return workout;

        let updatedGroupIds = [...workout.groupIds];
        if (action.payload.isAssignment) {
          updatedGroupIds.push(action.payload.groupId);
        } else {
          updatedGroupIds = updatedGroupIds.filter((id) => id !== action.payload.groupId);
        }
        return {
          ...workout,
          groupIds: updatedGroupIds,
        };
      });

    case Actions.UPDATE_MANY_LABELS:
      return state.map((workout) => {
        if (!action.payload.workoutIds.includes(workout.id)) return workout;

        let updatedLabels = [...workout.labels];
        if (action.payload.isAssignment) {
          if (!updatedLabels.includes(action.payload.label)) updatedLabels.push(action.payload.label);
        } else {
          updatedLabels = updatedLabels.filter((label) => label !== action.payload.label);
        }
        return {
          ...workout,
          labels: updatedLabels,
        };
      });

    default:
      return state;
  }
}
