import { Link } from "react-router-dom";
import Constants from "../../config/Constants";
import addReportIcon from "../../assets/images/add-report-icon.svg";
import addAthleteIcon from "../../assets/images/add-athlete-icon.svg";
import addGroupIcon from "../../assets/images/add-group-icon.svg";
import { FABImgIconStyled, FABWorkoutIconStyled } from "./useQuickCreateActions.components";
import useCanDo from "hooks/useCanDo";

const withLink = (to, children) => <Link to={to}>{children}</Link>;

function useSpeedDialActions() {
  const canDo = useCanDo();

  return [
    {
      icon: withLink(Constants.routes.athletesAdd.route, <FABImgIconStyled src={addAthleteIcon} alt="add athlete" />),
      name: "Athlete",
    },
    canDo("group.any") && {
      icon: withLink(Constants.routes.groupsAdd.route, <FABImgIconStyled src={addGroupIcon} alt="add group" />),
      name: "Group",
    },
    canDo("report.any") && {
      icon: withLink(Constants.routes.createReport.route, <FABImgIconStyled src={addReportIcon} alt="add report" />),
      name: "Report",
    },
    {
      icon: withLink("/workouts/create", <FABWorkoutIconStyled alt="add workout" />),
      name: "Workout",
    },
  ].filter(Boolean);
}
export default useSpeedDialActions;
