import { useContext, useEffect, useState } from "react";
import Loader from "../Loader/Loader";
import UserContext from "../../contexts/UserContext";
import UserService from "../../services/UserService";
import DrawerMenu from "../DrawerMenu/DrawerMenu";
import GlobalConfig from "components/GlobalConfig";
import Login from "../../features/Login";
import TimeZoneAutoSetter from "components/TimeZoneAutoSetter";
import InfoMessageProvider from "providers/InfoMessageProvider";
import LoadingProvider from "providers/LoadingProvider";
import AppRoutes from "./AppRoutes";
import RoleProvider from "providers/RoleProvider";
import "./App.scss";
import FeatureTrialEndingBanner from "./FeatureTrialEndingBanner";
import moment from "moment";
import RemoteConfigProvider from "providers/RemoteConfigProvider";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  const [{ user }, { onLoginSuccess, onSignOut }] = useContext(UserContext);
  const [verifying, setVerifying] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let authUnsubscribe;

    (async () => {
      const params = new URLSearchParams(window.location.search);

      const loginToken = params.get("loginToken");

      if (loginToken) {
        try {
          await UserService.signOut();
          await UserService.signInWithCustomToken(loginToken);
        } catch (error) {
          setError(error);
        }

        // Clearing the query params on token login
        // outside of the react-router lifecycle
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.delete("loginToken"); // Delete loginToken only
        window.history.replaceState({}, null, newUrl.href); // Replace the current URL without a page reload
      }

      authUnsubscribe = UserService.onAuthStateChanged(async (user) => {
        setVerifying(true);

        if (user) {
          try {
            await UserService.validateSigninClaims();
            await onLoginSuccess(user);
          } catch (loginError) {
            setError(loginError);
            await onSignOut();
          }
        } else {
          await onSignOut();
        }

        setVerifying(false);
      });
    })();

    return () => {
      authUnsubscribe && authUnsubscribe();
    };
  }, [onLoginSuccess, onSignOut]);

  if (verifying) {
    return <Loader withDarkBackground />;
  }

  const isAuthenticated = Boolean(user);

  if (!isAuthenticated) {
    return (
      <Router>
        <InfoMessageProvider>
          <RemoteConfigProvider>
            <Login error={error} setError={setError} />
          </RemoteConfigProvider>
        </InfoMessageProvider>
      </Router>
    );
  }

  return (
    <LoadingProvider>
      <RoleProvider>
        <GlobalConfig>
          <TimeZoneAutoSetter />
          <DrawerMenu>
            <FeatureTrialEndingBanner enabled={moment().isBefore("2024-05-31")} />
            <AppRoutes />
          </DrawerMenu>
        </GlobalConfig>
      </RoleProvider>
    </LoadingProvider>
  );
}

export default App;
