import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import useImperialMuiField from "../hooks/useImperialMuiField";
import UnitHelper from "../../../helpers/UnitHelper";
import Mui5 from "components/mui5";

const imperialConverterOptions = {
  convertToMetric: (value) => UnitHelper.convert(value, UnitHelper.units.Pound, UnitHelper.units.Kilogram).toFixed(2),
  convertToImperial: (value) => UnitHelper.convert(value, UnitHelper.units.Kilogram, UnitHelper.units.Pound).toFixed(2),
  metricAdornmentLabel: "KG",
  imperialAdornmentLabel: "LBS",
};

function WeightInput(props) {
  const [field] = useImperialMuiField(props, imperialConverterOptions);

  return <NumberFormat customInput={Mui5.TextField} {...props} {...field} />;
}

WeightInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  inverse: PropTypes.bool,
};

export default WeightInput;
