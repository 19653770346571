import { useMemo } from "react";
import useQuery from "hooks/useQuery";
import useQueryStatusBuilder from "hooks/useQueryStatusBuilder";
import AthleteService from "services/AthleteService";
import { MetadataType } from "types";
import type { PersonalBest } from "types";
import type { Status } from "types/Status";

interface UseGetPersonalBestsQuery {
  data: PersonalBest[];
  status: Status;
}

interface UseGetPersonalBestQueryProps {
  athleteId: string;
  exerciseId: string;
  type: MetadataType;
}

function useGetManyPersonalBestQuery(params: UseGetPersonalBestQueryProps[], enabled = true): UseGetPersonalBestsQuery {
  const key = useMemo(() => {
    if (!enabled) return null;
    const filterMetadataExercisesOnly = params.filter((param) => param.type === MetadataType.EXERCISE);
    return ["AthleteService.getPersonalBests", filterMetadataExercisesOnly] as const;
  }, [params, enabled]);

  const { data, isLoading, error } = useQuery(key, async ([_, params]) => {
    const results = await Promise.allSettled(
      params.map((param) => AthleteService.getPersonalBest(param.athleteId, { exerciseId: param.exerciseId }))
    );
    const fulfilled = results.filter(
      (result): result is PromiseFulfilledResult<PersonalBest> => result.status === "fulfilled"
    );
    return fulfilled.map((result) => result.value);
  });

  const status = useQueryStatusBuilder({ isLoading, isError: !!error });

  return useMemo(() => ({ data: data || [], status }), [data, status]);
}

export default useGetManyPersonalBestQuery;
