import { styled } from "@mui/material";
import List from "@mui/material/List";

const IconPopperMenuListbox = styled(List, { shouldForwardProp: (key) => key !== "colour" })({
  width: "100%",
  height: "100%",
  margin: 0,
  padding: 0,
  zIndex: 1,
  position: "absolute",
  listStyle: "none",
  overflowY: "auto",
});

export default IconPopperMenuListbox;
