import TableGroupAssignPopperContent from "components/Tables/TableGroupAssignPopperContent";
import Tables from "components/Tables";
import IconPopperMenu from "components/IconPopperMenu/IconPopperMenu";
import { useCallback, useMemo } from "react";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore";
import useMultiSelectIds from "providers/MultiSelectProvider/useMultiSelectIds";
import useAthleteActions from "providers/AthletesProvider/useAthleteActions";
import useRequest from "hooks/useRequest";
import { Group } from "types/groups";
import useEntityFilterById from "hooks/useEntityFilterById";
import useIntersectingGroupIds from "hooks/useIntersectingGroupIds";
import useGroups from "providers/GroupsProvider/useGroups";
import useInfoMessage from "hooks/useInfoMessage";
import useRole from "providers/RoleProvider/useRole";
import uniq from "lodash/uniq";
import { useTranslation } from "react-i18next";

interface AthleteTableBulkGroupAssignProps {}

function AthleteTableBulkGroupAssign(props: AthleteTableBulkGroupAssignProps) {
  const selectedAthleteIds = useMultiSelectIds();
  const athletes = useAthleteStore();
  const athleteActions = useAthleteActions();
  const groups = useGroups();
  const { t } = useTranslation();

  const visibleSelectedAthletes = useEntityFilterById(selectedAthleteIds, athletes);
  const visibleSelectedAthleteIds = useMemo(() => visibleSelectedAthletes.map((a) => a.id), [visibleSelectedAthletes]);
  const intersectingGroupIds = useIntersectingGroupIds(visibleSelectedAthletes);
  const intersectingGroups = useEntityFilterById(intersectingGroupIds, groups);

  const role = useRole();
  const infoMessage = useInfoMessage({ type: "info" });
  const bulkAssignGroups = useRequest(athleteActions.bulkAssignGroups);

  const handleBulkAssignGroups = useCallback(
    async (newGroups: Group[]) => {
      const newGroupIds = newGroups.map((group) => group.id);

      const requestBody = visibleSelectedAthletes
        .map((athlete) => ({
          id: athlete.id,
          groupIds: uniq([
            /**
             * @densk1
             * Here, `.filter()` removes intersecting groupIds of selected
             * athletes as they are be included in `newGroupIds` */
            ...athlete.groupIds.filter((id) => !intersectingGroupIds.includes(id)),
            ...newGroupIds,
          ]),
        }))
        .filter(({ id, groupIds }) => {
          const excludeAthlete = Boolean(role === "COACH" && !groupIds.length);

          if (excludeAthlete) {
            const athlete = visibleSelectedAthletes.find((athlete) => athlete.id === id)!;
            infoMessage({
              message: t("Athlete.bulkGroupAssignErrorMessage", {
                firstName: athlete.firstName,
                lastName: athlete.lastName,
              }),
            });
          }

          return !excludeAthlete;
        });

      await bulkAssignGroups(requestBody);
    },
    [bulkAssignGroups, infoMessage, intersectingGroupIds, role, visibleSelectedAthletes, t]
  );

  return (
    <IconPopperMenu
      tooltipTitle={t("Athlete.bulkGroupAssignTooltipTitle")}
      icon={<Tables.Icons.AssignGroups />}
      disabled={!Boolean(visibleSelectedAthleteIds.length)}
      popperContent={
        <TableGroupAssignPopperContent commonGroups={intersectingGroups} onChange={handleBulkAssignGroups} />
      }
    />
  );
}

export default AthleteTableBulkGroupAssign;
