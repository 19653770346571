import { TextFieldProps } from "@mui/material";
import { useField } from "formik";
import Mui5 from "components/mui5";

/**
 * @deprecated In favour of {@link FormText src/components/Inputs/Form/FormText.tsx}
 */
function FormText({ name, ...props }: TextFieldProps & { name: string }) {
  const [field, meta, helpers] = useField(name);

  const fieldProps = {
    name,
    onChange: (event: any) => helpers.setValue(event.target.value),
    value: field.value || "",
    onBlur: () => helpers.setTouched(true),
    error: !!Boolean(meta.touched && meta.error),
    helperText: meta.touched && meta.error ? meta.error : props.helperText,
  };

  return <Mui5.TextField {...props} {...fieldProps} />;
}

export default FormText;
