import type { Group } from "types/groups";
import Div from "components/primitives/Div";
import Typography from "@mui/material/Typography";
import Span from "components/primitives/Span";

function GroupTooltipTitle(props: { group: Group }) {
  const { group } = props;

  return (
    <Div sx={{ display: "flex", alignItems: "center", flexShrink: 0, flexWrap: "nowrap" }}>
      <Div sx={{ display: "flex" }} aria-hidden="true">
        <Span
          sx={{
            display: "inline-block",
            width: "14px",
            height: "14px",
            borderRadius: "4px",
            backgroundColor: group.colour,
          }}
        />
        <Span
          sx={{
            position: "absolute",
            width: "1px",
            height: "1px",
            margin: "-1px",
            padding: 0,
            overflow: "hidden",
            clip: "rect(0, 0, 0, 0)",
            border: 0,
          }}
        >
          ({group.name})
        </Span>
      </Div>
      <Typography variant="TextS" sx={{ ml: "4px" }}>
        {group.name}
      </Typography>
    </Div>
  );
}

export default GroupTooltipTitle;
