import { styled } from "@mui/material";
import Paper from "components/mui5/Paper";
import Grid from "@mui/material/Grid";

export const CardPaper = styled(Paper)(({ theme }) => ({
  minHeight: theme.spacing(9),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(2),
}));

export const GridRow = styled(Grid)(({ theme }) => ({
  height: theme.spacing(9),
}));
