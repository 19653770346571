import { Workout } from "features/Workouts/Workouts.types";
import WorkoutOverviewPreviewStepList from "./WorkoutOverviewPreviewStepList";
import Stack from "@mui/material/Stack";
import WorkoutService from "features/Workouts/services/WorkoutService";
import Typography from "@mui/material/Typography";

interface WorkoutOverviewPreviewProps {
  workout: Workout;
}

function WorkoutOverviewPreview(props: WorkoutOverviewPreviewProps) {
  const deserializedWorkout = WorkoutService.deserialize(props.workout);

  return (
    <Stack minHeight={0} height="100%">
      <Typography variant="h3" gutterBottom fontWeight="fontWeightMedium">
        Workout Preview
      </Typography>
      <Stack flex={1} minHeight={0}>
        <WorkoutOverviewPreviewStepList {...deserializedWorkout} />
      </Stack>
    </Stack>
  );
}

export default WorkoutOverviewPreview;
