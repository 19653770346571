import { createTheme } from "@mui/material/styles";
import muiTheme from "components/MuiThemeProvider/muiTheme";

export const fullscreenLeaderboardTheme = createTheme({
  spacing: 12,
  palette: {
    ...muiTheme.palette,
  },
  typography: {
    htmlFontSize: 18,
  },
});
