import { autocompleteClasses } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import type { Shadows } from "@mui/material/styles";
import CaretIcon from "components/Icons/CaretIcon";

const paletteColors = {
  text: {
    primary: "#000",
    secondary: "#4D4D4D",
  },
  common: {
    white: "#FFFFFF",
  },
  primary: {
    main: "#000",
    contrastText: "#FFFFFF",
  },
  /**
   * Secondary - For positive connotations UI elements and backgrounds(e.g: target achieved, progress made...)
   */
  secondary1: {
    180: "#0A604B",
    100: "#3DC4A3",
    60: "#92FFDD",
    10: "#E9FFF8",
  },
  /**
   * Secondary2 - For negative connotations UI elements and backgrounds (e.g: regression in progress..)
   */
  secondary2: {
    180: "#6B001A",
    100: "#E02C57",
    60: "#FF8CA8",
    10: "#FFE4EA",
  },
  /**
   * Secondary3 - For notes on exercises and workouts
   */
  secondary3: {
    100: "#F1C40F",
    60: "#FCF3CF",
  },
  /**
   * System Feedback Colours
   */
  error: {
    main: "#C0392B",
    light: "#FFDBD7",
  },
  warning: {
    main: "#E67E22",
    light: "#FFE7D2",
  },
  success: {
    main: "#04B86C",
    light: "#CCFFEA",
  },
  info: {
    main: "#2980B9",
    light: "#D7EFFF",
  },
  danger: {
    /** error.main */
    main: "#C0392B",
  },
  /**
   * Grey - For neutral UI elements and backgrounds (e.g: no progress...)
   */
  grey: {
    10: "#FAFBFE",
    20: "#EEF1F8",
    40: "#D5DCE3",
    60: "#BABCBE",
    100: "#848A90",
    120: "#676D73",
  },
};
/**
 * @description New theme palette structure
 */
const theme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: paletteColors.grey[10],
    },
    ...paletteColors,
    /**
     * @deprecated Used internally
     */
    secondary: {
      main: paletteColors.secondary1[100],
    },
    divider: paletteColors.grey[20],
    action: {
      // active: paletteColors.grey[100],
      hover: paletteColors.grey[20],
      selected: paletteColors.primary.main,
      // disabled: paletteColors.grey[10],
      // disabledBackground: paletteColors.grey[10],
    },
  },
  spacing: 8,
  shape: {
    /** @property {borderRadiusSmall: 4} */
    borderRadiusSmall: 4,
    /** @property {borderRadius: 10} */
    borderRadius: 10,
    /** @property {borderRadiusLarge: 20} */
    borderRadiusLarge: 20,
  },
  /**
   * @description New shadows design
   *
   * - None
   * - Box Shadow Default
   * - Box Shadow Hover
   * - Box Shadow Modal
   * - Box Shadow Small Element
   * - Box Shadow Floating Element
   */
  shadows: [
    "none",
    /**
     * Box Shadow Default
     */
    "2px 2px 10px 0px rgba(0, 0, 0, 0.08)",
    /**
     * Box Shadow Hover
     */
    "2px 2px 14px 0px rgba(0, 0, 0, 0.20)",
    /**
     * Box Shadow Modal
     */
    "4px 4px 20px 0px rgba(0, 0, 0, 0.12)",
    /**
     * Box Shadow Small Element
     */
    "2px 2px 10px 0px rgba(0, 0, 0, 0.1)",
    /**
     * Box Shadow Floating Element
     */
    "0px 2px 10px 0px rgba(0, 0, 0, 0.25)",
    /**
     * Box Shadow Top Navigation
     */
    "4px 4px 20px 0px rgba(0, 0, 0, 0.05)",
    ...Array(18).fill("2px 2px 10px 0px rgba(0, 0, 0, 0.08)"),
  ] as Shadows,
  transitions: { duration: { standard: 200 } },
  typography: {
    fontFamily: ["'Roboto'", "-apple-system", "BlinkMacSystemFont", "sans-serif"].join(", "),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    /* Heading XXL 64px */
    h1: {
      fontSize: 64,
      fontWeight: 500,
      lineHeight: "70px" /* 109.375% */,
    },
    /* Heading XL 48px */
    h2: {
      fontSize: 48,
      fontWeight: 500,
      lineHeight: "54px" /* 112.5% */,
    },
    /* Heading L 40px */
    h3: {
      fontSize: 40,
      fontWeight: 500,
      lineHeight: "46px" /* 115% */,
    },
    /* Heading M 36px */
    h4: {
      fontSize: 36,
      fontWeight: 500,
      lineHeight: "42px" /* 116.667% */,
    },
    /* Heading S 32px */
    h5: {
      fontSize: 32,
      fontWeight: 500,
      lineHeight: "38px" /* 118.75% */,
    },
    /* Heading XS 24px */
    h6: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: "30px" /* 118.75% */,
    },
    /* Text XL 20px */
    subtitle1: {
      fontSize: 20,
      lineHeight: "26px" /* 130% */,
    },
    /* Text L 18px */
    subtitle2: {
      fontSize: 18,
      lineHeight: "24px" /* 133.33% */,
    },
    /* Text M 16px */
    body1: {
      fontSize: 16,
      lineHeight: "22px",
    },
    /* Text S 14px */
    body2: {
      fontSize: 14,
      lineHeight: "20px",
    },
    /* Text XS 12px */
    caption: {
      fontSize: 12,
      lineHeight: "18px",
    },
    button: {
      fontSize: 16,
      lineHeight: "22px",
      fontWeight: 500,
    },
  },
  // // V5 overrides
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          Caption: "p",
          TextXS: "p",
          TextS: "p",
          TextL: "p",
          // Heading
          HeadingXS: "h1",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover:not([aria-selected='true'])": {
            color: paletteColors.primary.main,
          },
          '&[aria-selected="true"]': {
            backgroundColor: paletteColors.primary.main,
            color: paletteColors.common.white,
          },
          '&[aria-selected="true"]:hover': {
            backgroundColor: paletteColors.primary.main,
            color: paletteColors.common.white,
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <CaretIcon color="primary" />,
      },
      styleOverrides: {
        groupLabel: ({ theme }) => ({
          color: theme.palette.grey[100] + "!important",
          lineHeight: "18px",
          fontSize: "12px",
          fontWeight: 400,
          padding: theme.spacing(0.5, 1.5),
        }),
        paper: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
        }),
        popupIndicator: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
        clearIndicator: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
        popper: ({ theme }) => ({
          [`& .${autocompleteClasses.groupLabel}`]: { color: theme.palette.primary.main },
          [`& .${autocompleteClasses.listbox}`]: {
            "&::-webkit-scrollbar": {
              width: "16px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "16px",
              border: "4px solid transparent",
              backgroundColor: theme.palette.grey[60],
              backgroundClip: "padding-box",
              minHeight: "30px",
            },
            [`& .${autocompleteClasses.option}`]: {
              fontSize: "14px",
              lineHeight: "20px",
              padding: theme.spacing(1, 1.5),
              color: theme.palette.primary.main,
              "&.Mui-focused": {
                color: theme.palette.primary.main,
              },
              '&[aria-selected="true"]': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                // if selected and disabled color should the same as selected
                opacity: 1,
              },
              '&[aria-selected="true"].Mui-focused': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
              },
            },
          },
        }),
        endAdornment: {
          lineHeight: "16px",
          svg: {
            fontSize: "24px",
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: (props) => <CaretIcon {...props} fontSize="small" />,
      },
      styleOverrides: {
        icon: {
          color: paletteColors.primary.main,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: paletteColors.grey[100],
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
        InputLabelProps: { shrink: true },
        SelectProps: {
          IconComponent: (props) => <CaretIcon {...props} color="primary" />,
        },
      },
    },
  },
});

export default theme;
