import React, { useCallback, useEffect, useMemo } from "react";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link, useLocation } from "react-router-dom";
import Constants from "../../config/Constants";
import outputLogoFull from "../../assets/images/Logo.svg";
import outputLogoStripes from "./outputLogoStripes.svg";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import DrawerMenuLink from "./DrawerMenuLink";
import { useUserContext } from "hooks";
import * as Components from "./DrawerMenu.components";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import { useDrawerMenuItems } from "components/App/App.config";
import RemoteAnnouncementBanner from "components/RemoteAnnouncementBanner";
import useMakeProfileImageURL from "hooks/useMakeProfileImageURL";
import useRole from "providers/RoleProvider/useRole";
import { OrganisationRole } from "types/organisation";
import CoachHelpers from "helpers/CoachHelpers";
import { useMediaQuery, useTheme } from "@mui/material";
import useCanDo from "hooks/useCanDo";
import Avatar from "components/mui5/Avatar";
import AthleteHelpers from "helpers/AthleteHelpers";
import { useTranslation } from "react-i18next";

/**
 *
 * @param {String} route
 * @param {String} location
 * @param {String} homeRoute
 * @returns {Boolean} indicates if the route is active
 */
const isRouteActive = (route, location, homeRoute) => {
  return location.pathname.includes(route) || (route === homeRoute && location.pathname === "/");
};

function DrawerMenu(props) {
  const menuItems = useDrawerMenuItems();
  const [open, setOpen] = React.useState(LocalStorageHelper.get(LocalStorageHelper.keys.drawerMenuOpen, false));
  const location = useLocation();
  const [{ profile }] = useUserContext();
  const organisation = useOrganisation();
  const role = useRole();
  const canDo = useCanDo();
  const { t } = useTranslation();

  const toggleDrawer = useCallback(() => {
    setOpen((open) => {
      LocalStorageHelper.set(LocalStorageHelper.keys.drawerMenuOpen, !open);
      return !open;
    });
  }, []);

  const makeIcon = useCallback((icon, isNew) => {
    if (!isNew) return icon;

    return (
      <Components.NewIndicatorBadge
        variant="dot"
        color="#92FFDD" // TODO: Use theme color after dashboard theme is implemented globally
        overlap="circular"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {icon}
      </Components.NewIndicatorBadge>
    );
  }, []);

  const profileImagePath = useMemo(() => {
    if (role !== OrganisationRole.COACH) return profile?.profilePhotoName;
    return CoachHelpers.getProfileImagePath(profile);
  }, [profile, role]);

  const photoUrl = useMakeProfileImageURL(profileImagePath);

  const initials = useMemo(() => {
    if (role !== OrganisationRole.COACH) return AthleteHelpers.getInitials(profile);
    return CoachHelpers.getInitials(profile);
  }, [profile, role]);

  const theme = useTheme();
  const alwaysClosed = useMediaQuery(theme.breakpoints.down(1290));

  const homeRoute = useMemo(() => {
    return canDo("dashboard.any") ? "/dashboards" : "/live";
  }, [canDo]);

  useEffect(() => {
    /**
     * @author {Carden}
     * Set drawer to closed on small screens */
    if (alwaysClosed) setOpen(false);
  }, [alwaysClosed]);

  return (
    <Box display="flex">
      <RemoteAnnouncementBanner />
      <CssBaseline />
      <Components.Drawer open={open} variant="permanent">
        <List>
          <ListItem>
            <Link to={Constants.routes.home.route}>
              <Components.LogoContainerStyledBox mt={2} mb={2.5} pl={0.5} display="block">
                <Components.Logo src={outputLogoFull} alt="Output Sports logo" show={open} pl={1} width={192} />
                <Components.Logo src={outputLogoStripes} alt="Output Sports stripes" show={!open} ml="-3px" />
              </Components.LogoContainerStyledBox>
            </Link>
          </ListItem>
          {menuItems.map((item) => (
            <DrawerMenuLink
              key={item.title}
              selected={isRouteActive(item.route, location, homeRoute)}
              to={item.route}
              icon={
                <Box pl={1.5} pt={0} lineHeight={0} pr={0} mr={0}>
                  {makeIcon(item.icon, item.isNew)}
                </Box>
              }
              primary={
                <Typography variant="h4" gutterBottom={false}>
                  <Box fontWeight="fontWeightMedium" sx={{ transition: "opacity 300ms", opacity: !open ? 0 : 1 }}>
                    {item.title}
                  </Box>
                </Typography>
              }
            />
          ))}
        </List>
        <Box flex={1} />
        <Box p={2}>
          <Grid container alignItems="center" spacing="8px" wrap="nowrap">
            <Grid item>
              <Link to={Constants.routes.settings.route}>
                <Avatar
                  src={photoUrl}
                  alt={t("profileAvatarLabel")}
                  sx={{
                    height: theme.spacing(5),
                    width: theme.spacing(5),
                    marginLeft: theme.spacing(0.5),
                    fontSize: "18px",
                  }}
                >
                  {initials}
                </Avatar>
              </Link>
            </Grid>
            <Grid item xs title={organisation.name} zeroMinWidth>
              <Typography variant="h3" noWrap pl={1.5} maxWidth="220px">
                {profile.name}
              </Typography>
              <Typography noWrap pl={1.5} maxWidth="220px">
                {organisation.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing="8px">
            <Grid item xs={12}>
              <Components.DrawerOpenIconButton
                onClick={toggleDrawer}
                disabled={alwaysClosed}
                sx={alwaysClosed ? { opacity: 0.3 } : undefined}
              >
                {open ? <ChevronLeftIcon htmlColor="white" /> : <ChevronRightIcon htmlColor="white" />}
              </Components.DrawerOpenIconButton>
            </Grid>
          </Grid>
        </Box>
      </Components.Drawer>
      <Components.Content>{props.children}</Components.Content>
    </Box>
  );
}

export default DrawerMenu;
