import IconPopperMenuSearchInput from "components/IconPopperMenu/IconPopperMenuSearchInput";
import Grid from "@mui/material/Grid";
import { useCallback, useState } from "react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import useAutocomplete, { AutocompleteChangeReason } from "@mui/material/useAutocomplete";
import IconPopperMenuListbox from "components/IconPopperMenu/IconPopperMenuListbox";
import AutocompleteOptionColourable from "components/AutocompleteOptionColourable";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore/useAthleteStore";
import { Athlete } from "types/athlete";
import AthleteHelpers from "helpers/AthleteHelpers/AthleteHelpers";
import difference from "lodash/difference";

interface TableAthletesAssignPopperContentProps {
  commonAthletes: Athlete[];
  onChange(athleteId: string, isAssignment: boolean): void;
}

function TableAthletesAssignPopperContent(props: TableAthletesAssignPopperContentProps) {
  const { onChange } = props;
  const [inputValue, setInputValue] = useState("");
  const athletes = useAthleteStore();

  const handleChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, value: Athlete[], reason: AutocompleteChangeReason) => {
      let diffAthletes: Athlete[];
      if (reason === "selectOption") {
        diffAthletes = difference(value, props.commonAthletes);
        onChange(diffAthletes[0].id, true);
      } else if (reason === "removeOption") {
        diffAthletes = difference(props.commonAthletes, value);
        onChange(diffAthletes[0].id, false);
      }
    },
    [onChange, props.commonAthletes]
  );

  const handleInputChange = useCallback(
    (event: React.SyntheticEvent<Element>, value: string) => {
      setInputValue(value);
    },
    [setInputValue]
  );

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
    id: "athlete-assign-autocomplete",
    options: athletes,
    value: props.commonAthletes,
    getOptionLabel: AthleteHelpers.getFullName,
    open: true,
    onChange: handleChange,
    multiple: true,
    disableClearable: true,
    inputValue,
    onInputChange: handleInputChange,
    clearOnBlur: false,
  });

  const inputProps = getInputProps();
  const listboxProps = getListboxProps();

  return (
    <Grid container {...getRootProps()} flexDirection="column" flexWrap="nowrap" height="100%">
      <Grid item width="100%" height="62px">
        <IconPopperMenuSearchInput inputProps={inputProps} placeholder={"Assign Athletes"} />
        <Divider />
      </Grid>
      <Grid
        item
        width="100%"
        position="relative"
        height={groupedOptions.length * 37} // 37 === IconPopperMenuListItem height
        minHeight={43} // 43 === "No results found" height
        maxHeight={320}
      >
        <IconPopperMenuListbox {...listboxProps}>
          {groupedOptions.length > 0 ? (
            (groupedOptions as Athlete[]).map((athlete, index) => {
              const selected = props.commonAthletes.includes(athlete);

              return (
                <AutocompleteOptionColourable {...getOptionProps({ option: athlete, index })} key={athlete.id}>
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    {selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                  </ListItemIcon>
                  {AthleteHelpers.getFullName(athlete)}
                </AutocompleteOptionColourable>
              );
            })
          ) : (
            <Box p={1.25} pl={3} pb={0} color="grey.600">
              No results found
            </Box>
          )}
        </IconPopperMenuListbox>
      </Grid>
    </Grid>
  );
}

export default TableAthletesAssignPopperContent;
