import Constants from "../../config/Constants";

function createOptionLabel(section, option) {
  switch (section) {
    case "Athletes":
      return option.fullName;
    default:
      return option.name;
  }
}

function createOptionUrl(section, option) {
  switch (section) {
    case "Athletes":
      return `${Constants.routes.athletes.route}/${option.id}`;
    case "Groups":
      return `${Constants.routes.groups.route}/${option.id}`;
    default:
      return;
  }
}

export function combineSections(sections) {
  return Object.entries(sections).reduce(
    (combinedAccumulator, [sectionName, sectionOptions]) => [
      ...combinedAccumulator,
      ...sectionOptions.map((option) => ({
        section: sectionName,
        label: createOptionLabel(sectionName, option),
        url: createOptionUrl(sectionName, option),
        ...option,
      })),
    ],
    [] // initial combinedAccumulator
  );
}
