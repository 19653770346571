import Grid from "@mui/material/Grid";
import { MultiFactorInfo } from "firebase/auth";
import { Formik, Form } from "formik";
import { useCallback } from "react";
import * as Components from "../../Login.components";
import SmsCodeSchema from "./SmsCodeInput.schema";
import Mui5 from "components/mui5";
import ohLogo from "../../../../assets/images/Logo.svg";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

interface SmsCodeInputProps {
  hint?: MultiFactorInfo;
  verifyCode(smsCode: string): Promise<void>;
  resendCode(): Promise<void>;
  onCancel(): void;
  errorMessage?: string;
  errorCode?: string;
}

function SmsCodeInput(props: SmsCodeInputProps) {
  const { t } = useTranslation();
  const { verifyCode, hint, onCancel, errorCode, errorMessage } = props;

  const handleVerify = useCallback(({ code }: { code: string }) => verifyCode(code), [verifyCode]);

  const error = (errorCode && t(errorCode)) || errorMessage;

  return (
    <Formik initialValues={{ code: "" }} onSubmit={handleVerify} validationSchema={SmsCodeSchema}>
      {({ isSubmitting, values }) => (
        <Form>
          <Grid container spacing="24px" maxWidth={480}>
            <Grid item xs={12} justifyContent="center">
              <img src={ohLogo} className="oh-logo" alt="logo" width={240} />
            </Grid>
            <Grid item xs={12}>
              <Components.LoginFormText
                name="code"
                label="SMS Code"
                placeholder="Enter your SMS Code"
                variant="standard"
                color="secondary"
                fullWidth
                helperText={
                  <Box component="span" color="#FFF">
                    A 6 digit code has been sent to the number matching {(hint as any)?.phoneNumber}
                  </Box>
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Mui5.Button
                        color="secondary"
                        size="small"
                        onClick={props.resendCode}
                        sx={{
                          mb: 1.5,
                          "&.Mui-disabled": { backgroundColor: "secondary.main", opacity: 0.5 },
                        }}
                      >
                        Resend
                      </Mui5.Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Mui5.Button
                id="submit-mfa-code-button"
                type="submit"
                color="secondary"
                fullWidth
                size="small"
                variant="contained"
                disabled={values.code.length !== 6 || isSubmitting}
                disableElevation
                sx={{
                  "&.Mui-disabled": { backgroundColor: "secondary.main", opacity: 0.5 },
                }}
              >
                Submit
              </Mui5.Button>
            </Grid>
            <Grid item xs={4}>
              <Mui5.Button
                onClick={onCancel}
                id="cancel-mfa-button"
                type="button"
                color="secondary"
                fullWidth
                size="small"
                variant="contained"
                disableElevation
              >
                Cancel
              </Mui5.Button>
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Typography variant="caption" color="error">
                  {error}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default SmsCodeInput;
