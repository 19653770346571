import ShowMoreActivityContext from "features/Live/contexts/ShowMoreActivityContext";

interface ShowMoreActivityProviderProps {
  children?: React.ReactNode;
  showMore(): Promise<void>;
}

function ShowMoreActivityProvider(props: ShowMoreActivityProviderProps) {
  return <ShowMoreActivityContext.Provider value={props.showMore}>{props.children}</ShowMoreActivityContext.Provider>;
}

export default ShowMoreActivityProvider;
