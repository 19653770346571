import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { Group } from "contexts/GroupsContext/GroupsContext";
import Mui5 from "components/mui5";
import { Coach } from "types/coach";
import useMakeProfileImageURL from "hooks/useMakeProfileImageURL";
import CoachHelpers from "helpers/CoachHelpers";

export const getColumnConfig = (groups: Group[]): GridColDef[] => [
  {
    field: "fullName",
    headerName: "Name",
    minWidth: 180,
    sortable: true,
    editable: false,
    valueGetter(params: GridValueGetterParams<Coach>) {
      return params.row.name;
    },
    renderHeader: (params: GridColumnHeaderParams<Coach>) => <Box pl={6}>Name</Box>,
    renderCell: function RenderCoachAvatarCell(params: GridRenderCellParams<Coach>) {
      const { name } = params.row;

      const photoUrl = useMakeProfileImageURL(CoachHelpers.getProfileImagePath(params.row));

      const avatarInitials = CoachHelpers.getInitials(params.row);

      return (
        <Grid container alignItems="center" minWidth={0}>
          <Grid lineHeight={0} minWidth={0}>
            <Mui5.Avatar src={photoUrl || undefined}>{avatarInitials}</Mui5.Avatar>
          </Grid>
          <Grid item xs lineHeight={0}>
            <Box pl={2}>
              <Typography component="span" variant="h4">
                {name}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    },
    flex: 3,
  },
  {
    field: "email",
    headerName: "Email",
    sortable: true,
    editable: false,
    flex: 3,
  },
  {
    field: "groups",
    headerName: " Assigned Groups",
    flex: 6,
    sortable: false,
    renderCell: (params: GridRenderCellParams<Coach>) => (
      <Box pb={1} pt={1} minHeight={64} pr={2} display="flex" alignItems="center">
        <Grid container spacing="4px">
          {groups
            .filter((group) => params.row.groupIds.includes(group.id))
            .map((group) => (
              <Grid key={group.id}>
                <Mui5.Chip customColor={group.colour} label={group.name} />
              </Grid>
            ))}
        </Grid>
      </Box>
    ),
  },
];
