import { useCallback } from "react";
import { useInfoMessage, useLoadingContext } from "hooks";

function useRequest<T extends (...args: any[]) => ReturnType<T>>(requestFunction: T, shouldThrow: boolean = false) {
  const onInfoMessage = useInfoMessage({ type: "error" });
  const [, setLoading] = useLoadingContext();

  return useCallback(
    async (...args: Parameters<T>): Promise<ReturnType<T> | undefined> => {
      setLoading(true);
      try {
        const result = await requestFunction(...args);
        setLoading(false);
        return result;
        // eslint-disable-next-line prettier/prettier
      } catch (error: any) {
        const errorOrMessage = typeof error.response?.data === "string" ? { message: error.response?.data } : error;
        errorOrMessage.message = error.response?.data?.detail || errorOrMessage.message;
        onInfoMessage(errorOrMessage);
        setLoading(false);
        if (shouldThrow) throw error;
      }
    },
    [onInfoMessage, requestFunction, setLoading, shouldThrow]
  );
}

export default useRequest;
