import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const size = {
  default: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.55556 21.8889H16.4444M12 21.8889V17.4444M12 17.4444C8.93176 17.4444 6.44444 14.9571 6.44444 11.8889V3H17.5556V11.8889C17.5556 14.9571 15.0682 17.4444 12 17.4444ZM17.5556 5.22222H19.2222C20.7563 5.22222 22 6.46588 22 8C22 9.53412 20.7563 10.7778 19.2222 10.7778H17.5556M6.44444 10.7778H4.77778C3.24366 10.7778 2 9.53412 2 8C2 6.46588 3.24366 5.22222 4.77778 5.22222H6.44444"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

function LeaderboardsIcon(props: SvgIconProps) {
  return <SvgIcon {...props}>{size.default}</SvgIcon>;
}
export default LeaderboardsIcon;
