import DateNavigator from "components/DateNavigator";
import { useCallback, useMemo, useState } from "react";
import moment from "moment";
import { DateTime } from "types";
import AthleteProfileActivityFeedWellness from "./AthleteProfileActivityFeedWellness";
import { getMonthDateRangeParams } from "./AthleteProfileActivityFeed.helpers";
import AthleteProfileAllActivity from "./AthleteProfileAllActivity";
import AthleteProfileActivityFeedSkeleton from "./AthleteProfileActivityFeedSkeleton";
import useActivityFeedWellness from "./hooks/useActivityFeedWellness";
import useActivityFeedAllActivity from "./hooks/useActivityFeedAllActivity";
import useActivityFeedWorkouts from "./hooks/useActivityFeedWorkouts";
import AthleteProfileActivityFeedCompleteWorkouts from "./AthleteProfileActivityFeedCompleteWorkouts";
import useActivityDateFinder from "./hooks/useActivityDateFinder";
import NoDataWrapper from "components/NoDataWrapper";
import * as Components from "./AthleteProfileActivityFeed.components";
import { useTranslation } from "react-i18next";
import CanDoAction from "components/CanDoAction";
import useGetAthleteActivityQuery from "./hooks/useGetAthleteActivityQuery";

interface AthleteProfileActivityFeedProps {
  id: string;
}

function AthleteProfileActivityFeed(props: AthleteProfileActivityFeedProps) {
  const { id } = props;
  const { t } = useTranslation();
  const [date, setDate] = useState<moment.Moment>(moment());
  const [startEndDate, setStartEndDate] = useState(() => getMonthDateRangeParams(moment()));
  const [activityDates, activityDateActions] = useActivityDateFinder(id, moment(date));

  const { data: activity } = useGetAthleteActivityQuery(id, startEndDate);

  const handleOnMonthChange = useCallback((date: DateTime) => {
    setStartEndDate(getMonthDateRangeParams(date));
  }, []);

  const handleOnDatePickerOpen = useCallback(() => {
    setStartEndDate(getMonthDateRangeParams(date));
  }, [date]);

  const handlePreviousDayClick = useMemo(() => {
    if (activityDates.previous === null) return undefined;

    const checkBeforeDate = activityDates.previous;

    return () => {
      activityDateActions.findPrevious();
      setDate(moment(checkBeforeDate));
    };
  }, [activityDates.previous, activityDateActions]);

  const handleNextDayClick = useMemo(() => {
    if (activityDates.next === null) return undefined;

    const checkAfterDate = activityDates.next;

    return () => {
      activityDateActions.findNext();
      setDate(moment(checkAfterDate));
    };
  }, [activityDates.next, activityDateActions]);

  const wellness = useActivityFeedWellness({ id, date });
  const completedWorkouts = useActivityFeedWorkouts({ id, date });
  const measurements = useActivityFeedAllActivity({ id, date });

  const hasWellness = Boolean(Object.values(wellness).length);
  const hasCompletedWorkouts = Boolean(completedWorkouts.length);
  const hasMeasurements = Boolean(measurements.length);
  const hasData = Boolean(hasWellness || hasCompletedWorkouts || hasMeasurements);

  function handleCalendarDateChange(selectedDate: DateTime) {
    activityDateActions.findPreviousAndNext(id, moment(selectedDate).toISOString());

    setDate(moment(selectedDate));
  }

  return (
    <Components.Root>
      <DateNavigator
        value={date}
        onChange={handleCalendarDateChange}
        highlightedDays={activity}
        onMonthChange={handleOnMonthChange}
        onOpen={handleOnDatePickerOpen}
        onPreviousDayClick={handlePreviousDayClick}
        onNextDayClick={handleNextDayClick}
      />
      <Components.ScrollBox>
        <NoDataWrapper
          hasData={hasData}
          fallback={<AthleteProfileActivityFeedSkeleton message={t("AthleteProfileActivityFeed.noDataMessage")} />}
        >
          <AthleteProfileActivityFeedWellness id={id} date={date} wellness={wellness} />
          <CanDoAction action="workout.any">
            <AthleteProfileActivityFeedCompleteWorkouts completedWorkouts={completedWorkouts} />
          </CanDoAction>
          <AthleteProfileAllActivity id={id} date={date} measurements={measurements} />
        </NoDataWrapper>
      </Components.ScrollBox>
    </Components.Root>
  );
}

export default AthleteProfileActivityFeed;
