import React, { useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useUserContext } from "hooks";
import useRequest from "hooks/useRequest";
import useMakeProfileImageURL from "hooks/useMakeProfileImageURL";
import ProfilePhotoInput from "components/Inputs/ProfilePhotoInput";
import useRole from "providers/RoleProvider/useRole";
import { OrganisationRole } from "types/organisation";
import CoachHelpers from "helpers/CoachHelpers";
import FormHelperText from "@mui/material/FormHelperText";

interface SettingsAccountProfilePhotoEditProps {}

function SettingsAccountProfilePhotoEdit(props: SettingsAccountProfilePhotoEditProps) {
  const [{ profile }, userActions] = useUserContext();
  const role = useRole();
  const setProfileImage = useRequest(userActions.onSetProfileImage, true);
  const deleteProfileImage = useRequest(userActions.onDeleteProfileImage);
  const [error, setError] = React.useState<string | null>(null);

  const handlePhotoChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setError(null);
      const file = event.target.files && event.target.files[0];

      if (!file) return;

      const fileSizeInMib = file.size / 1024 / 1024;

      if (fileSizeInMib > 10) {
        setError("File size must be less than 10MB");
        return;
      } else if (file.name.length > 100) {
        setError("File name must be fewer than 100 characters");
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (progressEvent) => {
        if (!progressEvent.target?.result) throw new Error("File ProgressEvent not valid");

        const newPhoto: { content: string | ArrayBuffer; name: string; contentType: string } = {
          content: progressEvent.target.result,
          name: file.name,
          contentType: file.type,
        };

        await setProfileImage(newPhoto);
      };
    },
    [setProfileImage]
  );

  const handleDeleteProfilePicture = useCallback(
    async (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      await deleteProfileImage();
    },
    [deleteProfileImage]
  );

  const profilePhotoUrl = useMakeProfileImageURL(
    role !== OrganisationRole.COACH ? profile?.profilePhotoName : CoachHelpers.getProfileImagePath(profile)
  );

  return (
    <>
      <Typography variant="h3" pb={2}>
        <Box component="span" fontWeight="fontWeightMedium">
          Profile Photo
        </Box>
      </Typography>
      <ProfilePhotoInput
        imageUrl={profilePhotoUrl}
        onChange={handlePhotoChange}
        onDelete={handleDeleteProfilePicture}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
}

export default SettingsAccountProfilePhotoEdit;
