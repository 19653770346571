import http from "axiosSetup";
import { Dashboard } from "types/Dashboard";
import { DashboardActions } from "features/Dashboards/providers/DashboardProvider";
class DashboardService {
  static delete: DashboardActions["deleteOne"] = async (dashboard): Promise<{ id: string }> => {
    return http.delete<{ id: string }>(`/dashboards/${dashboard.id}`).then((response) => response.data);
  };

  static create: DashboardActions["addOne"] = async (newDashboard): Promise<{ id: string }> => {
    return http.post<{ id: string }>(`/dashboards`, newDashboard).then((response) => response.data);
  };

  static async save(dashboard: Dashboard): Promise<{ id: string }> {
    return http
      .put<{ id: string }>(`/dashboards/${dashboard.id}`, JSON.parse(JSON.stringify(dashboard)))
      .then((response) => response.data);
  }

  static async updateView(dashboard: Pick<Dashboard, "id" | "isGlobal">): Promise<void> {
    return http
      .patch(`/dashboards/${dashboard.id}/global`, { isGlobal: dashboard.isGlobal })
      .then((response) => response.data);
  }

  static async getAll(): Promise<Dashboard[]> {
    const result = await http.get<Dashboard[]>("/dashboards").then((response) => response.data);

    /**
     * @author {Carden}
     *
     * Create a dashboard if none exist
     */
    if (!result.length) {
      const createResult = await DashboardService.create({
        type: "Athlete",
        // @ts-ignore
        athleteId: null,
        groupId: null,
        description: null,
        name: "Sample Dashboard",
        isGlobal: false,
        timePeriod: {
          type: "Previous",
          relativePeriod: "P365D",
        },
        excludedAthleteIds: [],
        tiles: [
          {
            id: "f934666a-c30a-429a-92da-d10b3396259b",
            layout: { width: 6 },
            widget: {
              id: "891c5cd3-99c4-454b-b29c-73b7b6bf2137",
              groupId: null,
              name: null,
              showPb: true,
              calculateBy: "Best",
              // @ts-ignore
              type: "AthleteMetricLineChart",
              timePeriod: null,
              excludedAthleteIds: [],
              items: [
                {
                  id: "BARBELL_BACK_SQUAT",
                  field: "estimatedOneRepMax",
                  // @ts-ignore
                  type: "Exercise",
                  targets: null,
                },
              ],
            },
          },
          {
            id: "f438f46d-9097-43bc-a39a-faf1b1862fb7",
            layout: { width: 3 },
            widget: {
              id: "db29a865-61a4-4a4e-931f-bc27ea4beeb7",
              groupId: null,
              name: null,
              showPb: false,
              calculateBy: "Average",
              // @ts-ignore
              type: "AthleteKpiNumerical",
              timePeriod: null,
              excludedAthleteIds: [],
              items: [
                {
                  id: "SHOULDER_ROTATION_EXTERNAL",
                  field: "rangeOfMotion",
                  // @ts-ignore
                  type: "Exercise",
                  targets: {
                    default: [150],
                  },
                },
              ],
            },
          },
          {
            id: "2c43dd5a-014d-4f89-80eb-2669f8aba37f",
            layout: { width: 3 },
            widget: {
              id: "c62398ff-1699-49c5-8906-25b3d503e217",
              groupId: null,
              name: null,
              showPb: false,
              calculateBy: "Best",
              // @ts-ignore

              type: "AthleteTargetGauge",
              timePeriod: null,
              excludedAthleteIds: [],
              items: [
                {
                  id: "BILATERAL_CMJ",
                  field: "jumpHeight",
                  // @ts-ignore

                  type: "Exercise",
                  targets: {
                    default: [0.4],
                  },
                },
              ],
            },
          },
          {
            id: "ea7be1d0-5b6a-4b53-829a-59542ed94762",
            layout: { width: 6 },
            widget: {
              id: "366f5c5d-3083-4368-a4a8-f9509556d052",
              groupId: null,
              name: null,
              showPb: false,
              calculateBy: "Best",
              // @ts-ignore

              type: "AthleteTargetLineChart",
              timePeriod: null,
              excludedAthleteIds: [],
              items: [
                {
                  id: "10_5_TEST",
                  field: "tenFiveScore",
                  // @ts-ignore

                  type: "Exercise",
                  targets: {
                    default: [2],
                  },
                },
              ],
            },
          },
        ],
      });

      await http
        .patch("/users/me/preferences/default-dashboard", { id: createResult.id })
        .then((response) => response.data);

      return DashboardService.getAll();
    }

    return result;
  }

  static async get(id: string): Promise<Dashboard> {
    const result = await http.get<Dashboard>(`/dashboards/${id}`).then((response) => response.data);
    return result;
  }
}

export default DashboardService;
