import React, { useMemo } from "react";
import { Group } from "@visx/group";
import { GridRows } from "@visx/grid";
import AxisLeft from "features/Charting/components/AxisLeft";
import useChartingColors from "features/Charting/hooks/useChartingColors";
import AxisBottom from "features/Charting/components/AxisBottom";
import LineDots from "features/Charting/components/LineDots";
import Line from "features/Charting/components/Line";
import useChartDimensions from "features/Charting/hooks/useChartDimensions";
import useTimeScale from "components/charting/hooks/useTimeScale";
import useChartData from "features/Charting/hooks/useChartData";
import { ChartLine, LinePointData } from "components/charting/types/Chart.types";
import useChartLinesCoordinatePlotter from "features/Charting/hooks/useChartLinesCoordinatePlotter";
import useChartYScales from "features/Charting/hooks/useChartYScales";
import NoChartData from "features/Charting/components/NoChartData";
import Loader20 from "components/Loader20";
import PbLine from "components/charting/annotations/PbLine";
import ChartScalesContext from "features/Charting/contexts/ChartScalesContext";
import useChartSelections from "components/charting/hooks/useChartSelections";
import useChartAnnotations from "components/charting/annotations/hooks/useChartAnnotations";
import LineChartAverageLine from "components/charting/LineChart/LineChartAverageLine";
import LineChartStandardDeviation from "components/charting/LineChart/LineChartStandardDeviation";
import ChartTooltipManagerProvider from "components/charting/providers/ChartTooltipManagerProvider";
import ChartTooltipCardProvider from "components/charting/providers/ChartTooltipCardProvider";
import TooltipCard from "components/charting/LineChart/TooltipCard";
import AxisRight from "features/Charting/components/AxisRight";
import OneRepMaxLine from "components/charting/annotations/OneRepMaxLine/OneRepMaxLine";
import useChartYScalesOptions from "features/Charting/hooks/useChartYScalesOptions";

function LineChart() {
  const selections = useChartSelections();
  const { grid } = useChartingColors();
  const chartLines = useChartData();
  const allLineData = useMemo(
    () => chartLines.reduce<LinePointData[]>((acc, cur) => [...acc, ...cur.line], []),
    [chartLines]
  );
  const dimensions = useChartDimensions();
  const xScale = useTimeScale(allLineData);
  const yScalesOptions = useChartYScalesOptions(selections);
  const yScales = useChartYScales(chartLines, { altMaxValue: yScalesOptions });
  const coordinatedChartLines: ChartLine[] = useChartLinesCoordinatePlotter(chartLines, xScale, yScales);
  const dataLines = coordinatedChartLines;

  const scales = useMemo(() => ({ x: xScale, y: yScales }), [xScale, yScales]);

  const annotations = useChartAnnotations(dataLines);

  if (!dataLines.length) return <NoChartData />;

  return (
    <ChartScalesContext.Provider value={scales}>
      <ChartTooltipCardProvider TooltipCard={TooltipCard}>
        <ChartTooltipManagerProvider>
          <Group left={dimensions.margin.left} top={dimensions.margin.top}>
            {Object.entries(yScales).map(([yScaleId, yScale], yScaleIndex) => (
              <React.Fragment key={yScaleIndex}>
                <GridRows
                  scale={yScale}
                  width={dimensions.inner.width}
                  height={dimensions.inner.height}
                  strokeWidth={1}
                  stroke={grid.line}
                  strokeDasharray="4,6"
                  strokeLinecap="round"
                />
                {yScaleIndex % 2 ? (
                  <AxisRight
                    scale={yScale}
                    scaleId={yScaleId}
                    left={yScaleIndex === 3 ? dimensions.inner.width + 24 + 48 + 24 : dimensions.inner.width + 24}
                  />
                ) : (
                  <AxisLeft showLabel scale={yScale} scaleId={yScaleId} left={yScaleIndex === 0 ? 0 : -72} />
                )}
              </React.Fragment>
            ))}
            <AxisBottom scale={xScale} top={dimensions.inner.height} />
            <LineChartStandardDeviation annotations={annotations} />
            <LineChartAverageLine annotations={annotations} />
            {dataLines.map((lineData, lineIndex) => {
              const showOneRepMax = selections.showOneRepMax && lineData.measure.metricField === "weight";

              return (
                <React.Fragment key={lineData.entityId + lineIndex}>
                  {selections.showPersonalBest && <PbLine {...lineData} />}
                  {showOneRepMax && <OneRepMaxLine {...lineData} />}
                  <Line {...lineData} />
                  <LineDots {...lineData} />
                </React.Fragment>
              );
            })}
          </Group>
        </ChartTooltipManagerProvider>
      </ChartTooltipCardProvider>
      <Loader20 disableBackdrop />
    </ChartScalesContext.Provider>
  );
}

export default LineChart;
