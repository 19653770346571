import LineChartIcon from "components/Icons/charts/LineChartIcon";
import MeasurementsTableIcon from "components/Icons/charts/MeasurementsTableIcon";
import ProfilingChartIcon from "components/Icons/charts/ProfilingChartIcon";
import SetComparisonChartIcon from "components/Icons/charts/SetComparisonChartIcon";
import Tables from "components/Tables";
import i18n from "config/i18n";

export enum DataVisualizationType {
  LINE_CHART = "LINE_CHART_V3",
  MEASUREMENTS_TABLE = "MEASUREMENTS_TABLE_V3",
  SET_COMPARISON_CHART = "SET_COMPARISON_CHART_V3",
  PROFILING_CHART = "PROFILING_CHART_V3",
  ONE_REP_MAX_CHART = "ONE_REP_MAX_CHART_V3",
}

export const dataVisualizationOptions = [
  {
    type: DataVisualizationType.LINE_CHART,
    icon: <LineChartIcon color="inherit" />,
    description: i18n.t("ChartTypes.lineChartDescription"),
  },
  {
    type: DataVisualizationType.MEASUREMENTS_TABLE,
    icon: <MeasurementsTableIcon color="inherit" />,
    description: i18n.t("ChartTypes.measurementsTableDescription"),
  },
  {
    type: DataVisualizationType.SET_COMPARISON_CHART,
    icon: <SetComparisonChartIcon color="inherit" />,
    description: i18n.t("ChartTypes.setComparisonDescription"),
  },
  {
    type: DataVisualizationType.PROFILING_CHART,
    icon: <ProfilingChartIcon color="inherit" />,
    description: i18n.t("ChartTypes.profilingChartDescription"),
  },
  {
    type: DataVisualizationType.ONE_REP_MAX_CHART,
    icon: <Tables.Icons.OneRepMaxNoOutline />,
    description: i18n.t("ChartTypes.1rmChartDescription"),
  },
];
