import moment from "moment";
import http from "../../axiosSetup";

class AthleteService {
  /**
   * @returns {Promise<import("types/athlete").Athlete[]>}
   */
  static async getAll() {
    return http.get("/athletes").then((response) => response.data);
  }

  /**
   * @param {string} athleteId
   * @returns {Promise<import("types/athlete").Athlete>}
   */
  static async getById(athleteId) {
    return http.get(`/athletes/${athleteId}`).then((response) => response.data);
  }

  /**
   * @param {Omit<import("types/athlete").Athlete, "id">} athlete
   * @returns {Promise<{ id: string }>}
   */
  static async create({ profilePhotoName, ...athlete }) {
    const result = await http.post("/athletes", athlete).then((response) => response.data);

    if (profilePhotoName) await AthleteService.setProfileImage(result.id, profilePhotoName);

    return result;
  }

  /**
   * @param {import("types/athlete").Athlete} athlete
   * @returns {Promise<{ id: string }>}
   */
  static async update({ profilePhotoName, ...athlete }) {
    const result = await http.put(`/athletes/${athlete.id}`, athlete).then((response) => response.data);

    if (typeof profilePhotoName !== "string") {
      // typeof 'string' infers a profile photo url
      // Thus the profile photo has not been updated
      await AthleteService.setProfileImage(athlete.id, profilePhotoName);
    }

    return result;
  }

  /**
   * @param {string} id
   * @param {: string | null | { content: string | ArrayBuffer; name: string; contentType: string }} profilePhoto
   * @returns {Promise<void>}
   */
  static async setProfileImage(id, profilePhoto) {
    if (!profilePhoto) {
      return http.delete(`/athletes/${id}/profile-image`).then((response) => response.data);
    }

    return http.patch(`/athletes/${id}/profile-image`, profilePhoto).then((response) => response.data);
  }

  /**
   * @param {string} athleteId
   * @returns {Promise<{ id: string }>}
   */
  static async delete(athleteId) {
    return http.delete(`/athletes/${athleteId}`).then((response) => response.data);
  }
  /**
   * @param {string} athleteId
   * @param {boolean} isArchived
   * @returns {Promise<unknown>}
   */
  static async archive(athleteId, isArchived) {
    return http.patch(`/athletes/${athleteId}/archived`, { isArchived }).then((response) => response.data);
  }

  static async resendInvite(id) {
    return http.post(`/athletes/${id}/send-invite`).then((response) => response.data);
  }

  static async getHeights(id, { page = undefined, size = undefined, startDate, endDate }) {
    return http
      .get(`/athletes/${id}/height`, { params: { page, size, startDate, endDate } })
      .then((response) => response.data);
  }

  static async getWeights(id, { page = undefined, size = undefined, startDate, endDate }) {
    return http
      .get(`/athletes/${id}/weight`, { params: { page, size, startDate, endDate } })
      .then((response) => response.data);
  }

  /**
   *
   * @param {string} athleteId
   * @param {*} params
   * @param {string} params.date
   * @param {string} params.id
   *
   * @returns {Promise<{
   *  id: string;
   *  date: string;
   * }>}
   */
  static async getPreviousHeightDate(athleteId, params) {
    return http
      .get(`/athletes/${athleteId}/height/previous-activity-date`, { params })
      .then((response) => response.data);
  }

  /**
   *
   * @param {string} athleteId
   * @param {*} params
   * @param {string} params.date
   * @param {string} params.id
   *
   * @returns {Promise<{
   *  id: string;
   *  date: string;
   * }>}
   */
  static async getPreviousWeightDate(athleteId, params) {
    return http
      .get(`/athletes/${athleteId}/weight/previous-activity-date`, { params })
      .then((response) => response.data);
  }

  /**
   * @param {string[]} emails
   * @returns {Promise<string[]>}
   */
  static async validateEmails(emails) {
    return http.post(`/athlete-upload/email-conflicts`, { emails }).then((response) => response.data.emailConflicts);
  }

  /**
   * @param {String[]} athleteIds
   * @param {String} ragStatusTemplateId
   *
   * @returns {Promise<{
   *   athleteId: string;
   *   ragStatuses: import("contexts/AthleteMonitoringStatusesContext/AthleteMonitoringStatusesContext.types").RagStatus[];
   * }[]>}
   */
  static async getTemplateByAthleteIds(ragStatusTemplateId, athleteIds) {
    return http.post("athletes/rag-status", { ragStatusTemplateId, athleteIds }).then((response) => response.data);
  }

  /**
   * @param {string} id
   * @param {{ startDate: string; endDate: string }} params
   * @returns {Promise<{
   * [date: string]: {
   *   "hasWellnessMeasurement": boolean;
   *   "hasExerciseMeasurement": boolean;
   * }
   * }>}
   * [date: string] label is in YYYY-MM-DD format
   *
   * @important
   * ! **This is a known issue and will be fixed (decided) in the future**
   * 
   * Backend receives the start date and adds 1 day to check if user has a wellness and exercise measurement,
   * if the timezone changes mid-month the backend will return the activity for the month in which the date is potentially in the past or future
   * To solve that issue we need to adjust the date to the timezone of the user and check if the UTC offset changes between the current day and the previous day.
   *
   * **We could potentially add a new endpoint to the backend to handle this by sending the timezone
   * of use something like:**
   * ```tsx
   *  function adjustDayForTimezoneChange(date) {
   *  const datetime = moment(date);

   *  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
   *  const year = moment(date).year();
   *  const month = moment(date).month();
   *  const day = moment(date).date();

   *  // Create moment objects for the given day and the day before
   *  const currentDay = moment.tz({ year, month, day }, timezone).utcOffset();
   *  const dayBefore = currentDay.clone().subtract(1, "days").utcOffset();

   *  // Check if the UTC offset changes between the current day and the day before
   *  if (currentDay > dayBefore) {
   *    datetime.add(1, "hour");
   *  } else if (currentDay < dayBefore) {
   *    datetime.subtract(1, "hour");
   *  }
   *  return datetime;
   * }
   *  ```
   */
  static async getActivity(id, params) {
    return http
      .get(`/measurements/athlete/${id}/activity`, { params })
      .then((response) => response.data)
      .then((data) =>
        data.reduce((acc, { date, ...rest }) => ({ ...acc, [moment(date).format("YYYY-MM-DD")]: rest }), {})
      );
  }

  /**
   * @param {string} athleteId
   * @param {{ endDate: string; startDate: string; inAscendingOrder?: boolean }} body
   * @param {{ page?: number; size?: number }} [params]
   * @returns {Promise<{
   *  athleteMeasurements: {
   *    athleteId: string;
   *    measurements: import("types").Wellness[];
   *  }
   * }>}
   */
  static async getAthleteWellness(athleteId, body, params) {
    return http
      .post("/measurements/athlete-wellness", { athleteId, ...body }, { params })
      .then((response) => response.data);
  }

  /**
   * @param {string} athleteId
   * @param {{ exerciseId: string }} params
   * @returns {Promise<import("types/personalbest").PersonalBest>}
   */
  static async getPersonalBest(athleteId, params) {
    return http.get(`/athletes/${athleteId}/personal-bests/${params.exerciseId}`).then((response) => response.data);
  }

  /**
   * @param {{ id: string, groupIds: string[] }[]} requestBody
   * @returns {Promise<void>}
   */
  static async bulkAssignGroups(requestBody) {
    return http.patch("/athletes/group-assignment-bulk", requestBody).then((response) => response.data);
  }
}
export default AthleteService;
