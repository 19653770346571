import useChartingColors from "features/Charting/hooks/useChartingColors";
import { ChartPoint } from "components/charting/types/Chart.types";
import useChartTooltipManager from "components/charting/hooks/useChartTooltipManager";
import useChartTooltipCard from "components/charting/hooks/useChartTooltipCard";
import { ProfilingChartEntityId } from "components/charting/types";
import useChartEntityColor from "./useChartEntityColor";

function LineDot(props: ChartPoint) {
  const styles = useChartingColors();
  const { variant, x, y } = props;
  const { open, onMouseEnter, onMouseLeave, onClick, ChartTooltipPortal } = useChartTooltipManager(props);
  const color = useChartEntityColor(props.entityId);

  const TooltipCard = useChartTooltipCard();

  const isProfilingChartLine = [
    String(ProfilingChartEntityId.PROFILING_LINE),
    String(ProfilingChartEntityId.COMPARATIVE_LINE),
  ].includes(props.entityId);

  const letter = Boolean(!isProfilingChartLine && variant) && variant?.charAt(0);

  const hasLetter = Boolean(letter);
  const dotRadius = isProfilingChartLine ? 3 : hasLetter ? 8 : 5;

  const radius = open ? dotRadius + 2 : dotRadius;

  const label = hasLetter && (
    <text
      fontFamily={styles.fontFamily}
      fill={styles.getContrastText(color)}
      fontSize={14}
      y={1}
      width={dotRadius * 2}
      fontWeight={600}
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {letter}
    </text>
  );

  return (
    <>
      <g transform={`translate(${x},${y})`}>
        <circle r={radius} fill={color} />
        {label}
        <circle
          r={radius}
          fill="transparent"
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          cursor={props.metadataType === "Exercise" ? "pointer" : undefined}
        />
      </g>
      <ChartTooltipPortal>
        <TooltipCard {...props} />
      </ChartTooltipPortal>
    </>
  );
}

export default LineDot;
