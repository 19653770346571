import Box from "@mui/material/Box";
import MessageWithLogo from "components/MessageWithLogo";
import ShowLeaderboardCreateButton from "features/Leaderboards/ShowLeaderboardCreateButton";
import { useTranslation } from "react-i18next";

interface NoLeaderboardsMessageProps {}

function NoLeaderboardsMessage(props: NoLeaderboardsMessageProps) {
  const { t } = useTranslation();

  return (
    <Box>
      <MessageWithLogo>{t("Leaderboard.noLeaderboardsMessage")}</MessageWithLogo>
      <Box pt={2} pb={16}>
        <ShowLeaderboardCreateButton />
      </Box>
    </Box>
  );
}

export default NoLeaderboardsMessage;
