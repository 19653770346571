import { GridProps } from "@mui/material/Grid";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import UnarchiveOutlined from "@mui/icons-material/UnarchiveOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AssignGroupsIcon from "./icons/AssignGroupsIcon";
import AssignAthletesIcon from "./icons/AssignAthletesIcon";
import AssignLabelsIcon from "./icons/AssignLabelsIcon";
import DeleteIcon from "./icons/DeleteIcon";
import EditIcon from "./icons/EditIcon";
import SearchIcon from "components/Icons/SearchIcon";
import OneRepMaxIcon from "./icons/OneRepMaxIcon";
import OneRepMaxIconNoOutlineIcon from "./icons/OneRepMaxNoOutlineIcon";
import SortIcon from "@mui/icons-material/Sort";
import * as Components from "./Tables.components";

const Icons = {
  AssignGroups: AssignGroupsIcon,
  AssignAthletes: AssignAthletesIcon,
  AssignLabels: AssignLabelsIcon,
  Delete: DeleteIcon,
  Edit: EditIcon,
  OneRepMax: OneRepMaxIcon,
  OneRepMaxNoOutline: OneRepMaxIconNoOutlineIcon,
  Filter: TuneOutlinedIcon,
  Label: LabelOutlinedIcon,
  Search: SearchIcon,
  Archive: ArchiveOutlinedIcon,
  Unarchive: UnarchiveOutlined,
  Visible: VisibilityOutlinedIcon,
  Sort: SortIcon,
  Hidden: VisibilityOffOutlinedIcon,
  Copy: ContentCopyOutlinedIcon,
  ContextMenu: MoreVertOutlinedIcon,
};

const controlCells: Record<"checkbox" | "standard" | "actions", GridProps> = {
  checkbox: { item: true, px: 1, width: 58 },
  standard: { item: true, pr: 1, width: 50 },
  actions: {
    item: true,
    width: 44 + "px" /* 5px scrollbar + 3px padding = theme.spacing(1) */,
    pr: "3px",
  },
};

const controlsContainerProps: GridProps = {
  container: true,
  alignItems: "center",
  minHeight: 64,
  mt: 0,
  flexWrap: "nowrap",
};

const headerContainerProps: GridProps = {
  container: true,
  component: Components.TableHeader,
  alignItems: "center",
  minHeight: 52,
  fontSize: 14, // controls all header font sizing
  pr: "5px",
  justifyContent: "space-between",
};

const rowContainerProps: GridProps = {};

const config = {
  Icons,
  controlCells,
  controlsContainerProps,
  headerContainerProps,
  rowContainerProps,
};

export default config;
