import Grid from "@mui/material/Grid";
import { useAthlete } from "hooks";
import AthleteName from "components/AthleteName";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import moment from "moment";

type TooltipCardHeaderProps =
  | {
      athleteId: string;
      category?: undefined;
      color: string;
      completedDate: string;
      name?: undefined;
    }
  | {
      athleteId?: undefined;
      category: string;
      color?: undefined;
      completedDate: string;
      name: string;
    };

function TooltipCardHeader(props: TooltipCardHeaderProps) {
  const athlete = useAthlete(props.athleteId);

  const gridTitleComponent = props.athleteId ? (
    <Grid item xs>
      <AthleteName athlete={athlete} id={props.athleteId} avatarColor={props.color} />
    </Grid>
  ) : (
    <Grid item xs>
      <Typography noWrap title={props?.category}>
        <Box component="span" color="grey.800" fontWeight="fontWeightNormal" fontSize={12}>
          {props?.category}
        </Box>
      </Typography>
      <Typography variant="h4" noWrap title={props.name}>
        {props.name}
      </Typography>
    </Grid>
  );

  return (
    <Grid container spacing={0} alignItems="center">
      {gridTitleComponent}
      {props.completedDate && (
        <Grid item>
          <Typography gutterBottom={false}>{moment(props.completedDate).format("DD MMM YYYY")}</Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default TooltipCardHeader;
