import { Workout } from "features/Workouts/Workouts.types";
import { useMemo } from "react";

const useIntersectingWorkoutLabels = (workouts: Workout[]): string[] => {
  return useMemo(() => {
    const labels = workouts.map((workout) => workout.labels);
    const commonLabels = labels.reduce((acc, curr) => acc.filter((label) => curr.includes(label)), labels[0] || []);
    return commonLabels;
  }, [workouts]);
};

export default useIntersectingWorkoutLabels;
