import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NotFoundSvg from "./NotFound.svg";
import * as Components from "./NotFound.components";
import Mui5 from "components/mui5";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

export interface NotFoundProps {
  buttonText?: string;
  link?: string;
  message?: string;
}

function NotFound(props: NotFoundProps) {
  const { t } = useTranslation();
  const { buttonText, link = "/", message } = props;

  return (
    <Box height="100vh" width="100%" display="flex" justifyContent="center" alignItems="center">
      <Stack>
        <Components.PaddedRow textAlign="center" height={96}>
          <img src={NotFoundSvg} alt="not found" />
        </Components.PaddedRow>
        <Components.PaddedRow textAlign="center" height={72}>
          <Typography variant="h1" fontSize={32}>
            <Box component="span" fontWeight={500} fontSize={32}>
              {t("NotFound.title")}
            </Box>
          </Typography>
        </Components.PaddedRow>
        <Components.PaddedRow textAlign="center" height={72}>
          <Typography variant="h1" fontSize={32}>
            <Box component="span" fontWeight={400} fontSize={24}>
              {message || t("NotFound.message")}
            </Box>
          </Typography>
        </Components.PaddedRow>
        <Components.PaddedRow textAlign="center" height={80} pt={2}>
          <Mui5.Button variant="contained" color="primary" href={link} fullWidth={false}>
            <Box px={6}>{buttonText || t("NotFound.buttonText")}</Box>
          </Mui5.Button>
        </Components.PaddedRow>
      </Stack>
    </Box>
  );
}

export default NotFound;
