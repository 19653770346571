import moment from "moment";
import { DateTime } from "types";
import { DashboardTimePeriod } from "types/Dashboard";

class TimeHelper {
  static getDiff(startTime?: DateTime, endTime?: DateTime) {
    if (!(startTime && endTime)) return null;

    const start = moment(startTime);
    const end = moment(endTime);
    const diff = moment.utc(end).diff(moment.utc(start));

    /**
     * @note
     * `HH:mm` is 24 hour time
     * `hh:mm` is 12 hour time
     */

    return moment.utc(diff).format("HH:mm");
  }

  static getRelativePeriodDates(
    relativePeriod: DashboardTimePeriod["relativePeriod"],
    params?: { endDate?: DateTime }
  ) {
    const duration = moment.duration(relativePeriod);

    if (!["P1D", "P7D", "P30D", "P90D", "P365D"].includes(`${relativePeriod}`)) {
      return null;
    }

    // Today end of Day
    const end = moment(params?.endDate).endOf("day");
    // Subtract duration from end of day
    const start = end.clone().subtract(duration, "day").startOf("day");

    return {
      start: start.toISOString(),
      end: end.toISOString(),
    };
  }

  static getDateRanges(relativePeriod: "P365D" | "P90D" | "P30D" | "P7D") {
    const { unit, count } = {
      P7D: { unit: "day" as const, count: 7 },
      P30D: { unit: "day" as const, count: 30 },
      P90D: { unit: "week" as const, count: 13 }, // 12.85 weeks in 90 days
      P365D: { unit: "month" as const, count: 12 },
    }[relativePeriod];

    // Set as start of the first period
    const firstPeriodStartDate = moment().add(1, unit).startOf(unit).subtract(count, unit);
    // Set as end of the first period
    const firstPeriodEndDate = firstPeriodStartDate.clone().endOf(unit);

    return new Array(count)
      .fill({
        startDate: firstPeriodStartDate.toISOString(),
        endDate: firstPeriodEndDate.toISOString(),
      })
      .map(({ startDate, endDate }: { startDate: string; endDate: string }, index) => {
        const isLast = index === count - 1;
        return {
          // .add(2, "hour").startOf("day") is to avoid DST issues
          startDate: moment(startDate).add(2, "hour").startOf("day").add(index, unit).toISOString(),
          endDate: (isLast
            ? moment().endOf("day")
            : // .subtract(2, "hour").endOf("day") is to avoid DST issues
              moment(endDate).subtract(2, "hour").endOf("day").add(index, unit).endOf(unit)
          ).toISOString(),
        };
      });
  }
}

export default TimeHelper;
