import Autocomplete, { AutocompleteProps, AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import Mui5 from "components/mui5";
import React, { useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItemCheckbox from "../MenuItemCheckbox";
import usePreferences from "hooks/usePreferences";
import moment from "moment";
import { MeasurementSet } from "types";
import { CheckboxProps, TextFieldProps } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import WeightHelpers from "helpers/WeightHelpers";

interface SetSelectorProps {
  value: MeasurementSet[];
  onChange(sets: MeasurementSet[]): void;
  options: MeasurementSet[];
  AutoCompleteProps?: Partial<AutocompleteProps<MeasurementSet, true, false, false>>;
  TextFieldProps?: Partial<TextFieldProps>;
  CheckboxProps?: Partial<Omit<CheckboxProps, "checked" | "size">>;
}

function SetSelector(props: SetSelectorProps) {
  const [preferences] = usePreferences();
  const { value, options, onChange } = props;
  const { t } = useTranslation();

  const handleChange = useCallback(
    (event: unknown, sets: MeasurementSet[]) => {
      onChange(sets);
    },
    [onChange]
  );

  const renderAutocompleteInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <Mui5.TextField
        {...params}
        sx={{ minWidth: 172 }}
        label={t("SetSelector.setsInputLabel")}
        fullWidth={false}
        size="small"
        color="primary"
        {...props?.TextFieldProps}
        InputProps={{
          ...params.InputProps,
          ...props?.TextFieldProps?.InputProps,
          endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
        }}
      />
    ),
    [props?.TextFieldProps, t]
  );

  const renderInputValue = useCallback((sets: MeasurementSet[]) => <Box ml={1}>{sets.length} Sets</Box>, []);

  const getOptionLabel = useCallback(
    (set: MeasurementSet) => {
      const weight = WeightHelpers.toReadable(set.weightValue, preferences.imperialSystemEnabled);

      return [
        t("SetSelector.setLegendSet", { set: set.setNumber }),
        t("SetSelector.setLegendReps", { count: set.repetitionCount, variant: set.variant }),
        weight && t("SetSelector.setLegendWeight", { weight }),
      ]
        .filter(Boolean)
        .join(" / ");
    },
    [preferences.imperialSystemEnabled, t]
  );

  const renderSetOption = useCallback(
    (optionProps: React.HTMLAttributes<HTMLLIElement>, option: MeasurementSet, { selected }: { selected: boolean }) => (
      <MenuItem {...optionProps} key={option.completedDate} sx={{ pl: "16px!important" }}>
        <MenuItemCheckbox checked={selected} {...props.CheckboxProps} />
        <Typography component="span" variant="body2">
          {getOptionLabel(option)}
        </Typography>
      </MenuItem>
    ),
    [getOptionLabel, props.CheckboxProps]
  );

  const groupBy = useCallback((option: MeasurementSet) => moment(option.completedDate).format("ddd, DD MMM YY"), []);

  return (
    <Autocomplete
      disablePortal
      multiple
      disableCloseOnSelect
      options={options}
      onChange={handleChange}
      value={value}
      getOptionLabel={getOptionLabel}
      groupBy={groupBy}
      renderInput={renderAutocompleteInput}
      renderTags={renderInputValue}
      renderOption={renderSetOption}
      componentsProps={{ popper: { placement: "bottom-end", style: { width: 240 } } }}
      {...props.AutoCompleteProps}
    />
  );
}

export default SetSelector;
