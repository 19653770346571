import { useTranslation } from "react-i18next";
import AthleteIcon from "components/Icons/AthletesIcon";
import InfoCard from "components/InfoCard";
import Grid from "@mui/material/Grid";
import InfoCardControlDescriptor from "components/InfoCard/InfoCardControlDescriptor";
import Tables from "components/Tables";
import Box from "@mui/material/Box";
import Mui5 from "components/mui5";
import RagStatusIndicator from "components/RagStatusIndicator";

export interface AthleteTableInfoCardProps {
  onClose(): void;
}

function AthleteTableInfoCard(props: AthleteTableInfoCardProps) {
  const { t } = useTranslation();

  return (
    <InfoCard title={t("AthleteTableInfoCard.title")} icon={<AthleteIcon />} onClose={props.onClose}>
      <Grid container columns={12} spacing={2}>
        <Grid item xs={12}>
          <InfoCardControlDescriptor
            title={t("AthleteTableInfoCard.organiseControlTitle1")}
            control={
              <>
                <Tables.Icons.Filter />
                <Box pr={1} display="inline-block" />
                <Tables.Icons.Sort />
              </>
            }
            label={t("AthleteTableInfoCard.organiseControlLabel1")}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoCardControlDescriptor
            title={t("AthleteTableInfoCard.organiseControlTitle2")}
            control={
              <>
                <Tables.Icons.OneRepMax />
                <Box pr={1} display="inline-block" />
                <Tables.Icons.AssignGroups />
              </>
            }
            label={t("AthleteTableInfoCard.organiseControlLabel2")}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoCardControlDescriptor
            title={t("AthleteTableInfoCard.organiseControlTitle3")}
            control={
              <>
                <Mui5.Button color="primary" disableElevation variant="contained" size="small">
                  {t("AthleteTableInfoCard.organiseControlButtonText3")}
                </Mui5.Button>
              </>
            }
            label={t("AthleteTableInfoCard.organiseControlLabel3")}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoCardControlDescriptor
            title={t("AthleteTableInfoCard.organiseControlTitle4")}
            control={
              <>
                <RagStatusIndicator values={[{ variant: null, progressArrow: 1 }]} />
              </>
            }
            label={t("AthleteTableInfoCard.organiseControlLabel4")}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoCardControlDescriptor
            title={t("AthleteTableInfoCard.organiseControlTitle5")}
            control={
              <>
                <Tables.Icons.Delete />
                <Box pr={1} display="inline-block" />
                <Tables.Icons.Archive />
              </>
            }
            label={t("AthleteTableInfoCard.organiseControlLabel5")}
          />
        </Grid>
      </Grid>
    </InfoCard>
  );
}

export default AthleteTableInfoCard;
