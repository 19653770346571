import React, { useCallback, useMemo } from "react";
import { Group } from "@visx/group";
import { GridRows } from "@visx/grid";
import useChartingColors from "features/Charting/hooks/useChartingColors";
import LineDots from "features/Charting/components/LineDots";
import Line from "features/Charting/components/Line";
import useChartDimensions from "features/Charting/hooks/useChartDimensions";
import { ChartLine, LineData, LinePointData } from "components/charting/types/Chart.types";
import Loader20 from "components/Loader20";
import ChartScalesContext from "features/Charting/contexts/ChartScalesContext";
import { scaleLinear } from "@visx/scale";
import useAthleteProfilingChartData from "./useAthleteProfilingChartData";
import max from "lodash/max";
import min from "lodash/min";
import { useUnitFormatter } from "hooks";
import { AxisLeft as VisxAxisLeft } from "@visx/axis";
import useChartSelections from "../hooks/useChartSelections";
import { AxisBottom as VisxAxisBottom } from "@visx/axis";
import ChartHelpers from "features/Charting/helpers/ChartHelpers";
import AthleteProfilingTooltipCard from "./AthleteProfilingTooltipCard";
import { weightMetric } from "./AthleteProfilingChart.config";
import NoDataWrapper from "components/NoDataWrapper";
import AthleteProfilingChartNoData from "./AthleteProfilingChartNoData";
import ChartTooltipCardProvider from "../providers/ChartTooltipCardProvider";
import ChartTooltipManagerProvider from "../providers/ChartTooltipManagerProvider";
import useAthleteProfilingChartAnnotations from "./useAthleteProfilingChartAnnotations";
import AthleteProfilingChartOneRepMaxEstimateLine from "./AthleteProfilingChartOneRepMaxEstimateLine";
import ExerciseHelpers from "helpers/ExerciseHelpers";

interface AthleteProfilingChartProps {}

function AthleteProfilingChart(props: AthleteProfilingChartProps) {
  const { grid, axis } = useChartingColors();
  const selections = useChartSelections();
  const chartLines: LineData[] = useAthleteProfilingChartData();

  const allLineData = useMemo(
    () => chartLines.reduce<LinePointData[]>((acc, cur) => [...acc, ...cur.line], []),
    [chartLines]
  );
  const dimensions = useChartDimensions();

  const xScale = useMemo(() => {
    const domainMin = min(allLineData.map((d): number => Number(d.x))) || 0;
    const domainMax = max(allLineData.map((d): number => Number(d.x))) || 0;
    return scaleLinear({
      range: [0, dimensions.inner.width],
      domain: [domainMin, domainMax],
      nice: true,
      round: true,
    });
  }, [allLineData, dimensions.inner.width]);

  const yScale = useMemo(() => {
    return scaleLinear({
      range: dimensions.ranges.y,
      domain: [min(allLineData.map((d): number => d.y)) || 0, max(allLineData.map((d): number => d.y)) || 0],
      nice: true,
    });
  }, [allLineData, dimensions.ranges.y]);

  const coordinatedChartLines: ChartLine[] = useMemo(
    () =>
      chartLines.map((lineData) => ({
        ...lineData,
        line: lineData.line
          .map((linePoint) => ({
            ...linePoint,
            x: xScale(linePoint.x),
            y: yScale(linePoint.y),
          }))
          .filter((linePoint) => isFinite(linePoint.x) && isFinite(linePoint.y)),
      })),
    [chartLines, xScale, yScale]
  );

  const dataLines = coordinatedChartLines;

  const annotations = useAthleteProfilingChartAnnotations(dataLines);

  const formatUnit = useUnitFormatter();
  const formatRepMetricValue = useCallback(
    (value: any) => formatUnit(value, selections.measures[0]?.repMetric),
    [formatUnit, selections.measures]
  );

  const showOneRepMaxEstimate = Boolean(
    selections.measures[0].repMetric?.field === "meanVelocity" &&
      selections.measures[0].type === "Exercise" &&
      ExerciseHelpers.isOneRepMaxExercise(selections.measures[0].metadata)
  );
  const formatWeightValue = useCallback((value: any) => formatUnit(value, weightMetric), [formatUnit]);

  const scales = useMemo(
    () => ({ x: xScale, y: { [ChartHelpers.getScaleId(selections.measures[0])]: yScale } }),
    [selections.measures, xScale, yScale]
  );

  return (
    <NoDataWrapper hasData={Boolean(dataLines.length)} fallback={<AthleteProfilingChartNoData />}>
      <ChartScalesContext.Provider value={scales}>
        <ChartTooltipCardProvider TooltipCard={AthleteProfilingTooltipCard}>
          <ChartTooltipManagerProvider>
            <Group left={dimensions.margin.left} top={dimensions.margin.top}>
              <GridRows
                scale={yScale}
                width={dimensions.inner.width}
                height={dimensions.inner.height}
                strokeWidth={1}
                stroke={grid.line}
                strokeDasharray="4,6"
                strokeLinecap="round"
              />
              <VisxAxisLeft
                hideAxisLine
                hideTicks
                left={-24}
                scale={yScale}
                labelOffset={26}
                labelProps={{ fill: axis.text }}
                tickFormat={formatRepMetricValue}
                tickLabelProps={() => ({
                  fill: axis.line,
                  color: axis.line,
                  fontSize: 12,
                  textAnchor: "end",
                  dy: "0.3em",
                  dx: "-0.4em",
                })}
                stroke={axis.line}
                tickStroke={axis.line}
                tickLength={2}
                hideZero
              />
              <VisxAxisBottom
                left={0}
                top={dimensions.inner.height}
                scale={xScale}
                stroke={axis.line}
                tickStroke={axis.line}
                tickFormat={formatWeightValue}
                tickLabelProps={() => ({
                  fill: axis.line,
                  fontSize: 12,
                  textAnchor: "middle",
                })}
                strokeWidth={1}
              />
              {dataLines.map((lineData, lineIndex) => (
                <React.Fragment key={lineData.entityId + lineIndex}>
                  <Line {...lineData} />
                  <LineDots {...lineData} />
                </React.Fragment>
              ))}
              {showOneRepMaxEstimate && (
                <AthleteProfilingChartOneRepMaxEstimateLine
                  entityId={dataLines[0] ? dataLines[0].entityId : ""}
                  estimatedOneRepMax={annotations.estimatedOneRepMax}
                  minimumVelocityThreshold={annotations.minimumVelocityThreshold}
                  xScale={xScale}
                  yScale={yScale}
                />
              )}
            </Group>
          </ChartTooltipManagerProvider>
        </ChartTooltipCardProvider>
        <Loader20 disableBackdrop />
      </ChartScalesContext.Provider>
    </NoDataWrapper>
  );
}

export default AthleteProfilingChart;
