import { buttonClasses, styled } from "@mui/material";
import Mui5 from "components/mui5";

export const SortButton = styled(Mui5.Button, { shouldForwardProp: (key: string) => key !== "showSort" })<{
  showSort: boolean;
}>((props) => ({
  minWidth: 0,
  lineHeight: 0,
  fontSize: "inherit",
  [`&:hover .${buttonClasses.startIcon}, &:hover .${buttonClasses.endIcon}`]: {
    opacity: 1,
  },
  [`& .${buttonClasses.startIcon}`]: {
    opacity: props.showSort ? 1 : 0,
    marginRight: 0,
  },
  [`& .${buttonClasses.endIcon}`]: {
    opacity: props.showSort ? 1 : 0,
    marginLeft: 0,
  },
}));
